// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import addons
import { Spacer } from './Spacer'

// Import Altheim Interface
import { Cards } from './Alts/cards'
import { Dash } from './Alts/dash'
import { PProfile } from './Alts/pprofile'
import { Profile } from './Alts/profile'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export function Altheim (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    const params = props.params

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={2} />
        
        {
            // #region ====[ Layout: Cards ]=====================================================================
            (props.type === "cards" &&
                <>
                    {
                        <Cards primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} params={params} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                    }                    
                </>
            )
            // #endregion ====[ Layout: Cards ]==================================================================
            
            ||

            // #region ====[ Layout: Dashboard ]=================================================================
            (props.type === "dash" &&
                <>
                    {
                        <Dash primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} params={params} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                    }                    
                </>
            )
            // #endregion ====[ Layout: Cards ]==================================================================

            ||

            // #region ====[ Layout: Profile ]===================================================================
            (props.type === "profile" &&
                <>
                    {
                        <Profile primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} params={params} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                    }
                </>
            )            
            // #endregion ====[ Layout: Public Profile ]=========================================================

            ||

            // #region ====[ Layout: Public Profile ]============================================================
            (props.type === "pprofile" &&
                <>
                    {
                        <PProfile primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} params={params} id={props.id} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                    }
                </>
            )            
            // #endregion ====[ Layout: Public Profile ]=========================================================

        } 
        </>
    )
}