// Import from React
import { useEffect } from "react"

// Import from Toastify
import { toast } from 'react-toastify'

// Import from React DOM
import { useNavigate } from "react-router-dom"

const useGlobalToastNotifications = (props) => {
    const navigate = useNavigate()
    const {
        resourcesAuth,
        resourcesCategory,
        resourcesCode,
        resourcesNavigationitem,
        resourcesPost,
        resourcesSetting,
        resourcesTag
    } = props.resources

    const handleToast = (type, message) => {
        if (type === 'error') {
            toast.error(message)
            resourcesAuth.setShake(true)
            setTimeout(() => {
                resourcesAuth.setShake(false)
            }, 500)
        } else if (type === 'success') {
            toast.success(message)
        }
    }

    const processToast = (toasts, conditions) => {
        if (!toasts) return
        Array.from(toasts).forEach(ele => {
            let showToast = true

            // Add messages from backend that you don't want to be displayed here
            if (ele.message?.includes('Successfully Retrieved')) {
                showToast = false
            } else if (ele.message?.includes('Successfully Checked License')) {
                showToast = false
            } else if (ele.message?.includes('Successfully found license')) {
                showToast = false
            }

            conditions.forEach(condition => {
                if (showToast && condition.check()) {
                    handleToast(ele.type, ele.message)
                    condition.action()
                    condition.clearErrors()
                }
            })
        })
    }

    const authConditions = [
        {
            check: () => resourcesAuth.AccountProfileImageAdding,
            action: () => {
                resourcesAuth.setAccountProfileImageAdding(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.AccountHeaderImageAdding,
            action: () => {
                resourcesAuth.setAccountHeaderImageAdding(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.AccountUpdatingSent,
            action: () => {
                resourcesAuth.setAccountUpdatingSent(false)
                resourcesAuth.setAccountUpdating(false)
                resourcesAuth.setAccountEditing(true)
                resourcesAuth.setAccountFormIsDisabled(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.AccountUpdatingSentSuccess,
            action: () => {
                resourcesAuth.setAccountUpdatingSentSuccess(false)
                resourcesAuth.setAccountUpdating(false)
                resourcesAuth.setAccountEditing(false)
                resourcesAuth.setAccountFormIsDisabled(true)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isGivingToUser,
            action: () => {
                resourcesAuth.setIsGivingToUser(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isLoggingIn,
            action: () => {
                resourcesAuth.setIsLoggingIn(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isForgettingPassword,
            action: () => {
                resourcesAuth.setIsForgettingPassword(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isForgettingPasswordSuccess,
            action: () => {                
                resourcesAuth.setIsForgettingPasswordSuccess(false)
                setTimeout(() => {
                    navigate('../resetpassword')
                }, 3000)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isResettingPassword,
            action: () => {
                resourcesAuth.setIsResettingPassword(false)                
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isResettingPasswordSuccess,
            action: () => {
                resourcesAuth.setIsResettingPasswordSuccess(false)
                setTimeout(() => {
                    navigate('../login')
                }, 3000)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isSendingVC,
            action: () => {
                resourcesAuth.setIsSendingVC(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isSendingVCSuccess,
            action: () => {
                resourcesAuth.setIsSendingVCSuccess(false)
                setTimeout(() => {
                    navigate('../verifyemail')
                }, 3000)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isSettingFollow,
            action: () => {
                resourcesAuth.setIsSettingFollow(false)
                resourcesAuth.setIsSettingUnfollow(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isSettingUnfollow,
            action: () => {
                resourcesAuth.setIsSettingFollow(false)
                resourcesAuth.setIsSettingUnfollow(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.RegisterFormSent,
            action: () => {
                resourcesAuth.setAccountFormIsDisabled(false)
                resourcesAuth.setIsRegistering(false)
                resourcesAuth.setRegisterFormSent(false)
                resourcesAuth.setRegDone(false)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.RegisterFormSentSuccess,
            action: () => {
                resourcesAuth.setIsRegistering(false)
                resourcesAuth.setRegisterFormSent(false)
                resourcesAuth.setRegDone(false)
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isVerifyingEmail,
            action: () => {
                resourcesAuth.setIsVerifyingEmail(false)                
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
        {
            check: () => resourcesAuth.isVerifyingEmailSuccess,
            action: () => {
                resourcesAuth.setIsVerifyingEmailSuccess(false)
                setTimeout(() => {
                    navigate('../login')
                }, 3000)
            },
            clearErrors: resourcesAuth.clearErrorsAuth
        },
    ]

    const categoryConditions = [
        {
            check: () => resourcesCategory.isCreatingSliceSent,
            action: () => {
                resourcesCategory.setIsCreatingSlice(false)
                resourcesCategory.setIsCreatingSliceSent(false)
                setTimeout(() => {
                    resourcesCategory.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
        {
            check: () => resourcesCategory.isCreatingSliceSentSuccess,
            action: () => {
                resourcesCategory.setIsCreatingSliceSentSuccess(false)
                setTimeout(() => {
                    navigate('/dashboard/categories/'+resourcesCategory.currentSlice.slug)
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
        {
            check: () => resourcesCategory.isUpdatingSliceSent,
            action: () => {
                resourcesCategory.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesCategory.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
        {
            check: () => resourcesCategory.isUpdatingSliceSentSuccess,
            action: () => {
                resourcesCategory.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesCategory.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
        {
            check: () => resourcesCategory.SliceIsMovingToTrash,
            action: () => {
                resourcesCategory.setSliceIsMovingToTrash(false)
                setTimeout(() => {
                    navigate('/dashboard/categories')
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
        {
            check: () => resourcesCategory.isRestoringSlice,
            action: () => {
                resourcesCategory.setIsRestoringSlice(false)
                setTimeout(() => {
                    navigate('/dashboard/categories')
                }, 2000)
            },
            clearErrors: resourcesCategory.clearErrorsCategory
        },
    ]

    const codeConditions = [
        {
            check: () => resourcesCode.isCreatingSliceSent,
            action: () => {
                resourcesCode.setIsCreatingSlice(false)
                resourcesCode.setIsCreatingSliceSent(false)
                setTimeout(() => {
                    resourcesCode.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCode.clearErrorsCode
        },
        {
            check: () => resourcesCode.isCreatingSliceSentSuccess,
            action: () => {
                resourcesCode.setIsCreatingSliceSentSuccess(false)
                setTimeout(() => {
                    navigate('/dashboard/codes/'+resourcesCode.currentSlice.slug)
                }, 2000)
            },
            clearErrors: resourcesCode.clearErrorsCode
        },
        {
            check: () => resourcesCode.isUpdatingSliceSent,
            action: () => {
                resourcesCode.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesCode.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCode.clearErrorsCode
        },
        {
            check: () => resourcesCode.isUpdatingSliceSentSuccess,
            action: () => {
                resourcesCode.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesCode.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesCode.clearErrorsCode
        },
    ]

    const navigationConditions = [
        {
            check: () => resourcesNavigationitem.isCreatingNavigationitemSent,
            action: () => {
                resourcesNavigationitem.setIsCreatingNavigationitemSent(false)
            },
            clearErrors: resourcesNavigationitem.clearErrorsNavigationitem
        },
        {
            check: () => resourcesNavigationitem.isDeletingNavigationitemSent,
            action: () => {
                resourcesNavigationitem.setIsDeletingNavigationitemSent(false)
            },
            clearErrors: resourcesNavigationitem.clearErrorsNavigationitem
        },
        {
            check: () => resourcesNavigationitem.isUpdatingNavigationitemSent,
            action: () => {
                resourcesNavigationitem.setIsUpdatingNavigationitemSent(false)
            },
            clearErrors: resourcesNavigationitem.clearErrorsNavigationitem
        }
    ]

    const postConditions = [
        {
            check: () => resourcesPost.isCreatingSliceSent,
            action: () => {
                resourcesPost.setIsCreatingSlice(false)
                resourcesPost.setIsCreatingSliceSent(false)
                setTimeout(() => {
                    resourcesPost.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesPost.clearErrorsPost
        },
        {
            check: () => resourcesPost.isCreatingSliceSentSuccess,
            action: () => {
                resourcesPost.setIsCreatingSliceSentSuccess(false)
                setTimeout(() => {
                    navigate('/dashboard/posts/'+resourcesPost.currentSlice.slug)
                }, 2000)
            },
            clearErrors: resourcesPost.clearErrorsPost
        },
        {
            check: () => resourcesPost.isUpdatingSliceSent,
            action: () => {
                resourcesPost.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesPost.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesPost.clearErrorsPost
        },
        {
            check: () => resourcesPost.isUpdatingSliceSentSuccess,
            action: () => {
                resourcesPost.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesPost.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesPost.clearErrorsPost
        },
    ]

    const settingConditions = [
        {
            check: () => resourcesSetting.isUpdatingSettingSent,
            action: () => {
                resourcesSetting.setIsUpdatingSettingSent(false)
                setTimeout(() => {
                    
                }, 2000)
            },
            clearErrors: resourcesSetting.clearErrorsSetting
        },
        {
            check: () => resourcesSetting.isUpdatingSettingSentSuccess,
            action: () => {
                resourcesSetting.setIsUpdatingSettingSent(false)
                resourcesSetting.setIsUpdatingSettingSentSuccess(false)
                setTimeout(() => {
                    
                }, 2000)
            },
            clearErrors: resourcesSetting.clearErrorsSetting
        }
    ]

    const tagConditions = [
        {
            check: () => resourcesTag.isCreatingSliceSent,
            action: () => {
                resourcesTag.setIsCreatingSlice(false)
                resourcesTag.setIsCreatingSliceSent(false)
                setTimeout(() => {
                    resourcesTag.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isCreatingSliceSentSuccess,
            action: () => {
                resourcesTag.setIsCreatingSliceSentSuccess(false)
                setTimeout(() => {
                    navigate('/dashboard/tags/'+resourcesTag.currentSlice.slug)
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isPermDeletingSliceSent,
            action: () => {
                resourcesTag.setIsPermDeletingSlice(false)
                resourcesTag.setIsPermDeletingSliceSent(false)
                setTimeout(() => {
                    
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isPermDeletingSliceSentSuccess,
            action: () => {
                resourcesTag.setIsPermDeletingSlice(false)
                resourcesTag.setIsPermDeletingSliceSent(false)
                resourcesTag.setIsPermDeletingSliceSentSuccess(false)
                setTimeout(() => {
                    navigate('/dashboard/tags')
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isRestoringSlice,
            action: () => {
                resourcesTag.setIsRestoringSlice(false)
                setTimeout(() => {
                    navigate('/dashboard/tags')
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isUpdatingSliceSent,
            action: () => {
                resourcesTag.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesTag.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.isUpdatingSliceSentSuccess,
            action: () => {
                resourcesTag.setIsUpdatingSliceSent(false)
                setTimeout(() => {
                    resourcesTag.setSliceFormIsDisabled(false)
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
        {
            check: () => resourcesTag.SliceIsMovingToTrash,
            action: () => {
                resourcesTag.setSliceIsMovingToTrash(false)
                setTimeout(() => {
                    navigate('/dashboard/tags')
                }, 2000)
            },
            clearErrors: resourcesTag.clearErrorsTag
        },
    ]

    useEffect(() => {
        processToast(resourcesAuth.toastsAuth, authConditions)
        processToast(resourcesCategory.toastsCategory, categoryConditions)
        processToast(resourcesCode.toastsCode, codeConditions)
        processToast(resourcesNavigationitem.toastsNavigationitem, navigationConditions)
        processToast(resourcesPost.toastsPost, postConditions)
        processToast(resourcesTag.toastsTag, tagConditions)
        processToast(resourcesSetting.toastsSetting, settingConditions)

        // Clean-up function to avoid memory leaks
        return () => {
            // Perform any necessary clean-up here
        }

    // eslint-disable-next-line 
    }, [
        navigate,
        resourcesAuth,
        resourcesAuth.clearErrorsAuth,        
        resourcesAuth.setAccountEditing,
        resourcesAuth.setAccountProfileImageAdding,
        resourcesAuth.setAccountUpdating,
        resourcesAuth.setAccountUpdatingSent,
        resourcesAuth.AccountUpdatingSentSuccess,
        resourcesAuth.setAccountFormIsDisabled,
        resourcesAuth.setIsForgettingPassword,
        resourcesAuth.setIsForgettingPasswordSuccess,
        resourcesAuth.setIsGivingToUser,
        resourcesAuth.setIsLoggingIn,
        resourcesAuth.setIsRegistering,
        resourcesAuth.setIsResettingPassword,
        resourcesAuth.setIsResettingPasswordSuccess,
        resourcesAuth.setIsSendingVC,
        resourcesAuth.setIsSettingFollow,
        resourcesAuth.setIsSettingUnfollow,
        resourcesAuth.setIsVerifyingEmail,
        resourcesAuth.setIsVerifyingEmailSuccess,
        resourcesAuth.setRegDone,
        resourcesAuth.setRegisterFormSent,
        resourcesAuth.RegisterFormSentSuccess,
        resourcesAuth.clearErrorsAuth,
        resourcesAuth.isForgettingPassword,
        resourcesAuth.isForgettingPasswordSuccess,
        resourcesAuth.isGivingToUser,
        resourcesAuth.isLoggingIn,
        resourcesAuth.isRegistering,
        resourcesAuth.isResettingPassword,
        resourcesAuth.isResettingPasswordSuccess,
        resourcesAuth.isSendingVC,
        resourcesAuth.isSendingVCSuccess,
        resourcesAuth.isSettingFollow,
        resourcesAuth.isSettingUnfollow,
        resourcesAuth.isVerifyingEmail,
        resourcesAuth.isVerifyingEmailSuccess,
        resourcesAuth.RegisterFormSent, 
        resourcesAuth.toastsAuth,
        //
        resourcesCategory,
        resourcesCategory.clearErrorsCategory,
        resourcesCategory.isCreatingSlice,
        resourcesCategory.isCreatingNavigationitemSent,
        resourcesCategory.isCreatingNavigationitemSentSuccess,
        resourcesCategory.isRestoringSlice,
        resourcesCategory.isUpdatingSliceSent,
        resourcesCategory.isUpdatingSliceSentSuccess,
        resourcesCategory.setIsRestoringSlice,
        resourcesCategory.setIsUpdatingSlice,
        resourcesCategory.setIsUpdatingNavigationitemSent,
        resourcesCategory.setSliceFormIsDisabled,
        resourcesCategory.setSliceIsMovingToTrash,
        resourcesCategory.SliceIsMovingToTrash,
        resourcesCategory.toastsCategory,                  
        //  
        resourcesCode,
        resourcesCode.clearErrorsPost,        
        resourcesCode.isCreatingSlice,
        resourcesCode.isCreatingSliceSent,
        resourcesCode.isCreatingSliceSentSuccess,
        resourcesCode.isUpdatingSlice,
        resourcesCode.isUpdatingSliceSent,
        resourcesCode.isUpdatingSliceSentSuccess,
        resourcesCode.setIsUpdatingSlice,
        resourcesCode.setIsUpdatingSliceSent,
        resourcesCode.setIsUpdatingSliceSuccess,
        resourcesCode.setSliceFormIsDisabled,        
        resourcesCode.toastsCode,          
        //   
        resourcesNavigationitem,
        resourcesNavigationitem.toastsNavigationitem,
        resourcesNavigationitem.isCreatingNavigationitemSent,
        resourcesNavigationitem.isDeletingNavigationitemSent,
        resourcesNavigationitem.isUpdatingNavigationitemSent,
        resourcesNavigationitem.clearErrorsNavigationitem,               
        //
        resourcesPost,
        resourcesPost.clearErrorsPost,
        resourcesPost.isCreatingSlice,
        resourcesPost.isCreatingSliceSent,
        resourcesPost.isCreatingSliceSentSuccess,
        resourcesPost.setIsUpdatingSlice,
        resourcesPost.setIsUpdatingNavigationitemSent,
        resourcesPost.setSliceFormIsDisabled,
        resourcesPost.isUpdatingSliceSent,
        resourcesPost.isUpdatingSliceSentSuccess,
        //  
        resourcesSetting,
        resourcesSetting.clearErrorsSetting,
        resourcesSetting.toastsSetting,  
        resourcesSetting.isUpdatingSettingSent,
        resourcesSetting.isUpdatingSettingSentSuccess,
        resourcesSetting.setIsUpdatingSettingSent,
        resourcesSetting.setIsUpdatingSettingSentSuccess,
        //
        resourcesTag,
        resourcesTag.clearErrorsTag,
        resourcesTag.isCreatingSlice,
        resourcesTag.isCreatingSliceSent,
        resourcesTag.isCreatingSliceSentSuccess,
        resourcesTag.isPermDeletingSlice,
        resourcesTag.isPermDeletingSliceSent,
        resourcesTag.isPermDeletingSliceSentSuccess,
        resourcesTag.isRestoringSlice,
        resourcesTag.isUpdatingSliceSent,
        resourcesTag.isUpdatingSliceSentSuccess,  
        resourcesTag.setIsPermDeletingSlice,      
        resourcesTag.setIsPermDeletingSliceSentSuccess,
        resourcesTag.setIsRestoringSlice,
        resourcesTag.setIsUpdatingSlice,
        resourcesTag.setIsUpdatingSliceSent,
        resourcesTag.setIsUpdatingSliceSentSuccess,
        resourcesTag.setSliceFormIsDisabled,
        resourcesTag.toastsTag,
        //   
    ])
}

export default useGlobalToastNotifications