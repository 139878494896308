export const step = {
    icon: {
        base: {
            background: "transparent",
            color: "black",
            colorHighlight: "violet",
            colorText: "violet",
            colorTextHighlight: "black"
        }
    },
    label: {
        base: {
            background: "transparent",
            color: "black",
            colorHighlight: "purple"
        }
    },
}