// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Auth
import { useSetting, useAPIEndpoints } from '../../middleware/contextHooks'

// Import from MUI
import {
    //Container,
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { CallToAction } from '../../components/Addon/CallToAction'
//import { Singlet } from '../../components/Addon/Singlet'
import RichDataGrid from '../../components/RichDataGrid'

// Import addons
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { Spacer } from '../../components/Addon/Spacer'
import { Singlet } from '../../components/Addon/Singlet'
//import { RichDataTable } from '../../components/Addon/RichDataTable'

// Import utils
import { settingLookup } from '../../middleware/utils'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function API (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        //clearErrors, 
        //currentUser, 
        //getProfile, 
        //getGuestProfile, 
        //getPublicProfile, 
        //isAuthenticated,
        //toasts
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    //const [ sliceItemDisplay, setSliceItemDisplay ] = useState(null)
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    
    // Bring from context
    const {       
        // Variables
        apiendpoints,
        
        // Functions
        getAllAPIEndpointsPublically,        
    } = useAPIEndpoints()

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Home',
            'type': 'link',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'API',
            'type': 'text',
            'grayed': true,
            'url': ''
        },
    ]

    const columns = [
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 120,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'slug', 
            headerName: 'Slug', 
            width: 450,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'endpointstarter', 
            headerName: 'Endpoint Starter', 
            width: 250,
            //renderCell: (params) =>
                //params.row.type
        },
        { 
            field: 'endpointsection', 
            headerName: 'Endpoint Section', 
            width: 200,
            //renderCell: (params) =>
                //params.row.status
        },
        { 
            field: 'endpointender', 
            headerName: 'Endpoint Ender',         
            width: 300,
            //renderCell: (params) => 
                //params.row.namespace
        },
        { 
            field: 'completeendpoint', 
            headerName: 'Complete Endpoint', 
            width: 400,
            align: 'left',
            //renderCell: (params) =>
                //params.row.visibility // WIP: Need to update this how the views are
        },
        { 
            field: 'description', 
            headerName: 'Description',         
            width: 400,            
        },
        { 
            field: 'permission', 
            headerName: 'Permission', 
            width: 150
        },
        { 
            field: 'functionName', 
            headerName: 'Function', 
            width: 300,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        /*
        { 
            field: 'other1', 
            headerName: 'Other 1', 
            width: 140,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'other2', 
            headerName: 'Other 2', 
            width: 140,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'other3', 
            headerName: 'Other 3', 
            width: 140,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'other4', 
            headerName: 'Other 4', 
            width: 140,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'other5', 
            headerName: 'Other 5', 
            width: 140,
            rowHeight: 200,
            //renderCell: (params) =>  
                //<props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        } 
        */
    ]

    // Handle createNew
    const handleCreateNew = () => {
        //alert('Creating new '+query)
        toast.info(`Permission denied: You cannot create new API endpoints...yet?`, {theme: "dark"})
    }

    const handleUnknownFunction = (func) => {
        if (func) {
            toast.info(`Did I stutter? I said DO NOT click me`, {theme: "dark"})
        } else {
            toast.info(`I lost the game`, {theme: "dark"})
        }
    }

    //const apiendpoints = [
        //{ _id: 1, status: 'enabled', endpointstarter: '/api/v1/', endpointsection: "anypost", endpointender: "/", completeendpoint: "/api/v1/anypost", description: "Get all posts", permission: "Admin", function: "getAllPosts()" },
    //]
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    // State management for loading API Endpoints
    useEffect(() => {
        // Get all API Endpoints
        // dont know if needed as it's managed by state context; if there is a "Maximum Depth error", it's required
        if (!apiendpoints) {
            
        }
        
        getAllAPIEndpointsPublically("apiendpoints", null, 'global', 'any', 'completeendpoint', 'ASC')

        //console.log(apiendpoints?.length)
    }, [ getAllAPIEndpointsPublically, apiendpoints ])
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    const isPermitted = true

    const {
        variables
    } = useSetting()
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={4} />
        <props.skeleton.CustomStack spacing={0}>
            <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
            {
                isPermitted ?
                (
                    <>
                    <CallToAction skeleton={props.skeleton}     
                        abovebutton={10}
                        belowbutton={5}
                        abovetext={7}
                        belowtext={7}
                        bodytext={'The complete Application Protocol Interface (API) for this ecosystem and everything you need to know how to use it.'}
                        bodytextalign={'left'}
                        buttontext={'Jump to Book [WIP]'}
                        buttoncolor={'primary'} // options: primary, secondary, tertiary, quarternary
                        buttonurl={'#book'}
                        color={'primary'} // options: primary, secondary, tertiary, quarternary
                        padding={1}
                        primarytext={`${settingLookup('SiteTitle', variables)} API Book`}
                        primarytextalign={'left'}
                        secondarytext={'...where the magic happens.'}
                        secondarytextalign={'left'}
                    />

                    <Singlet skeleton={props.skeleton} 
                        color={'secondary'} // options: primary, secondary, tertiary, quarternary
                        content={`
                            This page contains all of what is known as Application Protocol Interface (API) or what we in the magic-creating-community call "endpoints" that can be used to access specific parts of the system's code without us magicians exposing pieces of the code in order to avoid security vulnerabilities.
                            
                            <br /><br />
                            
                            <b>What can I do with this?</b>
                            <br />
                            Anything you want!
                            
                            <br /><br />
                            
                            <b>How many "endpoints" are there?</b>
                            <br />
                            Theoretically, infinitely many, but in the current state, there are <b>${apiendpoints?.length} endpoints</b>!
                            
                            <br /><br />
                            
                            <b>How do I use them?</b>
                            <br />
                            First, decide what you want to do. Next, look for the particular endpoint (utilize the description column) and copy the "Complete Endpoint" and use it somewhere.

                            <br /><br />
                            
                            <b>What's the point?</b>
                            <br />
                            I don't think you get what this is and how powerful it is. You can technically use ${settingLookup('SiteTitle', variables)} without accessing it using a browser via every endpoint - whether it's logging in, creating a post, or updating something. The API can be accessed via a web browser or using a tool like <a href="https://www.postman.com/" target="_blank">Postman</a> or <a href="https://www.thunderclient.com/" target="_blank">Thunder Client</a> 

                            <br /><br />
                            <b>Not tech-savvy enough?</b>
                            <br />
                            Don't worry! In time, with learning, you too will learn how to use this.

                            <br /><br />
                            <b>Fun fact</b>
                            <br />
                            The very fact that this system runs on an API allows the ability of "interconnectivity" between ${settingLookup('SiteTitle', variables)} and other networks using the same framework.

                            <br /><br />
                            <b>WORD OF WARNING</b>
                            <br />
                            DO <b>NOT</b> click the button that says "Don't click me". It might unravel this system. We warned you.
                        `}
                        padding={'50px 0px 50px 0px'} // Top Right Bottom Left
                        textalign={'left'}
                        title={'What is this page?'}
                    />  

                    <section id="book">
                        <props.skeleton.CustomGrid container spacing={0}>
                            <props.skeleton.CustomGrid item xs={12} sx={{ border: 'thin solid black' }}>
                                {/* #region -----[ RichTable - Toolbar ]------------------------------------------------------------------ */}
                                <props.skeleton.CustomGrid container richtablenougat="true" richtabletopbar="true" sx={{ }}>
                                {/* #region -----[ NOUGAT ]--------------------------------------------------------------------------------*/}
                                {/* #region ----[ Left Column ]--------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={9} lg={9} alignItems={'center'}>
                                        <props.skeleton.CustomGrid container>
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={2} lg={2}>
                                                <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleCreateNew()}>Create New</props.skeleton.Bar>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}

                                {/* #region ----[ Right Column ]-------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} justifyContent={'flex-end'}>
                                        <props.skeleton.CustomGrid container spacing={0}>
                                            <>
                                            {/* #region -----[ Non-Multi-type Section]-------------------------------------------- */}
                                            {/* #region ----[ Button - Unknown ] ------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={6} lg={6} alignItems={'center'}>
                                                <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => (handleUnknownFunction())}>Click Me</props.skeleton.Bar>
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ----------------------------------------------------------------------- */}                                                            
                                            {/* #region ----[ Button - Filter ]--------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={6} lg={6} justifyContent={'flex-end'}>
                                                {
                                                    <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => (handleUnknownFunction('dontclick'))}>Don't click me</props.skeleton.Bar>
                                                }                                                
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ----------------------------------------------------------------------- */}
                                            {/* #endregion ----------------------------------------------------------------------- */}
                                            </>
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                                
                                {/* #region -----[ RichTable - Contents ]----------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid container spacing={0} richtable="true" sx={{ }}>
                                    <props.skeleton.CustomGrid item xs={12}>
                                    {/* #region -----[ NOUGAT ]----------------------------------------------------------------------------*/}                                                                                   
                                    <props.skeleton.CustomGrid container richtablenougat="true" sx={{  }}>
                                        {/* #region ----[ Full Column ]------------------------------------------------------------------- */}
                                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12}>
                                            <props.skeleton.CustomBox 
                                                sx={{ 
                                                    minHeight: 'auto', // 79.25vh
                                                    maxHeight: '79.25vh',
                                                    height: '100%',                                         
                                                }}
                                            >        
                                            {
                                                // Check for apiendpoints not null
                                                apiendpoints && (
                                                    <RichDataGrid pageSize={50} rows={apiendpoints} skeleton={props.skeleton} columns={columns} styles={props.skeleton.CustomDataGrid} />
                                                )
                                            }

                                            {
                                                // Check for apiendpoints not null
                                                !apiendpoints && (
                                                    <RichDataGrid rows={[]} skeleton={props.skeleton} columns={columns} styles={props.skeleton.CustomDataGrid} />
                                                )
                                            }
                                            </props.skeleton.CustomBox>
                                        </props.skeleton.CustomGrid>
                                        {/* #endregion ----------------------------------------------------------------------------------- */}
                                    </props.skeleton.CustomGrid>
                                    {/* #endregion ----------------------------------------------------------------------------------------*/}
                                    </props.skeleton.CustomGrid>                                 
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    </section>
                    </>
                )
                :
                (
                    <props.skeleton.Heading h3>
                        You do not have permission to view this page
                    </props.skeleton.Heading>
                )
            }
        </props.skeleton.CustomStack>
        </>
    )
}