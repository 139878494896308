// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import addons
import { Spacer } from '../Spacer'

// Import BreadCrumbs
// eslint-disable-next-line
import { BreadCrumbs } from '../../BreadCrumbs'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'

import useE from '../../../middleware/hooks'

// Import utils
import { PrettyNumber } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

// eslint-disable-next-line
function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export function Cards (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        // Functions
        getAllUsersPublically,
        // eslint-disable-next-line
        getFollowers,  
        // eslint-disable-next-line
        getFollowing,
        // eslint-disable-next-line
        getFriends, 
        getPublicProfile, 
        PublicProfile,

        // Variables
        AllUsers,
        // eslint-disable-next-line
        Followers,
        Following,
        Friends,
        isAuthenticated
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    const params = props.params

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // Set up breadcrumbs here
    // eslint-disable-next-line
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'User',
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/account'
        },
        {
            'name': 'My Profile',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/profile'
        },
    ]

    const UseBorder = false
    const Border = 'thin dashed black'
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   

    // Handle view profile
    const handleViewProfile = (userName) => {
        // Get the public profile and set PublicProfile
        getPublicProfile(userName)

        // Navigate
        navigate('/@/'+userName)
    }

    // State Rendering for getting all users
    useE(30000, false, getAllUsersPublically, { isAuthenticated }, PublicProfile)

    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={2} />
        
        {
            // #region ====[ Layout: Cards ]=====================================================================        
            // #region ===[ Section: Title ]=========================================================
            (params.aligntext === "center" &&
                <props.skeleton.CustomTypography variant="h4" props={props} center="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "left" &&
                <props.skeleton.CustomTypography variant="h4" props={props} left="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "right" &&
                <props.skeleton.CustomTypography variant="h4" props={props} right="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            // #endregion ===[ Section: Title ]======================================================                   
        } 

        {
            // #region -----[ All Users Display ]--------------------------------------------------------------------------------
            <props.skeleton.CustomGrid container sx={{ padding: '20px', justifyContent: 'center' }}>
            <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                marginBottom: '10px',
                border: (UseBorder) ? Border : 'none'
            }}>
                <props.skeleton.CustomGrid container sx={{ 
                    border: (UseBorder) ? Border : 'none'
                }}>
                    {
                        /* #region -------------[ Map the users ]-------------------------------------------------------- */
                        (AllUsers) 
                        ? (
                            Array.from(AllUsers).map(ele => (
                                <props.skeleton.CustomGrid item xs={12} sm={6} md={4} key={ele.udata.userName} sx={{ 
                                    border: (UseBorder) ? Border : 'none',
                                    paddingTop: '10px',
                                    paddingBottom: '10px'
                                }}>
                                    <props.skeleton.CustomCard>
                                        <props.skeleton.CustomCardContent>
                                            {
                                                (ele.udata.profileImage && (ele.udata.profileImage !== "no-profileimage.png" && ele.udata.profileImage !== ""))
                                                ? (
                                                    <props.skeleton.CustomBox
                                                        component="img"
                                                        sx={{
                                                            padding: '0px',
                                                            height: {xs: 350, sm: 300, md: 300, lg: 300},
                                                            width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},

                                                        }}
                                                        alt={`${ele.udata.userName}'s Profile Image`}
                                                        src={loadUserProfileImage(ele.udata.userName, ele.udata.profileImage, props.settingServerAPIBaseUrl )}
                                                    />
                                                )
                                                : (
                                                    <props.skeleton.CustomBox
                                                        component="img"
                                                        sx={{
                                                            height: {xs: 350, sm: 300, md: 300, lg: 300},
                                                            width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'},
                                                        }}
                                                        alt={`${ele.udata.userName}'s Profile Image`}
                                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                                    />
                                                )
                                            }
                                            <props.skeleton.CustomTypography variant="h4" component="div" padding="0px 10px 0px 10px" bgprimary="true">
                                                {ele.udata.userName}
                                            </props.skeleton.CustomTypography>

                                            <props.skeleton.CustomTypography variant="body2" padding="10px" italic="true" bgsecondary="true">
                                                {ele.udata.bio || "I have not written a bio yet..."}
                                            </props.skeleton.CustomTypography>

                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid item xs={4} sm={4} md={4} lg={4}>
                                                    <props.skeleton.CustomTypography variant="body2" padding="10px" bgtertiary="true">
                                                        <b>{PrettyNumber(Friends, "a lot of")}</b><br />Friends
                                                    </props.skeleton.CustomTypography>                                       
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={4} sm={4} md={4} lg={4}>
                                                    <props.skeleton.CustomTypography variant="body2" padding="10px" bgtertiary="true">
                                                        <b>{PrettyNumber(ele.followerCount, "a lot of")}</b><br /> Follower{(ele.followerCount === 1) ? "" : "s"}
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={4} sm={4} md={4} lg={4}>
                                                    <props.skeleton.CustomTypography variant="body2" padding="10px" bgtertiary="true">
                                                        <b>{PrettyNumber(Following, "a lot of")}</b><br />Following
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.Bar sx={{ 
                                                textAlign: 'center' ,
                                                paddingTop: { xs: '5px', sm: '10px', md: '10px', lg: '10px' },
                                                paddingBottom: { xs: '5px', sm: '10px', md: '10px', lg: '10px' }
                                            }} button="true" padding10lronly="true" center="true" onClick={() => handleViewProfile(ele.udata.userName)}>View Profile</props.skeleton.Bar>
                                        </props.skeleton.CustomCardContent>
                                    </props.skeleton.CustomCard>
                                    
                                </props.skeleton.CustomGrid>
                            ))
                        )
                        : null
                        /* #endregion ----------------------------------------------------------------------------------- */
                    }
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomGrid>                
        </props.skeleton.CustomGrid>
        /* #endregion -------------------------------------------------------------------------------------------------- */
        }
        </>
    )
}