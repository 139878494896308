/* 
#############################[ NOTES ]#######################################################################################################
* Page-specific styles
* 
#############################################################################################################################################
*/

// import styled-components
import styled, { css } from 'styled-components'

import { 
    Avatar,
    Breadcrumbs,
    Box, 
    Button,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Container,
    //IconButton,
    LinearProgress,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tab,
    Tabs,
    TablePagination,
    TableRow,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'

// Import telinput to handle telephone input
import { MuiTelInput } from 'mui-tel-input'

// Import Components
import Drawer from "../../../components/Drawer"

// Import MUI Data Grid
import { DataGrid } from '@mui/x-data-grid'
import { ToastContainer } from 'react-toastify'

export const Bar = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 0 0 86%;
    height: ${(props) =>
        (props.gembutton)
        ? "50px"
        : "37px"
    };
    justify-content: ${(props) => 
        (props.center)
        ? "center"
        : "left"
    };
    align-items: center;

    ${(props) => 
        props.border &&
        css`
            border: thin solid black;
        `
    }

    ${(props) => 
        props.bottomgizmo &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.base.background};
            bottom: 0;            
            color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.base.color};
            padding: 12px 6px 12px 6px;            
            position: sticky;
            position: -webkit-sticky;                        
            width: 100%;
            z-index: 100;            
        `
    }
        
    ${(props) => 
        props.bottomgizmobutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.background};           
            color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.color};         
            cursor: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.cursor};     
            height: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.height};
            justify-content: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.textalign};            
            margin: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.margin};
            padding: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.padding};            
            text-align: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.textalign};
            width: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.width};            

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.button &&
        css`
            background: ${(props) => props.theme.main.modules.bar.button.base.background};
            color: ${(props) => props.theme.main.modules.bar.button.base.color};
            cursor: pointer;
            font-size: 20px;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.bar.button.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.bar.button.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.bar.button.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.bar.button.base.colorActive};
            }
        `
    }

    ${(props) => 
        props.buttonnobg &&
        css`
            background: ${(props) => props.theme.main.modules.bar.button.nobg.background};
            color: ${(props) => props.theme.main.modules.bar.button.nobg.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;
            font-family: 'Rubik', sans-serif;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.bar.button.nobg.backgroundHover};
                color: ${(props) => props.theme.main.modules.bar.button.nobg.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.bar.button.nobg.backgroundActive};
                color: ${(props) => props.theme.main.modules.bar.button.nobg.colorActive};
            }
        `
    }

    ${(props) => 
        props.drawer &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.base.background};
            color: ${(props) => props.theme.main.modules.drawer.base.color};
            cursor: auto;
        `
    }

    ${(props) => 
        props.drawernavbutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.button.base.background};
            color: ${(props) => props.theme.main.modules.drawer.button.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweight};
            padding-bottom: ${(props) => props.theme.main.modules.drawer.button.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.drawer.button.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.drawer.button.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.drawer.button.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.button.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.button.base.colorHover};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.button.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.button.base.colorActive};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweightActive};
            }
        `
    }
    
    ${(props) => 
        props.drawernestednavbutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.button.nested.base.background};
            color: ${(props) => props.theme.main.modules.drawer.button.nested.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweight};
            padding-bottom: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.button.nested.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.button.nested.base.colorHover};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.button.nested.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.button.nested.base.colorActive};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.fontbarfooterbutton &&
        css`
            background: ${(props) => props.theme.main.modules.footer.button.background};
            color: ${(props) => props.theme.main.modules.footer.button.color};
            cursor: pointer;
            user-select: none;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.footer.button.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.fontbarfooterbuttonempty &&
        css`
            background: ${(props) => props.theme.main.modules.footer.button.background};
            color: ${(props) => props.theme.main.modules.footer.button.color};
            cursor: pointer;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.footer.button.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.fullheight &&
        css`
            min-height: 100%;
        `
    }

    ${(props) => 
        props.fullwidth &&
        css`
            min-width: 100%;
        `
    }

    ${(props) => {
        if (props.gembutton) {
            const color = props.color || "base"
            const theme = props.theme.main.modules.gem.button
            const bg = theme[color]?.background || theme.base.background
            const colorStyle = theme[color]?.color || theme.base.color
            const hoverBg = theme[color]?.backgroundHover || theme.base.backgroundHover
            const hoverColor = theme[color]?.colorHover || theme.base.colorHover
            const activeBg = theme[color]?.backgroundActive || theme.base.backgroundActive
            const activeColor = theme[color]?.colorActive || theme.base.colorActive
    
            return css`
                align-items: center;
                user-select: none;
                background: ${bg};
                color: ${colorStyle};
                cursor: pointer;
                font-size: 20px;
                justify-content: center;
    
                &:hover,
                &:focus {
                    background: ${hoverBg};
                    color: ${hoverColor};
                }
    
                &:active {
                    background: ${activeBg};
                    color: ${activeColor};
                }
            `
        }
    }}   

    ${(props) => 
        props.navbutton &&
        css`
            background: ${(props) => props.theme.main.modules.navbar.button.background};
            color: ${(props) => props.theme.main.modules.navbar.button.color};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.navbar.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.navbar.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.navbar.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.navbar.button.colorActive};
            }
        `
    }
    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding5lronly &&
        css`
            padding: 0 5px 0 5px;
        `
    }

    ${(props) => 
        props.padding10lronly &&
        css`
            padding: 0 10px 0 10px;
        `
    }

    ${(props) => 
        props.padding15 &&
        css`
            padding: 15px;
        `
    }

    ${(props) => 
        props.padding15LROnly &&
        css`
            padding: 0 15x 0 15px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) => 
        props.padding20LROnly &&
        css`
            padding: 0 20px 0 20px;
        `
    }

    ${(props) => 
        props.paddingbottom30  &&
        css`
            padding-bottom: 30px;
        `
    }

    ${(props) => 
        props.richtable && 
        css`
            font-size: 1.5em;
            background: white;
            color: black;
            cursor: pointer;
            text-align: center;

            &:hover,
            &:focus {
                background: violet;
                color: black;
            }
            &:active {
                background: violet;
                color: black;
            }
        `
    }

    ${(props) => 
        props.richtablebutton && 
        css`
            font-size: 1.5em;
            background: #ffd3db;
            color: black;
            cursor: pointer;
            text-align: center;
            padding: 0px 5px 0px 5px;

            &:hover,
            &:focus {
                background: red;
                color: white;
            }
            &:active {
                background: red;
                color: white;
            }
        `
    }

    ${(props) => 
        props.sticky &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            top:0;
            z-index: 100;
        `
    }

    ${(props) =>
        props.underbar &&
        css`
            border: ${(props) => props.theme.main.modules.navbar.button.underbar.params.border};
            border-width: ${(props) => props.theme.main.modules.navbar.button.underbar.params.borderwidth};
        `
    }
`

export const Body = styled.div`
    padding: 3em 5em;
`

export const Bold = styled.b`
    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }
`

export const CustomAvatarLetter = styled.div`
    background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.background};
    color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.color};
    font-size: ${(props) => props.theme.main.modules.typography.gem.avatarletter.fontsize};
    border-radius: ${(props) => props.theme.main.modules.typography.gem.avatarletter.borderradius};
    font-weight: ${(props) => props.theme.main.modules.typography.gem.avatarletter.fontweight};
    padding: 10px 15px 15px 15px;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.backgroundHover};
        color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.backgroundActive};
        color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.colorActive};
    }
`

export const CustomAvatar = styled(Avatar)`
    
`

export const CustomCard = styled.div`
    background: ${(props) => props.theme.main.modules.gem.card.background};
    color: ${(props) => props.theme.main.modules.gem.card.color};
    display: inline-block;
    margin: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.main.modules.gem.card.backgroundHover};
        color: ${(props) => props.theme.main.modules.gem.card.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.main.modules.gem.card.backgroundActive};
        color: ${(props) => props.theme.main.modules.gem.card.colorActive};
    }
`

export const CustomCardActions = styled(CardActions)`
    display: flex;
    width: 100%;
    min-height: 5em;
    align-items: center;
    justify-content: center;
`

export const CustomCollapse = styled(Collapse)`

`

export const CustomCardContent = styled(CardContent)`
    margin-top: 20px;
    background: ${(props) => props.theme.main.modules.gem.card.background}
    color: ${(props) => props.theme.main.modules.gem.card.color};
    text-align: left;
`

export const CustomCardHeader = styled(CardHeader)`
    .MuiCardHeader-subheader {
        color: ${(props) => props.theme.main.modules.typography.gem.faded};

        &:hover,
        &:focus {
            color: ${(props) => props.theme.main.modules.typography.gem.fadedHover};
        }
        &:active {
            color: ${(props) => props.theme.main.modules.typography.gem.fadedHover};
        } 
    }

    .MuiCardHeader-title {
        font-size: 20px;
    }

    padding-top: 20px;
    padding-bottom: 20px;
`

export const CustomCardMedia = styled(CardMedia)`
    
`

export const CustomBreadcrumbs = styled(Breadcrumbs)`
    padding-left: 10px;
`

export const CustomBreadcrumbsSeparator = styled.div`    
    align-items: center;
    background: ${(props) => props.theme.main.modules.typography.breadcrumbSeparator.base.background};
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.main.modules.typography.breadcrumbSeparator.border.styles.one
            } else {
                return props.theme.main.modules.typography.breadcrumbSeparator.base.border
            }            
        }        
    }};
    color: ${(props) => {
        if (props.border) {
            return props.theme.main.modules.typography.breadcrumbSeparator.base.colorwithborder
        } else {
            return props.theme.main.modules.typography.breadcrumbSeparator.base.color
        }
    }};
    display: flex;
    font-size: 25px;
    justify-content: center;
    padding: ${(props) => props.theme.sys.modules.typography.breadcrumbSeparator.base.padding};
`

export const CustomButton = styled.button`
    border: none;
    font-size: 2em;
    ${(props) => 
        props.blue &&
        css`
            background: ${(props) => props.theme.tertiaryBackground};
        `
    }
`

export const CustomBox = styled(Box)`
    padding: 5px 5px 10px 5px;
    color: ${(props) => props.theme.text};
    align-items: center;
    justify-content: center;    
    width: auto;
    background: ${(props) => props.theme.secondaryBackground};

    ${(props) => 
        props.leftAlign &&
        css` 
            align-items: left;
            justify-content: left;
        `
    }

    ${(props) => 
        props.nobg &&
        css` 
            background: transparent;
        `
    }

    ${(props) => 
        props.stickyBottom &&
        css` 
            position: fixed;
            bottom: 0;
        `
    }
`

export const CustomDataGrid = styled(DataGrid)`
    && {
        .MuiDataGrid-columnSeparator {
            display: 'flex';
            color: red;
        }
    
        .MuiDataGrid-columnHeader {
            background: red;
            color: white;
            font-family: 'Rubik', sans-serif;
        }
    
        & .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        }

        .MuiCheckbox-root {
            color: ${(props) => props.theme.checkbox};
        }
    
        .Mui-Checked {
            background: red;
        }
    
        & .MuiDataGrid-root {
            //border: "thin solid";
            //borderColor: ${(props) => props.theme.secondaryBackground};
        }
    
        & .MuiDataGrid-cell {    
            background: pink; 
            borderColor: (props) => props.theme.secondaryBackground;
            color: ${(props) => props.theme.text};
            font-family: 'Rubik', sans-serif;
            font-size: 1.25em;
        }
    
        & .MuiDataGrid-cell:hover {
            color: white;
            background: violet;
        }
    
        & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
            outline: none !important;
        }
    
        & .MuiDataGrid-columnHeader:focus-within, 
        & .MuiDataGrid-columnHeader:focus {
            outline: none !important;            
        }
    
        & .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus {
            outline: none;
        }
    }
`

export const CustomDrawer = styled(Drawer)`
    
`

export const CustomGrid = styled(Grid)` 
    background: ${(props) => {
        if (props.gem) {
            switch (props.color) {
                case "primary":
                    return props.theme.main.modules.gem.base.primary.background
                case "secondary":
                    return props.theme.main.modules.gem.base.secondary.background
                case "tertiary":
                    return props.theme.main.modules.gem.base.tertiary.background
                case "quarternary":
                    return props.theme.main.modules.gem.base.quarternary.background
                case "quarternarydarker":
                    return props.theme.main.modules.gem.base.quarternarydarker.background
                case "transparent":
                    return "none"
                default:
                    return props.theme.main.modules.gem.base.primary.background
            }
        } else if (props.sink) {
            return props.theme.main.modules.grid.sink.background
        } else if (props.highlight) {
            switch (props.highlight) {
                case "golden":
                    return props.theme.main.modules.grid.highlight.golden.base.background
                case "normal":
                    return props.theme.main.modules.grid.highlight.normal.base.background
                default:
                    return props.highlight
            }
        } else if (props.panel) {
            return props.theme.main.modules.grid.panel.background
        } else if (props.panelback) {
            return props.theme.main.modules.grid.panelback.background
        } else if (props.darkerheading) {
            return props.theme.main.modules.grid.darkerheading.background
        } else {
            return "none"
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.main.modules.grid.border.styles.one
            } else {
                return props.theme.main.modules.grid.border.params.border
            }            
        } else if (props.bordertopbottom) {
            return props.theme.main.modules.grid.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }};

    border-width: ${(props) => {
        if (props.border) {
            return props.theme.main.modules.grid.border.params.borderWidth
        } else if (props.bordertopbottom) {
            return props.theme.main.modules.grid.bordertopbottom.params.borderWidth
        } else {
            return "none"           
        }
    }};

    color: ${(props) => {
        if (props.gem) {
            if (props.color === "primary") {
                return props.theme.main.modules.gem.base.primary.color
            } else if (props.color === "secondary") {
                return props.theme.main.modules.gem.base.secondary.color
            } else if (props.color === "tertiary") {
                return props.theme.main.modules.gem.base.tertiary.color
            } else if (props.color === "quarternary") {
                return props.theme.main.modules.gem.base.quarternary.color
            } else {
                return props.theme.main.modules.gem.base.primary.color
            }
        } else if (props.sink) {
            return props.theme.main.modules.grid.sink.color
        } else if (props.highlight) {
            if (props.highlight === "golden") {
                return props.theme.main.modules.grid.highlight.golden.base.color
            } else {
                return props.highlight
            }
        } else if (props.panel) {
            return props.theme.main.modules.grid.panel.color
        } else {
            return "none"
        }
    }};

    font-size: ${(props) => {
        if (props.gem) {
            return "20px";
        } else if (props.sink) {
            return props.theme.main.modules.grid.params.fontsize
        } else {
            return "12px"
        }
    }};

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.gem) {
            return props.padding ? `${props.padding}px` : "0px"
        } else {
            return props.padding ? props.padding : "0px"
        }
    }};

    user-select: ${(props) => {
        if (props.nouserselect) {
            return 'none'
        } else {
            return 'auto'
        }
    }}
    ;

    text-align: ${(props) => {
        if (props.center) {
            return 'center'
        } else if (props.right) {
            return 'right'
        } else {
            return 'left'
        }
    }}
    ;

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
            background: ${(props) => props.theme.main.modules.grid.button.primary.background};
            color: ${(props) => props.theme.main.modules.grid.button.primary.color};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.grid.button.primary.backgroundHover};
                color: ${(props) => props.theme.main.modules.grid.button.primary.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.grid.button.primary.backgroundActive};
                color: ${(props) => props.theme.main.modules.grid.button.primary.colorActive};
            }
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            //border: thin solid black;
            background: white;
            color: white;
        `
    }

    ${(props) => 
        (props.richtablenougat) &&
        css`
            background: ${props.theme.main.modules.grid.richtablenougat.base.background};
            //border: thin solid green;
        `
    }
    
    ${(props) => 
        (props.richtabletopbar) &&
        css`
            background: ${props.theme.main.modules.grid.richtablenougat.topbar.background};
            padding: 10px;
            //border: thin solid green;
        `
    }
`

export const CustomIconButton = styled.div`
    color: ${(props) => props.theme.main.base.iconbutton.color};
    cursor: pointer;
`

export const CustomItem = styled.div`
    background: ${(props) => 
        (props.footeritem)
        ? props.theme.main.modules.footer.item.base.background
        : (
            (props.footerfullitem)
            ? props.theme.main.modules.footer.item.full.background
            : (
                (props.bottomgizmo) 
                ? props.theme.main.modules.item.bottomgizmo.background
                : props.theme.main.modules.item.base.background
            )        
        )
    };
    color: ${(props) => 
        (props.footeritem)
        ? props.theme.main.modules.footer.item.color
        : (
            (props.footerfullitem)
            ? props.theme.main.modules.footer.item.full.color
            : props.theme.main.modules.item.color
        )        
    };
    cursor: ${(props) => 
        (props.button) && 'pointer'
    };
    
    font-size: ${(props) => 
        (props.footeritem) 
        ? props.theme.main.modules.footer.base.fontsize
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? props.theme.main.modules.drawer.bottomgizmo.params.fontsizeprimary
                : (
                    (props.bottomgizmodetailssecondary)
                    ? props.theme.main.modules.drawer.bottomgizmo.params.fontsizesecondary
                    : props.theme.main.modules.drawer.bottomgizmo.params.fontsize
                )
            )
            : '20px'
          )
    };
    font-weight: ${(props) => 
        (props.footeritem) 
        ? 500
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? 900
                : (
                    (props.bottomgizmodetailssecondary)
                    ? 400
                    : 100
                )
            )
            : 400
          )
    };

    margin-right: ${(props) => 
        (props.right)
        ? "10px"
        : "0px"
    };

    padding: ${(props) => 
        (props.padding10)
        ? "10px 10px 10px 10px"
        : (
            (props.bottomgizmo)
            ? "1px 1px 1px 1px"
            : "0px 0px 0px 0px"
        )
    };

    text-align: ${(props) =>
        (props.richtable)
        ? "left"
        : "center"
    };

    user-select: ${(props) => {
        if (props.footerfullitem) {
            return "none"
        }
    }};

    ${(props) => 
        (props.richtable) &&
        css`
            background: none !important;
        `
    }
`

export const CustomLinearProgress = styled(LinearProgress)`
    
`

export const CustomLink = styled.a`
    background: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.main.modules.customlink.copyrightlink.base.background
        } else {
            if (props.nobg) {
                return "none"
            } else if (props.alt) {
                return props.theme.main.modules.customlink.basealt.background
            } else if (props.breadcrumb) {
                return props.theme.main.modules.customlink.breadcrumb.base.background
            } else {
                return props.theme.main.modules.customlink.base.background
            }
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.main.modules.customlink.border.styles.one
            } else {
                return props.theme.main.modules.customlink.border.params.border
            }            
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.richtable) {
            return props.theme.main.modules.customlink.richtable.base.borderradius
        } else if (props.rounded) {
            if (props.rounded) {
                if (props.roundedamt) {
                    return props.roundedamt
                } else {
                    return "10px"
                }    
            } else {
                return "0px"
            }
        } else {
            return "0px"
        }
    }};

    color: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.main.modules.customlink.copyrightlink.base.color
        } else if (props.alt) {
            return props.theme.main.modules.customlink.basealt.color
        } else if (props.breadcrumb) {
            return props.theme.main.modules.customlink.breadcrumb.base.color
        } else {
            return props.theme.main.modules.customlink.base.color
        }
    }};

    cursor: pointer;

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
    
    padding: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.main.modules.customlink.copyrightlink.base.params.padding
        } else {
            if (props.padding5) {
                return "5px"
            } else {
                return props.theme.main.modules.customlink.base.params.padding
            }
        }
    }};
    
    text-decoration: underline;

    user-select: none;

    &:hover,
    &:focus {
        ${(props) => {
            if (props.breadcrumb) {
                return (
                    css `
                        background: ${(props) => props.theme.main.modules.customlink.breadcrumb.base.backgroundHover};
                        color: ${(props) => props.theme.main.modules.customlink.breadcrumb.base.colorHover};
                    `
                )
            } else {
                return (
                    css`
                        background: ${(props) => (props.nohoverbg) ? "none" : props.theme.main.modules.customlink.base.backgroundHover};
                        color: ${(props) => props.theme.main.modules.customlink.base.colorHover};
                    `
                )
            }
        }
    }
    &:active {
        background: ${(props) => (props.nohoverbg) ? "none" : props.theme.main.modules.customlink.base.backgroundActive};
        color: ${(props) => props.theme.main.modules.customlink.base.colorActive};
    } 

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            background: ${(props) => 
                props.theme.main.modules.customlink.richtable.base.background
            };
            color: ${(props) => props.theme.main.modules.customlink.richtable.base.color};
            font-size: 16px;
            padding: ${(props) => props.theme.main.modules.customlink.richtable.params.padding};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.customlink.richtable.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.customlink.richtable.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.customlink.richtable.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.customlink.richtable.base.colorActive};
            }
        `
    }
`
// SHOUTOUT: To this for helping for solve:
// https://stackoverflow.com/questions/73925276/how-to-override-mui-paperprops-with-styled-components
export const CustomMenu = styled(Menu)`
    && {
        & .MuiPaper-root {
            background-color: ${(props) => props.theme.main.modules.menu.base.background};
            color: ${(props) => props.theme.main.modules.menu.base.color};
            min-width: ${(props) => props.theme.main.modules.menu.params.minwidth};
            padding: ${(props) => props.theme.main.modules.menu.params.padding};
        }

        // arrow
        & .MuiPaper-root:before {
            background-color: ${(props) => props.theme.main.modules.menu.arrow.base.background};
            top: ${(props) => props.theme.main.modules.menu.arrow.params.top};
            right: ${(props) => props.theme.main.modules.menu.arrow.params.right};
            height: ${(props) => props.theme.main.modules.menu.arrow.params.height};
            transform: translateY(${(props) => props.theme.main.modules.menu.arrow.params.transform.rotate}) rotate(${(props) => props.theme.main.modules.menu.arrow.params.transform.rotate});
        }
    }
`   

// SHOUTOUT: To this for helping solve this: 
// https://stackoverflow.com/questions/73335643/how-to-apply-styles-to-mui-menulist-with-styled-compoents
export const CustomMenuItem = styled(MenuItem)`
    && {
        padding: ${(props) => props.theme.main.modules.menu.item.params.padding};

        &.Mui-selected {
            background-color: ${(props) => props.theme.main.modules.menu.item.base.background};
            color: ${(props) => props.theme.main.modules.menu.item.base.color};
        }

        &:hover {
            background-color: ${(props) => props.theme.main.modules.menu.item.base.backgroundHover};
            color: ${(props) => props.theme.main.modules.menu.item.base.colorHover};
        }
    }
`

export const CustomMuiTelInput = styled(MuiTelInput)({
    'width': '100%',
    'background': (props) => props.theme.secondaryBackground,
    'height': '3.5em',

    // MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
    '& label.Mui-focused': {
      color: (props) => props.theme.textSecondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiInputLabel-root': {
      color: (props) => props.theme.textSecondary,
    },
    '& .MuiOutlinedInput-input': {
      color: (props) => props.theme.inputColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
});

export const CustomStack = styled(Stack)`
    ${(props) =>
        (props.gemitem) &&
        css `
            &:hover {
                border: thin solid red;
            }
        `
    }

    ${(props) => 
        (props.bottomgizmo) &&
        css`
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            z-index: 100;
        `
    }

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
        `
    }
`

export const CustomTextField = styled(TextField)`
    background: ${(props) => props.theme.main.modules.textfield.base.background};
    
    & label.Mui-focused {
        color: ${(props) => props.theme.main.modules.textfield.base.color};
    }

    & .MuiInput-underline:after': {
        borderBottomColor: black;
    }

    & .MuiInputLabel-root {
        color: white;
    }
    
    & .MuiOutlinedInput-input {
        color: white;
    }
    
    & .MuiOutlinedInput-root {
        & fieldset {
            borderColor: white;
        }

        &:hover fieldset {
            borderColor: white;
        }

        &.Mui-focused fieldset {
            borderColor: white;
        }

        &.Mui-disabled fieldset {        
            borderColor: white;
        }

        & .MuiInputBase-input.Mui-disabled {
            WebkitTextFillColor: white;
        }

        & label.Mui-disabled {
            color: white;
        }
    }
`
/*
'width': '100%',
'background': (props) => props.theme.quartinaryBackground,

// MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
'& label.Mui-focused': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiInput-underline:after': {
    borderBottomColor: 'black',
},
'& .MuiInputLabel-root': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiOutlinedInput-input': {
    color: (props) => props.theme.drawergizmoinputcolor,
},
'& .MuiOutlinedInput-root': {
    '& fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&:hover fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-focused fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-disabled fieldset': {        
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& label.Mui-disabled': {
    color: (props) => props.theme.drawergizmoinputcolor,
    }
},*/

 export const CustomPagination = styled(Pagination)`
    & .MuiPagination-root {
        font-size: 40px;
    };

    // pagination background
    & .MuiPagination-ul {
        background: ${(props) => props.theme.main.modules.pagination.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.base.color};
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-ul .MuiButtonBase {
        background: red;
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-outlined {
        font-size: 40px;
    };

    & .MuiPagination-text {
        background: black;
        color: white;
        font-size: 40px;
    };

    & .Mui-selected {
        background: cyan;
        color: black;
        font-size: 40px;
    };

    & button {
        background: white;
    };

    // Pagination button not selected
    & ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected) {
        background: ${(props) => props.theme.main.modules.pagination.buttonnotselected.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.buttonnotselected.base.color};
        font-size: 40px;
    };

    // Pagination button
    & button {
        background: ${(props) => props.theme.main.modules.pagination.button.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.button.base.color};
        font-size: 40px;
    };

    // Pagination button hover
    & button:hover {
        background: violet;
        color: black;
        font-size: 40px;
    };
 `

 export const CustomPaginationItem = styled(PaginationItem)`
 
 `

export const CustomToggleButton = styled(ToggleButton)`

`

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`

`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
    
`

export const CustomTable = styled(Table)`

`

export const CustomTableBody = styled(TableBody)`

`

export const CustomTableCell = styled(TableCell)`

`

export const CustomTableContainer = styled(TableContainer)`

`

export const CustomTableHead = styled(TableHead)`

`

export const CustomTab = styled(Tab)({

})

export const CustomTabs = styled(Tabs)`
    background: ${(props) => props.theme.main.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;
`

export const CustomTablePagination = styled(TablePagination)`

`

export const CustomTableRow = styled(TableRow)`

`

export const CustomToastContainer = styled(ToastContainer)` 
    // ???
    .Toastify {
        
    }
    
    // ???
    .Toastify__toast-container {
        
    } 

    // Normal progress bar style
    .Toastify__progress-bar {
        background: ${(props) => props.theme.main.modules.notification.progressbar.normal.background};
    }

    // Error progress bar style
    .Toastify__progress-bar--error {
        background: ${(props) => props.theme.main.modules.notification.progressbar.error.background};
    }

    // Info progress bar style
    .Toastify__progress-bar--info {
        background: ${(props) => props.theme.main.modules.notification.progressbar.info.background};
    }

    // Warning progress bar style
    .Toastify__progress-bar--warning {
        background: ${(props) => props.theme.main.modules.notification.progressbar.warning.background};
    }

    // Icon (WIP: cant find it)
    .Toastify__toast-icon {
        
    } 
    
    // Body of notification (text and what not)
    .Toastify__toast-body {
        font-size: 20px;
        font-family: 'Rubik';
    }

    // Normal style 
    .Toastify__toast {
        background: ${(props) => props.theme.main.modules.notification.body.normal.background};
        color: ${(props) => props.theme.main.modules.notification.body.normal.color};
        min-height: 60px; // minimum is 60px
        width: 300px;
    } 

    // Error style
    .Toastify__toast--error {
        background: ${(props) => props.theme.main.modules.notification.body.error.background};
        color: ${(props) => props.theme.main.modules.notification.body.error.color};
    }

    // Info style
    .Toastify__toast--info {
        background: ${(props) => props.theme.main.modules.notification.body.info.background};
        color: ${(props) => props.theme.main.modules.notification.body.info.color};
    }

    // Warning style
    .Toastify__toast--warning {
        background: ${(props) => props.theme.main.modules.notification.body.warning.background};
        color: ${(props) => props.theme.main.modules.notification.body.warning.color};
    }
`

export const CustomToolbar = styled.div`
    
`

export const CustomTooltip = styled.div`
    
`

export const CustomTypography = styled(Typography)`
    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    fontSize: ${(props) =>
        ((props.h0) && "4em") ||
        ((props.h1) && "2.4em") ||
        ((props.h2) && "2.2em") ||
        ((props.h3) && "2em") ||
        ((props.h4) && "1.85em") ||
        ((props.h5) && "1.6em") ||
        ((props.h6) && "1.4em") ||
        ((props.h7) && "1.35em") ||
        ((props.h8) && "1.2em")

    };
    font-weight: 500;
    color: ${(props) => props.theme.text};

    ${(props) => 
        props.button &&
        css`
            cursor: pointer;
            user-select: none;
            padding: 5px;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.button};
                color: ${(props) => props.theme.buttonColor};
            }
            &:active {
                color: ${(props) => props.theme.buttonColorActive};
            }
        `
    }

    ${(props) => 
        props.outline &&
        css`
        border: thin solid ${(props) => props.theme.text};
        border-radius: 5px;
        `
    }

    ${(props) => 
        props.grayed &&
        css` 
            color: ${(props) => props.theme.textGrayed}
        `
    }
`

export const Heading = styled.div`
    background: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.main.modules.typography.divider1.background
        } else if (props.footerdivider2) {
            return props.theme.main.modules.typography.divider2.background
        } else if (props.footerdivider3) {
            return props.theme.main.modules.typography.divider3.background
        } else if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.main.modules.typography.badge.permissiontier0.background
            } else if (props.permissiontier1) {
                return props.theme.main.modules.typography.badge.permissiontier1.background
            } else if (props.permissiontier2) {
                return props.theme.main.modules.typography.badge.permissiontier2.background
            } else if (props.permissiontier3) {
                return props.theme.main.modules.typography.badge.permissiontier3.background
            } else {
                return props.theme.main.modules.typography.badge.base.background
            }
        } else {
            if (props.nobg) {
                return "none"
            } else {
                if (props.darkerheading) {
                    return props.theme.main.modules.typography.darkerheading.background
                } else {
                    return props.theme.main.modules.typography.base.background
                }
            }
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.main.modules.typography.border.styles.one
            } else {
                return props.theme.main.modules.typography.border.params.border
            }            
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }    
        } else {
            return "0px"
        }
    }};

    color: ${(props) => {
        if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.main.modules.typography.badge.permissiontier0.color
            } else if (props.permissiontier1) {
                return props.theme.main.modules.typography.badge.permissiontier1.color
            } else if (props.permissiontier2) {
                return props.theme.main.modules.typography.badge.permissiontier2.color
            } else if (props.permissiontier3) {
                return props.theme.main.modules.typography.badge.permissiontier3.color
            } else {
                return props.theme.main.modules.typography.badge.base.color
            }
        } else {
            if (props.grid) {
                if (props.grid === "grayed") {
                    return props.theme.main.modules.typography.grid.grayed.color
                } else {
                    return props.theme.main.modules.typography.grid.base.color
                }
            } else {
                if (props.highlight) {
                    if (props.highlight === "golden") {
                        return props.theme.main.modules.grid.highlight.golden.base.color
                    } else if (props.highlight === "goldengrayed") {
                        return props.theme.main.modules.grid.highlight.goldengrayed.base.color
                    } else {
                        return props.highlight
                    }
                } else {
                    if (props.grayed) {
                        return props.theme.main.modules.typography.grayed.color
                    } else if (props.alt) {
                        return props.theme.main.modules.typography.basealt.color
                    } else {
                        return props.theme.main.modules.typography.base.color
                    }
                }
            }
        }
    }} !important;

    cursor: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.main.modules.typography.divider1.cursor
        } else if (props.footerdivider2) {
            return props.theme.main.modules.typography.divider2.cursor
        } else if (props.footerdivider3) {
            return props.theme.main.modules.typography.divider3.cursor
        } else {
            if (props.nobg) {
                return "normal"
            } else {
                if (props.button) {
                    return "pointer"
                } else {
                    return props.theme.main.modules.typography.base.cursor
                }
            }
        }
    }};

    font-family: ${(props) => {
        if (props.navbar) {
            return props.theme.main.modules.typography.navbar.fontfamily
        } else if (props.footer) {
            return props.theme.main.modules.typography.footer.fontfamily
        } else if (props.gem) {
            if (props.h1) {
                return props.theme.main.modules.typography.gem.h1.fontfamily
            } else if (props.h6) {
                return props.theme.main.modules.typography.gem.h6.fontfamily
            } else {
                return props.theme.main.modules.typography.gem.h1.fontfamily
            }
        } else {
            return props.theme.main.modules.typography.base.fontfamily
        }
    }};

    font-size: ${(props) => {
        if (props.badge) {
            return "17px"
        } else {
            if (props.h1) {
                return "2.4em"
            } else if (props.h2) {
                return "2.2em"
            } else if (props.h3) {
                return "2em"
            } else if (props.h4) {
                return "1.85em"
            } else if (props.h5) {
                return "1.6em"
            } else if (props.h6) {
                return "1.4em"
            } else if (props.h7) {
                return "1.35em"
            } else if (props.h8) {
                return "1.2em"
            } else if (props.h9) {
                return "1em"
            }
        }
    }};

    font-weight: 500;
    
    margin: ${(props) => {
        if (props.badge) {
            return "0px 3px 0px 3px"
        } else {
            return "none"
        }
    }};

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.padding10) {
            return "10px"
        } else {
            if (props.badge) {
                return "4px"
            } else {
                if (props.padding) {
                    return props.padding
                } else {
                    return "0px"
                }
            }
        }
    }};

    text-align: ${(props) => {
        if (props.center) {
            return "center"
        } else if (props.right) {
            return "right"
        } else {
            return "left"
        }
    }};

    text-justify: distribute;
    
    text-transform: ${(props) => {
        if (props.insetmedium) {
            return "uppercase"
        } else if (props.insetlarge) {
            return "uppercase"
        } else if (props.insetxlarge) {
            return "uppercase"
        } else {
            return "none"
        }
    }};

    user-select: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.main.modules.typography.divider1.userselect
        } else if (props.footerdivider2) {
            return props.theme.main.modules.typography.divider2.userselect
        } else if (props.footerdivider3) {
            return props.theme.main.modules.typography.divider3.userselect
        } else {
            if (props.nobg) {
                return "none"
            } else if (props.button) {
                return "pointer"
            } else if (props.drawernavbuttonheading) {
                return "none"
            } else if (props.badge) {
                return "none"
            } else {
                return props.theme.main.modules.typography.base.userselect
            }
        }
    }};

    ${(props) => 
        props.breadcrumb &&
        css`
            background: ${(props) => props.theme.main.modules.typography.breadcrumb.base.background};
            color: ${(props) => props.theme.main.modules.typography.breadcrumb.base.color};
            font-size: ${(props) => props.theme.main.modules.typography.breadcrumb.base.fontsize};
            padding: ${(props) => props.theme.main.modules.typography.breadcrumb.base.padding}
        `
    }

    ${(props) =>
        (props.button) &&
            css`
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 5px;
            `
        }


    ${(props) =>
    (props.drawernavbuttonheading) &&
        css`
            background: ${(props) => props.theme.main.modules.typography.drawer.base.background};
            color: ${(props) => props.theme.main.modules.typography.drawer.base.color} !important;
            font-size: ${(props) => props.theme.main.modules.typography.drawer.params.fontsize};
            font-family: ${(props) => props.theme.main.modules.typography.drawer.params.fontfamily};
            padding-bottom: ${(props) => props.theme.main.modules.typography.drawer.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.typography.drawer.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.typography.drawer.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.typography.drawer.params.paddingtop};
        `
    }

    ${(props) =>
        (props.grayed) &&
        css`
            background: ${(props) => props.theme.main.modules.typography.grayed.base.background};
            color: ${(props) => props.theme.main.modules.typography.grayed.base.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextarea) &&
        css`
            font-size: 17px;
            background: ${(props) => props.theme.main.modules.typography.userinfotextarea.base.background};
            color: ${(props) => props.theme.main.modules.typography.userinfotextarea.base.color};
            padding: 4px;
        `
    }
`

export const InnerContent = styled.div`
    padding: 0px 20px 0px 20px;
`

export const InnerDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.main.modules.drawer.base.background};
    color: ${(props) => props.theme.main.modules.drawer.base.color};
`

export const InnerInnerDrawer = styled.div`
    height: 100vh;
    background: ${(props) => props.theme.main.modules.drawer.inner.base.background};
    color: ${(props) => props.theme.main.modules.drawer.inner.base.color};    
    padding-bottom: ${(props) => props.theme.main.modules.drawer.inner.params.paddingbottom};
    padding-left: ${(props) => props.theme.main.modules.drawer.inner.params.paddingleft};
    padding-right: ${(props) => props.theme.main.modules.drawer.inner.params.paddingright};
    padding-top: ${(props) => props.theme.main.modules.drawer.inner.params.paddingtop};
`

export const ItemContainer = styled.div`
    border: thin solid red;
`

export const NavBar = styled.div`
    background: ${(props) => props.theme.main.modules.navbar.base.background};
    padding-top: calc(${(props) => props.theme.main.modules.navbar.params.height} / 2);
    padding-bottom: calc(${(props) => props.theme.main.modules.navbar.params.height} / 2);
    width: 100%;
    z-index: 900;
    
    ${(props) => 
        props.stickytop &&
        css` 
            position: fixed;
            top: 0;
        `
    }
`

export const Para = styled.div`
    font-size: ${(props) =>
        (props.gem)
        ? "1.2em"
        : (
            ((props.h1) && "1em") ||
            ((props.h2) && ".8em") ||
            ((props.h3) && ".7em") ||
            ((props.h4) && ".6em") ||
            ((props.h5) && ".45em") ||
            ((props.h6) && ".33em") ||
            ((props.h7) && ".25em") ||
            ((props.h8) && ".1em")
        )

    };
    text-align: justify;
    text-justify: distribute;
    color: ${(props) => props.theme.main.modules.typography.para.base.color};

    ${(props) => 
        props.center &&
        css`            
            text-align: center; 
            justify-content: distribute;                
            `        
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        props.right &&
        css`            
            text-align: right; 
            justify-content: distribute;                
            `        
    }

    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }
`

export const PostCards = styled(Grid)`
    display: flex;
    justify-content: space-between;
`

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    user-select: none;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => 
            props.theme.main.modules.llswitch.nougat.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    span:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: ${(props) => 
            props.theme.main.modules.llswitch.button.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: ${(props) => props.theme.main.modules.llswitch.nougat.background};
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`

export const TextFieldLabel = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.text};
`

// ==== [ Login/Register Form ]========================================================
export const LRImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const FloatingButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    z-indez: 1
`

export const StyledButton = styled(Button)`    
    background-color: white !important;
    border: 2px solid #000000;
    color: black !important; 
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-top: 15px !important;
    &:hover {
        background-color: #008080 !important;
        color: white !important; 
    }
`

export const StyledContainer = styled(Container)`    
    align-items: center;
    display: flex;
    height: 90vh;
    justify-content: center; 
`

export const StyledForm = styled.form`
    margin-top: 1rem;
    width: 100%; // Fix IE 11 issues
`

export const StyledPaper = styled(Paper)`
    align-items: center;
    background-color: ${(props) => props.theme.main.modules.paper.base.background} !important;
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.main.modules.paper.border.styles.one
            } else {
                return props.theme.main.modules.paper.border.params.border
            }
        } else if (props.bordertopbottom) {
            return props.theme.main.modules.paper.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};
    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }} !important;
    color: ${(props) => props.theme.main.modules.paper.base.color} !important;
    display: flex;
    flex-direction: column;
    //padding: 2rem;        

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
`
// ================================================================================================