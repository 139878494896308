// Import styled components
import styled, { css, } from 'styled-components'
import {
    
 } from '@mui/material/'


export const CustomContainer = styled.div`
    display: flex;
    flex-direction: column;
`

// Used for the content of the page
export const Content = styled.div`       
    background: ${(props) => (props.profile && props.profileBG) ? props.profileBG : props.theme.global.base.background}; 
    background-image: ${(props) => (props.profile && props.profileBGImage) ? `url(${props.profileBGImage})` : 'none'}; 
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    color: ${(props) => props.theme.global.base.color};
    padding-top: calc(${(props) => props.theme.global.base.params.paddingtop} + 25px);
    padding-bottom: calc(${(props) => props.theme.global.base.params.paddingbottom} + 25px);
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    ${(props) => 
        props.left &&
        css` 
            justify-content: left;
        `
    }
    ${(props) => 
        props.center &&
        css` 
            justify-content: center;
        `
    }
    ${(props) => 
        props.right &&
        css` 
            justify-content: right;
        `
    }
    ${(props) => 
        props.column &&
        css`
            flex-direction: column;
        `
    }
`

export const FullContainer = styled.div`
    min-height: calc(100vh - 2rem)
`

export const Heading = styled.div`
    font-size: 1em;
    font-weight: 500;
    text-align: left;
    text-justify: distribute;
    color: ${(props) => props.theme.text};
    
    ${(props) => 
        props.button &&
        css`
            cursor: pointer;
            user-select: none;
            padding: 5px;
        `
    }

    ${(props) => 
        props.center &&
        css` 
            text-align: center;
            text-justify: distribute;
        `
    }

    ${(props) =>
        props.grayed &&
        css`
            color: ${(props) => props.theme.textGrayed};
        `
    }

    ${(props) => 
        props.h1 &&
        css` 
            font-size: 2.4em;
        `
    }
    
    ${(props) => 
        props.h2 &&
        css` 
            font-size: 2.2em;
        `
    }

    ${(props) => 
        props.h3 &&
        css` 
            font-size: 2em;
        `
    }

    ${(props) => 
        props.h4 &&
        css` 
            font-size: 1.85em;
        `
    }

    ${(props) => 
        props.h5 &&
        css` 
            font-size: 1.6em;
        `
    }

    ${(props) => 
        props.h6 &&
        css` 
            font-size: 1.4em;
        `
    }

    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) =>
        props.swoop &&
        css`
            background: ${(props) => props.theme.tertiaryBackground};
            color: ${(props) => props.theme.textTerinary};
        `
    }
    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `
    }
`