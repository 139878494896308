import {createContext, useReducer} from 'react'
import axios from 'axios'
import categoryReducer from './categoryReducer'
import * as ActionTypes from '../ContextActions'

export const CategoryContext = createContext()

export default function CategoryState(props){
    const initialstate = {
        categories: null,
        categoriesCreated: false,
        CreatingSliceSent: false,
        currentSlice: null,
        featuredcategories: null,
        hasDeletedSlice: false,
        hasRestoredSlice: false,
        hasMovedSliceToTrash: false,
        isPermDeletingSlice: false,
        isPermDeletingSliceSent: false,
        isCreatingSlice: false,
        isCreatingSliceSent: false,
        isCreatingSliceSentSuccess: false,
        isRestoringSlice: false,
        isUpdatingSlice: false,
        isUpdatingSliceSent: false,
        isUpdatingSliceSentSuccess: false,
        NewSliceSlug: null,
        PermDeleteSliceData: null,
        PermDeleteSliceSlug: null,
        selectedCategories: [],
        sliceAuthor: null,
        sliceComments: null,
        sliceCreated: false,
        sliceDelete: false,        
        SliceFormIsDisabled: true,
        SliceIsMovingToTrash: false,
        sliceLikes: null,
        sliceShares: null,
        SliceDeletingData: null,
        SliceRestoringData: null,
        sliceViews: null,
        sliceViewsVisitors: null, 
        theslices: null,  
        toastsCategory: null,
        uploadFiles: null        
    }

    const [state, dispatch] = useReducer(categoryReducer, initialstate)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const clearCurrentSlice = () =>{
        dispatch({type: ActionTypes.CLEAR_CURRENT_SLICE})
    }

    const clearErrorsCategory = async () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_CATEGORY,
        })
    }

    const clearSlices = async () => {
        dispatch({
            type: ActionTypes.CLEAR_SLICES
        })
    }

    const createSlice = async (type, sliceData) => {
        try {
            //console.log(sliceData)
            const res = await axios.post('/api/v1/'+type+'/create', sliceData, config)
            dispatch({
                type: ActionTypes.NEW_SLICE_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.NEW_SLICE_FAIL,
                payload: err.response.data,
            })
        }
    }

    const createInteraction = async (type, action, sliceslug) => {
        try {
            const sliceData = {
                type: type,
                action: action, 
                postslug: sliceslug
            }            
                        
            const res = await axios.post(`/api/v1/interaction/create`, sliceData, config)
            dispatch({
                type: ActionTypes.NEW_INTERACTION_SUCCESS,
                payload: res.data
            })            
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.INTERACTION_FAIL,
                payload: err.response.data,
            })
        }
    }

    const createInteractionVisitor = async (type, action, sliceslug) => {
        try {
            const sliceData = {
                type: type,
                action: action, 
                postslug: sliceslug
            }            
                        
            const res = await axios.post(`/api/v1/interaction/visitor/create`, sliceData, config)
            dispatch({
                type: ActionTypes.NEW_INTERACTION_SUCCESS,
                payload: res.data
            })            
        } catch (err) {
            console.log(err.response.data)
            dispatch({
                type: ActionTypes.INTERACTION_FAIL,
                payload: err.response.data,
            })
        }
    }

    // NOTE: This is not Move To Trash
    const deleteSlice = async (type, currentSlug) => {
        try {
            const res = await axios.delete(`/api/v1/`+type+`/delete/${currentSlug}`, config)
            dispatch({
                type: ActionTypes.SLICE_DELETE,
                payload: res.data
            })            
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.SLICE_DELETE_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllCategories = async (limit = null, namespace = 'local') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace
            }
            const res = await axios.post('/api/v1/categories/', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLCATEGORIES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLCATEGORIES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllCategoriesPublically = async (limit = null, namespace = 'local') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace
            }
            const res = await axios.post('/api/v1/categories/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLCATEGORIES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLCATEGORIES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllFeaturedCategories = async (type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                order: order,
                orderBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLFEATUREDCATEGORIES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLFEATUREDCATEGORIES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getMyCategories = async (limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                order: order,
                orderBy: orderBy
            }

            const res = await axios.post('/api/v1/categories/mycategories', parameters, config)
            dispatch({
                type: ActionTypes.GET_MYCATEGORIES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_MYCATEGORIES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllSlices = async (type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                order: order,
                orderBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLSLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllSlicesPublically = async (type, limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                order: order,
                orderBy: orderBy
            }
            const res = await axios.post('/api/v1/'+type+'/public', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLSLICESPUBLICALLY_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllMySlices = async (type, limit = null, namespace = null, stat = null) => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat
            }
            const res = await axios.post('/api/v1/'+type+'/mycategories', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLMYSLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_ALLMYSLICES_FAIL,
                payload: err.response.data,
            })
        }
    }

    /*
    const getMySlices = async (type, limit = null, namespace = null, stat = null) => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat
            }
            const res = await axios.post('/api/v1/'+type+'/myposts', parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICES_SUCCESS,                
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.SLICE_FAIL,
                payload: err.response.data,
            })
        }
    }
    */
    
    const getSliceAuthorDetails = async (authorID) => {
        try {
            const res = await axios.get(`/api/v1/user/detail/${authorID}`, config)
            dispatch({
                type: ActionTypes.GET_SLICEAUTHOR_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICEAUTHOR_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceComments = async (sliceid) => {
        try {
            const parameters = {
                what: "comments"
            }
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)

            dispatch({
                type: ActionTypes.GET_SLICECOMMENTS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICECOMMENTS_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceLikes = async (sliceid) => {
        try {
            const parameters = {
                what: "likes"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICELIKES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICELIKES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceShares = async (sliceid) => {
        try {
            const parameters = {
                what: "shares"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config)
            dispatch({
                type: ActionTypes.GET_SLICESHARES_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICESHARES_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceViews = async (sliceid) => {
        try {
            const parameters = {
                what: "views"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/${sliceid}`, parameters, config).then()
            dispatch({
                type: ActionTypes.GET_SLICEVIEWS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICEVIEWS_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceViewsVisitors = async (sliceid) => {
        try {
            const parameters = {
                what: "views"
            }
            // Even though this is a "GET" request, in order to pass parameters we must use a post method. Weird right?
            const res = await axios.post(`/api/v1/interactions/post/visitor/${sliceid}`, parameters, config).then()
            dispatch({
                type: ActionTypes.GET_SLICEVIEWSVISITORS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICEVIEWSVISITORS_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceById = async (type, sliceId) => {
        try {
            const res = await axios.get('/api/v1/'+type+'/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICE_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.SLICE_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getSliceBySlug = async (type, sliceId) => {
        //console.log("/api/v1/"+type+"/public/"+sliceId)
        try {
            const res = await axios.get('/api/v1/'+type+'/public/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICEBYSLUG_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICEBYSLUG_FAIL,
                payload: err.response.data,
            })
        }
    }    

    const getSliceFromSlug = async (type, sliceId) => {
        try {
            const res = await axios.get('/api/v1/'+type+'/'+sliceId, config)
            dispatch({
                type: ActionTypes.GET_SLICEFROMSLUG_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_SLICEFROMSLUG_FAIL,
                payload: err.response.data,
            })
        }
    } 
    
    const updateSlice = async (type, sliceData, currentSlug) => {
        //console.log(sliceData)
        try {
            const res = await axios.put(`/api/v1/`+type+`/update/${currentSlug}`, sliceData, config)
            dispatch({
                type: ActionTypes.UPDATE_SLICE,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.UPDATE_SLICE_FAIL,
                payload: err.response.data,
            })
        }
    }

    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setCurrentSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_CURRENTSLICE,
            payload: value
        })
    }
    
    const setHasDeletedSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASDELETEDSLICE,
            payload: value
        })
    }

    const setHasMovedSliceToTrash = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASMOVEDSLICETOTRASH,
            payload: value
        })
    }

    const setHasRestoredSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_HASRESTOREDSLICE,
            payload: value
        })
    }
    
    const setNewSliceSlug = (value) =>{
        dispatch({
            type: ActionTypes.SET_NEW_SLICE_SLUG,
            payload: value
        })
    }
    
    const setSliceFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_SLICE_FORM_IS_DISABLED,
            payload: value
        })
    }    
    
    const setIsCreatingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISCREATINGSLICE,
            payload: value
        })
    }
    
    const setIsCreatingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISCREATINGSLICESENT,
            payload: value
        })
    }

    const setIsCreatingSliceSentSuccess = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISCREATINGSLICESENTSUCCESS,
            payload: value
        })
    }

    const setIsPermDeletingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICEPERMDELETING,
            payload: value
        })
    }

    const setIsPermDeletingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICEPERMDELETINGSENT,
            payload: value
        })
    }

    const setIsRestoringSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISSLICERESTORING,
            payload: value
        })
    }

    const setSliceIsMovingToTrash = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICE_ISMOVINGTOTRASH,
            payload: value
        })
    }

    const setIsUpdatingSlice = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISUPDATING_SLICE,
            payload: value
        })
    }
    
    const setIsUpdatingSliceSent = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISUPDATING_SLICE_SENT,
            payload: value
        })
    }

    const setIsUpdatingSliceSentSuccess = (value) =>{
        dispatch({
            type: ActionTypes.SET_ISUPDATING_SLICE_SENT_SUCCESS,
            payload: value
        })
    }
    
    const setPermDeleteSliceSlug = (value) =>{
        dispatch({
            type: ActionTypes.SET_PERM_DELETE_SLICE_SLUG,
            payload: value
        })
    }

    const setSelectedCategories = (value) =>{
        dispatch({
            type: ActionTypes.SET_SELECTEDCATEGORIES,
            payload: value
        })
    }

    const setSliceDeletingData = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEDELETINGDATA,
            payload: value
        })
    }

    const setSliceRestoringData = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICERESTORINGDATA,
            payload: value
        })
    }
    
    const setUploadFiles = (value) =>{
        dispatch({
            type: ActionTypes.SET_UPLOADFILES,
            payload: value
        })
    }

    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    

    // #endregion

    return (
        <CategoryContext.Provider value={{
            categories: state.categories,
            categoriesCreated: state.categoriesCreated,
            CreatingSliceSent: state.CreatingSliceSent,
            currentSlice: state.currentSlice,
            featuredcategories: state.featuredcategories,
            hasDeletedSlice: state.hasDeletedSlice,
            hasMovedSliceToTrash: state.hasMovedSliceToTrash,
            hasRestoredSlice: state.hasRestoredSlice,
            isPermDeletingSlice: state.isPermDeletingSlice,
            isPermDeletingSliceSent: state.isPermDeletingSliceSent,
            isCreatingSlice: state.isCreatingSlice,
            isCreatingSliceSent: state.isCreatingSliceSent,
            isCreatingSliceSentSuccess: state.isCreatingSliceSentSuccess,
            isRestoringSlice: state.isRestoringSlice,
            isMovingToTrash: state.isMovingToTrash,
            isUpdatingSlice: state.isUpdatingSlice,
            isUpdatingSliceSent: state.isUpdatingSliceSent,
            isUpdatingSliceSentSuccess: state.isUpdatingSliceSentSuccess,
            NewSliceSlug: state.NewSliceSlug,
            PermDeleteSliceData: state.PermDeleteSliceData,
            PermDeleteSliceSlug: state.PermDeleteSliceSlug,
            selectedCategories: state.selectedCategories,
            sliceAuthor: state.sliceAuthor,
            sliceComments: state.sliceComments,
            sliceCreated: state.sliceCreated,
            sliceDelete: state.sliceDelete,
            SliceDeletingData: state.SliceDeletingData,            
            SliceFormIsDisabled: state.SliceFormIsDisabled,
            SliceIsMovingToTrash: state.SliceIsMovingToTrash,
            sliceIsUpdating: state.sliceIsUpdating,
            sliceLikes: state.sliceLikes,
            sliceShares: state.sliceShares,
            SliceRestoringData: state.SliceRestoringData,
            sliceViews: state.sliceViews, 
            sliceViewsVisitors: state.sliceViewsVisitors,
            theslices: state.theslices,
            toastsCategory: state.toastsCategory,
            uploadFiles: state.uploadFiles,
            
            clearCurrentSlice,
            clearErrorsCategory,
            clearSlices,
            createInteraction,
            createInteractionVisitor,
            createSlice,
            deleteSlice,
            getAllCategories,
            getAllCategoriesPublically,
            getAllFeaturedCategories,
            getMyCategories,
            getAllSlices,
            getAllSlicesPublically,
            getAllMySlices,
            getSliceAuthorDetails,
            getSliceComments,
            getSliceLikes,
            getSliceShares,
            getSliceViews,
            getSliceViewsVisitors,
            getSliceById,          
            getSliceBySlug, 
            getSliceFromSlug,
            setCurrentSlice,
            setHasDeletedSlice,
            setHasMovedSliceToTrash,
            setHasRestoredSlice,
            setIsPermDeletingSlice,            
            setIsPermDeletingSliceSent,
            setIsCreatingSlice,
            setIsCreatingSliceSent,
            setIsCreatingSliceSentSuccess,
            setIsRestoringSlice,
            setIsUpdatingSlice, 
            setIsUpdatingSliceSent,
            setIsUpdatingSliceSentSuccess,
            setNewSliceSlug,   
            setPermDeleteSliceSlug, 
            setSelectedCategories,           
            setSliceFormIsDisabled,
            setSliceIsMovingToTrash,                 
            setSliceDeletingData,
            setSliceRestoringData,
            setUploadFiles,
            updateSlice                       
        }}>
            {props.children}
        </CategoryContext.Provider>
    )
}