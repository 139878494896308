// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

import ReactHtmlParser from 'react-html-parser'

// Import Auth
import { useAuth, usePage, useSetting } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Container,
} from '@mui/material'

// Import components
//import { CallToAction } from '../components/Addon/CallToAction'
//import { Singlet } from '../components/Addon/Singlet'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { BreadCrumbs } from '../components/BreadCrumbs'
import { Spacer } from '../components/Addon/Spacer'
//import { QueryList } from '../components/Addon/QueryList'

// Import partials
//import PartialNotFound from './PartialNotFound'

// Import errors
import Error401 from './Error401'
import Error404 from './Error404'

// Import utils
//import { settingLookup } from '../middleware/utils'

// Import from Natch
import { ParseGem } from '../middleware/Natch'

// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----[ Outside Functions ]-----------------------------------------------------------------------------

// #region ----[ End Outside Functions ]-------------------------------------------------------------------------

export default function Other (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][]'''''''''[][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Initialize BreadCrumbs
    let BCItems = []

    // Dynamically set BCItems
    if (props.tertiary) {
        BCItems = [
            {
                'name': 'Home',
                'type': 'link',
                'grayed': true,
                "url": '/',
            }, 
            {
                'name': props.secondary.charAt(0).toUpperCase()+props.secondary.slice(1),
                'type': 'link',
                'grayed': true,
                'url': '/pages'
            },
            {
                'name': props.tertiary.charAt(0).toUpperCase()+props.tertiary.slice(1),
                'type': 'text',
                'grayed': true,
                'url': ''
            },
        ]
    } else {
        BCItems = [
            {
                'name': 'Home',
                'type': 'link',
                'grayed': true,
                "url": '/',
            }, 
            {
                'name': props.secondary.charAt(0).toUpperCase()+props.secondary.slice(1),
                'type': 'text',
                'grayed': true,
                'url': ''
            },
        ]
    }

    // Use Page
    const {       
        currentSlice,
        getSliceBySlug
    } = usePage()

    const {
        // Variables
        variables,

    } = useSetting()

    // 2.8.2 Update to store the current page to compare with the props.secondary for when it changes
    const [ tempPage, setTempPage ] = useState(null)

    const determineFound = () => {
        if (currentSlice) return true
    }

    const determineAuthorized = () => {
        // WIP: Check from interactions db for a "pass"
        // FOR NOW: Hardcode it
        let authorized = false

        if (currentSlice && authorized) {return true} else {return }
    }

    const parsedContent = (content) => {
        // Setup variables
        let parsed = ""

        // Setup number of lines
        let lines = content.split(/\r\n|\r|\n/)

        // For each line parse the code
        Array.from(lines).forEach((line) => {
            // Add for each gem type
            parsed += ParseGem(line, "heading", variables)
            parsed += ParseGem(line, "singlet", variables)
            parsed += ParseGem(line, "twocolumn", variables)
        })

        return ReactHtmlParser(parsed)
    }

    // #endregion -----------------------------------------------------------------------------------------------
    // #region ------------[ State Variables ]-------------------------------------------------------------------
    // State management for pages
    useEffect(() => {
        if (props.secondary) {
            if (!currentSlice) {
                // No current page
                // Get the page via API call
                getSliceBySlug("page", props.secondary)

                // Set a tempPage (the temp variable to check with the new change)
                setTempPage(props.secondary)
            } else {
                // current page is found; check to see if there is a change in the page (props.secondary)
                if (props.secondary !== tempPage) {
                    // Get page via API call
                    getSliceBySlug("page", props.secondary)
                    // Set the tempPage
                    setTempPage(props.secondary)
                }
            }
        }
        // eslint-disable-next-line
    }, [
        currentSlice,
        props.secondary,
        tempPage
    ])
    
    /* DEPRECATED < 2.8.2 : Running API call every so often; can overload the browser
    useEffect(() => {
        if (props.secondary) {
            if (!currentSlice) {
                getSliceBySlug("page", props.secondary)
            } else {
                setTimeout(() => {
                    getSliceBySlug("page", props.secondary)
                }, 5000)
            }
        }
        // eslint-disable-next-line
    }, [
        currentSlice,
        props.secondary
    ])
    */

    // State management for debugging
    useEffect(() => {
        //if (currentSlice) console.log(currentSlice)
    }, [
        //currentSlice
    ])
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    if (currentSlice) {
        if (determineFound && determineAuthorized) {
            return (
                <>
                <Spacer length={5} />
        
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
    
                {
                    parsedContent(currentSlice.content)
                }
                </>
            )
        } else if(determineFound && !determineAuthorized) {
            return (
                <>
                    <Error401 skeleton={props.skeleton} />
                </>
            )
        }
    } else {
        return (
            <>
            <Spacer length={5} />
        
            <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

            <Error404 skeleton={props.skeleton} />
            </>
        )
    }
}