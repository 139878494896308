export const grid = {
    base: {
        primary: {
            background: "#fff9fa",
            color: "black"
        }, 
        secondary: {
            background: "#ff8080",
            color: 'black'
        },
        tertiary: {
            background: "#ffb3b3",
            color: 'black'
        },
        quarternary: {
            background: "white",
            color: 'black'
        }
    }, 
    border: {
        params: {
            border: "thin solid #006b6b"
        },
        styles: {
            one: "2px solid white",
            default: "thin solid cyan"
        },
    },
    bordertopbottom: {
        params: {
            border: "1px solid #474747",
            borderWidth: "1px 0px 1px 0px"
        }
    }, 
    button: {
        base: {
            background: "white",
            backgroundHover: "#8b0000",
            backgroundActive: "white",
            color: "#ff4d4d",
            colorHover: "white",
            colorActive: "white",
        },
        primary: {
            background: "#009999",
            backgroundHover: "#00b3b3",
            backgroundActive: "#00b3b3",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        secondary: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        tertiary: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        quarternary: {
            background: "white",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
        }
    },
    darkerheading: {
        background: "#171717",
        color: "black",
        fontfamily: "Rubik",
        userselect: "none"
    },
    gem: {
        base: {
            background: "lime",
        }, 
        type1: {
            background: "#ffffff"
        },
        type2: {
            background: "#80ff80",
        },
        type3: {
            background: "#00ff00",
        },
        type4: {
            background: "#008000",
        }
    },
    highlight: {
        golden: {
            base: {
                background: "#ccac00",
                color: "#ffd700"
            }
        },
        goldengrayed: {
            base: {
                background: "#ccac00",
                color: "#ffd700"
            }
        },
        normal: {
            base: {
                background: "#006666",
                color: "cyan"
            }
        },
        normalgrayed: {
            base: {
                background: "#ccac00",
                color: "#003333"
            }
        }
    },
    panel: {
        background: "#00b3b3",
        color: "cyan",
        icon: {
            color: "black"
        }
    },
    params: {
        fontsize: "16px"
    },
    richtable: {
        header: {
            background: '#191919',
            color: 'white'
        },
        rows: {
            background: '#303030',
            backgroundHover: '#00b3b3',
            color: 'white',
            colorHover: 'black'
        }
    },
    richtablenougat: {
        base: {
            background: '#191919'
        },
        topbar: {
            background: '#191919'
        }
    },
    sink: {
        background: "#191919",
        color: "white"
    }, 
}