export const typography = {
    base: {
        background: "black",
        color: "white",
        fontfamily: "Rubik"
    },
    bgprimary: {
        background: "#00cb8f",
        color: "black",
        fontfamily: "Rubik"
    },
    bgsecondary: {
        background: "#b3efdd",
        color: "black",
        fontfamily: "Rubik"
    },
    bgtertiary: {
        background: "#000000",
        color: "white",
        fontfamily: "Rubik"
    },
    badge: {
        base: {
            background: "lime",
            color: "black",
        },
        permissiontier0: {
            background: "#c0c0c0",
            color: "black",
        },
        permissiontier1: {
            background: "#808080",
            color: "white",
        },
        permissiontier2: {
            background: "#00cccc",
            color: "black",
        },
        permissiontier3: {
            background: "#e6c200",
            color: "black",
        }
    },
    breadcrumb: {
        base: {
            background: "#191919",
            color: "cyan !important", // sometimes you need !important if it doesn't change
            fontsize: "15px",
            padding: "10px"
        }
    },
    breadcrumbSeparator: {
        base: {
            background: "transparent",
            border: "thin solid transparent",
            color: "black",
            colorwithborder: "white !important",
            padding: "0px 6px 0px 6px"
        },
        border: {        
            params: {
                border: "thin solid #006b6b"
            },
            styles: {
                one: "2px solid transparent",
                default: "thin solid cyan"
            },
        },
    },
    darkerheading: {
        background: "#171717",
        color: "black",
        fontfamily: "Rubik",
        userselect: "none"
    },
    darktext: {
        color: "black"
    },
    divider1: {
        background: "#5e7e7e",
        color: "white",
    }, 
    divider2: {
        background: "#476c6c",
        color: "white",
    }, 
    divider3: {
        background: "#305959",
        color: "white",
    },
    drawer: {
        base: {
            background: "lime",
            color: "black"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "30px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    invisibletext: {
        color: 'black'
    },
    navbar: {
        background: "lime",
        color: "white",
        fontfamily: "Rubik"
    },
    userinfotextarea: {
        base: {
            background: "transparent",
            color: "#999999",
        },
        type1: {
            background: "transparent",
            color: "#999999",
        },
        type2: {
            background: "transparent",
            color: "black",
        },
        type3: {
            background: "transparent",
            color: "black",
        }, 
        type4: {
            background: "transparent",
            color: "#b3b3b3",
        }
    },
}