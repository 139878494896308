// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import addons
import { Spacer } from '../Spacer'

// Import BreadCrumbs
import { BreadCrumbs } from '../../BreadCrumbs'

// Import proptypes for tabs
import { PropTypes } from 'prop-types'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################

// Import Modules
// eslint-disable-next-line
import { Dummy } from '../../Modules/Dashboard/Dummy'
import { Feed } from '../../Modules/Dashboard/Feed'
import { FollowedUsers } from '../../Modules/Dashboard/FollowedUsers'
import { LatestFollower } from '../../Modules/Dashboard/LatestFollower'
import { MyBalance } from '../../Modules/Dashboard/MyBalance'
import { PostTracker } from '../../Modules/Dashboard/PostTracker'
import { SiteTraffic } from '../../Modules/Dashboard/SiteTraffic'
import { SiteStats } from '../../Modules/Dashboard/SiteStats'

// Import from MUI
import {
        
} from '@mui/material'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// Function for creating tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal.tabpanel-${index}`}
        aria-labelledby={`horizontal.tab-${index}`}
        {...other}
      >
        {value === index && (
          <props.skeleton.CustomBox sx={{ p: 0, width: '100%' }}>
            <props.skeleton.CustomBox sx={{ width: '100%' }}>{children}</props.skeleton.CustomBox>
          </props.skeleton.CustomBox>
        )}
      </div>
    )
}

// Set the propsTypes for tabpanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function Dash (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        currentUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    const params = props.params

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // Set tabValue
    const [tabValue, setTabValue] = useState(0)

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'Overview',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/overview'
        },
    ]

    const tabsections = [
        {
            id: 0, 
            title: 'All',
            name: 'all',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Feed",
                    component: <Feed skeleton={props.skeleton} type="all" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },
        {
            id: 1, 
            title: 'Following',
            name: 'following',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Feed",
                    component: <Feed skeleton={props.skeleton} type="following" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },
        {
            id: 2, 
            title: 'Events',
            name: 'events',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Feed",
                    component: <Feed skeleton={props.skeleton} type="events" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },
        {
            id: 3, 
            title: 'Questions'
        },
        {
            id: 4, 
            title: 'Pages'
        },
    ]

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Handle for changing tab
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    function allyProps(index) {
        return {
          id: `horizontal.tab-${index}`,
          'aria-controls': `horizontal-tabpanel-${index}`,
        }
    }

    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={2} />
        
        {
            <BreadCrumbs primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} list={BCItems} skeleton={props.skeleton} />                  
        } 
        {
            // #region ===[ Section: Title ]==================================================================
            (params.aligntext === "center" &&
                <props.skeleton.CustomTypography nobg="true" variant="h4" props={props} center="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "left" &&
                <props.skeleton.CustomTypography nobg="true" variant="h4" props={props} left="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "right" &&
                <props.skeleton.CustomTypography nobg="true" variant="h4" props={props} right="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            // #endregion ===[ Section: Title ]==============================================================
        }

        {
            // #region ===[ Section: Main Content ]==========================================================
            <props.skeleton.CustomGrid container spacing={0} sx={{ margin: '10px', minHeight: '100vh' }}>
                {                                
                    // #region ==[ Column: Left ]============================================================
                    <props.skeleton.CustomGrid item xs={11.76} sm={11.76} md={3} lg={2} sx={{ padding: '10px' }}>
                        <props.skeleton.CustomTypography variant="body1">
                            {
                                
                            }
                        </props.skeleton.CustomTypography>
                        {
                            // #region ===[ Left side modules here ]=====================================
                            <>
                            <FollowedUsers skeleton={props.skeleton} />
                            <Spacer length={2} />
                            <PostTracker skeleton={props.skeleton} />
                            </>
                            // #end region ===[ Left side modules here ]=================================
                        }
                    </props.skeleton.CustomGrid>

                    
                    // #endregion ==[ Column: Left ]=========================================================
                }

                {
                    // #region ==[ Column: Middle ]===========================================================
                    <props.skeleton.CustomGrid item xs={11.76} sm={11.76} md={5.8} lg={7.8} sx={{ padding: '0px', marginTop: '10px' }} rounded="true" roundedamt="15px" border="border1">
                        <props.skeleton.CustomTypography variant="body1">
                            
                        </props.skeleton.CustomTypography>

                        <props.skeleton.CustomBox sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>                        
                            <props.skeleton.CustomTabs
                                orientation="horizontal"
                                centered
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Horizontal tab"
                                sx={{ 
                                    borderRight: 1, 
                                    borderColor: 'divider', 
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {
                                    tabsections.map((tab) => {
                                        return (
                                            <props.skeleton.CustomTab key={tab.id} label={tab.title} {...allyProps(tab.id)} sx={{
                                                flex: 1,
                                                maxWidth: 'none'
                                            }} />
                                        )
                                    })
                                }
                                
                            </props.skeleton.CustomTabs>

                        </props.skeleton.CustomBox>

                        {
                            Array.from(tabsections).map((ele, index) => {
                                return (
                                    <TabPanel key={ele+index} value={tabValue} index={index} skeleton={props.skeleton}>
                                        {/* #region --[ Tab Nuget ]-------------------------------------------- */}
                                        <props.skeleton.CustomGrid container spacing={0} sx={{  padding: '0px', textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                {/* #region --[ Tab Inner Nuget ]------------------------------ */}
                                                <props.skeleton.CustomGrid container spacing={0} direction={"row"} justifyContent={"stretch"} alignItems={"stretch"}>
                                                    {
                                                        (ele.enabled) 
                                                        ? (
                                                            Array.from(ele.modules).map((elem, key2) => {
                                                                return (
                                                                    /*
                                                                    <props.skeleton.CustomTypography variant="body1">
                                                                        {`Module #${elem.id} - ${elem.name}`}
                                                                    </props.skeleton.CustomTypography>
                                                                    */
                                                                   (elem.enabled) 
                                                                   ? (
                                                                        <div key={key2}>
                                                                            {
                                                                                elem.component
                                                                            }
                                                                        </div>
                                                                   )                                                                   
                                                                   : null
                                                                )
                                                            })
                                                        )
                                                        : null
                                                    }
                                                </props.skeleton.CustomGrid>
                                                {/* #endregion ------------------------------------------------ */}
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                        {/* #endregion ---------------------------------------------------------*/}
                                    </TabPanel>
                                )
                            })
                        }
                    </props.skeleton.CustomGrid>
                    
                    // #endregion ==[ Column: Middle ]========================================================
                }

                {
                    // #region ==[ Column: Right ]============================================================
                    <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={2} sx={{ padding: '10px' }}>                    
                        <props.skeleton.CustomTypography variant="body1">
                            
                        </props.skeleton.CustomTypography>
                        {
                        // #region ===[ Right side modules here ]=========================================
                        }
                        
                        {
                        (currentUser.role === "admin" || currentUser.role === "superadmin")
                        ? (
                            <>
                            <SiteStats skeleton={props.skeleton} />
                            <Spacer length={1} />
                            <SiteTraffic skeleton={props.skeleton} />                                                        
                            <Spacer length={1} />
                            </>
                        )
                        : null                            
                        }

                        {     
                            <>
                            <MyBalance skeleton={props.skeleton} />
                            <Spacer length={1} />
                            </>                           
                        }

                        {      
                            <>                          
                            <LatestFollower skeleton={props.skeleton} />
                            <Spacer length={1} />
                            </>
                        }

                        {
                        // #end region ===[ Right side modules here ]====================================
                        }                                                
                    </props.skeleton.CustomGrid>
                    // #endregion ==[ Column: Right ]========================================================
                }
            </props.skeleton.CustomGrid>                        
            // #endregion ===[ Section: Main Content ]=======================================================
        }
        </>
    )
}