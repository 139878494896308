// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    Avatar,
    //Button,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    //Card,
    //CardActions,
    //CardContent,
    //CardHeader,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomTextField as TextField,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'

// Import Icons
//import EditIcon from '@mui/icons-material/Edit'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
//import SendIcon from '@mui/icons-material/Send'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'

// #region -----------------------[ Functions ]-------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export function ProfileGizmo (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        //AccountEditing,
        //AccountFormIsDisabled,
        currentUser, 
        //isAuthenticated, 
        logoutUser,
        //setAccountEditing,
        //setAccountFormIsDisabled,
        //updateUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // eslint-disable-next-line
    const [ updateStatusSent, setUpdateStatusSent ] = useState(false)

    // Set a temp state to be used with updating profile from gizmo
    //const [temp, setTemp] = useState(null)
    const [notifications, setNotifications] = useState(null)

    const [ showUserName, setShowUserName ] = useState(false)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------  
    const handleUnknown = (path) => {
        toast.info('I lost the game.', {theme: 'dark'})
    }

    const handleLogoutUser = (path) => {
        logoutUser()
        navigate(path)
    }

    const handleHoverUserName = () => {
        setShowUserName(true)
    }

    const handleHoverOutUserName = () => {
        setShowUserName(false)
    }

    const handleNotifications = (notifications) => {
        if (notifications) {
            toast.info('Notifications turned on. WIP: JK.', {theme: 'dark'})
            setNotifications(false)
        } else {
            toast.info('Notifications turned off. WIP: JK.', {theme: 'dark'})
            setNotifications(true)            
        }
    }
    
    // State Rendering
    useEffect(() => {
        if (notifications == null) {
            setNotifications(true)
        }
        // eslint-disable-next-line
    }, [ notifications ])

    // State management for debugging
    useEffect(() => {
        
    }, [  ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {
        // #region -----[ Module: ProfileGizmo ]----------------------------------------------------------------------------------------
        }
        
        <props.skeleton.CustomStack
            bottomgizmo="true"
            spacing={0}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
            sx={{
                //border: 'thin solid black',
            }}
        >
            <props.skeleton.CustomItem bottomgizmo>
                <Avatar alt={currentUser?.displayName} sx={{ width: "40px", height: "40px" }} src={
                    (currentUser?.profileImage !== "" && currentUser?.profileImage !== "no-profileimage.png")
                    ? loadUserImg(currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl)
                    : window.location.origin + "/user/thumbs/no-profileimage.png"
                } />
            </props.skeleton.CustomItem>

            <props.skeleton.CustomItem bottomgizmo sx={{ 
                flexGrow: 1,
             }}>
                <props.skeleton.CustomGrid container spacing={0} onMouseOver={handleHoverUserName} onMouseOut={handleHoverOutUserName}>
                    <props.skeleton.CustomGrid item xs={12}>
                        <props.skeleton.CustomTypography bottomgizmo="true" nobg="true" h6="true">
                            {
                                (currentUser)
                                ? (currentUser?.displayName.length <= 13) ? currentUser?.displayName : currentUser?.displayName?.substr(0, 13)+"..."
                                : "The Guest"
                            }
                        </props.skeleton.CustomTypography>
                    </props.skeleton.CustomGrid>                    

                    <props.skeleton.CustomGrid item={12}>
                        <props.skeleton.CustomGrid container spacing={0}>
                            <props.skeleton.CustomGrid item xs={12} sx={{
                                display: showUserName ? 'inline' : 'none'
                            }}>
                                <props.skeleton.CustomTypography bottomgizmo="true" nobg="true" variant="h7" h7="true">
                                {
                                    (currentUser)
                                    ? (currentUser?.userName?.length <= 13) ? currentUser?.userName : currentUser?.userName?.substr(0, 10)+"..."
                                    : "theguest"
                                } 
                                </props.skeleton.CustomTypography>
                            </props.skeleton.CustomGrid>

                            <props.skeleton.CustomGrid item={12} sx={{
                                display: !showUserName ? 'inline' : 'none'
                            }}>
                                <props.skeleton.CustomTypography bottomgizmo="true" nobg="true" variant="h7" h7="true">
                                {
                                    (currentUser)
                                    ? (currentUser?.status.length <= 16) ? currentUser?.status : currentUser?.status?.substr(0, 16)+"..."
                                    : "In the code..."
                                }
                                </props.skeleton.CustomTypography>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomItem>

            <props.skeleton.CustomItem bottomgizmo>
                <props.skeleton.CustomStack
                    direction={"row"}

                >
                    <props.skeleton.CustomItem>
                        {
                            (currentUser)
                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}><BluetoothIcon /></props.skeleton.Bar>                                        
                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                        }
                    </props.skeleton.CustomItem>

                    <props.skeleton.CustomItem>
                        {
                            (currentUser)
                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleNotifications(notifications)}>{(notifications) ? <NotificationsOffIcon /> : <NotificationsIcon />}</props.skeleton.Bar>                                        
                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleUnknown()}></props.skeleton.Bar>                                        
                        }
                    </props.skeleton.CustomItem>
                    
                    <props.skeleton.CustomItem>
                        {
                            (currentUser)
                            ? <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleLogoutUser("/login")}><LogoutIcon /></props.skeleton.Bar>                                        
                            : <props.skeleton.Bar bottomgizmobutton="true" onClick={() => handleLogoutUser("/login")}><LoginIcon /></props.skeleton.Bar>                                        
                        }
                    </props.skeleton.CustomItem>
                </props.skeleton.CustomStack>
            </props.skeleton.CustomItem>
        </props.skeleton.CustomStack>

        {
        // #endregion -----[ Module: ProfileGizmo ]----------------------------------------------------------------------------------------
        }
        </>
    )
}