//import { useState } from "react"

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

export function DeTemps(func = null, date) {
    if (func) {
        // something besides returning a datestring
        let year, month, day, hour, min, sec
        switch (func) {
            case 'prettyShort':
                // Function: format into a pretty string (Month Day, Year)
                var DateString = date

                year = date?.substr(0, 4)
                month = date?.substr(5, 2)
                day = date?.substr(8, 2)

                // Parse month
                if (month === "01"){month = "January"}
                if (month === "02"){month = "February"}
                if (month === "03"){month = "March"}
                if (month === "04"){month = "April"}
                if (month === "05"){month = "May"}
                if (month === "06"){month = "June"}
                if (month === "07"){month = "July"}
                if (month === "08"){month = "August"}
                if (month === "09"){month = "September"}
                if (month === "10"){month = "October"}
                if (month === "11"){month = "November"}
                if (month === "12"){month = "December"}

                DateString = month + " " + day + ", " + year

                return DateString    
                
            case 'prettyShortWithTime':
                // Function: format into a pretty string (Month Day, Year)
                DateString = date

                //console.log(DateString)

                //2023-07-21T05:08:10.436Z
                //012345678901234567890123

                year = date.substr(0, 4)
                month = date.substr(5, 2)
                day = date.substr(8, 2)
                hour = date.substr(11, 2)
                min = date.substr(14, 2)
                sec = date.substr(17, 2)

                // Parse month
                if (month === "01"){month = "January"}
                if (month === "02"){month = "February"}
                if (month === "03"){month = "March"}
                if (month === "04"){month = "April"}
                if (month === "05"){month = "May"}
                if (month === "06"){month = "June"}
                if (month === "07"){month = "July"}
                if (month === "08"){month = "August"}
                if (month === "09"){month = "September"}
                if (month === "10"){month = "October"}
                if (month === "11"){month = "November"}
                if (month === "12"){month = "December"}

                DateString = month + " " + day + ", " + year + " @ " + hour + ":" + min + ":" + sec

                return DateString                


            case 'timeFrom':
                // Function: get how much time (in seconds) from the date provided
                // Setup variables                
                const DateFromData = new Date(date)
                const NowDate = new Date()

                // Get the offset of the "user"
                const offset = NowDate.getTimezoneOffset() * 60 * 1000

                // Convert DateFromData to millseconds
                const DateFromDataMilliseconds = DateFromData.getTime()

                // Convert NowDate to millseconds
                let NowDateMillseconds = NowDate.getTime()

                // Subtract the timezone
                NowDateMillseconds = NowDateMillseconds - offset

                // Get the milliseconds between the Now and when the date is
                const milliseconds = Math.abs(NowDateMillseconds - DateFromDataMilliseconds).toString()

                // Get the seconds
                const seconds = parseInt(milliseconds / 1000)

                // Get the minutes 
                const minutes = parseInt(seconds / 60)

                // Get the hours
                const hours = parseInt(minutes / 60)

                // Get the days
                const days = parseInt(hours / 24)

                // Create a time string
                // eslint-disable-next-line
                const time = days + ":" + hours % 24 + ":" + minutes % 60 + ":" + seconds % 60

                if (seconds < 30){ return "just now"}
                else if (seconds >= 30 && seconds < 60) { return `${seconds} second${seconds > 1 ? "s" : ""} ago` }
                else if (minutes >= 1 && minutes <= 60) { return `${minutes} minute${minutes > 1 ? "s" : ""} ago` }
                else if (hours >= 1 && hours <= 24) { return `${hours} hour${hours > 1 ? "s" : ""} ago` }
                else if (days >= 1) { return `${days} day${days > 1 ? "s" : ""} ago` }

                /* OLD
                // Calculate difference from Today to the Date from db in milliseconds
                var DateDiffInMilliseconds = Today - DateFromdate

                // Datetime is reporting being 5 hours ahead, subtract 18,000,000 milliseconds (5 hours); I think this is a timezone thing but
                // mongodb does timezone based on ISO so no need to worry. 
                // multiply by 2 if daylight savings time
                // should get the daylight savings time automatically 
                //var DateDiffInMillisecondsFix = DateDiffInMilliseconds - ( 18000000 * 2)

                // Calculate difference from Today to the Date from db in seconds
                var DateDiffInMSeconds = Math.floor(DateDiffInMilliseconds / 1000)

                // Calculate difference from Today to the Date from db in minutes
                var DateDiffInMinutes = Math.floor(DateDiffInMSeconds / 60)

                // Calculate difference from Today to the Date from db in hours
                var DateDiffInHours = Math.floor(DateDiffInMinutes / 60)

                // Calculate difference from Today to the Date from db in days
                var DateDiffInDays = Math.floor(DateDiffInHours / 24)

                // Calculate difference from Today to the Date from db in months
                var DateDiffInMonths = Math.floor(DateDiffInDays / 30)

                // Calculate difference from Today to the Date from db in years
                var DateDiffInYears = Math.floor(DateDiffInMonths / 12)

                if (DateDiffInMSeconds < 60) {
                    return "just now"
                } else if (DateDiffInMinutes < 60) {
                    return `${DateDiffInMinutes} minute${DateDiffInMinutes > 1 ? "s" : ""} ago`
                } else if (DateDiffInHours < 24) {
                    return `${DateDiffInHours} hour${DateDiffInHours > 1 ? "s" : ""} ago`
                } else if (DateDiffInDays < 30) {
                    return  `${DateDiffInDays} day${DateDiffInDays > 1 ? "s" : ""} ago`
                } else if (DateDiffInMonths < 12) {
                    return `${DateDiffInMonths} month${DateDiffInMonths > 1 ? "s" : ""} ago`
                } else {
                    return `${DateDiffInYears} year${DateDiffInYears > 1 ? "s" : ""} ago`
                }
                */
            break 
            
            default: 
                return "error function"
        }
    } else {
        // Set up Joined data
        const TheDate = date

        // Create a Date object
        const DateObj = new Date(TheDate)

        // Get the full year from JoinedDate
        const DateYear = DateObj.getFullYear()

        // Get the month number (1-12) from date
        var DateMonthRaw = DateObj.getMonth()+1

        var DateDay = DateObj.getDate()

        // eslint-disable-next-line
        var DateHours = DateObj.getHours()

        // eslint-disable-next-line
        var DateMins = DateObj.getMinutes()

        // Set up a variable to hold the month word
        var DateMonthWord = ""

        // Parse to see what Month word to use
        if (DateMonthRaw === 1) DateMonthWord = "Jan"
        if (DateMonthRaw === 2) DateMonthWord = "Feb"
        if (DateMonthRaw === 3) DateMonthWord = "Mar"
        if (DateMonthRaw === 4) DateMonthWord = "Apr"
        if (DateMonthRaw === 5) DateMonthWord = "May"
        if (DateMonthRaw === 6) DateMonthWord = "Jun"
        if (DateMonthRaw === 7) DateMonthWord = "Jul"
        if (DateMonthRaw === 8) DateMonthWord = "Aug"
        if (DateMonthRaw === 9) DateMonthWord = "Sep"
        if (DateMonthRaw === 10) DateMonthWord = "Oct"
        if (DateMonthRaw === 11) DateMonthWord = "Nov"
        if (DateMonthRaw === 12) DateMonthWord = "Dec"

        // If date is 1-9, put a "0" infront of it and save it to a variable to avoid mutating the original
        //if (DateMonthRaw < 10) var DateMonth = '0' + DateMonthRaw

        // Create the DateJoinedString
        DateString = DateMonthWord + " "+ DateDay + ", " + DateYear

        return DateString
    }
}

export function settingLookup(name, variables) {
    // Setup variables
    let TheSettingValue = "[ERR : 212]" // Set to a default value to avoid a blank variable value causing problems

    if (variables?.length > 0) {
        // DEBUG: Display all variables in array
        //console.log(variables)

        // For each variables, do an includes match
        variables.forEach((variable) => {
            // DEBUG: Display setting
            //console.log(variable)

            // DEBUG: Display the second item in the setting (the value)
            //console.log(variable[0])
            //console.log(name+": "+variable.includes(name))

            // Conditionally check setting
            if (variable[0] === name) {
                // DEBUG: Display variable value
                //console.log(variable[1])
                
                // Set TheSetting
                TheSettingValue = variable[1]
            }
        })
    }

    // Return the slab!
    return TheSettingValue
}

export function PrettyNumber(number, tooBigString = "TOO BIG") {
    if (number) {
        // setup variables
        let numberInt = 0
        let numberPretty
        let numberString = number.toString()

        // Remove comma
        numberInt = numberString.replace(",", "")

        // Convert to int
        numberInt = parseInt(numberInt)

        // Pretty it
        if (numberInt < 1000) numberPretty = numberInt
        else if (numberInt === 1000) numberPretty = (numberInt / 1000).toPrecision(1) + "K"
        else if (numberInt >= 1000 && numberInt < 10000) numberPretty = (numberInt / 1000).toPrecision(2) + "K"
        else if (numberInt >= 10000 && numberInt < 100000) numberPretty = (numberInt / 1000).toPrecision(3) + "K"
        else if (numberInt >= 100000 && numberInt < 1000000) numberPretty = (numberInt / 1000).toPrecision(4) + "K"
        else if (numberInt >= 1000000 && numberInt < 10000000) numberPretty = (numberInt / 1000000).toPrecision(3) + "M"
        else if (numberInt >= 10000000 && numberInt < 100000000) numberPretty = (numberInt / 1000000).toPrecision(4) + "M"
        else if (numberInt >= 100000000 && numberInt < 1000000000) numberPretty = (numberInt / 1000000).toPrecision(4) + "M"
        else if (numberInt >= 1000000000 && numberInt < 10000000000) numberPretty = (numberInt / 1000000000).toPrecision(3) + "B"
        else if (numberInt >= 10000000000 && numberInt < 100000000000) numberPretty = (numberInt / 1000000000).toPrecision(3) + "B"
        else if (numberInt >= 100000000000 && numberInt < 1000000000000) numberPretty = (numberInt / 1000000000).toPrecision(4) + "B"
        else if (numberInt >= 1000000000000 && numberInt < 10000000000000) numberPretty = (numberInt / 1000000000000).toPrecision(2) + "T"
        else if (numberInt >= 10000000000000 && numberInt < 100000000000000) numberPretty = (numberInt / 1000000000000).toPrecision(3) + "T"
        else if (numberInt >= 100000000000000 && numberInt < 1000000000000000) numberPretty = (numberInt / 1000000000000).toPrecision(4) + "T"
        else numberPretty = tooBigString

        // Return the slab!
        return numberPretty
    } else {
        return 0
    }
}

export function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}