// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import middleware
//import { AuthCheck } from '../../middleware/utils'
import { Greetings, GreetingIO } from '../../middleware/GreetingIO'

// Import Icons
//import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
//import AdminIcon from '@mui/icons-material/AdminPanelSettings'

// Import from MUI
import {
    //Container, Typography,
    //FormGroup,
    //FormControl,
    //FormControlLabel,
    //FormLabel,
    //InputAdornment,
    //Switch,
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import proptypes for tabs
// eslint-disable-next-line
import { PropTypes } from 'prop-types'

// Import components
// eslint-disable-next-line
import { Spacer } from '../../components/Addon/Spacer'

import {  } from '../../middleware/utils'
import { Altheim } from '../../components/Addon/Altheim'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function Overview (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        currentUser
    } = useAuth() 

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set tabValue
    // eslint-disable-next-line
    const [tabValue, setTabValue] = useState(0)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------
    // Set up state for greeting
    const [ Greeting, setGreeting ] = useState(null)
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Normal Functions

    // State rendering
    // GreetingIO
    useEffect(() => {
        setGreeting(GreetingIO(Greetings))
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Altheim primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} type={'dash'} params={{ aligntext: 'left', title: `${Greeting} ${currentUser?.userName}!` }} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
        </props.skeleton.InnerContent>
        </>
    )
}