export const footer = {
    base: {
        background: "#191919",
        color: "white",
        fontsize: "25px",
        fontfamily: 'Rubik'
    },
    button: {
        background: 'cyan',
        backgroundHover: '#009999',
        backgroundActive: '#009999',
        color: 'black',
        colorHover: 'white',
        colorActive: 'white',
        fontfamily: 'Rubik'
    },
    item: {
        base: {
            background: 'cyan',
            backgroundHover: 'white',
            backgroundActive: 'white',
            color: 'black',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        },
        full: {
            background: '#191919',
            backgroundHover: 'white',
            backgroundActive: 'white',
            color: 'white',
            colorHover: 'black',
            colorActive: 'black',
            fontfamily: 'Rubik'
        }
    },
    full: {
        background: "green",
        color: "white",
        fontsize: "25px",
        fontfamily: 'Rubik'
    }
}