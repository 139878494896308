// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

import useE from '../../../middleware/hooks'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'

// Import utils
// eslint-disable-next-line
import { DeTemps, PrettyNumber } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// eslint-disable-next-line
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------


export function MyBalance (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        // Functions
        CheckMyBalance,

        // Variables
        myBalance,
        isAuthenticated
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Hard code for now; database later
    const MyBalanceInfo = [
        { 
            id: 1,
            balance: myBalance,
            currency: "Qoin"
        },        
    ]

    // Sort posts with highest likes
    //traffic.sort(dynamicSort("-likes"))

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions
    
    // State Rendering
    // State Management for getting balance
    useE(2000, true, CheckMyBalance, { isAuthenticated })

    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {/* #region --[ Module - PostTracker ]-------------------------------------------- */} 
        <props.skeleton.CustomGrid container spacing={0} sx={{  }} rounded="true" roundedamt="15px" border="border1">
            {/* #region -[ Heading ]------------------------------------------------------ */}
            <props.skeleton.CustomGrid item gem="true" color="quarternarydarker" xs={12} sx={{ 
                padding: '13px',
                fontSize: '12px',
                borderBottom: 'thin solid #4d4d4d'
                }}>
                <props.skeleton.Heading h3 nobg>
                    
                </props.skeleton.Heading>
                <props.skeleton.Heading h7 nobg>
                    My Balance
                </props.skeleton.Heading>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}

            {/* #region -[ Body ]--------------------------------------------------------- */}
            <props.skeleton.CustomGrid item xs={12} sx={{ 
                
                }}>                                        
                    <props.skeleton.CustomGrid item xs={12} gem="true" color="quarternary" sx={{ fontSize: '12px', padding: '10px', borderBottom: 'thin solid #4d4d4d' }}>
                        <props.skeleton.Heading h8 nobg>
                            {(myBalance) ? MyBalanceInfo[0].balance.toLocaleString() : null} {(myBalance) ? MyBalanceInfo[0].currency : null}
                        </props.skeleton.Heading>
                    </props.skeleton.CustomGrid>

            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}
            
        </props.skeleton.CustomGrid>
        {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}