// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, usePost } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
    Avatar
} from '@mui/material'

// Import custom hooks
//import useE from '../../../middleware/hooks'

// Import utils
import { DeTemps, PrettyNumber } from '../../../middleware/utils'
import { Spacer } from '../../Addon/Spacer'
import { LookupLicon } from '../../../libraries/licons'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}

function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

// eslint-disable-next-line
function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function Feed (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        // Functions

        // Variables
        isAuthenticated,
        currentUser
    } = useAuth()   

    const { 
        // Functions

        // Variables
        theslices
    } = usePost()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion -----------------------------------------------------------------------------------------------

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    let thefeed = []

    const LiveFeedAll = [
        { 
            id: 1,
            type: "shortpost",
            authorDetails: {
                profileImage: "drphil.jpg",
                userName: "drphil"
            },
            snippet: "I like to be a pirate and I do not know why. It is good. Don't mind this little short.",
            dateandtime: "2024-01-13T19:53:40.005+00:00",
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Nice post!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }
        },
        { 
            id: 2,
            type: "longpost",
            title: "Living life like a sable",
            slug: "living-life-like-a-sable",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            snippet: "I like to be a sable. Sables are very good and are so soft.",
            dateandtime: "2024-02-11T19:53:40.005+00:00",
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "This is fun",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }
        },
        {
            id: 3,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-02-11T19:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you",
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 4,
            type: "like",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "drphil"
            },
            dateandtime: "2024-02-10T11:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            },
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 5,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "mrbeast"
            },
            dateandtime: "2023-02-05T14:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you",  
            params: {
                allowComment: false,
                showStats: false,
            }          
        },
        {
            id: 6,
            type: "longpost",
            title: "Drumming Compilation #234",
            snippet: "A compilation of videos from my stream while I drum.",
            slug: "drumming-compilation---num--234",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "drumstickpony"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "I like this!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",    
            params: {
                allowComment: true,
                showStats: true,
            }        
        },
        {
            id: 7,
            type: "longpost",
            title: "All about kitty butts and tails",
            snippet: "Did you ever wanna know about kitty butts? Look no further then this fluffy article describing all about kitty tails.",
            slug: "all-about-kitty-butts-and-tails",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Kitties!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-05-29T02:10:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",  
            params: {
                allowComment: true,
                showStats: true,
            }          
        },
        {
            id: 8,
            type: "unfollow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-02-13T15:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "unfollowed you",            
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 9,
            type: "like",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "deadpiratebrandon"
            },
            dateandtime: "2024-02-13T16:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            },
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 10,
            type: "income",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-04-14T00:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: `gave ${PrettyNumber(23439)} Qoin to you`,  
            params: {
                allowComment: false,
                showStats: false,
            }          
        },
        {
            id: 11,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2023-02-14T00:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you", 
            params: {
                allowComment: false,
                showStats: false,
            }           
        },
    ]

    const LiveFeedEvents = [
        {
            id: 1,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-02-11T19:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you",
            params: {
                allowComment: false,
                showStats: false,
            },
        },
        {
            id: 2,
            type: "like",
            authorDetails: {
                profileImage: "drphil.jpg",
                userName: "drphil"
            },
            dateandtime: "2024-02-10T11:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            },
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 3,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "mrbeast"
            },
            dateandtime: "2023-02-05T14:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you", 
            params: {
                allowComment: false,
                showStats: false,
            }           
        },        
        {
            id: 8,
            type: "unfollow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-02-13T15:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "unfollowed you",  
            params: {
                allowComment: false,
                showStats: false,
            }          
        },
        {
            id: 9,
            type: "like",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "deadpiratebrandon"
            },
            dateandtime: "2024-02-13T16:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            },
            params: {
                allowComment: false,
                showStats: false,
            }
        },
        {
            id: 10,
            type: "income",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-04-14T00:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: `gave ${PrettyNumber(23439)} Qoin to you`,  
            params: {
                allowComment: false,
                showStats: false,
            }          
        },
        {
            id: 11,
            type: "follow",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2023-02-14T00:53:40.005+00:00",            
            commentsCount: 5,
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "followed you",     
            params: {
                allowComment: false,
                showStats: false,
            }       
        },
    ]

    const LiveFeedFollowing = [
        { 
            id: 1,
            type: "shortpost",
            title: null,
            authorDetails: {
                profileImage: "drphil.jpg",
                userName: "drphil"
            },
            snippet: "I like to be a pirate and I do not know why. It is good. Don't mind this little short.",
            dateandtime: "2024-01-13T19:53:40.005+00:00",
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Nice post!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }
        },
        { 
            id: 2,
            type: "longpost",
            title: "Living life like a sable",
            slug: "living-life-like-a-sable",
            authorDetails: {                
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            snippet: "I like to be a sable. Sables are very good and are so soft.",
            dateandtime: "2024-02-11T19:53:40.005+00:00",
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "This is fun",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }
        },        
        {
            id: 3,
            type: "longpost",
            title: "Drumming Compilation #234",
            snippet: "A compilation of videos from my stream while I drum.",
            slug: "drumming-compilation---num--234",
            authorDetails: {
                profileImage: "no-profileimage.png",
                userName: "drumstickpony"                
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "I like this!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }         
        },
        {
            id: 4,
            type: "longpost",
            title: "All about kitty butts and tails",
            snippet: "Did you ever wanna know about kitty butts? Look no further then this fluffy article describing all about kitty tails.",
            slug: "all-about-kitty-butts-and-tails",
            authorDetails: {                
                profileImage: "no-profileimage.png",
                userName: "seorisethesable"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Kitties!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-05-29T02:10:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }            
        },
    ]

    const Profile = [
        { 
            id: 1,
            type: "shortpost",
            authorDetails: {
                profileImage: "serid9.jpg",
                userName: "mrimpress"
            },
            snippet: "I like to be a pirate and I do not know why. It is good. Don't mind this little short.",
            dateandtime: "2024-01-13T19:53:40.005+00:00",
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Nice post!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-01-19T14:53:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",
            params: {
                allowComment: true,
                showStats: true,
            }
        },        
        {
            id: 2,
            type: "longpost",
            title: "All about kitty butts and tails",
            snippet: "Did you ever wanna know about kitty butts? Look no further then this fluffy article describing all about kitty tails.",
            slug: "all-about-kitty-butts-and-tails",
            authorDetails: {
                profileImage: "serid9.jpg",
                userName: "mrimpress"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            commentsCount: 5,
            comments: [
                {
                    id: 1, 
                    content: "Kitties!",
                    authorDetails: {
                        profileImage: "serid9.jpg",
                        userName: "mrimpress"
                    },
                    dateandtime: "2024-05-29T02:10:40.005+00:00",
                }
            ],
            dislikesCount: 2,            
            likesCount: 24,
            sharesCount: 12,
            action: "posted",  
            params: {
                allowComment: true,
                showStats: true,
            }          
        }
    ]

    // Sort livefeed
    thefeed.sort(dynamicSort("-dateandtime"))
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------
    const [ commentData, setCommentData ] = useState({
        id: null,
        value: null
    })
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions
    const handleAddingComment = (id) => {
        setCommentData({...commentData, id: id})
    }

    const handleLike = (id) => {
        toast.info(`WIP: Sending a like on: ${id} to API.`, { theme: 'light'})
    }

    const handleDislike = (id) => {
        toast.info(`WIP: Sending a dislike on: ${id} to API.`, { theme: 'light'})
    }

    const handleSendComment = (id) => {
        if (id === commentData.id) {
            // All good, right box
            // Check for blank
            if (commentData.value && commentData.value !== "") {
                // No blanks, send to api
                // For now toast
                toast.info(`WIP: Sending comment to API`, {theme: 'light'})
            } else {
                // Blank 
                toast.error(`Comment cannot be blank`, {theme: 'light'})
            }
        } else {
            // Trying to send from another comment box; fake and show cannot be blank
            toast.error(`Comment cannot be blank`, {theme: 'light'})
        }
    }

    const handleStopAddingComment = (id) => {
        setCommentData({...commentData, id: id})
    }

    const getCommentData = (id) => {
        if (commentData.id === id) {
            return commentData.value
        } else {
            return ''
        }
    }

    // State Rendering - debugging
    useEffect(() => {
        //console.log("ID: "+commentData.id)
        //console.log("Value: "+commentData.value)
        //if (theslices) {console.log(theslices)}
    }, [ theslices ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    if (props.type === "all") thefeed = (theslices) ? theslices : LiveFeedAll
    else if (props.type === "following") thefeed = (theslices) ? theslices : LiveFeedFollowing
    else if (props.type === "events") thefeed = (theslices) ? theslices : LiveFeedEvents
    else if (props.type === "groups") thefeed = (theslices) ? theslices : Profile
    else if (props.type === "pages") thefeed = (theslices) ? theslices : Profile
    else if (props.type === "profile") thefeed = (theslices) ? theslices : Profile    
    else thefeed = (theslices) ? theslices : LiveFeedAll

    // Render
    return (
        <>
        {/* #region --[ Module - Feed ]--------------------------------------------------- */} 
        <props.skeleton.CustomGrid container spacing={0} sx={{ }}>
            {/* #region -[ Heading ]------------------------------------------------------ */}
            <props.skeleton.CustomGrid item xs={12} sx={{ 
                padding: "0px",
            }}>
                <props.skeleton.Heading h3 center nobg>
                    
                </props.skeleton.Heading>
                <props.skeleton.Heading h6 center nobg>
                    
                </props.skeleton.Heading>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}

            {/* #region -[ Body ]--------------------------------------------------------- */}
            <props.skeleton.CustomGrid panelback="true" item xs={12} sm={12} md={12} sx={{ 
                padding: "0px"
            }}>                                            
                {
                    thefeed.map((item, key) => {
                        return (
                            <props.skeleton.CustomGrid panel="true" container spacing={0} key={key} sx={{ padding: '10px', marginTop: '0px', marginBottom: '30px' }}>                               
                                {
                                // #region ====[ Title ]=============================================================================
                                }
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.CustomGrid container spacing={0}>
                                        <props.skeleton.CustomGrid item xs={.7}>
                                        {
                                            // #region ====[ Author Image ]======================================================================
                                            }                                        

                                            {                                                    
                                                (item.authorDetails.profileImage && (item.authorDetails.profileImage !== "no-profileimage.png" && item.profileImage !== ""))
                                                ? (
                                                    <props.skeleton.CustomBox
                                                        component="img"
                                                        sx={{
                                                            height: 35,
                                                            width: 35,
                                                            maxHeight: { xs: 35, md: 35 },
                                                            maxWidth: { xs: 35, md: 35 },
                                                            position: 'relative',
                                                        }}
                                                        alt={`${item.authorDetails.userName}'s Profile Image`}
                                                        src={loadUserProfileImage(item.authorDetails.userName, item.authorDetails.profileImage, props.settingServerAPIBaseUrl )}
                                                    />
                                                )
                                                : (
                                                    <props.skeleton.CustomBox
                                                        component="img"
                                                        sx={{
                                                            height: 35,
                                                            width: 35,
                                                            maxHeight: { xs: 35, md: 35 },
                                                            maxWidth: { xs: 35, md: 35 },
                                                            position: 'relative',
                                                        }}
                                                        alt={`${item.authorDetails.userName}'s Profile Image`}
                                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                                    />
                                                )
                                            }    

                                            {
                                            // #endregion ====[ Author Image ]===================================================================
                                            }
                                        </props.skeleton.CustomGrid>

                                        {
                                        // #region ====[ Author Name ]===========================================================================
                                        }

                                        <props.skeleton.CustomGrid item xs={10.8} sm={11.1} md={10.8} lg={11.1} sx={{ lineHeight: '0.8em', marginLeft: { xs: '10px', sm: '0px', md: '10px', lg: '0px' } }}>
                                            <props.skeleton.Heading button h5 nobg>
                                                {item.authorDetails.userName}                                                         
                                            </props.skeleton.Heading> 
                                            
                                            <props.skeleton.Heading button h8 nobg grayed>
                                                @{item.authorDetails.userName} - {DeTemps('timeFrom', item.dateandtime)}                                                  
                                            </props.skeleton.Heading> 
                                        </props.skeleton.CustomGrid>

                                        {
                                        // #endregion ====[ Author Name ]========================================================================
                                        }
                                        
                                        {
                                        // #region ====[ Author Action ]=========================================================================
                                        }
                                        <props.skeleton.CustomGrid item xs={11.5}>
                                            <props.skeleton.Heading button h7 nobg>
                                                {item.action} 
                                                {
                                                    (item.type !== "follow" && item.type !== "unfollow" && item.type !== "like" && item.type !== "income") 
                                                    ? (
                                                        (item.title) 
                                                        ? (
                                                            <props.skeleton.CustomLink nobg onClick={() => navigate(`/post/${item.slug}`)}>
                                                                {` "${item.title}"`}
                                                            </props.skeleton.CustomLink>
                                                        )
                                                        : ` a ${item.type} that contained:`
                                                    )
                                                    : ""
                                                }
                                            </props.skeleton.Heading> 
                                        </props.skeleton.CustomGrid>

                                        {
                                        // #endregion ====[ Author Action ]======================================================================
                                        }
                                    </props.skeleton.CustomGrid>                                                                                                                                                                          
                                </props.skeleton.CustomGrid>
                                {
                                // #endregion ====[ Title ]========================================================================
                                }

                                {
                                // #region ====[ Snippet ]=========================================================================
                                }

                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading button h8 nobg>
                                        {
                                            (item.snippet) 
                                            ? item.snippet
                                            : null
                                        }
                                    </props.skeleton.Heading>                                                                                                                               
                                </props.skeleton.CustomGrid>
                                
                                {
                                // #endregion ====[ Snippet ]========================================================================
                                }

                                <Spacer length={3} />

                                {
                                // #region ====[ Stats ]=============================================================================
                                }

                                {
                                    (item.params?.showStats)
                                    ? (
                                        <props.skeleton.CustomGrid item xs={12} bordertopbottom="true">
                                            <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                                                <props.skeleton.CustomGrid item xs={3} sx={{  }}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        {LookupLicon("CommentIcon")} {PrettyNumber(item.commentsCount)}
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Bar center="true" buttonsmallnobg="true" onClick={() => handleLike(item.id)}>
                                                        <props.skeleton.Heading button center h5 nobg>
                                                            {LookupLicon("ThumbsUpIcon")} {PrettyNumber(item.likesCount)}
                                                        </props.skeleton.Heading>
                                                    </props.skeleton.Bar>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Bar center="true" buttonsmallnobg="true" onClick={() => handleDislike(item.id)}>
                                                        <props.skeleton.Heading button center h5 nobg>
                                                            {LookupLicon("ThumbsDownIcon")} {PrettyNumber(item.dislikesCount)}
                                                        </props.skeleton.Heading>
                                                    </props.skeleton.Bar>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        {LookupLicon("ShareIcon")} {PrettyNumber(item.sharesCount)}
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    )
                                    : (
                                        <props.skeleton.CustomGrid item xs={12}>
                                            <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                                                <props.skeleton.CustomGrid item xs={3} sx={{  }}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        &nbsp; &nbsp; &nbsp;
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        &nbsp; &nbsp; &nbsp;
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        &nbsp; &nbsp; &nbsp;
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={3}>
                                                    <props.skeleton.Heading button center h5 nobg>
                                                        &nbsp; &nbsp; &nbsp;
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    )
                                }

                                {
                                // #endregion ====[ Likes ]================================================================================
                                }

                                <Spacer length={3} />

                                {
                                // #region ====[ Comment Section ]=========================================================================
                                }
                                {
                                    (item.params?.allowComment)
                                    ? (
                                        <>
                                        <props.skeleton.CustomGrid item xs={12} sx={{ marginTop: '10px' }}>
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                {
                                                    (item.comments)
                                                    ? (
                                                        (item.comments.length > 0) 
                                                        ? (
                                                            // eslint-disable-next-line
                                                            Array.from(item.comments).map((comment, key) => {
                                                                if (key <= 5) {
                                                                    return (
                                                                        <props.skeleton.CustomGrid key={key} item xs={12} bordertopbottom="true">
                                                                            <props.skeleton.CustomGrid container spacing={0}>
                                                                                <props.skeleton.CustomGrid item xs={.7}>
                                                                                    {                                                    
                                                                                        (comment.authorDetails.profileImage && (comment.authorDetails.profileImage !== "no-profileimage.png" && comment.profileImage !== ""))
                                                                                        ? (
                                                                                            <props.skeleton.CustomBox
                                                                                                paddingzero="true"
                                                                                                component="img"
                                                                                                sx={{
                                                                                                    height: 35,
                                                                                                    width: 35,
                                                                                                    maxHeight: { xs: 35, md: 35 },
                                                                                                    maxWidth: { xs: 35, md: 35 },
                                                                                                    position: 'relative',
                                                                                                }}
                                                                                                alt={`${comment.authorDetails.userName}'s Profile Image`}
                                                                                                src={loadUserProfileImage(comment.authorDetails.userName, comment.authorDetails.profileImage, props.settingServerAPIBaseUrl )}
                                                                                            />
                                                                                        )
                                                                                        : (
                                                                                            <props.skeleton.CustomBox
                                                                                                component="img"
                                                                                                paddingzero="true"
                                                                                                sx={{
                                                                                                    height: 35,
                                                                                                    width: 35,
                                                                                                    maxHeight: { xs: 35, md: 35 },
                                                                                                    maxWidth: { xs: 35, md: 35 },
                                                                                                    position: 'relative',
                                                                                                }}
                                                                                                alt={`${comment.authorDetails.userName}'s Profile Image`}
                                                                                                src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                                                                            />
                                                                                        )
                                                                                    }  
                                                                                </props.skeleton.CustomGrid>

                                                                                <props.skeleton.CustomGrid item xs={9.1} sx={{ justifyContent: 'center', alignContent: 'center' }}>
                                                                                    <props.skeleton.CustomTypography variant="body1">
                                                                                        {comment.authorDetails.userName}: {comment.content}
                                                                                    </props.skeleton.CustomTypography>
                                                                                </props.skeleton.CustomGrid>

                                                                                <props.skeleton.CustomGrid item xs={1.8} sx={{ justifyContent: 'center', alignContent: 'center' }}>
                                                                                    <props.skeleton.CustomTypography variant="body1">
                                                                                            {DeTemps("timeFrom", comment.dateandtime)}
                                                                                    </props.skeleton.CustomTypography>
                                                                                </props.skeleton.CustomGrid>
                                                                            </props.skeleton.CustomGrid> 
                                                                        </props.skeleton.CustomGrid>
                                                                    )
                                                                }
                                                            })
                                                        )
                                                        : null
                                                    )
                                                    : null
                                                }
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                        
                                        <props.skeleton.CustomGrid item xs={12} sx={{ marginTop: '10px' }}>
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid item xs={.8} sx={{ border: '', margin: '0 auto', alignContent: 'center' }}>
                                                    <props.skeleton.Heading h7 nobg center >
                                                        {isAuthenticated 
                                                            ? ((currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                                            ? (
                                                                <Avatar 
                                                                alt={currentUser?.displayName} sx={{ width: "40px" }} 
                                                                src={loadUserImg(currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl )} 
                                                                />
                                                            )
                                                            : (
                                                                <Avatar 
                                                                alt={currentUser?.displayName} sx={{ width: "40px" }} 
                                                                src={window.location.origin+"/user/thumbs/no-profileimage.png"} 
                                                                />
                                                            )
                                                            )
                                                            : <Avatar alt="Guest" sx={{ width: "40px" }} src={window.location.origin+"/user/thumbs/no-profileimage.png"} />
                                                        }
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={10.2}>
                                                    <props.skeleton.Heading h7 nobg>
                                                        {isAuthenticated
                                                            ? (
                                                                <props.skeleton.CustomTextField
                                                                    label={`Say something nice`}
                                                                    name={`comment-${item.id}`}
                                                                    value={getCommentData(item.id) || ''} 
                                                                    onFocus={() => handleAddingComment(item.id)}
                                                                    onBlur={() => handleStopAddingComment(item.id)}
                                                                    onChange={(e) => setCommentData({...commentData, value: e.target.value})}
                                                                />
                                                            )
                                                            : (
                                                                // Don't how you got here, this is the feed on the dashboard; so it will never show this but just in case
                                                                <props.skeleton.CustomTypography variant="body1">
                                                                    Must be logged in to comment
                                                                </props.skeleton.CustomTypography>
                                                            )
                                                        }
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={1}>
                                                    <props.skeleton.Heading button right h4 nobg>
                                                        <props.skeleton.Bar sx={{ paddingTop: '7px', paddingBottom: '7px' }} button="true" center="true" onClick={() => handleSendComment(item.id)}>Send</props.skeleton.Bar>
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                        </>
                                    )
                                    : (
                                        <props.skeleton.CustomGrid item xs={12} sx={{ border: '' }}>
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid item xs={.8} sx={{ border: '', margin: '0 auto', alignContent: 'center' }}>
                                                    <props.skeleton.Heading h7 nobg center >
                                                        {isAuthenticated 
                                                            ? ((currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                                            ? (
                                                                <>&nbsp; &nbsp; &nbsp;</>
                                                            )
                                                            : (
                                                                <>&nbsp; &nbsp; &nbsp;</>
                                                            )
                                                            )
                                                            : <>&nbsp; &nbsp; &nbsp;</>
                                                        }
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={10.2}>
                                                    <props.skeleton.Heading h7 nobg>
                                                        {isAuthenticated
                                                            ? (
                                                                <props.skeleton.CustomTextField
                                                                    label={``}
                                                                    disabled={true}
                                                                    name={`unknown`}
                                                                    sx={{ opacity: '0' }}
                                                                    value={''}                                                                             
                                                                />
                                                            )
                                                            : (
                                                                // Don't how you got here, this is the feed on the dashboard; so it will never show this but just in case
                                                                <props.skeleton.CustomTypography variant="body1">
                                                                    &nbsp; &nbsp; &nbsp;
                                                                </props.skeleton.CustomTypography>
                                                            )
                                                        }
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={1}>
                                                    <props.skeleton.Heading button right h4 nobg>
                                                        <>&nbsp; &nbsp; &nbsp;</>
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    )
                                }
                                {
                                // #endregion ====[ Comment Section ]======================================================================
                                }
                            </props.skeleton.CustomGrid>
                        )
                    })
                }
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}
            
        </props.skeleton.CustomGrid>
        {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}