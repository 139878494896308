// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'
import { PrettyNumber } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------


export function FollowedUsers (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Hard code for now; database later
    const followedUsers = [
        { 
            id: 1,
            name: "DrPhil",
            status: "Doing doctor things",
            followers: 293
        },
        {
            id: 2,
            name: "MrBeast",
            status: "giving $10k away to a random stranger",
            followers: 2398
        },
        {
            id: 3,
            name: "DrumstickPony",
            status: "being a pony",
            followers: 320
        },
        {
            id: 4,
            name: "DeadPirateBrandon",
            status: "doing pirate things",
            followers: 9383
        }, 
        {
            id: 5,
            name: "SeoriseTheSable",
            status: "I'm doing something good",
            followers: 349
        }
    ]

    // Sort followedUsers
    followedUsers.sort(dynamicSort("name"))

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions
    // Determine the top follower 
    const determineTopFollowerCount= (items) => {
        // Sort items by highest
        const topItem = items.sort(dynamicSort("-followers"))[0]

        // Return the id
        return topItem.id
    }
    
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {/* #region --[ Module - Friends ]------------------------------------------------ */} 
        <props.skeleton.CustomGrid container spacing={0} sx={{  }} rounded="true" roundedamt="15px" border="border1">
            {/* #region -[ Heading ]------------------------------------------------------ */}
            <props.skeleton.CustomGrid item gem="true" color="quarternarydarker" xs={12} sx={{ 
                padding: '13px',
                fontSize: '12px',
                borderBottom: 'thin solid #4d4d4d'
                }}>
                <props.skeleton.Heading h3 nobg>
                    
                </props.skeleton.Heading>
                <props.skeleton.Heading h6 nobg>
                    Followed Users
                </props.skeleton.Heading>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}

            {/* #region -[ Body ]--------------------------------------------------------- */}
            <props.skeleton.CustomGrid item xs={12} sx={{ 
                    
                }}>                                        
                    {
                        followedUsers.map((item, key) => {
                            return (
                                <props.skeleton.CustomGrid gem="true" color="quarternary" container highlight={
                                    (item.id === determineTopFollowerCount(followedUsers)) ? "golden" : "normal"
                                } spacing={0} key={key} sx={{ padding: '10px', borderBottom: 'thin solid #4d4d4d' }}>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading button highlight={
                                            (item.id === determineTopFollowerCount(followedUsers)) ? "golden" : "normal"
                                        } h7 nobg>
                                            {item.name}
                                        </props.skeleton.Heading>                                                                                                                               
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.CustomGrid container spacing={0}>
                                            <props.skeleton.CustomGrid item xs={12}>
                                                <props.skeleton.Heading button center highlight={
                                                    (item.id === determineTopFollowerCount(followedUsers)) ? "goldengrayed" : "normal"
                                                } h9 nobg>
                                                    ?: {item.status}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={12}>
                                                <props.skeleton.Heading button highlight={
                                                    (item.id === determineTopFollowerCount(followedUsers)) ? "goldengrayed" : "normal"
                                                } right h6 nobg>
                                                    {PrettyNumber(item.followers)} followers
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            )
                        })
                    }
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}
            
        </props.skeleton.CustomGrid>
        {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}