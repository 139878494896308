// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useRef, useCallback } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, usePost } from '../../middleware/contextHooks'

// Import reactDOM
//import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps, PrettyNumber } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { CustomContainer as Container } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
//import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
//import ArrowBackIcon from '@mui/icons-material/ArrowBack'
//import CalendarIcon from '@mui/icons-material/CalendarMonth'
//import CloseIcon from '@mui/icons-material/Close'
//import LocationIcon from '@mui/icons-material/LocationOn'
//import RealityIcon from '@mui/icons-material/Public'
//import SendIcon from '@mui/icons-material/Send'
//import VerifiedIcon from '@mui/icons-material/Verified'
//import WarningIcon from '@mui/icons-material/Warning'
//import WorkIcon from '@mui/icons-material/Work'

// Import from MUI
//import { CardContent } from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomItem as Item,
    CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
    CustomTypography as Typography,
    //CustomBox
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import components
//import PublicSlice from '../../components/PublicSlice'
import { Spacer } from '../../components/Addon/Spacer'
//import { PostCard } from '../../components/Addon/PostCard'
//import PublicSlice from '../../components/PublicSlice'

// Import Splide (carousel)
// eslint-disable-next-line
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

// Import 
import { Altheim } from '../../components/Addon/Altheim'

// Import Custom Hooks
import useE from '../../middleware/hooks'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// eslint-disable-next-line
function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

// eslint-disable-next-line
function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export default function Profile (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Functions
        //CheckIsFollowing,
        CheckMyBalance,
        getAllUsersPublically,
        getFollowers,  
        getFollowing,
        getFriends,      
        getPublicProfile,        
        // Variables
        //AllUsers,
        isAuthenticated,
        //isFollowing,
        //isFriend,
        currentUser,             
        //Followers,
        //Following,
        //Friends,
        //FollowerCount,
        //FollowingCount,
        //FriendsCount,
        //myBalance,
    } = useAuth()   

    const { 
        // Functions
        getAllSlicesByUserNamePublically,

        // Variables
    } = usePost()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------    

    // State Rendering for getting Public Profile
    useE(2000, false, getPublicProfile, { args: { id: props.id }, isAuthenticated }, props.id)

    // State Rendering for setting Public Profile - Slices
    useE(4000, false, getAllSlicesByUserNamePublically, { args: { id: props.id, type: 'anyposts' }, isAuthenticated }, props.id)
    
    // State Rendering for getting all users
    useE(30000, false, getAllUsersPublically, { isAuthenticated })

    // State Management for getting balance
    useE(2000, true, CheckMyBalance, { isAuthenticated })
    
    // State Rendering for getting Public Profile - followers
    useE(8000, false, getFollowers, { args: { id: props.id }, isAuthenticated }, props.id)

    // State Rendering for getting Public Profile - following
    useE(8000, false, getFollowing, { args: { id: props.id }, isAuthenticated }, props.id)
    
    // State Rendering for getting Public Profile - friends
    useE(8000, false, getFriends, { args: { id: props.id }, isAuthenticated }, props.id)

    // State Rendering for debugging
    useEffect(() => {
        //if (theslices) {console.log(theslices)}
        //console.log("isFollowing: "+isFollowing)
        //console.log("isSettingUnfollow: "+isSettingUnfollow)
        //console.log(sendingCurrencyData)
        //console.log(myBalance)
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={2} />
        {
            (currentUser)
            ? (
                // #region -----[ Individual Profile ]----------------------------------------------------------------------------
                <>
                <Altheim primary={props.primary} secondary={props.secondary} tertiary={props.tertiary} skeleton={props.skeleton} type={'profile'} currentUser={currentUser} params={{ aligntext: 'center', title: "" }} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                </>
                // #endregion ---------------------------------------------------------------------------------------------------
            )
            : null
        }
        </>
    )
}