// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import others
import { useSetting } from '../../middleware/contextHooks'

// Import from MUI
import {
    //Container,
} from '@mui/material'

// Import components
import { CallToAction } from '../../components/Addon/CallToAction'
import { Singlet } from '../../components/Addon/Singlet'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { Spacer } from '../../components/Addon/Spacer'

// Import utils
import { settingLookup } from '../../middleware/utils'

// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function About (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Home',
            'type': 'link',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'About',
            'type': 'text',
            'grayed': true,
            'url': ''
        },
    ]
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    const {
        variables
    } = useSetting()
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <Spacer length={5} />

        <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
        <Spacer length={2} />
        <CallToAction skeleton={props.skeleton}     
            abovebutton={9}
            belowbutton={5}
            abovetext={7}
            belowtext={7}
            bodytext={`${settingLookup('aboutpageSingletOne', variables)}`}
            bodytextalign={'left'}
            buttontext={'What? A button?'}
            buttoncolor={'primary'} // options: primary, secondary, tertiary, quarternary
            buttonurl={'/'} // Where the button goes to; will override if buttontoast is set
            buttontoast={'I lost the game'} // Leave blank to not set it as a notification
            buttontype={'normal'} // options: normal, error, info, warning
            color={'primary'} // options: primary, secondary, tertiary, quarternary
            padding={1}
            primarytext={`${settingLookup('SiteTitle', variables)}`}
            primarytextalign={'left'}
            secondarytext={'...all about it.'}
            secondarytextalign={'left'}
        />

        <Singlet skeleton={props.skeleton} 
            color={'secondary'} // options: primary, secondary, tertiary, quarternary
            content={`${settingLookup('aboutpageSingletOne', variables)}`}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'left'}
            title={`About ${settingLookup('SiteTitle', variables)}`}
        />

        <Singlet skeleton={props.skeleton} 
            color={'tertiary'} // options: primary, secondary, tertiary, quarternary
            content={`
                * Advanced Support Ticketing System (Update 2.9.3)<br />
                * AI guided exploration (Way Far Future)<br />
                * Complete RESTful API backend<br />
                * Dynamic Navigation System (DNS)<br />
                * Dynamic Page loading (via database or file)<br />
                * Dynamic Permission Node System (DPNS) (Update 2.9.2)<br />
                * Forgot Password System and Email Verify System<br />
                * Full-Featured Dashboard<br />
                * In-house Mailer as to not have to rely on gmail (Update 2.8.4)<br />
                * Magic-Execution<br />
                * One-of-a-kind Payment Network (Far Future)<br />
                * Plugin System (Update 2.9.1)<br />
                * Robust Theming Engine<br />
                * Secure JWT authentication<br />
        
            `}
            padding={'50px 0px 50px 0px'} // Top Right Bottom Left
            textalign={'left'}
            title={`Features of ${settingLookup('SiteTitle', variables)}`}
        />
        </>
    )
}