export const customlink = {
    base: {
        background: "none",
        backgroundHover: "red",
        backgroundActive: "red",
        color: "black",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    basealt: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "black",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    border: {
        params: {
            border: "thin solid #e65fa2"
        },
        styles: {
            one: "thin solid white",
            default: "thin solid cyan"
        },
    },
    breadcrumb: {
        base: {
            background: "red",
            backgroundHover: "#191919",
            color: "black !important", // sometimes you need !important if it doesn't change
            colorHover: "white !important",
            fontsize: "15px",
            padding: "10px"
        }
    },
    copyrightlink: {
        base: {
            background: "none",
            backgroundHover: "red",
            backgroundActive: "red",
            color: "white",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    primary: {
        background: "none",
        backgroundHover: "red",
        backgroundActive: "red",
        color: "black",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
}