// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'
import { LookupLicon } from '../../../libraries/licons'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function AccountButtons (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        // Functions

        // Variables
        //isAuthenticated,
        currentUser,
        PublicProfile
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion -----------------------------------------------------------------------------------------------

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions

    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {
            (props.params.reqAuth) 
            ? (
                (currentUser && PublicProfile) 
                ? (
                    (currentUser.userName === PublicProfile.userName) 
                    ? (
                        <props.skeleton.CustomGrid container spacing={0} rounded="true" roundedamt="15px" border={props.border} sx={{
                            marginTop: (props.params.margintop ? props.params.margintop : '0px')    
                        }}>
                            <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                                <props.skeleton.CustomTypography padding={props.params.padding} margin={props.params.margin} variant="h6">
                                    {props.name}
                                </props.skeleton.CustomTypography>
                            </props.skeleton.CustomGrid>
    
                            <props.skeleton.CustomGrid gem="true" color="quarternary" item xs={12} nouserselect="true" sx={{                
                                marginBottom: '0px',
                                marginRight: '0px',                
                                marginTop: (props.name !== "") ? '0px' : '0',
                                                
                            }}>
                                <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("MarkEmailUnreadIcon")} Invitation Requests (4)</props.skeleton.Bar>
                                <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("NoAccountsIcon")} Blocked Accounts (400)</props.skeleton.Bar>
                                <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("LibraryAddIcon")} Create Page</props.skeleton.Bar>
                                <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("LibraryAddIcon")} Create Group</props.skeleton.Bar>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid> 
                    )
                    : null                    
                )
                : null
            )
            : (
                <props.skeleton.CustomGrid container spacing={0} rounded="true" roundedamt="15px" border={props.border}>
                    <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                        <props.skeleton.CustomTypography padding={props.params.padding} margin={props.params.margin} variant="h6">
                            {props.name}
                        </props.skeleton.CustomTypography>
                    </props.skeleton.CustomGrid>

                    <props.skeleton.CustomGrid gem="true" color="quarternary" item xs={12} nouserselect="true" sx={{                
                        marginBottom: '15px',
                        marginRight: '0px',                
                        marginTop: (props.name !== "") ? '0px' : '0',
                                        
                    }}>
                        <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("MarkEmailUnreadIcon")} Invitation Requests (4)</props.skeleton.Bar>
                        <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("NoAccountsIcon")} Blocked Accounts (400)</props.skeleton.Bar>
                        <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("LibraryAddIcon")} Create Page</props.skeleton.Bar>
                        <props.skeleton.Bar button="true" padding10="true" fontSize="14px">{LookupLicon("LibraryAddIcon")} Create Group</props.skeleton.Bar>
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid> 
            )
        }
        </>
    )
}