// import React and useContext
import React, { useContext } from 'react'

// import from the styled-components libraries
import { createGlobalStyle, ThemeProvider } from 'styled-components'

// import normalize
import { normalize } from 'styled-normalize'

// import the globalcontext
import { GlobalContext } from '../context/globalContext'

// ----[ import themes ]------------------------------------------------------------------------------
// ---[ Config ]--------------------------------------------------------------------------------------
// Version 1 - Hardcode each theme
//import { darkTheme as DarkTheme1, lightTheme as LightTheme1 } from '../themes/Darwinian2023/config.ts'
//import { darkTheme as DarkTheme2, lightTheme as LightTheme2 } from '../themes/Templar2024/config.ts'

// Version 2 - Dynamic control w/ JSON data
import { themes } from '../themes/Themes'
const buildThemeConfigs = themes.map(item => require('../themes/' + item.name + '/config.ts'))
// ---------------------------------------------------------------------------------------------------

// Version 3 - Loaded themes from database using a component and useEffect() to check for themes
// ???
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------

// #region ----------------------[ 1. GlobalStyle constant defines the base styles that are generally defined in the index.css ]-------------------------------------------------
const GlobalStyle = createGlobalStyle`
${normalize}
* {
    text-decoration: none;
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialised;
    font-size: 16px;
}
body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;    
    background: ${(props) => props.theme.global.base.background};
    color: ${(props) => props.theme.global.base.color};
}
`
// #endregion -------------------------------------------------------------------------------------------------------------------------------------------------------------------

// #region ----------------------[ 2. The Layout component has two constants: darkTheme and lightTheme and we create a toggle button to switch theme ]---------------------------
// Version 2.7.0 Update: instead of just passing {children} in order to pass along props use "props" in the ()
const Layout = (props) => {    
    // #region ----------------------[ 3. Fetch the currentTheme from globalContext ]--------------------------------------------------------------------------------------------
    const currentTheme = useContext(GlobalContext)  
    // #endregion ---------------------------------------------------------------------------------------------------------------------------------------------------------------

    // #region ----------------------[ 4. Populate the "theme" variables to be passed to ThemeProvider ]-------------------------------------------------------------------------
    let theme
    
    // Version 2.7.0 Update: pass children from the props to children constant instead of in the ()
    const children = props.children

    const themeNumber = props.themeNumber

    // Switch dark mode light mode
    // NOTE: Target .theme so that you can use theme.<section>...
    switch (currentTheme.theme) {
        case "dark":
            // ---[ Version 1: This works but can be cumbersome ]--------------------------------------------------------------------------------------------------------------------------------------------------------      
            /*
            switch (themeNumber) {
                case '1': 
                    theme = DarkTheme1.theme
                break

                case '2': 
                    theme = DarkTheme2.theme
                break

                default: 
                    theme = DarkTheme1.theme
                break
            }
            */
            // --------------------------------------------------------------------------------------------------------------------------------------------------------      

            // ---[ Version 2: Dynamically based on buildThemeConfigs ]-----------------------------------------------------------------------------------------------
            if (buildThemeConfigs[themeNumber - 1]) {
                theme = buildThemeConfigs[themeNumber - 1].darkTheme.theme
            } else {
                theme = buildThemeConfigs[0].darkTheme.theme
            }
            // --------------------------------------------------------------------------------------------------------------------------------------------------------
        break

        case "light":
            // ---[ Version 1: This works but can be cumbersome ]--------------------------------------------------------------------------------------------------------------------------------------------------------      
            /*
            switch (themeNumber) {
                case '1': 
                    theme = LightTheme1.theme
                break

                case '2': 
                    theme = LightTheme2.theme
                break

                default: 
                    theme = LightTheme1.theme
                break
            }
            */
            // --------------------------------------------------------------------------------------------------------------------------------------------------------      

            // ---[ Version 2: Dynamically based on buildThemeConfigs ]-----------------------------------------------------------------------------------------------
            if (buildThemeConfigs[themeNumber - 1]) {
                theme = buildThemeConfigs[themeNumber - 1].lightTheme.theme
            } else {
                theme = buildThemeConfigs[0].lightTheme.theme
            }
            // --------------------------------------------------------------------------------------------------------------------------------------------------------
        break

        default: 
            theme = buildThemeConfigs[0].lightTheme.theme
    }

    //console.log(theme)

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <main>{children}</main>
        </ThemeProvider>
    )
    // #endregion ---------------------------------------------------------------------------------------------------------------------------------------------------------------


}
// #endregion -------------------------------------------------------------------------------------------------------------------------------------------------------------------    

export default Layout