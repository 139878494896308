// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import React, { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, useNavigationitem } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import {  } from '../../middleware/utils'

// Import libraries
import { Licons, LookupLicon } from '../../libraries/licons'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from MUI
import {
    //Avatar,
    //Container,
    //InputAdornment,
    //Switch,
    //Typography
    //Box,
    //Checkbox,
    //Container,
    // eslint-disable-next-line
    //FormGroup,
    //InputAdornment,
    //InputLabel,
    //ListItemText,
    //Paper,
    //MenuItem,
    //OutlinedInput,
    //Toolbar,
    //Typography
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    Customprops.skeleton.CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    CustomMuiTelInput as MuiTelInput,
    //Heading,
    //Para,
    CustomTab as Tab,
    CustomTabs as Tabs,
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import BreadCrumbs
//import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import proptypes for tabs
//import { PropTypes } from 'prop-types'

// Import Draggable
//import Draggable from 'react-draggable'

// Import addons
import { Spacer } from '../../components/Addon/Spacer'
import { BreadCrumbs } from '../../components/BreadCrumbs'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Checkbox from '@mui/material/Checkbox'

//import { themes } from '../../themes/Themes'
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function Menu (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        currentUser, 

        // Functions        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------

    // #endregion -----------------[ State Variables ]------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Context loading
    const {
        // Variables
        //isUpdatingNavigationitemSent,
        //navigationitems,
        menuitems,
        menuItems,
        menuSelected,
        //toastsNavigationitem,
        //navItems,

        // Functions
        //clearErrorsNavigationitem,
        //getNavigationitemsPublically,   
        createNavigationItem,
        deleteNavigationItem,
        getMenuItemsPublically,
        setMenuSelected,
        setMenuItems,
        updateNavigationitem,
        setIsCreatingNavigationitemSent,
        setIsDeletingNavigationitemSent,
        setIsUpdatingNavigationitemSent,    
        //setNavItems
    } = useNavigationitem()

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'Settings',
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/settings/'
        },
        {
            'name': 'Menu',
            'type': 'text',
            'grayed': false,
            'url': ''
        },
    ]  

    // for draggables
    const initialState = {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    }

    // for draggables - child
    const initialStateChild = {
        draggedFromChild: null,
        draggedToChild: null,
        isDraggingChild: false,
        originalOrderChild: [],
        updatedOrderChild: []
    }

    // Setup initial variables
    let menus = []

    // Set up menus
    // from the types that are in the navigationitems (this avoids having to create a whole new context and database just to hold "menus")
    if (menuitems) {
        menuitems.forEach(element => {
            // DEBUG: Display element type
            //console.log(element.type)

            // Push to menus if not in there already
            let data = menus.find(function(ele) {
                return ele.name === element.type
            })
            if (!data) {
                menus.push({"name": element.type})
            }
        })

        // DEBUG: Display menus
        //console.log(menus)
    }
    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    const [ addingItem, setAddingItem ] = useState(false)   
    const [ addingChildItem, setAddingChildItem ] = useState(false)   
    const [ childTemp, setChildTemp ] = useState(null)
    //const [ clickedItem, setClickedItem ] = useState(null)
    const [ editingField, setEditingField ] = useState(false)
    const [ editingChildField, setEditingChildField ] = useState(false)
    const [ expanded, setExpanded ] = useState(null)
    const [ menuChildItems, setMenuChildItems ] = useState([])
    //const [ opened, setOpened ] = useState(false)
    //const [ opening, setOpening ] = useState(false)
    const [ temp, setTemp ] = useState(null)        


    // Dragging 
    // Initial state
    const [ dragAndDrop, setDragAndDrop ] = useState(initialState)
    const [ dragAndDropChild, setDragAndDropChild ] = useState(initialStateChild)
    // #endregion -----------------[ State Variables ]------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    // Non-handles
    

    // Handles
    // Handle for Adding item to menu. Just the click part. Not the save button
    const handleAddItem = () => {
        //toast.info('WIP: Add new item to menu', {theme: 'light'})

        // Only if your not adding item or the clicking of the accordian would set it off
        if (!addingItem) {
            // Clear temp 
            setTemp(null)

            // Set temp
            setTemp({                 
                href: '',
                icon: '',
                namespace: 'global',
                navtype: 'single',
                order: 1,
                parent: '',
                roleReq: [],
                slug: '',                
                status: 'active',
                title: '',
                type: menuSelected || 'unknown',
                visibility: 'public'
            })

            setAddingItem(true)
        }
    }

    // Handle for Adding item to menu. Just the click part. Not the save button
    const handleAddChildItem = () => {
        //toast.info('WIP: Add new item to menu', {theme: 'light'})

        // Only if your not adding item or the clicking of the accordian would set it off
        if (!addingChildItem) {
            // Clear temp 
            setChildTemp(null)

            // Set temp
            setChildTemp({                 
                href: '',
                icon: '',
                namespace: 'global',
                navtype: 'single',
                order: 1,
                parent: '',
                roleReq: [],
                slug: '',                
                status: 'active',
                title: '',
                type: menuSelected || 'unknown',
                visibility: 'public'
            })

            setAddingChildItem(true)
        }
    }

    const handleChangeMenu = (menu) => {
        //console.log("Changed to: "+menu+" menu")
        setMenuItems([])
        
        setMenuSelected(menu)

        // set expanded states of items
        /*menuItems.forEach((item) => {
            setOpened([
                ...opened,
                {
                    name: item.name,
                    open: false,
                }
            ])
        })*/
    }  

    const handleDeleteDetails = (item) => {
        //toast.info(`WIP: Delete item`, { theme: 'light'})

        setIsDeletingNavigationitemSent(true)

        deleteNavigationItem(item.slug)
    }

    const handleDeleteChildDetails = (item) => {
        //toast.info(`WIP: Delete child item`, { theme: 'light'})

        setIsDeletingNavigationitemSent(true)

        deleteNavigationItem(item.slug)
    }

    const handleEditingField = (item, children = []) => {
        setTemp({ 
            href: item.href,
            icon: item.icon,
            namespace: item.namespace,
            navtype: item.navtype,
            order: item.id,
            parent: item.parent,
            roleReq: item.roleReq,
            slug: item.slug,
            status: item.status,
            title: item.name,
            type: item.type,            
            children: children
        })

        setEditingField(true)
    }

    const handleEditingChildField = (item, children = []) => {
        setChildTemp({ 
            href: item.href,
            icon: item.icon,
            namespace: item.namespace,
            navtype: item.navtype,
            order: item.order,
            parent: item.parent,
            roleReq: item.roleReq,
            slug: item.slug,
            status: item.status,
            title: item.title,
            type: item.type,            
            children: children
        })

        setEditingChildField(true)
    }

    const handleCancalEditingField = () => {
        setEditingField(false)
        setTemp(null)
    }

    const handleCancalEditingChildField = () => {
        setEditingChildField(false)
        setChildTemp(null)
    }

    const handleExpand = (id, itemContents, root) => (_, isExpanded) => {
        if (root) {
            // if expanded, set id to open/expand, close it otherwise
            setExpanded(isExpanded ? id : null)
            
            setMenuChildItems(isExpanded ? itemContents.children : [])
        } else {
            // A child
            // if expanded, set id to open/expand, close it otherwise
            //setExpanded(true)
        }
    }

    const handleRoleReqChange = (item, action, role) => {
        toast.info(`WIP: Change roleReq array (${action} ${role})`, { theme: 'light' })

        if (action === "add") {
            item?.roleReq?.push(role)
        }

        if (action === "remove") {
            item?.roleReq?.splice(item.roleReq.indexOf(role), 1)
        }
    }

    const handleSaveMenuItem = () => {

        // Only if addingitem
        if (addingItem) {
            // DEBUG: Display temp
            console.log(temp)

            // Set for notifications
            setIsCreatingNavigationitemSent(true)

            // Send to database
            createNavigationItem(temp)

            // Clear temp
            //setTemp(null)
            
            // DEBUB: Display a toast
            //toast.info(`WIP: Save new item to database for ${menuSelected}`, {theme: 'light'})
        }
    }

    const handleSaveMenuChildItem = (parent) => {

        // Only if addingitem
        if (addingChildItem) {
            // DEBUG: Display temp
            //console.log(childTemp)

            // Add parent to childTemp
            let newChildTemp = {
                ...childTemp,
                slug: parent+"-"+childTemp.title.toLowerCase().replace(" ", "-"),
                parent: parent
            }

            // DEBUG: Display newChild
            //console.log(newChildTemp)

            // Set for notifications
            setIsCreatingNavigationitemSent(true)

            // Send to database
            createNavigationItem(newChildTemp)

            // Clear temp
            //setTemp(null)
            
            // DEBUB: Display a toast
            //toast.info(`WIP: Save new item to database for ${menuSelected}`, {theme: 'light'})
        }
    }
    
    const handleUpdateDetails = () => {
        if (editingField) {
            //toast.info('WIP: Send update', { theme: 'light' })

            // DEBUG: Display what's in temp
            //console.log(temp)

            // DEBUG: Display what menuitems is
            //console.log(menuitems)

            // Update for notifications
            setIsUpdatingNavigationitemSent(true)

            // Send to database
            updateNavigationitem(temp.slug, temp.href, temp.icon, temp.namespace, temp.navtype, temp.order, temp.parent, temp.roleReq, temp.status, temp.title, temp.type, temp.visibility)

            // ---- Change menuitems ----------------------------------------------------------------------------------
            // get item in menuitems
            let mi = menuitems.filter(item => item.slug === temp.slug)

            mi.title = temp.title
            // --------------------------------------------------------------------------------------------------------

            // Reset temp
            /*setTemp({                 
                href: '',
                icon: '',
                namespace: 'global',
                navtype: 'single',
                order: 1,
                parent: '',
                roleReq: [],
                slug: '',                
                status: 'active',
                title: '',
                type: menuSelected || 'unknown',
                visibility: 'public'
            })*/

            setEditingField(false)
        } else {
            toast.info('Nothing to update. NOTE: Click Edit and update data.', { theme: 'light' })
        }
    }

    const handleUpdateChildDetails = (item) => {
        if (editingChildField) {
            //toast.info('WIP: Send update', { theme: 'light' })

            // DEBUG: Display what's in temp
            //console.log(childTemp)

            // Add parent to childTemp
            let newChildTemp = {
                ...childTemp,
                slug: item.slug,
                parent: item.parent
            }

            //console.log(newChildTemp)

            // Send to database
            // Update for notifications
            setIsUpdatingNavigationitemSent(true)

            // Send to database
            updateNavigationitem(childTemp.slug, newChildTemp.href, newChildTemp.icon, newChildTemp.namespace, newChildTemp.navtype, newChildTemp.order, newChildTemp.parent, newChildTemp.roleReq, newChildTemp.status, newChildTemp.title, newChildTemp.type, newChildTemp.visibility)

            // Reset temp
            setChildTemp({                 
                href: '',
                icon: '',
                namespace: 'global',
                navtype: 'single',
                order: 1,
                parent: '',
                roleReq: [],
                slug: '',                
                status: 'active',
                title: '',
                type: menuSelected || 'unknown',
                visibility: 'public'
            })

            setEditingChildField(false)
        } else {
            toast.info('Nothing to update. NOTE: Click Edit and update data.', { theme: 'light' })
        }
    }

    // Draggable - main items
    const onDragStart = (event) => {
        // We'll access the "data-position" attribute
        // of the current element dragged
        const initialPosition = Number(event.currentTarget.dataset.position)

        setDragAndDrop({
            // we spread the previous content
            // of the hook variable
            // so we don't override the properties
            // not being updated
            ...dragAndDrop,

            draggedFrom: initialPosition, // set the draggedFrom position
            isDragging: true,
            originalOrder: menuItems // store the current state of "menuItems"
        })

        // Note: this is only for Firefox
        // Without it, the DnD won't work
        // But we are not using it
        event.dataTransfer.setData("text/html", '')
    }

    const onDragStartChild = (event) => {
        // We'll access the "data-position" attribute
        // of the current element dragged
        const initialPosition = Number(event.currentTarget.dataset.position)

        setDragAndDropChild({
            // we spread the previous content
            // of the hook variable
            // so we don't override the properties
            // not being updated
            ...dragAndDropChild,

            draggedFromChild: initialPosition, // set the draggedFrom position
            isDraggingChild: true,
            originalOrderChild: menuChildItems // store the current state of "menuChildItems"
        })

        // Note: this is only for Firefox
        // Without it, the DnD won't work
        // But we are not using it
        event.dataTransfer.setData("text/html", '')
    }

    const onDragOver = (event) => {
        event.preventDefault()

        // Store the content of the original list
        // in this variable that we'll update
        let newMenuList = dragAndDrop.originalOrder

        // index of the item being dragged
        const draggedFrom = dragAndDrop.draggedFrom

        // index of the drop area being hovered
        const draggedTo = Number(event.currentTarget.dataset.position)

        const oldItem = newMenuList?.filter( item => item.id === draggedTo+1 )

        // get the element that's at the position of "draggedFrom"
        const itemDragged = newMenuList[draggedFrom]

        // filter out the item being dragged
        const remainingItems = newMenuList.filter((item, index) => index !== draggedFrom)

        // update the list
        newMenuList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ]
        
        // since this event fires many times
        // we check if the target are actually
        // different:
        if (draggedTo !== dragAndDrop.draggedTo) {
            // -- Swap id's with what is in the position that dragged is in        
            //console.log(oldItem)
            //console.log(draggedFrom+1)

            // Swap ids
            if (oldItem.length > 0) {oldItem[0].id = draggedFrom + 1}
            // ---------------------------------------------

            setDragAndDrop({
                ...dragAndDrop,

                // save the updated list state
                // we will render this onDrop
                updatedOrder: newMenuList,
                draggedTo: draggedTo
            })

            // Update itemDragged.id to draggedTo to change the item to save to db
            itemDragged.id = draggedTo + 1

            // WIP: Save order of item to db
        }
    }

    const onDragOverChild = (event) => {
        event.preventDefault()

        // Store the content of the original list
        // in this variable that we'll update
        let newMenuList = dragAndDropChild.originalOrderChild

        // index of the item being dragged
        const draggedFrom = dragAndDropChild.draggedFromChild

        // index of the drop area being hovered
        const draggedTo = Number(event.currentTarget.dataset.position)

        const oldItem = newMenuList?.filter( item => item?.id === draggedTo+1 )

        // get the element that's at the position of "draggedFrom"
        const itemDragged = newMenuList[draggedFrom]

        // filter out the item being dragged
        const remainingItems = newMenuList.filter((item, index) => index !== draggedFrom)

        // update the list
        newMenuList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ]
        
        // since this event fires many times
        // we check if the target are actually
        // different:
        if (draggedTo !== dragAndDropChild.draggedToChild) {
            // -- Swap id's with what is in the position that dragged is in        
            //console.log(oldItem)
            //console.log(draggedFrom+1)

            // Swap ids
            if (oldItem.length > 0) {oldItem[0].id = draggedFrom + 1}
            // ---------------------------------------------

            setDragAndDropChild({
                ...dragAndDropChild,

                // save the updated list state
                // we will render this onDrop
                updatedOrderChild: newMenuList,
                draggedToChild: draggedTo
            })

            // Update itemDragged.id to draggedTo to change the item to save to db
            if (itemDragged) {
                itemDragged.id = draggedTo + 1
            }
        }
    }

    const onDrop = () => {
        let newMenuList = dragAndDrop.originalOrder

        // index of the item being dragged
        const draggedFrom = dragAndDrop.draggedFrom

        const itemDragged = newMenuList[draggedFrom]

        // Here, we will: 
        // - update the rendered list
        // - and reset the DnD state

        // we use the updater function
        // for the "list" hook
        setMenuItems(dragAndDrop.updatedOrder)

        // Sort the menuItems to have them appear correctly in list
        menuItems.sort(dynamicSort("id"))
        
        // DEBUG: Display menuItems
        //console.log(menuItems)

        // and reset the state of 
        // the DnD
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: true
        })

        // Save to db
        //console.log("new order saving to db for item: "+parseInt(dragAndDrop.draggedTo+1))

        //console.log(itemDragged)

        // Create item update array
        let itemData = {
            href: itemDragged.href,
            icon: itemDragged.icon,
            namespace: itemDragged.namespace,
            navtype: itemDragged.navtype,
            order: itemDragged.id,
            parent: itemDragged.parent,
            roleReq: itemDragged.roleReq,
            slug: itemDragged.slug,
            status: itemDragged.status,
            title: itemDragged.name,
            type: itemDragged.type,
            visibility: itemDragged.visibility
        }

        //console.log(itemData)

        setIsUpdatingNavigationitemSent(true)

        updateNavigationitem(itemData.slug, itemData.href, itemData.icon, itemData.namespace, itemData.navtype, itemData.order, itemData.parent, itemData.roleReq, itemData.status, itemData.title, itemData.type, itemData.visibility)
    }

    const onDropChild = () => {
        let newMenuList = dragAndDropChild.originalOrderChild

        // index of the item being dragged
        const draggedFrom = dragAndDropChild.draggedFromChild

        const itemDragged = newMenuList[draggedFrom]

        // Here, we will: 
        // - update the rendered list
        // - and reset the DnD state

        // we use the updater function
        // for the "list" hook
        setMenuChildItems(dragAndDropChild.updatedOrderChild)

        // Sort the menuItems to have them appear correctly in list
        menuChildItems.sort(dynamicSort("id"))

        // DEBUG: Display new order of item's children
        //console.log(dragAndDropChild.updatedOrderChild)

        //console.log(menuChildItems)

        // and reset the state of 
        // the DnD
        setDragAndDropChild({
            ...dragAndDropChild,
            draggedFromChild: null,
            draggedToChild: null,
            isDraggingChild: true
        })

        // Save to db
        //console.log("new order saving to db for item: "+parseInt(dragAndDrop.draggedTo+1))

        //console.log(itemDragged)

        // Create item update array
        let itemData = {
            href: itemDragged.href,
            icon: itemDragged.icon,
            namespace: itemDragged.namespace,
            navtype: itemDragged.navtype,
            order: itemDragged.id,
            parent: itemDragged.parent,
            roleReq: itemDragged.roleReq,
            slug: itemDragged.slug,
            status: itemDragged.status,
            title: itemDragged.name,
            type: itemDragged.type,
            visibility: itemDragged.visibility
        }

        //console.log(itemData)

        setIsUpdatingNavigationitemSent(true)

        updateNavigationitem(itemData.slug, itemData.href, itemData.icon, itemData.namespace, itemData.navtype, itemData.order, itemData.parent, itemData.roleReq, itemData.status, itemData.title, itemData.type, itemData.visibility)
    }

    // #region -----[ STATE RENDERING ]------------------------------------------------------------------------------------------------------
    // State Rendering for getting navigationitems
    useEffect(() => {
        if (!menuitems) {
            // Get the navigationitems from database live
            getMenuItemsPublically(null, 'global', 'any', 'ASC', 'slug')
        } else {
            setTimeout(() => {
                getMenuItemsPublically(null, 'global', 'any', 'ASC', 'slug')
            }, 10000)
        }

        // NOTE: In order to live update the settings, settings is required in the dependancy. Idk if this will cause a maximum depth. 
        //       we will find out.
        // eslint-disable-next-line
    }, [ 
        menuitems,
    ])

    // State rendering for menu selection
    useEffect(() => {
        // #region -----[ SETUP ]----------------------------------------------------------------------------------------------------
        // Set up variables
        let build = false // used to hold value to determine to run this setup or not
        let menuItemsTopLevel = [] // used to hold top level navigation (those without a parent)
        let childItems = [] // used to hold the children of each top level
        let tempItems = [] // used to hold items in a temporary array until push to state array
        //let ic = 1 // used to hold a counter for child items

        // Check to make sure menu has been selected
        if (menuSelected) {
            build = true
        } else {
            build = false // redundant resetting of build
        }
        // #endregion ---------------------------------------------------------------------------------------------------------------

        // #region -----[ BUILD ]----------------------------------------------------------------------------------------------------
        // Can proceed
        if (build) {
            // Get top level menu items
            menuItemsTopLevel = menuitems.filter(item => item.type.indexOf(menuSelected) !== -1 && item.parent === "")

            // Loop through top level menu items
            menuItemsTopLevel.forEach((item) => {
                // Get the children that match item.slug
                childItems = menuitems.filter(i => i.type.indexOf(menuSelected) !== -1 && i.parent === item.slug)

                // Sort the children
                childItems.sort(dynamicSort("order"))

                // Push items
                tempItems.push({
                    id: item.order,
                    icon: item.icon,
                    href: item.href,
                    name: item.title,
                    namespace: item.namespace,
                    navtype: item.navtype,
                    parent: item.parent,
                    roleReq: item.roleReq,
                    slug: item.slug,
                    status: item.status,                    
                    type: item.type,
                    visibility: item.visibility,
                    children: childItems
                })
            })
            
            // Sort temp array by id
            tempItems.sort(dynamicSort("id"))

            
        }
        // #endregion ---------------------------------------------------------------------------------------------------------------

        // #region -----[ OUTPUT ]---------------------------------------------------------------------------------------------------
        // Can proceed
        if (build) {
            // Set menu items state
            setMenuItems(tempItems)
        }
        // #endregion ---------------------------------------------------------------------------------------------------------------

        // #region -----[ DEBUG ]----------------------------------------------------------------------------------------------------
        // Can proceed
        if (build) {
            // Display message about menuSelected
            //console.log("Menu has been selected: "+menuSelected)

            // Display top level menu items
            //console.log("---- DEBUG: Top-level Menu Items ----")
            //console.log(menuItemsTopLevel)
            //console.log("-------------------------------------")
            
            // Display menu items after being built
            //console.log("---- DEBUG: Menu Items post build ----")
            //console.log(menuItems)
            //console.log("-------------------------------------")
        }
        // #endregion ---------------------------------------------------------------------------------------------------------------

         // eslint-disable-next-line
    }, [ menuSelected ])

    // State rendering for debugging
    useEffect(() => {
        //console.log(opened)
        //console.log(opened)
        //if (menuItems) console.log(menuItems)
        //console.log("---[ Debugging ]---")
        //if (menuItems) console.log(menuItems)
        //if (settings) console.log(settings)
        //console.log("SettingsEditing: "+SettingsEditing)
        //if (temp) console.log(temp)
        //console.log("SettingsFormIsDisabled: "+SettingsFormIsDisabled)
        //console.log("AccountUpdating: "+AccountUpdating)
        //console.log("AccountUpdatingSent: "+AccountUpdatingSent)
        //console.log("image: "+image)
        //console.log("isDisabled: "+isDisabled)
        //console.log("Profile: "+profile.firstName)   
        //console.log("profile image: "+temp?.image)     
        //console.log("Toasts: "+toasts)
        //console.log("Updating: "+Updating)
        //console.log("UpdateSent: "+UpdateSent)  
    }, [ 
        menuItems,
        //opened
        //settings
        //SettingsEditing, 
        //temp
        //SettingsFormIsDisabled, AccountUpdating, AccountUpdatingSent 
    ])
    // #endregion ----------------------------------------------------------------------------------------------------------------------
    

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent paddingleft20paddingright10>
            <Spacer length={4} />

            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

                {
                    (currentUser && currentUser?.role === "superadmin")
                    ? (
                        <props.skeleton.CustomGrid container spacing={2} sx={{ padding: '15px' }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.Heading nobg h2 center>
                                    WIP: Menu system here
                                </props.skeleton.Heading>
                                
                                {/* #region -----[ MENU SYSTEM ]------------------------------------------------------------------ */}
                                {/* #region -----[ Top Bar ]---------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid container spacing={0}>
                                    <props.skeleton.CustomGrid item xs={12} sx={{ border: 'thin solid cyan', padding: '10px 10px 10px 10px' }}>
                                        <props.skeleton.Bar button="true" center="true" sx={{ width: '120px' }}>
                                            Create Menu
                                        </props.skeleton.Bar>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------*/}

                                <Spacer length={2} />

                                {/* #region -----[ Nuget ]------------------------------------------------------------------------ */}
                                <props.skeleton.CustomGrid container spacing={0}>
                                    {/* #region -----[ Left ]--------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={3} sx={{ border: 'thin solid cyan', padding: '10px 10px 10px 10px' }}>
                                        <props.skeleton.Heading h3 nobg>Menus</props.skeleton.Heading>
                                        {
                                            (menus) 
                                            ? (
                                                menus.map((item, key) => {
                                                    //console.log(item)
                                                    return (
                                                        <props.skeleton.Bar padding10="true" key={key} button="true" onClick={() => handleChangeMenu(item.name)}>
                                                            {item.name}
                                                        </props.skeleton.Bar>
                                                    )
                                                })
                                            )
                                            : null
                                        }
                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------*/}

                                    {/* #region -----[ Right ]-------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={9} sx={{ border: 'thin solid cyan', padding: '10px 10px 10px 10px' }}>
                                        <props.skeleton.Heading h3 nobg>{(menuSelected) ? menuSelected+" items" : "[Unknown] menu" }</props.skeleton.Heading>
                                        {
                                            (menuSelected) 
                                            ?
                                            (
                                                <props.skeleton.CustomAccordian
                                                    disableGutters
                                                    draggable="false"
                                                    onClick={() => handleAddItem()}
                                                >
                                                    <props.skeleton.CustomAccordianSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={"new-content"}
                                                        id={"new-header"}
                                                    >
                                                        Add New Item
                                                    </props.skeleton.CustomAccordianSummary>

                                                    <props.skeleton.CustomAccordianDetails>
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={1}>
                                                                <props.skeleton.CustomSelect
                                                                    sx={{ width: '100%' }}
                                                                    id={"nuget-icon-add"}
                                                                    label="Icon"
                                                                    value={temp?.icon || ''}
                                                                    onChange={(e) => setTemp({...temp, icon: e.target.value})}                                                                            
                                                                >
                                                                    {
                                                                        // Map through licons
                                                                        Licons.map((item, lindex) => {
                                                                            return (
                                                                                <props.skeleton.CustomMenuItem key={lindex} value={item.name}>{item.componentCode}</props.skeleton.CustomMenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                    
                                                                    
                                                                </props.skeleton.CustomSelect>
                                                            </props.skeleton.CustomGrid>

                                                            <props.skeleton.CustomGrid item xs={2}>
                                                                <props.skeleton.CustomTextField
                                                                    id={"nuget-title-add"}
                                                                    label="Title"
                                                                    type="text"
                                                                    value={temp?.title || ''}
                                                                    onChange={(e) => setTemp({...temp, title: e.target.value})}                                                                            
                                                                />
                                                            </props.skeleton.CustomGrid>

                                                            <props.skeleton.CustomGrid item xs={3}>
                                                                <props.skeleton.CustomTextField
                                                                    id={"nuget-href-add"}
                                                                    label="HREF"
                                                                    type="text"
                                                                    value={temp?.href || ''}
                                                                    onChange={(e) => setTemp({...temp, href: e.target.value})}
                                                                />
                                                            </props.skeleton.CustomGrid>

                                                            <props.skeleton.CustomGrid item xs={3}>
                                                                <props.skeleton.CustomSelect
                                                                    sx={{ width: '100%' }}
                                                                    id={"nuget-type-add"}
                                                                    label="Type"
                                                                    value={temp?.navtype || ''}
                                                                    onChange={(e) => setTemp({...temp, navtype: e.target.value})}                                                                            
                                                                >
                                                                    <props.skeleton.CustomMenuItem value={'multi'}>Multi</props.skeleton.CustomMenuItem>
                                                                    <props.skeleton.CustomMenuItem value={'single'}>Single</props.skeleton.CustomMenuItem>
                                                                </props.skeleton.CustomSelect>
                                                            </props.skeleton.CustomGrid>
                                                            
                                                            <props.skeleton.CustomGrid item xs={3}>
                                                                <props.skeleton.CustomSelect
                                                                    sx={{ width: '100%' }}
                                                                    id={"nuget-status-add"}
                                                                    label="Status"
                                                                    value={temp?.status || ''}
                                                                    onChange={(e) => setTemp({...temp, status: e.target.value})}                                                                            
                                                                >
                                                                    <props.skeleton.CustomMenuItem value={'active'}>Active</props.skeleton.CustomMenuItem>
                                                                    <props.skeleton.CustomMenuItem value={'inactive'}>Inactive</props.skeleton.CustomMenuItem>
                                                                </props.skeleton.CustomSelect>
                                                            </props.skeleton.CustomGrid>

                                                        </props.skeleton.CustomGrid>

                                                        <Spacer length={1} />

                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.CustomTextField
                                                                    id={"nuget-slug"}
                                                                    label="Slug"
                                                                    type="text"
                                                                    value={temp?.slug || ''}
                                                                    onChange={(e) => setTemp({...temp, slug: e.target.value})}
                                                                />
                                                            </props.skeleton.CustomGrid>

                                                        </props.skeleton.CustomGrid>

                                                        <Spacer length={1} />
                                                        
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                <props.skeleton.Heading h2 nobg>Roles required to see</props.skeleton.Heading>
                                                                <props.skeleton.Heading h5 nobg>None checked means that anyone can see this.</props.skeleton.Heading>
                                                                <props.skeleton.CustomFormGroup>
                                                                    <props.skeleton.CustomFormControlLabel control={(temp?.roleReq?.includes("editor")) ? <Checkbox onClick={() => handleRoleReqChange(temp, "remove", "editor")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(temp, "add", "editor")} checked={false} />} label="Editor" />
                                                                    <props.skeleton.CustomFormControlLabel control={(temp?.roleReq?.includes("admin")) ? <Checkbox onClick={() => handleRoleReqChange(temp, "remove", "admin")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(temp, "add", "admin")} checked={false} />} label="Admin" />
                                                                    <props.skeleton.CustomFormControlLabel control={(temp?.roleReq?.includes("superadmin")) ? <Checkbox onClick={() => handleRoleReqChange(temp, "remove", "superadmin")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(temp, "add", "superadmin")} checked={false} />} label="SuperAdmin" />
                                                                </props.skeleton.CustomFormGroup>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>
                                                    </props.skeleton.CustomAccordianDetails>
                                                    
                                                    <props.skeleton.CustomAccordianActions>
                                                        <props.skeleton.CustomButton variant="contained" color={"primary"} onClick={() => handleSaveMenuItem()}>Save</props.skeleton.CustomButton>
                                                    </props.skeleton.CustomAccordianActions>
                                                </props.skeleton.CustomAccordian>
                                            )
                                            : null
                                        }
                                        
                                        {
                                            (menuItems)
                                            ? (
                                                menuItems.map((item, key) => {
                                                    return (
                                                        /* --- LIST VARIANT ----------------------------------------------------------

                                                        <props.skeleton.CustomListItem
                                                        data-position={key}
                                                            draggable="true"
                                                            onDragStart={onDragStart}
                                                            onDragOver={onDragOver}
                                                            onDrop={onDrop}
                                                            key={key}
                                                            disableGutters                                                                    
                                                        >                                                                    
                                                            <props.skeleton.CustomListItemText primary={`${item.id} - ${item.name}`} />
                                                        </props.skeleton.CustomListItem>
                                                        ---------------------------------------------------------------------------- */

                                                        /* --- ACCORDIAN VARIANT ---------------------------------------------------
                                                        */
                                                        
                                                        <props.skeleton.CustomAccordian
                                                            data-position={key}
                                                            draggable="true"
                                                            onDragStart={onDragStart}
                                                            onDragOver={onDragOver}
                                                            onDrop={onDrop}
                                                            //onClick={() => handleItemClick(item)}
                                                            onChange={handleExpand(item.id, item, true)}
                                                            expanded={expanded === item.id}
                                                            key={key}
                                                            disableGutters
                                                        >
                                                            <props.skeleton.CustomAccordianSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={item.name+"-content"}
                                                                id={item.name+"-header"}
                                                            >
                                                                {item.id} - {item.name}
                                                            </props.skeleton.CustomAccordianSummary>

                                                            <props.skeleton.CustomAccordianDetails>
                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                    <props.skeleton.CustomGrid item xs={1}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b></b><br />{ LookupLicon(item.icon) }</props.skeleton.CustomTypography>
                                                                            : <props.skeleton.CustomSelect
                                                                                sx={{ width: '100%' }}
                                                                                id={"nuget-icon-"+item.id}
                                                                                label="Icon"
                                                                                value={temp?.icon || ''}
                                                                                onChange={(e) => setTemp({...temp, icon: e.target.value})}                                                                            
                                                                            >
                                                                                {
                                                                                    // Map through licons
                                                                                    Licons.map((item, lindex) => {
                                                                                        return (
                                                                                            <props.skeleton.CustomMenuItem key={lindex} value={item.name}>{item.componentCode}</props.skeleton.CustomMenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                
                                                                                
                                                                            </props.skeleton.CustomSelect>
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b>Title</b><br />{ item.name }</props.skeleton.CustomTypography>
                                                                            : <props.skeleton.CustomTextField
                                                                                id={"nuget-title-"+item.id}
                                                                                label="Title"
                                                                                type="text"
                                                                                value={temp.title || ''}
                                                                                onChange={(e) => setTemp({...temp, title: e.target.value})}                                                                            
                                                                            />
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                    <props.skeleton.CustomGrid item xs={3}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b>HREF</b><br />{ item.href }</props.skeleton.CustomTypography>
                                                                            : <props.skeleton.CustomTextField
                                                                                required
                                                                                id={"nuget-href-"+item.id}
                                                                                label="HREF"
                                                                                type="text"
                                                                                value={temp.href || ''}
                                                                                onChange={(e) => setTemp({...temp, href: e.target.value})}                                                                            
                                                                            />
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b>Type</b><br />{ item.navtype }</props.skeleton.CustomTypography>
                                                                            : <props.skeleton.CustomSelect
                                                                                sx={{ width: '100%' }}
                                                                                id={"nuget-navtype-"+item.id}
                                                                                label="Type"
                                                                                value={temp.navtype || ''}
                                                                                onChange={(e) => setTemp({...temp, navtype: e.target.value})}                                                                            
                                                                            >
                                                                                <props.skeleton.CustomMenuItem value={'multi'}>Multi</props.skeleton.CustomMenuItem>
                                                                                <props.skeleton.CustomMenuItem value={'single'}>Single</props.skeleton.CustomMenuItem>
                                                                            </props.skeleton.CustomSelect>
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b>Status</b><br />{ item.status }</props.skeleton.CustomTypography>
                                                                            : <props.skeleton.CustomSelect
                                                                                sx={{ width: '100%' }}
                                                                                id={"nuget-status-"+item.id}
                                                                                label="Status"
                                                                                value={temp.status || ''}
                                                                                onChange={(e) => setTemp({...temp, status: e.target.value})}                                                                            
                                                                            >
                                                                                <props.skeleton.CustomMenuItem value={'active'}>Active</props.skeleton.CustomMenuItem>
                                                                                <props.skeleton.CustomMenuItem value={'inactive'}>Inactive</props.skeleton.CustomMenuItem>
                                                                            </props.skeleton.CustomSelect>
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                        {
                                                                            (!editingField)
                                                                            ? <props.skeleton.Bar center="true" onClick={() => handleEditingField(item, item.children)} padding10="true" button="true">Edit</props.skeleton.Bar>
                                                                            : <props.skeleton.Bar center="true" onClick={() => handleCancalEditingField(item)} padding10="true" button="true">Cancel</props.skeleton.Bar>
                                                                        }
                                                                    </props.skeleton.CustomGrid>


                                                                </props.skeleton.CustomGrid>

                                                                <Spacer length={1} />

                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                    <props.skeleton.CustomGrid item xs={12}>
                                                                        {!editingField 
                                                                            ? <props.skeleton.CustomTypography><b>Slug</b><br />{ item.slug }</props.skeleton.CustomTypography>
                                                                            :  <props.skeleton.CustomTextField
                                                                                id={"nuget-slug"}
                                                                                disabled={true}
                                                                                label="Slug"
                                                                                type="text"
                                                                                value={temp?.slug || ''}
                                                                                onChange={(e) => setTemp({...temp, slug: e.target.value})}
                                                                            />
                                                                        }
                                                                    </props.skeleton.CustomGrid>

                                                                </props.skeleton.CustomGrid>

                                                                <Spacer length={1} />

                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                    <props.skeleton.CustomGrid item xs={12}>
                                                                        <props.skeleton.Heading h2 nobg>Roles required to see</props.skeleton.Heading>
                                                                        <props.skeleton.Heading h5 nobg>None checked means that anyone can see this.</props.skeleton.Heading>
                                                                        <props.skeleton.CustomFormGroup>
                                                                            <props.skeleton.CustomFormControlLabel control={(item?.roleReq?.includes("editor")) ? <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "remove", "editor")} checked={true} /> : <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "add", "editor")} checked={false} />} label="Editor" />
                                                                            <props.skeleton.CustomFormControlLabel control={(item?.roleReq?.includes("admin")) ? <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "remove", "admin")} checked={true} /> : <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "add", "admin")} checked={false} />} label="Admin" />
                                                                            <props.skeleton.CustomFormControlLabel control={(item?.roleReq?.includes("superadmin")) ? <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "remove", "superadmin")} checked={true} /> : <Checkbox disabled={(!editingField) ? true : false} onClick={() => handleRoleReqChange(item, "add", "superadmin")} checked={false} />} label="SuperAdmin" />
                                                                        </props.skeleton.CustomFormGroup>
                                                                    </props.skeleton.CustomGrid>
                                                                </props.skeleton.CustomGrid>

                                                                {
                                                                    (item.navtype === "multi")
                                                                    ? (
                                                                        <React.Fragment key={item.id}>
                                                                            <Spacer length={2} />
                                                                            <props.skeleton.CustomGrid container spacing={2} sx={{ background: "white", padding: "10px" }}>
                                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                                    {
                                                                                         <props.skeleton.CustomAccordian
                                                                                            disableGutters
                                                                                            draggable="false"
                                                                                            onClick={() => handleAddChildItem()}
                                                                                        >
                                                                                            <props.skeleton.CustomAccordianSummary
                                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                                aria-controls={"new-child-content"}
                                                                                                id={"new-childheader"}
                                                                                            >
                                                                                                Add New Item
                                                                                            </props.skeleton.CustomAccordianSummary>
                                        
                                                                                            <props.skeleton.CustomAccordianDetails>
                                                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                                                    <props.skeleton.CustomGrid item xs={1}>
                                                                                                        <props.skeleton.CustomSelect
                                                                                                            sx={{ width: '100%' }}
                                                                                                            id={"nuget-icon-addchild"}
                                                                                                            label="Icon"
                                                                                                            value={childTemp?.icon || ''}
                                                                                                            onChange={(e) => setChildTemp({...childTemp, icon: e.target.value})}                                                                            
                                                                                                            //
                                                                                                        >
                                                                                                            {
                                                                                                                // Map through licons
                                                                                                                Licons.map((item, lindex) => {
                                                                                                                    return (
                                                                                                                        <props.skeleton.CustomMenuItem key={lindex} value={item.name}>{item.componentCode}</props.skeleton.CustomMenuItem>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            
                                                                                                            
                                                                                                        </props.skeleton.CustomSelect>
                                                                                                    </props.skeleton.CustomGrid>
                                        
                                                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                                                        <props.skeleton.CustomTextField
                                                                                                            id={"nuget-title-addchild"}
                                                                                                            label="Title"
                                                                                                            type="text"
                                                                                                            value={childTemp?.title || ''}
                                                                                                            onChange={(e) => setChildTemp({...childTemp, title: e.target.value})}                                                                            
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                        
                                                                                                    <props.skeleton.CustomGrid item xs={3}>
                                                                                                        <props.skeleton.CustomTextField
                                                                                                            id={"nuget-href-addchild"}
                                                                                                            label="HREF"
                                                                                                            type="text"
                                                                                                            value={childTemp?.href || ''}
                                                                                                            onChange={(e) => setChildTemp({...childTemp, href: e.target.value})}
                                                                                                        />
                                                                                                    </props.skeleton.CustomGrid>
                                        
                                                                                                    <props.skeleton.CustomGrid item xs={3}>
                                                                                                        <props.skeleton.CustomSelect
                                                                                                            sx={{ width: '100%' }}
                                                                                                            id={"nuget-type-addchild"}
                                                                                                            label="Type"
                                                                                                            value={childTemp?.navtype || ''}
                                                                                                            onChange={(e) => setChildTemp({...childTemp, navtype: e.target.value})}                                                                            
                                                                                                        >
                                                                                                            <props.skeleton.CustomMenuItem value={'multi'}>Multi</props.skeleton.CustomMenuItem>
                                                                                                            <props.skeleton.CustomMenuItem value={'single'}>Single</props.skeleton.CustomMenuItem>
                                                                                                        </props.skeleton.CustomSelect>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                    
                                                                                                    <props.skeleton.CustomGrid item xs={3}>
                                                                                                        <props.skeleton.CustomSelect
                                                                                                            sx={{ width: '100%' }}
                                                                                                            id={"nuget-status-addchild"}
                                                                                                            label="Status"
                                                                                                            value={childTemp?.status || ''}
                                                                                                            onChange={(e) => setChildTemp({...childTemp, status: e.target.value})}                                                                            
                                                                                                        >
                                                                                                            <props.skeleton.CustomMenuItem value={'active'}>Active</props.skeleton.CustomMenuItem>
                                                                                                            <props.skeleton.CustomMenuItem value={'inactive'}>Inactive</props.skeleton.CustomMenuItem>
                                                                                                        </props.skeleton.CustomSelect>
                                                                                                    </props.skeleton.CustomGrid>
                                        
                                                                                                </props.skeleton.CustomGrid>
                                        
                                                                                                <Spacer length={1} />
                                                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                                                    <props.skeleton.CustomGrid item xs={12}>
                                                                                                        <props.skeleton.Heading h2 nobg>Roles required to see</props.skeleton.Heading>
                                                                                                        <props.skeleton.Heading h5 nobg>None checked means that anyone can see this.</props.skeleton.Heading>
                                                                                                        <props.skeleton.CustomFormGroup>
                                                                                                            <props.skeleton.CustomFormControlLabel control={(childTemp?.roleReq?.includes("editor")) ? <Checkbox onClick={() => handleRoleReqChange(childTemp, "remove", "editor")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(childTemp, "add", "editor")} checked={false} />} label="Editor" />
                                                                                                            <props.skeleton.CustomFormControlLabel control={(childTemp?.roleReq?.includes("admin")) ? <Checkbox onClick={() => handleRoleReqChange(childTemp, "remove", "admin")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(childTemp, "add", "admin")} checked={false} />} label="Admin" />
                                                                                                            <props.skeleton.CustomFormControlLabel control={(childTemp?.roleReq?.includes("superadmin")) ? <Checkbox onClick={() => handleRoleReqChange(childTemp, "remove", "superadmin")} checked={true} /> : <Checkbox onClick={() => handleRoleReqChange(childTemp, "add", "superadmin")} checked={false} />} label="SuperAdmin" />
                                                                                                        </props.skeleton.CustomFormGroup>
                                                                                                    </props.skeleton.CustomGrid>
                                                                                                </props.skeleton.CustomGrid>
                                                                                            </props.skeleton.CustomAccordianDetails>
                                                                                            
                                                                                            <props.skeleton.CustomAccordianActions>
                                                                                                <props.skeleton.CustomButton variant="contained" color={"primary"} onClick={() => handleSaveMenuChildItem(item.slug)}>Save</props.skeleton.CustomButton>
                                                                                            </props.skeleton.CustomAccordianActions>
                                                                                        </props.skeleton.CustomAccordian>
                                                                                    }
                                                                                    {
                                                                                       item?.children?.map((child, index) => {
                                                                                            // 2024-02-10 - Seri had a "took too much moment" and forgot to add a return (). F. Return the slab
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    {/* LIST STYLE */}
                                                                                                    {
                                                                                                    /*
                                                                                                    <props.skeleton.CustomListItem
                                                                                                        data-position={index}
                                                                                                        draggable="true"
                                                                                                        onDragStart={onDragStartChild}
                                                                                                        onDragOver={onDragOverChild}
                                                                                                        onDrop={onDropChild}
                                                                                                        key={index}
                                                                                                        >
                                                                                                        <props.skeleton.CustomListItemText primary={`${child.id} - ${child.name}`} />
                                                                                                    </props.skeleton.CustomListItem>
                                                                                                    */
                                                                                                    }
                                                                                                    {/* ACCORDIAN STYLE */}
                                                                                                    {
                                                                                                        <props.skeleton.CustomAccordian
                                                                                                            data-position={index}
                                                                                                            draggable="true"
                                                                                                            onDragStart={onDragStartChild}
                                                                                                            onDragOver={onDragOverChild}
                                                                                                            onDrop={onDropChild}
                                                                                                            // onClick={() => handleItemChildClick(item)}
                                                                                                            onChange={handleExpand(child.id, item, false)}
                                                                                                            key={index}
                                                                                                            disableGutters
                                                                                                        >
                                                                                                            <props.skeleton.CustomAccordianSummary
                                                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                                                aria-controls={child.name+"-content"}
                                                                                                                id={child.name+"-header"}
                                                                                                            >
                                                                                                                {child.order} - {child.title}
                                                                                                            </props.skeleton.CustomAccordianSummary>

                                                                                                            <props.skeleton.CustomAccordianDetails>
                                                                                                                <props.skeleton.CustomGrid container spacing={1}>
                                                                                                                    <props.skeleton.CustomGrid item xs={1}>
                                                                                                                        {!editingChildField 
                                                                                                                            ? <props.skeleton.CustomTypography><b></b><br />{ LookupLicon(child.icon) }</props.skeleton.CustomTypography>
                                                                                                                            : <props.skeleton.CustomSelect
                                                                                                                                sx={{ width: '100%' }}
                                                                                                                                id={"nuget-icon-"+child.slug}
                                                                                                                                label="Icon"
                                                                                                                                value={childTemp?.icon || ''}
                                                                                                                                onChange={(e) => setChildTemp({...childTemp, icon: e.target.value})}                                                                            
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    // Map through licons
                                                                                                                                    Licons.map((item, lindex) => {
                                                                                                                                        return (
                                                                                                                                            <props.skeleton.CustomMenuItem key={lindex} value={item.name}>{item.componentCode}</props.skeleton.CustomMenuItem>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                                
                                                                                                                                
                                                                                                                            </props.skeleton.CustomSelect>
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>

                                                                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                                                                        {!editingChildField 
                                                                                                                            ? <props.skeleton.CustomTypography><b>Title</b><br />{ child.title }</props.skeleton.CustomTypography>
                                                                                                                            : <props.skeleton.CustomTextField
                                                                                                                                required
                                                                                                                                id={"nuget-title-"+child.slug}
                                                                                                                                label="Title"
                                                                                                                                type="text"
                                                                                                                                value={childTemp.title || ''}
                                                                                                                                onChange={(e) => setChildTemp({...childTemp, title: e.target.value})}                                                                            
                                                                                                                            />
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>

                                                                                                                    <props.skeleton.CustomGrid item xs={3}>
                                                                                                                        {!editingChildField 
                                                                                                                            ? <props.skeleton.CustomTypography><b>HREF</b><br />{ child.href }</props.skeleton.CustomTypography>
                                                                                                                            : <props.skeleton.CustomTextField
                                                                                                                                required
                                                                                                                                id={"nuget-href-"+child.slug}
                                                                                                                                label="HREF"
                                                                                                                                type="text"
                                                                                                                                value={childTemp.href || ''}
                                                                                                                                onChange={(e) => setChildTemp({...childTemp, href: e.target.value})}                                                                            
                                                                                                                            />
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>

                                                                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                                                                        {!editingChildField 
                                                                                                                            ? <props.skeleton.CustomTypography><b>Type</b><br />{ child.navtype }</props.skeleton.CustomTypography>
                                                                                                                            : <props.skeleton.CustomSelect
                                                                                                                                sx={{ width: '100%' }}
                                                                                                                                id={"nuget-navtype-"+child.slug}
                                                                                                                                label="Type"
                                                                                                                                value={childTemp.navtype || ''}
                                                                                                                                onChange={(e) => setChildTemp({...childTemp, navtype: e.target.value})}                                                                            
                                                                                                                            >
                                                                                                                                <props.skeleton.CustomMenuItem value={'multi'}>Multi</props.skeleton.CustomMenuItem>
                                                                                                                                <props.skeleton.CustomMenuItem value={'single'}>Single</props.skeleton.CustomMenuItem>
                                                                                                                            </props.skeleton.CustomSelect>
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>

                                                                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                                                                        {!editingChildField 
                                                                                                                            ? <props.skeleton.CustomTypography><b>Status</b><br />{ child.status }</props.skeleton.CustomTypography>
                                                                                                                            : <props.skeleton.CustomSelect
                                                                                                                                sx={{ width: '100%' }}
                                                                                                                                id={"nuget-status-"+child.slug}
                                                                                                                                label="Status"
                                                                                                                                value={childTemp.status || ''}
                                                                                                                                onChange={(e) => setChildTemp({...childTemp, status: e.target.value})}                                                                            
                                                                                                                            >
                                                                                                                                <props.skeleton.CustomMenuItem value={'active'}>Active</props.skeleton.CustomMenuItem>
                                                                                                                                <props.skeleton.CustomMenuItem value={'inactive'}>Inactive</props.skeleton.CustomMenuItem>
                                                                                                                            </props.skeleton.CustomSelect>
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>

                                                                                                                    <props.skeleton.CustomGrid item xs={2}>
                                                                                                                        {
                                                                                                                            (!editingChildField)
                                                                                                                            ? <props.skeleton.Bar center="true" onClick={() => handleEditingChildField(child)} padding10="true" button="true">Edit</props.skeleton.Bar>
                                                                                                                            : <props.skeleton.Bar center="true" onClick={() => handleCancalEditingChildField(child)} padding10="true" button="true">Cancel</props.skeleton.Bar>
                                                                                                                        }
                                                                                                                    </props.skeleton.CustomGrid>
                                                                                                                </props.skeleton.CustomGrid>

                                                                                                                <Spacer length={1} />

                                                                                                                <props.skeleton.CustomGrid container spacing={2}>
                                                                                                                    <props.skeleton.CustomGrid item xs={12}>
                                                                                                                        <props.skeleton.Heading h2 nobg>Roles required to see</props.skeleton.Heading>
                                                                                                                        <props.skeleton.Heading h5 nobg>None checked means that anyone can see this.</props.skeleton.Heading>
                                                                                                                        <props.skeleton.CustomFormGroup>
                                                                                                                            <props.skeleton.CustomFormControlLabel control={(child.roleReq.includes("editor")) ? <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "remove", "editor")} checked={true} /> : <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "add", "editor")} checked={false} />} label="Editor" />
                                                                                                                            <props.skeleton.CustomFormControlLabel control={(child.roleReq.includes("admin")) ? <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "remove", "admin")} checked={true} /> : <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "add", "admin")} checked={false} />} label="Admin" />
                                                                                                                            <props.skeleton.CustomFormControlLabel control={(child.roleReq.includes("superadmin")) ? <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "remove", "superadmin")} checked={true} /> : <Checkbox disabled={(!editingChildField) ? true : false} onClick={() => handleRoleReqChange(child, "add", "superadmin")} checked={false} />} label="SuperAdmin" />
                                                                                                                        </props.skeleton.CustomFormGroup>
                                                                                                                    </props.skeleton.CustomGrid>
                                                                                                                </props.skeleton.CustomGrid>
                                                                                                            </props.skeleton.CustomAccordianDetails>

                                                                                                            <props.skeleton.CustomAccordianActions>
                                                                                                                <props.skeleton.CustomButton buttonbg={'red'} variant="contained" color={"error"} onClick={() => handleDeleteChildDetails(child)}>Delete</props.skeleton.CustomButton>
                                                                                                                <props.skeleton.CustomButton variant="contained" color={"primary"} onClick={() => handleUpdateChildDetails(child)}>Update</props.skeleton.CustomButton>
                                                                                                            </props.skeleton.CustomAccordianActions>
                                                                                                        </props.skeleton.CustomAccordian>
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </props.skeleton.CustomGrid>
                                                                            </props.skeleton.CustomGrid>
                                                                        </React.Fragment>
                                                                    )
                                                                    : null
                                                                }
                                                            </props.skeleton.CustomAccordianDetails>

                                                            <props.skeleton.CustomAccordianActions>
                                                                <props.skeleton.CustomButton buttonbg={'red'} variant="contained" color={"error"} onClick={() => handleDeleteDetails(item)}>Delete</props.skeleton.CustomButton>
                                                                <props.skeleton.CustomButton variant="contained" color={"primary"} onClick={() => handleUpdateDetails()}>Update</props.skeleton.CustomButton>
                                                            </props.skeleton.CustomAccordianActions>
                                                        </props.skeleton.CustomAccordian>

                                                        /*
                                                        ---------------------------------------------------------------------------- */
                                                    )
                                                })
                                            )
                                            : (
                                                <props.skeleton.Heading h4 nobg>Select a menu to edit the items</props.skeleton.Heading>
                                            )
                                        }
                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------*/}
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------*/}

                                {/* #endregion ------------------------------------------------------------------------------------*/}
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    )
                    : (
                        <props.skeleton.CustomGrid container spacing={2} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.Heading h2>
                                    
                                </props.skeleton.Heading>
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.Heading nobg h2>
                                    You do not have permission to see this.
                                </props.skeleton.Heading>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    )
                }
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}