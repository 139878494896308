export const typography = {
    base: {
        background: "red",
        color: "black",
        fontfamily: "Rubik",
        userselect: "normal"
    },
    basealt: {
        background: "red",
        color: "black",
        fontfamily: "Rubik",
        userselect: "normal"
    },
    border: {
        params: {
            border: "thin solid #e65fa2"
        },
        styles: {
            one: "thin solid white",
            default: "thin solid cyan"
        },
    },
    breadcrumb: {
        base: {
            background: "#191919",
            color: "red !important", // sometimes you need !important if it doesn't change
            fontsize: "15px",
            padding: "10px"
        }
    },
    breadcrumbSeparator: {
        base: {
            background: "transparent",
            border: "thin solid transparent",
            color: "black",
            colorwithborder: "white !important",
            padding: "0px 6px 0px 6px"
        },
        border: {        
            params: {
                border: "thin solid #006b6b"
            },
            styles: {
                one: "2px solid transparent",
                default: "thin solid cyan"
            },
        },
    },
    button: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    divider1: {
        background: "#ffb3b3",
        color: "black",
        cursor: "normal",
        userselect: "none"
    }, 
    divider2: {
        background: "#ff8080",
        color: "black",
        cursor: "normal",
        userselect: "none"
    }, 
    divider3: {
        background: "#ff4d4d",
        color: "black",
        cursor: "normal",
        userselect: "none"
    },
    drawer: {
        base: {
            background: "#191919",
            color: "red"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "15px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        avatarletter: {
            background: 'white',
            backgroundHover: '#191919',
            backgroundActive: '#191919',
            borderradius: '5px',
            color: 'red',
            colorHover: 'red',
            colorActive: 'red',
            fontsize: '20px',
            fontweight: '700'
        },
        color: 'black',
        nougatcolor: 'white',
        faded: 'white',
        fadedHover: 'white',
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    navbar: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    para: {
        base: {
            background: "red",
            color: "black",
            fontfamily: "Rubik"
        },
    }
}