/* 
#############################[ NOTES ]#######################################################################################################
* Page-specific styles
* 
#############################################################################################################################################
*/

// import styled-components
import styled, { css } from 'styled-components'

import { 
    Avatar,
    Breadcrumbs,
    Box, 
    Button,
    Card, 
    CardContent,
    CardMedia,
    Container,
    Grid,
    Menu,
    MenuItem,
    Modal,
    Pagination,
    PaginationItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tab,
    Tabs,
    TablePagination,
    TableRow,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Stepper,
    Step,
    StepLabel
} from '@mui/material'

import { HashLink } from 'react-router-hash-link'

// Import telinput to handle telephone input
import { MuiTelInput } from 'mui-tel-input'

// Import from MUI lab
import {
    //TabContext,
    TabList,
    //TabPanel
} from '@mui/lab'

// Import Components
import Drawer from "../../../components/Drawer"

// Import MUI Data Grid
import { DataGrid } from '@mui/x-data-grid'

import { ToastContainer } from 'react-toastify'

export const Bar = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 0 0 86%;
    height: 37px;
    justify-content: ${(props) => 
        (props.center)
        ? "center"
        : "left"
    };
    align-items: center;
    user-select: ${(props) =>
        (props.fontbarfooterbutton)
        ? "none"
        : "auto"
    };

    ${(props) => 
        props.border &&
        css`
            border: thin solid black;
        `
    }

    ${(props) => 
        props.bottomgizmo &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            bottom:0;
            padding: 5px;
            z-index: 100;
            width: 100%;
        `
    }

    ${(props) => 
        props.bottomgizmobutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.background};           
            color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.color};         
            cursor: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.cursor};     
            height: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.height};
            justify-content: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.textalign};            
            margin: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.margin};
            padding: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.padding};            
            text-align: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.textalign};
            width: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.width};            

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.button &&
        css`
            background: ${(props) => props.theme.sys.modules.bar.button.base.background};
            color: ${(props) => props.theme.sys.modules.bar.button.base.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.bar.button.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.bar.button.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.bar.button.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.bar.button.base.colorActive};
            }
        `
    }

    ${(props) => 
        props.center &&
        css`
            align-items: center;
            justify-content: center;
        `
    }

    ${(props) => 
        props.drawernavbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.button.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.button.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweight};
            padding-bottom: ${(props) => props.theme.sys.modules.drawer.button.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.drawer.button.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.drawer.button.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.drawer.button.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.button.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.button.base.colorHover};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.button.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.button.base.colorActive};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.drawernestednavbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweight};
            padding-bottom: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.colorHover};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.colorActive};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.drawer &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.base.color};
            cursor: auto;
        `
    }

    ${(props) => 
        props.fontbarfooterbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.footer.button.background};
            color: ${(props) => props.theme.sys.modules.footer.button.color};
            cursor: pointer;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.footer.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.fullheight &&
        css`
            min-height: 100%;
        `
    }

    ${(props) => 
        props.fullwidth &&
        css`
            min-width: 100%;
        `
    }

    ${(props) => 
        props.navbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.navbar.button.background};
            color: ${(props) => props.theme.sys.modules.navbar.button.color};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.navbar.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.navbar.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.navbar.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.navbar.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding5lronly &&
        css`
            padding: 0 5px 0 5px;
        `
    }

    ${(props) => 
        props.padding10lronly &&
        css`
            padding: 0 10px 0 10px;
        `
    }

    ${(props) => 
        props.padding15 &&
        css`
            padding: 15px;
        `
    }

    ${(props) => 
        props.padding15LROnly &&
        css`
            padding: 0 15x 0 15px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) => 
        props.padding20LROnly &&
        css`
            padding: 0 20px 0 20px;
        `
    }

    ${(props) => 
        props.paddingbottom30  &&
        css`
            padding-bottom: 30px;
        `
    }

    ${(props) => 
        props.sticky &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            top:0;
            z-index: 100;
        `
    }

    ${(props) =>
        props.underbar &&
        css`
            border: ${(props) => props.theme.sys.modules.navbar.button.underbar.params.border};
            border-width: ${(props) => props.theme.sys.modules.navbar.button.underbar.params.borderwidth};
        `
    }
`

export const Body = styled.div`
    padding: 3em 5em;
`

export const Bold = styled.b`
    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }
`

export const CarouselSlide = styled(Box)(({ activeindex, index }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: `${(index - activeindex) * 100}%`,
    transition: 'top 0.5s ease-in-out'
}))

export const CardActions = styled.div`
    display: flex;
    background: ${(props) => props.theme.secondaryBackground};
    width: 100%;
    min-height: 5em;
    align-items: center;
    justify-content: center;
`

export const CustomAvatarLetter = styled.div`
    background-color: red;
    border-radius: 50px;
    font-weight: 500;
    padding: 10px 15px 15px 15px;
`

export const CustomAvatar = styled(Avatar)`
    
`

export const CustomBreadcrumbs = styled(Breadcrumbs)`
    
`

export const CustomBreadcrumbsSeparator = styled.div`    
    align-items: center;
    background: ${(props) => props.theme.sys.modules.typography.breadcrumbSeparator.base.background};
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.sys.modules.typography.breadcrumbSeparator.border.styles.one
            } else {
                return props.theme.sys.modules.typography.breadcrumbSeparator.base.border
            }            
        }        
    }};
    color: ${(props) => {
        if (props.border) {
            return props.theme.sys.modules.typography.breadcrumbSeparator.base.colorwithborder
        } else {
            return props.theme.sys.modules.typography.breadcrumbSeparator.base.color
        }
    }};
    display: flex;
    font-size: 25px;
    justify-content: center;
    padding: ${(props) => props.theme.sys.modules.typography.breadcrumbSeparator.base.padding};
`

export const CustomButton = styled.button`
    border: thin solid black;
    border-radius: 5px; 
    cursor: pointer;
    font-size: 2em;
    user-select: none;
`

export const CustomBox = styled(Box)`
    align-items: center;    
    background: ${(props) => props.theme.sys.modules.typography.base.background};
    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }};
    color: ${(props) => props.theme.sys.modules.typography.base.color};
    cursor: ${(props) => 
        (props.button)
        ? "pointer"
        : "auto"
    };
    justify-content: center; 
    margin: 0;   
    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
    padding: ${(props) => 
        (props.headerimage) 
        ? "0px"
        : (
            (props.paddingzero)
            ? "0px"
            : "5px"
        )
    };

    ${(props) => 
        props.leftAlign &&
        css` 
            align-items: left;
            justify-content: left;
        `
    }    

    ${(props) =>
        props.modal && 
        css`
            background: #191919;
            border: 1px solid #000;
            boxShadow: 24;
            left: 50%;
            p: 4;
            position: absolute;
            top: 50%;            
            transform: translate(-50%, -50%);
            width: 400;                        
        `
    }

    ${(props) => 
        props.nobg &&
        css` 
            background: transparent;
        `
    }

    ${(props) => 
        props.stickyBottom &&
        css` 
            position: fixed;
            bottom: 0;
        `
    }
`
export const CustomCard = styled(Card)`
    margin: 10px;
    &.MuiCard-root {
        background: ${(props) => props.theme.sys.modules.card.base.background};
    }
`

export const CustomCardMedia = styled(CardMedia)`
    
`

export const CustomCardContent = styled(CardContent)`

`

export const CustomDataGrid = styled(DataGrid)({
    '.MuiDataGrid-columnSeparator': {
        display: 'flex',
    },
    '.MuiDataGrid-columnHeader': {
        color: (props) => props.theme.text,
    },
    '.MuiCheckbox-root': {
        color: (props) => props.theme.checkbox,
    },
    '.Mui-Checked': {
        background: (props) => props.theme.checkboxChecked
    },
    '&.MuiDataGrid-root': {
        border: "thin solid",
        borderColor: (props) => props.theme.secondaryBackground,
    },
    '& .MuiDataGrid-cell': {     
        borderColor: (props) => props.theme.secondaryBackground,
        color: (props) => props.theme.text,
    },
    '& .MuiDataGrid-cell:hover': {
        color: (props) => props.theme.textSecondary,
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
        outline: "none !important",
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
})

export const CustomDrawer = styled(Drawer)`
    
`

export const CustomGrid = styled(Grid)` 
    background: ${(props) => {
        if (props.gem) {
            switch (props.color) {
                case "primary":
                    return props.theme.sys.modules.gem.base.primary.background
                case "secondary":
                    return props.theme.sys.modules.gem.base.secondary.background
                case "tertiary":
                    return props.theme.sys.modules.gem.base.tertiary.background
                case "quarternary":
                    return props.theme.sys.modules.gem.base.quarternary.background
                case "quarternarydarker":
                    return props.theme.sys.modules.gem.base.quarternarydarker.background
                case "transparent":
                    return "none"
                default:
                    return props.theme.sys.modules.gem.base.primary.background
            }
        } else if (props.sink) {
            return props.theme.sys.modules.grid.sink.background
        } else if (props.highlight) {
            switch (props.highlight) {
                case "golden":
                    return props.theme.sys.modules.grid.highlight.golden.base.background
                case "normal":
                    return props.theme.sys.modules.grid.highlight.normal.base.background
                default:
                    return props.highlight
            }
        } else if (props.panel) {
            return props.theme.sys.modules.grid.panel.background
        } else if (props.panelback) {
            return props.theme.sys.modules.grid.panelback.background
        } else if (props.darkerheading) {
            return props.theme.sys.modules.grid.darkerheading.background
        } else {
            return "none"
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.sys.modules.grid.border.styles.one
            } else {
                return props.theme.sys.modules.grid.border.params.border
            }
        } else if (props.bordertopbottom) {
            return props.theme.sys.modules.grid.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }};

    

    border-width: ${(props) => {
        if (props.border) {
            return props.theme.sys.modules.grid.border.params.borderWidth
        } else if (props.bordertopbottom) {
            return props.theme.sys.modules.grid.bordertopbottom.params.borderWidth
        } else {
            return "none"           
        }
    }};

    color: ${(props) => {
        if (props.gem) {
            if (props.color === "primary") {
                return props.theme.sys.modules.gem.base.primary.color
            } else if (props.color === "secondary") {
                return props.theme.sys.modules.gem.base.secondary.color
            } else if (props.color === "tertiary") {
                return props.theme.sys.modules.gem.base.tertiary.color
            } else if (props.color === "quarternary") {
                return props.theme.sys.modules.gem.base.quarternary.color
            } else {
                return props.theme.sys.modules.gem.base.primary.color
            }
        } else if (props.sink) {
            return props.theme.sys.modules.grid.sink.color
        } else if (props.highlight) {
            if (props.highlight === "golden") {
                return props.theme.sys.modules.grid.highlight.golden.base.color
            } else {
                return props.highlight
            }
        } else if (props.panel) {
            return props.theme.sys.modules.grid.panel.color
        } else if (props.icon) {
            return props.theme.sys.modules.grid.panel.icon.color
        } else {
            return "none"
        }
    }}
    ;

    font-size: ${(props) => {
        if (props.gem) {
            return "20px";
        } else if (props.sink) {
            return props.theme.sys.modules.grid.params.fontsize
        } else {
            return "12px"
        }
    }};
    //C <- 2024-06-14 - Neko (neko) contributed to this bug. It caused the rounding of the CustomGrid panels to not work
    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.gem) {
            return props.padding ? `${props.padding}px` : "0px"
        } else {
            return props.padding ? props.padding : "0px"
        }
    }};

    text-align: ${(props) => {
        if (props.center) {
            return 'center'
        } else if (props.right) {
            return 'right'
        } else {
            return 'left'
        }
    }};

    user-select: ${(props) => {
        if (props.nouserselect) {
            return 'none'
        } else {
            return 'auto'
        }
    }};

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
            background: ${(props) => props.theme.sys.modules.grid.button.primary.background};
            color: ${(props) => props.theme.sys.modules.grid.button.primary.color};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.grid.button.primary.backgroundHover};
                color: ${(props) => props.theme.sys.modules.grid.button.primary.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.grid.button.primary.backgroundActive};
                color: ${(props) => props.theme.sys.modules.grid.button.primary.colorActive};
            }
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            //border: thin solid black;
            background: white;
            color: white;
        `
    }

    ${(props) => 
        (props.richtablenougat) &&
        css`
            background: ${props.theme.dashboard.modules.grid.richtablenougat.base.background};
            //border: thin solid green;
        `
    }
    
    ${(props) => 
        (props.richtabletopbar) &&
        css`
            background: ${props.theme.dashboard.modules.grid.richtablenougat.topbar.background};
            padding: 10px;
            //border: thin solid green;
        `
    }
`

export const CustomHashLink = styled(HashLink)`

`

export const CardHeader = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`

export const CustomIconButton = styled.div`
    color: ${(props) => props.theme.sys.base.iconbutton.color};
    cursor: pointer;
`

export const CustomItem = styled.div`
    background: ${(props) => 
        (props.footeritem)
        ? props.theme.sys.modules.footer.item.base.background
        : (
            (props.footerfullitem)
            ? props.theme.sys.modules.footer.item.full.background
            : (
                (props.nobg) 
                ? "none"
                : props.theme.sys.modules.item.base.background
            )
        )
    };
    color: ${(props) => 
        (props.footeritem)
        ? props.theme.sys.modules.footer.item.color
        : (
            (props.footerfullitem)
            ? props.theme.sys.modules.footer.item.full.color
            : props.theme.sys.modules.item.base.color
        )        
    };
    font-size: ${(props) => 
        (props.footeritem) 
        ? props.theme.sys.modules.footer.base.fontsize
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? props.theme.sys.modules.drawer.bottomgizmo.params.fontsizeprimary
                : (
                    (props.bottomgizmodetailssecondary)
                    ? props.theme.sys.modules.drawer.bottomgizmo.params.fontsizesecondary
                    : props.theme.sys.modules.drawer.bottomgizmo.params.fontsize
                )
            )
            : '20px'
          )
    };
    font-weight: ${(props) => 
        (props.footeritem) 
        ? 500
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? 900
                : (
                    (props.bottomgizmodetailssecondary)
                    ? 400
                    : 100
                )
            )
            : 400
          )
    };

    margin-right: ${(props) => 
        (props.right)
        ? "10px"
        : "0px"
    };    

    padding: ${(props) => 
        (props.padding10)
        ? "10px 10px 10px 10px"
        : "0px 0px 0px 0px"
    };

    text-align: ${(props) =>
        (props.richtable)
        ? "left"
        : "center"
    };

    ${(props) => 
        (props.userinfobar) &&
        css`
            font-size: 13px;
        `
    }

    user-select: ${(props) => 
        (props.footerfullitem) 
        ? "none"
        : "auto"
    }; 
`

export const CustomLink = styled.a`
    background: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.background
        : (
            (props.nobg) 
            ? "none"
            : props.theme.sys.modules.customlink.base.background
        )
    };
    color: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.color
        : props.theme.sys.modules.customlink.base.color
    };
    cursor: pointer;
    padding: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.params.padding
        : (
            (props.nopadding)
            ? "0px"
            : props.theme.sys.modules.customlink.base.params.padding
        )
    };
    font-size: ${(props) => {
        if (props.smallertext) {
            return "15px"
        } else {
            return "22px"
        }
    }};
    text-decoration: underline;

    ${(props) => {
        (props.nobg) 
        ? (
            css`
            
            `
        )
        : (
            css`
            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.customlink.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.customlink.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.customlink.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.customlink.base.colorActive};
            } 
        `
        )
    }}

    ${(props) => 
        props.noUnderLine &&
        css`
            text-decoration: none;
        `
    }

    ${(props) => 
        props.grayed &&
        css`
            color: ${(props) => props.theme.textGrayed};
        `
    }
`

// SHOUTOUT: To this for helping for solve:
// https://stackoverflow.com/questions/73925276/how-to-override-mui-paperprops-with-styled-components
export const CustomMenu = styled(Menu)`
    && {
        & .MuiPaper-root {
            background-color: ${(props) => props.theme.sys.modules.menu.base.background};
            color: ${(props) => props.theme.sys.modules.menu.base.color};
            min-width: ${(props) => props.theme.sys.modules.menu.params.minwidth};
            padding: ${(props) => props.theme.sys.modules.menu.params.padding};
        }

        // arrow
        & .MuiPaper-root:before {
            background-color: ${(props) => props.theme.sys.modules.menu.arrow.base.background};
            top: ${(props) => props.theme.sys.modules.menu.arrow.params.top};
            right: ${(props) => props.theme.sys.modules.menu.arrow.params.right};
            height: ${(props) => props.theme.sys.modules.menu.arrow.params.height};
            transform: translateY(${(props) => props.theme.sys.theme.sys.modules.menu.arrow.params.transform.rotate}) rotate(${(props) => props.theme.sys.modules.menu.arrow.params.transform.rotate});
        }
    }
`   

// SHOUTOUT: To this for helping solve this: 
// https://stackoverflow.com/questions/73335643/how-to-apply-styles-to-mui-menulist-with-styled-compoents
export const CustomMenuItem = styled(MenuItem)`
    && {
        padding: ${(props) => props.theme.sys.modules.menu.item.params.padding};

        &.Mui-selected {
            background-color: ${(props) => props.theme.sys.modules.menu.item.base.background};
            color: ${(props) => props.theme.sys.modules.menu.item.base.color};
        }

        &:hover {
            background-color: ${(props) => props.theme.sys.modules.menu.item.base.backgroundHover};
            color: ${(props) => props.theme.sys.modules.menu.item.base.colorHover};
        }
    }
`

export const CustomModal = styled(Modal)`
    
`

export const CustomMuiTelInput = styled(MuiTelInput)`
    && {
        .MuiInputBase-root {
            background-color: ${(props) => props.theme.sys.modules.telinput.base.background};
            color: ${(props) => props.theme.sys.modules.telinput.base.color};
        }
        
        .MuiInputBase-root.Mui-focused {
            background-color: ${(props) => props.theme.sys.modules.telinput.base.backgroundHover};
            color: ${(props) => props.theme.sys.modules.telinput.base.colorHover};
        }

        .MuiOutlinedInput-root {
            & fieldset {
                border: ${(props) => props.theme.sys.modules.telinput.base.border};
            }   

            &:hover fieldset {
                border: ${(props) => props.theme.sys.modules.telinput.base.borderHover};
            }   
            
            &.Mui-focused fieldset {
                border: ${(props) => props.theme.sys.modules.telinput.base.borderFocused};
            }
        }

        .MuiInputLabel-root {
            background-color: ${(props) => props.theme.sys.modules.textfield.base.labelBackground};
            border: ${(props) => props.theme.sys.modules.textfield.base.labelBorder};
            color: ${(props) => props.theme.sys.modules.textfield.base.labelColor}; 
            padding: ${(props) => props.theme.sys.modules.textfield.base.labelPadding};
        }
        .MuiInputLabel-root.Mui-focused {
            background-color: ${(props) => props.theme.sys.modules.textfield.base.labelBackgroundFocus};
            border: ${(props) => props.theme.sys.modules.textfield.base.labelBorderFocus};
            color: ${(props) => props.theme.sys.modules.textfield.base.labelColorFocus};
            padding: ${(props) => props.theme.sys.modules.textfield.base.labelPaddingFocus};
        }
    }
    width: 100%;
    height: 3.5em;
`    

export const CustomPagination = styled(Pagination)`
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 260px;

    & .MuiPagination-root {
        font-size: 40px;
    };

    // pagination background
    & .MuiPagination-ul {
        background: ${(props) => props.theme.sys.modules.pagination.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.base.color};
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-ul .MuiButtonBase {
        background: red;
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-outlined {
        font-size: 40px;
    };

    & .MuiPagination-text {
        background: black;
        color: white;
        font-size: 40px;
    };

    & .Mui-selected {
        background: cyan;
        color: black;
        font-size: 40px;
    };

    & button {
        background: white;
    };

    // Pagination button not selected
    & ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected) {
        background: ${(props) => props.theme.sys.modules.pagination.buttonnotselected.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.buttonnotselected.base.color};
        font-size: 40px;
    };

    // Pagination button
    & button {
        background: ${(props) => props.theme.sys.modules.pagination.button.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.button.base.color};
        font-size: 40px;
    };

    // Pagination button hover
    & button:hover {
        background: violet;
        color: black;
        font-size: 40px;
    };
 `

 export const CustomPaginationItem = styled(PaginationItem)`
 
 `

export const CustomStack = styled(Stack)`
    ${(props) => 
        (props.userinfobar) &&
        css `
            //border: thin solid green;
            background: transparent;
            color: black;
            padding: 10px;
        `
    }
`
export const CustomStep = styled(Step)`
    
`

export const CustomStepLabel = styled(StepLabel)`
    background-color: transparent;
    .MuiStepLabel-label {
        color: ${(props) => (props.active ? props.theme.sys.modules.step.label.base.colorHighlight + ' !important' : props.theme.sys.modules.step.label.base.color )}; 
    }
    .MuiStepIcon-root {
        color: ${(props) => (props.active ? props.theme.sys.modules.step.icon.base.colorHighlight + ' !important' : props.theme.sys.modules.step.icon.base.color )};
    }
    .MuiStepIcon-text {
        fill: ${(props) => (props.active) ? props.theme.sys.modules.step.icon.base.colorTextHighlight : props.theme.sys.modules.step.icon.base.colorText };
    }
`

export const CustomStepper = styled(Stepper)`
    background-color: transparent;
`

export const CustomTextField = styled(TextField)`
    &width: 100%; 
    z-index: 1;

    && {
        .MuiInputLabel-root {
            background-color: ${(props) => props.theme.sys.modules.textfield.base.labelBackground};
            border: ${(props) => props.theme.sys.modules.textfield.base.labelBorder};
            color: ${(props) => props.theme.sys.modules.textfield.base.labelColor}; 
            padding: ${(props) => props.theme.sys.modules.textfield.base.labelPadding};
            font-size: ${(props) => props.theme.sys.modules.textfield.base.labelFontsize};
        }
        .MuiInputLabel-root.Mui-focused {
            background-color: ${(props) => props.theme.sys.modules.textfield.base.labelBackgroundFocus};
            border: ${(props) => props.theme.sys.modules.textfield.base.labelBorderFocus};
            color: ${(props) => props.theme.sys.modules.textfield.base.labelColorFocus};
            font-size: ${(props) => props.theme.sys.modules.textfield.base.labelFontsizeFocus};
            padding: ${(props) => props.theme.sys.modules.textfield.base.labelPaddingFocus};
        }
    }

    & label.Mui-focused {
        background: pink;
        border: thin solid black;
        color: black;
        font-size: 22px;
        font-family: 'Rubik', sans-serif;
        padding: 5px 5px 0px 5px;
    }

    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiInputBase-multiline {
        margin: 0px;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border: ${(props) => props.theme.sys.modules.textfield.base.border};
        }
        &:hover fieldset {
            border-color: black;
        }
        &.Mui-focused fieldset {            
            color: black;
            border-color: violet;
        }
    }

    & .MuiOutlinedInput-input {
        background: ${(props) => props.theme.sys.modules.textfield.base.background};
        color: ${(props) => props.theme.sys.modules.textfield.base.color};

        &:focus,
        &:hover {
            background-color: ${(props) => props.theme.sys.modules.textfield.base.backgroundHover};
            color: ${(props) => props.theme.sys.modules.textfield.base.colorHover};
        }
    }
`

export const CustomToastContainer = styled(ToastContainer)` 
    // ???
    .Toastify {
        
    }
    
    // ???
    .Toastify__toast-container {
        
    } 

    // Normal progress bar style
    .Toastify__progress-bar {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.normal.background};
    }

    // Error progress bar style
    .Toastify__progress-bar--error {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.error.background};
    }

    // Info progress bar style
    .Toastify__progress-bar--info {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.info.background};
    }

    // Warning progress bar style
    .Toastify__progress-bar--warning {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.warning.background};
    }

    // Icon (WIP: cant find it)
    .Toastify__toast-icon {
        
    } 
    
    // Body of notification (text and what not)
    .Toastify__toast-body {
        font-size: 20px;
        font-family: 'Rubik';
    }

    // Normal style 
    .Toastify__toast {
        background: ${(props) => props.theme.sys.modules.notification.body.normal.background};
        color: ${(props) => props.theme.sys.modules.notification.body.normal.color};
        min-height: 60px; // minimum is 60px
        width: 300px;
    } 

    // Error style
    .Toastify__toast--error {
        background: ${(props) => props.theme.sys.modules.notification.body.error.background};
        color: ${(props) => props.theme.sys.modules.notification.body.error.color};
    }

    // Info style
    .Toastify__toast--info {
        background: ${(props) => props.theme.sys.modules.notification.body.info.background};
        color: ${(props) => props.theme.sys.modules.notification.body.info.color};
    }

    // Warning style
    .Toastify__toast--warning {
        background: ${(props) => props.theme.sys.modules.notification.body.warning.background};
        color: ${(props) => props.theme.sys.modules.notification.body.warning.color};
    }
`

export const CustomToggleButton = styled(ToggleButton)`

`

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`

`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
    
`

export const CustomTable = styled(Table)`

`

export const CustomTableBody = styled(TableBody)`

`

export const CustomTableCell = styled(TableCell)`

`

export const CustomTableContainer = styled(TableContainer)`

`

export const CustomTableHead = styled(TableHead)`

`

export const CustomTab = styled(Tab)({

})

export const CustomTabs = styled(Tabs)`

    background: ${(props) => props.theme.sys.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;

    && {
        .MuiTab-root {
            color: ${(props) => props.theme.sys.modules.tabs.base.color}; 
        }

        .MuiTabs-indicator {
            background-color: ${(props) => props.theme.sys.modules.tabs.base.indicatorColor}; 
        }
    }
`

export const CustomTabList = styled(TabList)`
    background: ${(props) => props.theme.sys.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;
`

export const CustomTablePagination = styled(TablePagination)`

`

export const CustomTableRow = styled(TableRow)`

`

export const CustomToolbar = styled.div`
    
`

export const CustomTooltip = styled.div`
    
`

export const CustomTypography = styled(Typography)`
    background: ${(props) => props.nobg ? "none" : (
        props.bgprimary ? props.theme.sys.modules.typography.bgprimary.background :
        props.bgsecondary ? props.theme.sys.modules.typography.bgsecondary.background :
        props.bgtertiary ? props.theme.sys.modules.typography.bgtertiary.background :
        props.theme.sys.modules.typography.base.background
    )};
    color: ${(props) => 
        props.bgprimary ? props.theme.sys.modules.typography.bgprimary.color :
        props.bgsecondary ? props.theme.sys.modules.typography.bgsecondary.color :
        props.bgtertiary ? props.theme.sys.modules.typography.bgtertiary.color :
        props.theme.sys.modules.typography.base.color
    };
    font-size: 1em;
    font-weight: 500;
    margin: ${(props) => props.margin || "0px"}
    padding: ${(props) => props.padding || "0px"}
    user-select: ${(props) => props.nouserselect};

    ${(props) => {
        if (props.center) {
            return css`text-align: center;`
        } else if (props.left) {
            return css`text-align: left;`
        } else if (props.right) {
            return css`text-align: right;`
        }
    }}

    ${(props) => {
        if (props.fontheading1) {
            return css`font-family: 'Rubik', sans-serif;`
        } else if (props.fontheading6) {
            return css`font-family: 'Borel', sans-serif;`
        }
    }}

    ${(props) => {
        if (props.h1) {
            return css`font-size: 2.4em;`
        } else if (props.h2) {
            return css`font-size: 2.2em;`
        } else if (props.h3) {
            return css`font-size: 2em;`
        } else if (props.h4) {
            return css`font-size: 1.85em;`
        } else if (props.h5) {
            return css`font-size: 1.6em;`
        } else if (props.h6) {
            return css`font-size: 1.3em;`
        } else if (props.h7) {
            return css`font-size: 1em;`
        } else if (props.h8) {
            return css`font-size: 0.9em;`
        }
    }}

    ${(props) => 
        props.outline &&
        css`
            border: thin solid ${(props) => props.theme.text};
            border-radius: 5px;
        `
    }

    ${(props) => 
        props.grayed &&
        css` color: ${(props) => props.theme.textGrayed}; `
    }
`

export const Heading = styled.div`
    background: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.sys.modules.typography.divider1.background
        } else if (props.footerdivider2) {
            return props.theme.sys.modules.typography.divider2.background
        } else if (props.footerdivider3) {
            return props.theme.sys.modules.typography.divider3.background
        } else if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.sys.modules.typography.badge.permissiontier0.background
            } else if (props.permissiontier1) {
                return props.theme.sys.modules.typography.badge.permissiontier1.background
            } else if (props.permissiontier2) {
                return props.theme.sys.modules.typography.badge.permissiontier2.background
            } else if (props.permissiontier3) {
                return props.theme.sys.modules.typography.badge.permissiontier3.background
            } else {
                return props.theme.sys.modules.typography.badge.base.background
            }
        } else {
            if (props.nobg) {
                return "none"
            } else {
                if (props.darkerheading) {
                    return props.theme.sys.modules.typography.darkerheading.background
                } else {
                    return props.theme.sys.modules.typography.base.background
                }
            }
        }
    }};

    color: ${(props) => {
        if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.sys.modules.typography.badge.permissiontier0.color
            } else if (props.permissiontier1) {
                return props.theme.sys.modules.typography.badge.permissiontier1.color
            } else if (props.permissiontier2) {
                return props.theme.sys.modules.typography.badge.permissiontier2.color
            } else if (props.permissiontier3) {
                return props.theme.sys.modules.typography.badge.permissiontier3.color
            } else {
                return props.theme.sys.modules.typography.badge.base.color
            }
        } else {
            if (props.grid) {
                if (props.grid === "grayed") {
                    return props.theme.sys.modules.typography.grid.grayed.color
                } else {
                    return props.theme.sys.modules.typography.grid.base.color
                }
            } else {
                if (props.highlight) {
                    if (props.highlight === "golden") {
                        return props.theme.sys.modules.grid.highlight.golden.base.color
                    } else if (props.highlight === "goldengrayed") {
                        return props.theme.sys.modules.grid.highlight.goldengrayed.base.color
                    } else {
                        return props.highlight
                    }
                } else {
                    if (props.grayed) {
                        return props.theme.sys.modules.typography.grayed.color
                    } else {
                        return props.theme.sys.modules.typography.base.color
                    }
                }
            }
        }
    }} !important;

    cursor: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.sys.modules.typography.divider1.cursor
        } else if (props.footerdivider2) {
            return props.theme.sys.modules.typography.divider2.cursor
        } else if (props.footerdivider3) {
            return props.theme.sys.modules.typography.divider3.cursor
        } else {
            if (props.nobg) {
                return "normal"
            } else {
                if (props.button) {
                    return "pointer"
                } else {
                    return props.theme.sys.modules.typography.base.cursor
                }
            }
        }
    }};

    font-family: ${(props) => {
        if (props.navbar) {
            return props.theme.sys.modules.typography.navbar.fontfamily
        } else if (props.footer) {
            return props.theme.sys.modules.typography.footer.fontfamily
        } else if (props.gem) {
            if (props.h1) {
                return props.theme.sys.modules.typography.gem.h1.fontfamily
            } else if (props.h6) {
                return props.theme.sys.modules.typography.gem.h6.fontfamily
            } else {
                return props.theme.sys.modules.typography.gem.h1.fontfamily
            }
        } else {
            return props.theme.sys.modules.typography.base.fontfamily
        }
    }};

    font-size: ${(props) => {
        if (props.badge) {
            return "17px"
        } else {
            if (props.h1) {
                return "2.4em"
            } else if (props.h2) {
                return "2.2em"
            } else if (props.h3) {
                return "2em"
            } else if (props.h4) {
                return "1.85em"
            } else if (props.h5) {
                return "1.6em"
            } else if (props.h6) {
                return "1.4em"
            } else if (props.h7) {
                return "1.35em"
            } else if (props.h8) {
                return "1.2em"
            } else if (props.h9) {
                return "1em"
            }
        }
    }};

    font-weight: 500;
    
    margin: ${(props) => {
        if (props.badge) {
            return "0px 3px 0px 3px"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.padding10) {
            return "10px"
        } else {
            if (props.badge) {
                return "4px"
            } else {
                if (props.padding) {
                    return props.padding
                } else {
                    return "0px"
                }
            }
        }
    }};

    text-align: ${(props) => {
        if (props.center) {
            return "center"
        } else if (props.right) {
            return "right"
        } else {
            return "left"
        }
    }};

    text-justify: distribute;
    
    text-transform: ${(props) => {
        if (props.insetmedium) {
            return "uppercase"
        } else if (props.insetlarge) {
            return "uppercase"
        } else if (props.insetxlarge) {
            return "uppercase"
        } else {
            return "none"
        }
    }};

    user-select: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.sys.modules.typography.divider1.userselect
        } else if (props.footerdivider2) {
            return props.theme.sys.modules.typography.divider2.userselect
        } else if (props.footerdivider3) {
            return props.theme.sys.modules.typography.divider3.userselect
        } else {
            if (props.nobg) {
                return "none"
            } else if (props.button) {
                return "pointer"
            } else if (props.drawernavbuttonheading) {
                return "none"
            } else if (props.badge) {
                return "none"
            } else {
                return props.theme.sys.modules.typography.base.userselect
            }
        }
    }};

    ${(props) =>
        (props.button) &&
            css`
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 5px;
            `
        }


    ${(props) =>
    (props.drawernavbuttonheading) &&
        css`
            background: ${(props) => props.theme.sys.modules.typography.drawer.base.background};
            color: ${(props) => props.theme.sys.modules.typography.drawer.base.color} !important;
            font-size: ${(props) => props.theme.sys.modules.typography.drawer.params.fontsize};
            font-family: ${(props) => props.theme.sys.modules.typography.drawer.params.fontfamily};
            padding-bottom: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingtop};
        `
    }

    ${(props) =>
        (props.grayed) &&
        css`
            background: ${(props) => props.theme.sys.modules.typography.grayed.base.background};
            color: ${(props) => props.theme.sys.modules.typography.grayed.base.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextarea) &&
        css`
            font-size: 17px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.base.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.base.color};
            padding: 4px;
        `
    }
`

export const ImageContainer = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    height: ${(props) => { 
        if (props.heightfull) {
            // Handle below
            return "600px"
        } else {
            return "400px"
        }
    }}; /* adjust as needed */
    
    @media (min-width: 1200px) {
        ${(props) => {
            if (props.heightfull) {
                return "height: 600px;"
            }
        }}        
    }

    @media (min-width: 900px) {
        ${(props) => {
            if (props.heightfull) {
                return "height: 600px;"
            }
        }}     
    }

    @media (min-width: 600px) {
        ${(props) => {
            if (props.heightfull) {
                return "height: 600px;"
            }
        }}     
    }

    border: ${(props) => (props.border) ? props.theme.sys.modules.grid.border.styles.one : 'none'};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }} !important;

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
`

export const InnerContent = styled.div`
    padding: ${(props) => {
        if (props.nopadding) {
            return "0px"
        } else if (props.nopaddingleft) {
            return "0px 20px 0px 0px"
        } else if (props.paddingleft20paddingright10) {
            return "0px 20px 0px 10px"
        } else {
            return "0px 20px 0px 20px"
        }
    }};
`

export const InnerDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.sys.modules.drawer.base.background};
    color: ${(props) => props.theme.sys.modules.drawer.base.color};
`

export const InnerInnerDrawer = styled.div`
    height: 100vh;
    background: ${(props) => props.theme.sys.modules.drawer.inner.base.background};
    color: ${(props) => props.theme.sys.modules.drawer.inner.base.color};    
    padding-bottom: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingbottom};
    padding-left: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingleft};
    padding-right: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingright};
    padding-top: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingtop};
`

export const ItemContainer = styled.div`
    border: thin solid red;
`

export const NavBar = styled.div`
    background: ${(props) => props.theme.sys.modules.navbar.base.background};
    padding-top: calc(${(props) => props.theme.sys.modules.navbar.params.height} / 2);
    padding-bottom: calc(${(props) => props.theme.sys.modules.navbar.params.height} / 2);
    width: 100%;
    z-index: 900;
    
    ${(props) => 
        props.stickytop &&
        css` 
            position: fixed;
            top: 0;
        `
    }
`

export const OverlayBox = styled(Box)((props) => {
    const { params = {} } = props
    const positionStyles = {}
    const statusStyles = {}

    if (params.left !== undefined && params.left !== "0px") {
        positionStyles.left = params.left
    }

    if (params.margin !== undefined && params.margin !== "0px") {
        positionStyles.margin = params.margin
    }

    if (params.right !== undefined && params.right !== "0px") {
        positionStyles.right = params.right
    }

    if (params.top !== undefined && params.top !== "0px") {
        positionStyles.top = params.top
    }
    if (params.bottom !== undefined && params.bottom !== "0px") {
        positionStyles.bottom = params.bottom
    }

    if (params.disabled !== undefined) {
        if (params.disabled) {
            statusStyles.backgroundColor = 'rgba(255, 0, 0, 0.7)'
            statusStyles.color = 'gray'
        } else {
            statusStyles.backgroundColor = params.backgroundColor || 'rgba(0, 0, 0, 0.5)'
            statusStyles.color = params.color || 'white'
        }
    } else {
        statusStyles.backgroundColor = params.backgroundColor || 'rgba(0, 0, 0, 0.5)'
        statusStyles.color = params.color || 'white'
    }

    return {
        alignItems: params.alignitems || 'center',
        borderRadius: params.borderradius || '8px', // Rounded corners    
        cursor: params.button ? 'pointer' : params.cursor || 'normal',
        display: 'flex',
        fontSize: params.fontsize || '20px',
        height: params.height || '50px',
        justifyContent: params.justifycontent || 'flex-start', 
        padding: params.padding || '20px', // Padding    
        position: 'absolute',
        textAlign: params.textalign || 'left',
        userSelect: params.userselect || 'none',
        width: params.width || '100px',
        
        ...positionStyles, // Conditionally add left or right

        ...statusStyles,

        ...((params.button && !params.disabled) && {
            '&:focus, &:hover': {
                color: params.hoverColor || 'rgba(255, 255, 255, 1)',
                backgroundColor: params.hoverBackgroundColor || 'rgba(0, 0, 0, 0.7)', // Darker semi-transparent background
                transform: 'scale(1.05)', // Slightly scale up
                outline: 'none', // Remove default focus outline
            },
        }),
    }
})

export const Para = styled.p`
    font-size: 1.2em;
    text-align: justify;
    text-justify: distribute;
    background: ${(props) => props.theme.sys.modules.typography.base.background};
    color: ${(props) => props.theme.sys.modules.typography.base.color};

    ${(props) => 
        props.center &&
        css`            
            text-align: center; 
            justify-content: distribute;                
            `        
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        props.right &&
        css`            
            text-align: right; 
            justify-content: distribute;                
            `        
    }
`

export const StyledImage = styled('img')({
    width: (props) => props.params?.width || '100%',
    height: (props) => props.params?.height || '100%',
    borderRadius: (props) => props.params?.borderradius || '10px',
    overflow: 'hidden',
    objectFit: 'cover' // ensure the image covers the container
})

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    user-select: none;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => 
            props.theme.sys.modules.llswitch.nougat.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    span:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: ${(props) => 
            props.theme.sys.modules.llswitch.button.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: ${(props) => props.theme.sys.modules.llswitch.nougat.background};
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`

export const TextFieldLabel = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.text};
`

// ==== [ Login/Register Form ]========================================================
export const LRImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const FloatingButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    z-indez: 1
`

export const StyledButton = styled(Button)`    
    background-color: white !important;
    border: 2px solid #000000;
    color: black !important; 
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-top: 15px !important;
    &:hover {
        background-color: #008080 !important;
        color: white !important; 
    }
`

export const StyledContainer = styled(Container)`    
    align-items: center;
    display: flex;
    height: 90vh;
    justify-content: center; 
`

export const StyledForm = styled.form`
    margin-top: 1rem;
    width: 100%; // Fix IE 11 issues
`

export const StyledPaper = styled(Paper)`
    align-items: center;
    background-color: ${(props) => props.theme.sys.modules.paper.base.background} !important;
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.sys.modules.paper.border.styles.one
            } else {
                return props.theme.sys.modules.paper.border.params.border
            }
        } else if (props.bordertopbottom) {
            return props.theme.sys.modules.paper.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};
    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }} !important;
    color: ${(props) => props.theme.sys.modules.paper.base.color} !important;
    display: flex;
    flex-direction: column;
    //padding: 2rem;        

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
`
// ================================================================================================