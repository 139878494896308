// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    InputAdornment,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import components
//import { Spacer } from '../../components/Addon/Spacer'

// Import middleware
import { Greetings, GreetingIO } from '../../middleware/GreetingIO'
import styled, { keyframes, css } from 'styled-components'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
`

const ShakeWrapper = styled.div`
    ${({ shake }) =>
        shake && 
        css`
            animation: ${shakeAnimation} 0.5s ease-in-out;
        `
    }
`

export default function VerifyEmail (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        //AccountFormIsDisabled,
        currentUser,
        isAuthenticated,
        shake,

        // Functions
        logoutUser,        
        sendVC,
        setShake,
        verifyEmailUser
    } = useAuth()   

    // Set navigate
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Set default user
    const [user, setUser] = useState({
        userNameEmail: (isAuthenticated) ? currentUser.userName : '', 
        password: (isAuthenticated) ? 'USERLOGGED' : '',
        verificationCode: '',
    })

    // Set showVC
    const [showVC, setShowVC] = useState(false)

    // Set showPassword
    const [showPassword, setShowPassword] = useState(false)

    // Set Greeting
    const [ Greeting, setGreeting ] = useState([null])

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle Go to Dashboard
    const handleGotoDashboard = () => {
        navigate("/dashboard")
    }

    // Handle Go to My Account
    const handleGotoMyAccount = () => {
        navigate("/dashboard/account")
    }

    // Handle Go to login
    const handleGotoLogin = () => {
        navigate("/login")
    }

    // Handle Go to My Profile
    const handleGotoMyProfile = () => {
        navigate("/dashboard/profile")
    }
    
    // Handle Go to Register
    const handleGotoRegister = () => {
        navigate("/register")
    }

    const handleLogout = () => {
        // Do context login
        logoutUser()
    }
    
    // Handle resend VC
    const handleResendVC = () => {
        // Check for input on username/email
        if (user.userNameEmail !== "") {
            sendVC(user)
        } else {
            toast('Tell me your email or username first.', {type: 'error'})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        // handle form submission
        // Get form
        const { userNameEmail, verificationCode } = user
        
        // Validation
        if(!userNameEmail || !verificationCode ) {
            // Failed; nothing in field
            setShake(true)
            toast('Please fill all the fields', {type: 'error'})

            // Reset shake after 0.5s
            setTimeout(() => {
                setShake(false)
            }, 500)
            
            return
        } else {
            // Initial Success; send to api
            // Do context ForgetPassword
            verifyEmailUser(user)
        }
    }

    useEffect(() => {
        setGreeting(GreetingIO(Greetings))
    }, [])

    // State Rendering for debugging
    useEffect(() => {
        if (isAuthenticated) {
            //console.log("isAuthenticated: "+isAuthenticated)
        }
    }, [ isAuthenticated ])

    // Render
    return (
        <>
        <props.skeleton.StyledContainer sx={{ paddingTop: { xs: '8vh', sm: '23vh', md: '21vh', lg: '19vh' } }} component="main" maxWidth="lg">
            <ShakeWrapper shake={shake}>
                <props.skeleton.StyledPaper elevation={3} rounded="true" rounedamt="15px" border="border1">
                    {
                        (!isAuthenticated)
                        ? (
                            <>
                            
                            <props.skeleton.CustomGrid container>
                                <props.skeleton.CustomGrid item xs={12} sm={6}>
                                    <props.skeleton.ImageContainer heightfull="true">
                                    {
                                        <props.skeleton.LRImage
                                            alt="Image Alt Text"
                                            src="https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg"
                                        />  
                                    }
                                    {
                                        // #region ----[ Back Btn ]--------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleGotoLogin()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 1)', 
                                            borderradius: '20px',
                                            button: true,
                                            color: 'black',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            left: '20px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            top: '20px', 
                                            width: '150px'
                                        }}>
                                            Back to Login
                                        </props.skeleton.OverlayBox>                                            
                                        // #endregion ----[ Back Btn ]---------------------------------------------------------------------------
                                    }
                                    </props.skeleton.ImageContainer>
                                </props.skeleton.CustomGrid>

                                <props.skeleton.CustomGrid item xs={12} sm={6} center="true" sx={{ 
                                    paddingBottom: { xs: '5vh', sm: '5vh', md: '10vh', lg: '11vh' },
                                    paddingLeft: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' }, 
                                    paddingRight: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' },
                                    paddingTop: { xs: '5vh', sm: '5vh', md: '5vh', lg: '10vh' }
                                }}>
                                    <props.skeleton.CustomGrid icon="true" item xs={12} sx={{textAlign: 'center' }}>
                                        <LockOutlinedIcon  />
                                    </props.skeleton.CustomGrid>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.CustomTypography center="true" nobg="true" component="h1" variant="h5">
                                            Verify Email
                                        </props.skeleton.CustomTypography>

                                        <props.skeleton.StyledForm onSubmit={handleSubmit}>
                                            <props.skeleton.CustomTextField
                                                sx={{
                                                    mt: 0,
                                                    mb: 0.7
                                                }}
                                                autoComplete="email"
                                                autoFocus
                                                margin="normal"
                                                fullWidth
                                                id="userNameEmail"
                                                label="Username/Email Address"
                                                name="userNameEmail"
                                                size="small"
                                                variant="outlined"   
                                                onChange={(e) => setUser({...user, userNameEmail: e.target.value})}                    
                                            />

                                            <props.skeleton.CustomTextField 
                                                sx={{
                                                    mt: 0,
                                                    mb: 0.7
                                                }}
                                                autoComplete="current-password"
                                                fullWidth
                                                id="password"
                                                label="Password"
                                                margin="normal"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                size="small"
                                                variant="outlined"
                                                value={user.password} 
                                                InputProps={{
                                                    endAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                    </InputAdornment>
                                                }}
                                                onChange={(e) => setUser({...user, password: e.target.value})}
                                            />

                                            <props.skeleton.CustomTextField
                                                sx={{
                                                    mt: 0,
                                                    mb: 0.7
                                                }}
                                                fullWidth
                                                id="verificationCode"
                                                inputProps={{ maxLength: 15 }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" onClick={() => setShowVC({...showVC, vc: !showVC.vc})}>
                                                        {showVC.vc ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                    </InputAdornment>,
                                                }}
                                                label="Verification Code"
                                                name="verificationCode"
                                                size="small"
                                                type={showVC.vc ? 'text' : 'password'}
                                                value={user.verificationCode}
                                                variant="outlined"   
                                                onChange={(e) => setUser({...user, verificationCode: e.target.value})}                    
                                            />

                                            <props.skeleton.StyledButton
                                                sx={{
                                                    mt: 0,
                                                    mb: 0
                                                }}
                                                color="primary"
                                                fullWidth
                                                type="submit"
                                                size="small"
                                                variant="contained"
                                            >
                                                Verify
                                            </props.skeleton.StyledButton>
                                        </props.skeleton.StyledForm>
                                        <props.skeleton.StyledButton
                                            sx={{
                                                mt: 0,
                                                mb: 0
                                            }}
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                            size="small"
                                            variant="contained"
                                            onClick={() => handleGotoRegister()}
                                        >
                                            Need an account?
                                        </props.skeleton.StyledButton>
                                        <props.skeleton.StyledButton
                                            sx={{
                                                mt: 0,
                                                mb: 0
                                            }}
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                            size="small"
                                            variant="contained"
                                            onClick={() => handleResendVC()}
                                        >
                                            Resend Verification
                                        </props.skeleton.StyledButton>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>                                                                                   
                            </props.skeleton.CustomGrid>                                             
                            </>
                        )
                        : (
                            <>
                            <props.skeleton.CustomGrid container>
                                <props.skeleton.CustomGrid item xs={12} sm={6}>
                                    <props.skeleton.LRImage
                                        alt="Image Alt Text"
                                        src="https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg"
                                    />
                                </props.skeleton.CustomGrid>

                                <props.skeleton.CustomGrid item xs={12} sm={6} sx={{ 
                                    paddingBottom: { xs: '5vh', sm: '5vh', md: '10vh', lg: '11vh' },
                                    paddingLeft: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' }, 
                                    paddingRight: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' },
                                    paddingTop: { xs: '5vh', sm: '5vh', md: '5vh', lg: '10vh' }
                                }}>
                                    <props.skeleton.CustomTypography center="true" nobg="true" component="h1" variant="h4">
                                        {Greeting} {currentUser?.userName}!
                                    </props.skeleton.CustomTypography> 

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoDashboard()}
                                    >
                                        Dashboard
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoMyAccount()}
                                    >
                                        My Account
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoMyProfile()}
                                    >
                                        My Profile
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleLogout()}
                                    >
                                        Log Out
                                    </props.skeleton.StyledButton>                               
                                </props.skeleton.CustomGrid>                                                                              
                            </props.skeleton.CustomGrid>
                            </>
                        )
                    }
                </props.skeleton.StyledPaper>
            </ShakeWrapper>
        </props.skeleton.StyledContainer>
        </>
    )
}