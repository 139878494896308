export const customlink = {
    base: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    basealt: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "cyan",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    border: {        
        params: {
            border: "thin solid #006b6b"
        },
        styles: {
            one: "2px solid violet",
            default: "thin solid cyan"
        },
    },
    breadcrumb: {
        base: {
            background: "cyan",
            backgroundHover: "violet",
            color: "black !important", // sometimes you need !important if it doesn't change
            colorHover: "black !important",
            fontsize: "15px",
            padding: "10px"
        }
    },
    copyrightlink: {
        base: {
            background: "#191919",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "cyan",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    richtable: {
        base: {
            background: "#00a1a1",
            backgroundHover: "#00cfcf",
            backgroundActive: "#00cfcf",
            borderradius: "10px",
            color: "black",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black"
        },
        params: {
            padding: "2px 10px 2px 10px" // top right bottom left
        }
    }
}