export const customlink = {
    base: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    basealt: {
        background: "violet",
        backgroundHover: "pink",
        backgroundActive: "pink",
        color: "black",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
    border: {
        params: {
            border: "thin solid #e65fa2"
        },
        styles: {
            one: "thin solid white",
            default: "thin solid cyan"
        },
    },
    breadcrumb: {
        base: {
            background: "black",
            backgroundHover: "pink",
            color: "cyan !important", // sometimes you need !important if it doesn't change
            colorHover: "black !important",
            fontsize: "15px",
            padding: "10px"
        }
    },
    copyrightlink: {
        base: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "black",
            colorGray: 'gray',
            colorHover: "black",
            colorActive: "black",
            params: {
                padding: '0px 0px 0px 0px' // Top Right Bottom Left
            }
        }
    },
    richtable: {
        base: {
            background: "#00e6e6",
            backgroundHover: "#00a1a1",
            backgroundActive: "#00a1a1",
            borderradius: "10px",
            color: "black",
            colorGray: 'gray',
            colorHover: "white",
            colorActive: "white"
        },
        params: {
            padding: "2px 10px 2px 10px" // top right bottom left
        }
    }
}