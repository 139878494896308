import {createContext, useReducer} from 'react'
import axios from 'axios'
import authReducer from './authReducer'
import * as ActionTypes from '../ContextActions'

// eslint-disable-next-line
import { toast } from 'react-toastify'

export const AuthContext = createContext()

export default function AuthState(props) {

    const initialState = {
        AccountEditing: false,
        AccountFormIsDisabled: true,
        AccountProfileImageAdding: false,
        AccountHeaderImageAdding: false,
        AccountUpdating: false,
        AccountUpdatingSent: false,
        AccountUpdatingSentSuccess: false,
        AllUsers: null,
        CheckedIfFollowing: false,
        CheckedIfUnFollowing: false,
        currentUser: null,
        decodedUser: null,
        FollowerCount: 0,
        FollowingCount: 0,
        FriendsCount: 0,
        Followers: [],
        Following: [],
        Friends: [],
        headerImage: null,
        isAuthenticated: null,
        idleTime: 0,
        isFollowing: false,
        isFriend: false,
        isForgettingPassword: false,
        isForgettingPasswordSuccess: false,
        isGivingToUser: false,
        isLoggingIn: false,
        isModulesSet: false,
        isPremium: null,
        isRegistering: false,
        isResettingPassword: false,
        isResettingPasswordSuccess: false,
        isSendingVC: false,
        isSendingVCSuccess: false,
        isSettingFollow: false,
        isSettingUnfollow: false,
        isVerifyingEmail: false,
        isVerifyingEmailSuccess: false,
        myBalance: null,
        Modules: null,
        profileImage: null,
        PublicProfile: null,
        PPID: null,
        regDone: false,
        RegisterFormIsDisabled: false,
        RegisterFormSent: false,
        RegisterFormSentSuccess: false,
        RetrievedAllUsersPublically: false,
        RetrievedPublicProfile: false,
        shake: false,
        toastsAuth: null,
        token: localStorage.getItem('token'),     
        uploadFiles: null, 
        license: null,
    }

    const [state, dispatch] = useReducer(authReducer, initialState)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const CheckIsPremium = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/ispremium', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_FAIL,
                payload: err.response.data,
            })            
        }
    }
    
    const CheckLicense = async (domain) => {
        let MotherAPI = "https://lumierelabs.org/api/v1/"

        try {
            const res = await axios.get(MotherAPI+"license/"+domain+"/check/public", config)
            dispatch({
                type: ActionTypes.GET_LICENSE,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_LICENSE_FAIL,
                payload: err.response.data,
            })            
        }
    }
    
    const CheckMyBalance = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/mybalance', config)
            dispatch({
                type: ActionTypes.GET_USER_MYBALANCE_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_USER_MYBALANCE_FAIL,
                payload: err.response.data,
            })            
        }
    } 
    
    const clearErrorsAuth = () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_AUTH,
        })
    }
    
    const FollowUser = async (whom) => {
        try {
            const parameters = {
                type: "user",
                action: "follow",
                data: {
                    whom: whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.FOLLOW_USER,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.FOLLOW_USER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const forgotpasswordUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/forgotpassword', userData, config)
            dispatch({
                type: ActionTypes.FORGETPASSWORD_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.FORGETPASSWORD_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllUsersPublically = async (limit = null, order = 'ASC', orderBy = 'userName') => {
        try {
            const parameters = {
                limit: limit,
                order: order,
                orderBy: orderBy
            }

            const res = await axios.post('/api/v1/users/public/', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLUSERSPUBLICALLY_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_ALLUSERSPUBLICALLY_FAIL,
                payload: err.response.data,
            })            
        }
    }
    const getFollowers = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/followers', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_FAIL,
                payload: err.response.data,
            })            
        }
    }
    
    const getFollowing = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/following', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWING_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWING_FAIL,
                payload: err.response.data,
            })            
        }
    }
    
    const getFriends = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/friends', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FRIENDS_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FRIENDS_FAIL,
                payload: err.response.data,
            })            
        }
    }

    const getGuestProfile = async () => {
        try {
            //Version 1.3.8 Update fix on no authentication when using a privateroute and don't really need it (ie the homepage)                  
            const tempAuth = {
                id: 0,
                firstName: "No",
                displayName: "",
                email: "noone@no-reply.com",
                image: "no-one.jpg",
                lastName: "One", 
                postDisplay: "justmine",
                role: "user",
                status: "doing AI things...",
                userName: "guest"
            }

            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: tempAuth
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getProfile = async () => {
        try {
            const res = await axios.get('/api/v1/user/myprofile', config)
            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: res.data
            })   
        } catch (err) {
            // DEPRECATED < 2.4.5
            //Version 1.3.8 Update fix on no authentication when using a privateroute and don't really need it (ie the homepage)                  
            /*const tempAuth = {
                id: 0,
                firstName: "No",
                lastName: "One",
                userName: "guest",
                status: "doing AI things...",
                displayName: "",
                email: "noone@no-reply.com",
                image: "no-one.jpg",
                role: "user",                                
            }

            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: tempAuth
            })*/

            /* OLD
            console.log(err.response.data);
            dispatch({
                type: ActionTypes.AUTH_ERROR,
                payload: err.response.data,
            })
            */
        }
    }

    const getPublicProfile = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName, config)
            dispatch({
                type: ActionTypes.GET_PUBLIC_PROFILE_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLIC_PROFILE_FAIL,
                payload: err.response.data,
            })            
        }
    }

    const GiveToUser = async (userData) => {
        try {
            const parameters = {
                type: "user",
                action: "outgo",
                data: {
                    amount: userData.amount,
                    what: "from_user_to_user",
                    whom: userData.whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.POST_USER_GIVETOUSER_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.POST_USER_GIVETOUSER_FAIL,
                payload: err.response.data,
            })            
        }
    } 

    const loginUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/login', userData, config)
            dispatch({
                type: ActionTypes.LOGIN_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
                payload: err.response.data,
            })
        }
    }

    const logoutUser = async () => {
        dispatch({
            type: ActionTypes.LOGOUT,
        })
    }

    const registerUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/register', userData, config)
            dispatch({
                type: ActionTypes.REGISTER_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.REGISTER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const resetpasswordUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/resetpassword', userData, config)
            dispatch({
                type: ActionTypes.RESETPASSWORD_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.RESETPASSWORD_FAIL,
                payload: err.response.data,
            })
        }
    }

    const sendVC = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/resendvc', userData, config)
            dispatch({
                type: ActionTypes.RESENDVC_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.RESENDVC_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateUser = async (userData) => {
        //alert("From AuthState: "+userData.status)

        try {
            const res = await axios.put(`/api/v1/user/update/`, userData, config)
            dispatch({
                type: ActionTypes.UPDATE_USER,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: ActionTypes.UPDATE_USER_ERROR,
                payload: err.response.data,
            })
        }
    }

    const UnfollowUser = async (whom) => {
        try {
            const parameters = {
                type: "user",
                action: "unfollow",
                data: {
                    whom: whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.UNFOLLOW_USER,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.UNFOLLOW_USER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const uploadFile = async (type, sliceData, data) => {
        try {
            const config2 = {
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                }
            }

            const res = axios.post(`/api/v1/`+type+`/upload/${sliceData.what}`, data, config2)

            res.then((response) => {
                //console.log(response.data[0])
                
                // Determine the what
                switch (response.data[0].what) {
                    case 'header':
                        dispatch({
                            type: ActionTypes.UPLOAD_HEADER_IMAGE,
                            payload: response.data
                        }) 
                    break

                    case 'profile':
                        dispatch({
                            type: ActionTypes.UPLOAD_PROFILE_IMAGE,
                            payload: response.data
                        }) 
                    break

                    default:
                        // Unknown what
                    break
                }                
                
            })             
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.UPLOAD_IMAGE_FAIL,
                payload: err.response.data
            })      
        }
    }
    
    const verifyEmailUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/verifyemail', userData, config)
            dispatch({
                type: ActionTypes.VERIFYEMAIL_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.VERIFYEMAIL_FAIL,
                payload: err.response.data,
            })
        }
    }
    
    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setAccountFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_FORM_IS_DISABLED,
            payload: value
        })
    }

    const setAccountEditing = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_EDITING,
            payload: value
        })
    }

    const setAccountProfileImageAdding = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_PROFILEIMAGEADDING,
            payload: value
        })
    }

    const setAccountHeaderImageAdding = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_HEADERIMAGEADDING,
            payload: value
        })
    }

    const setAccountUpdating = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_UPDATING,
            payload: value
        })
    }

    const setAccountUpdatingSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_UPDATINGSENT,
            payload: value
        })
    }
    
    const setAccountUpdatingSentSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_UPDATINGSENTSUCCESS,
            payload: value
        })
    }
    
    const setCurrentUser = async (value) => {
        dispatch({
            type: ActionTypes.SET_CURRENT_USER,
            payload: value
        })
    }
    
    const setFollowers = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWERS,
            payload: value
        })
    }

    const setFollowing = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWING,
            payload: value
        })
    }

    const setFriends = async (value) => {
        dispatch({
            type: ActionTypes.SET_FRIENDS,
            payload: value
        })
    }

    const setFollowerCount = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWERCOUNT,
            payload: value
        })
    }
    
    const setFollowingCount = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWINGCOUNT,
            payload: value
        })
    }

    const setFriendsCount = async (value) => {
        dispatch({
            type: ActionTypes.SET_FRIENDSCOUNT,
            payload: value
        })
    }
    
    const setIsFollowing = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISFOLLOWING,
            payload: value
        })
    }
    
    const setIsForgettingPassword = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISFORGETTINGPASSWORD,
            payload: value
        })
    }

    const setIsForgettingPasswordSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISFORGETTINGPASSWORDSUCCESS,
            payload: value
        })
    }

    const setIsGivingToUser = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISGIVINGTOUSER,
            payload: value
        })
    }

    const setIdleTime = async (value) => {
        dispatch({
            type: ActionTypes.SET_IDLETIME,
            payload: value
        })
    }

    const setIsLoggingIn = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISLOGGINGIN,
            payload: value
        })
    }
    
    const setIsModulesSet = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISMODULESSET,
            payload: value
        })
    }

    const setIsRegistering = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISREGISTERING,
            payload: value
        })
    }

    const setIsResettingPassword = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISRESETTINGPASSWORD,
            payload: value
        })
    }
    
    const setIsResettingPasswordSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISRESETTINGPASSWORDSUCCESS,
            payload: value
        })
    }

    const setIsSendingVC = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSENDINGVC,
            payload: value
        })
    }
    
    const setIsSendingVCSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSENDINGVCSUCCESS,
            payload: value
        })
    }

    const setIsSettingFollow = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSETTINGFOLLOW,
            payload: value
        })
    }

    const setIsSettingUnfollow = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSETTINGUNFOLLOW,
            payload: value
        })
    }
    
    const setModules = async (value) => {
        dispatch({
            type: ActionTypes.SET_MODULES,
            payload: value
        })
    }
    
    const setIsVerifyingEmail = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISVERIFYINGEMAIL,
            payload: value
        })
    }

    const setIsVerifyingEmailSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISVERIFYINGEMAILSUCCESS,
            payload: value
        })
    }

    const setLicense = async (value) => {
        dispatch({
            type: ActionTypes.SET_LICENSE,
            payload: value
        })
    }

    const setMyBalance = async (value) => {
        dispatch({
            type: ActionTypes.SET_MYBALANCE,
            payload: value
        })
    }
    
    const setPPID = async (value) => {
        dispatch({
            type: ActionTypes.SET_PPID,
            payload: value
        })
    }
    
    const setPublicProfile = async (value) => {
        dispatch({
            type: ActionTypes.SET_PUBLICPROFILE,
            payload: value
        })
    }
    
    const setRegDone = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGDONE,
            payload: value
        })
    }
    
    const setRegisterFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGISTERFORMISDISABLED,
            payload: value
        })
    }
    
    const setRegisterFormSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGISTERFORMSENT,
            payload: value
        })
    }
    
    const setRegisterFormSentSuccess = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGISTERFORMSENTSUCCESS,
            payload: value
        })
    }
    
    const setRetrievedAllUsersPublically = async (value) => {
        dispatch({
            type: ActionTypes.SET_RETRIEVEDALLUSERSPUBLICALLY,
            payload: value
        })
    }
    
    const setRetrievedPublicProfile = async (value) => {
        dispatch({
            type: ActionTypes.SET_RETRIEVEDPUBLICPROFILE,
            payload: value
        })
    }

    const setShake = async (value) => {
        dispatch({
            type: ActionTypes.SET_SHAKE,
            payload: value
        })
    }

    const setUploadFiles = (value) =>{
        dispatch({
            type: ActionTypes.SET_UPLOADFILES,
            payload: value
        })
    }
    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    return (
        <AuthContext.Provider value={{
            AccountEditing: state.AccountEditing,
            AccountFormIsDisabled: state.AccountFormIsDisabled,
            AccountProfileImageAdding: state.AccountProfileImageAdding,
            AccountHeaderImageAdding: state.AccountHeaderImageAdding,
            AccountUpdating: state.AccountUpdating,
            AccountUpdatingSent: state.AccountUpdatingSent,
            AccountUpdatingSentSuccess: state.AccountUpdatingSentSuccess,
            AllUsers: state.AllUsers,
            CheckedIfFollowing: state.CheckedIfFollowing,
            CheckedIsUnFollowing: state.CheckedIsUnFollowing,
            currentUser: state.currentUser,
            FollowerCount: state.FollowerCount,
            Followers: state.Followers,
            FollowingCount: state.FollowingCount,
            Following: state.Following,
            Friends: state.Friends,
            FriendsCount: state.FriendsCount,
            headerImage: state.headerImage,
            isAuthenticated: state.isAuthenticated,
            idleTime: state.idleTime,
            isFollowing: state.isFollowing,
            isFriend: state.isFriend,
            isForgettingPassword: state.isForgettingPassword,
            isForgettingPasswordSuccess: state.isForgettingPasswordSuccess,
            isGivingToUser: state.isGivingToUser,
            isLoggingIn: state.isLoggingIn,
            isModulesSet: state.isModulesSet,
            isPremium: state.isPremium,
            isRegistering: state.isRegistering,     
            isResettingPassword: state.isResettingPassword,    
            isResettingPasswordSuccess: state.isResettingPasswordSuccess,
            isSendingVC: state.isSendingVC,   
            isSendingVCSuccess: state.isSendingVCSuccess,
            isSettingFollow: state.isSettingFollow,
            isSettingUnfollow: state.isSettingUnfollow,
            isVerifyingEmail: state.isVerifyingEmail,
            isVerifyingEmailSuccess: state.isVerifyingEmailSuccess,
            license: state.license,
            myBalance: state.myBalance,
            Modules: state.Modules,
            profileImage: state.profileImage,
            PPID: state.PPID,
            PublicProfile: state.PublicProfile,
            regDone: state.regDone,
            RegisterFormIsDisabled: state.RegisterFormIsDisabled,
            RegisterFormSent: state.RegisterFormSent,
            RegisterFormSentSuccess: state.RegisterFormSentSuccess,
            RetrievedAllUsersPublically: state.RetrievedAllUsersPublically,
            RetrievedPublicProfile: state.RetrievedPublicProfile,
            shake: state.shake,
            toastsAuth: state.toastsAuth,
            token: state.token,          
            uploadFiles: state.uploadFiles,

            CheckIsPremium,
            CheckLicense,
            CheckMyBalance,
            clearErrorsAuth,
            FollowUser,
            getAllUsersPublically,
            getFollowers, 
            getFollowing, 
            getFriends,          
            getGuestProfile,
            getProfile,
            getPublicProfile, 
            GiveToUser,
            loginUser,
            logoutUser, 
            forgotpasswordUser,                       
            registerUser,
            resetpasswordUser,
            sendVC,
            setAccountEditing,
            setAccountFormIsDisabled,
            setAccountProfileImageAdding,
            setAccountHeaderImageAdding,
            setAccountUpdating,
            setAccountUpdatingSent,  
            setAccountUpdatingSentSuccess,                      
            setCurrentUser,
            setFollowerCount,
            setFollowingCount,
            setFriendsCount,
            setFollowers, 
            setFollowing,
            setFriends,     
            setIsFollowing,
            setIsForgettingPassword,
            setIsForgettingPasswordSuccess,               
            setIsGivingToUser,
            setIdleTime,
            setLicense,
            setIsLoggingIn,
            setIsModulesSet,
            setIsRegistering,
            setIsResettingPassword,
            setIsResettingPasswordSuccess,
            setIsSettingFollow,
            setIsSettingUnfollow,   
            setIsVerifyingEmail,
            setIsVerifyingEmailSuccess,
            setIsSendingVC,
            setIsSendingVCSuccess,
            setMyBalance,
            setModules,
            setPPID,
            setPublicProfile,
            setRegDone,
            setRegisterFormIsDisabled,
            setRegisterFormSent,
            setRegisterFormSentSuccess,
            setRetrievedAllUsersPublically,
            setRetrievedPublicProfile,
            setShake,
            setUploadFiles,
            updateUser,
            UnfollowUser,
            uploadFile,
            verifyEmailUser
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}