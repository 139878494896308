export const typography = {
    base: {
        background: "#191919",
        color: "cyan",
        colornobg: "black",
        fontfamily: "Rubik",
        userselect: "none"
    },
    basealt: {
        background: "#191919",
        color: "cyan",
        fontfamily: "Rubik",
        userselect: "none"
    },
    badge: {
        base: {
            background: "lime",
            color: "black",
        },
        permissiontier0: {
            background: "#c0c0c0",
            color: "black",
        },
        permissiontier1: {
            background: "#808080",
            color: "white",
        },
        permissiontier2: {
            background: "#00cccc",
            color: "black",
        },
        permissiontier3: {
            background: "#e6c200",
            color: "black",
        }
    },
    border: {
        params: {
            border: "thin solid #ee82ee"
        },
        styles: {
            one: "thin solid #ee82ee",
            default: "thin solid cyan"
        },
    },
    breadcrumb: {
        base: {
            background: "cyan",
            color: "black !important", // sometimes you need !important if it doesn't change            
            fontsize: "15px",
            padding: "10px"
        }
    },
    breadcrumbSeparator: {
        base: {
            background: "transparent", // #ee82ee
            border: "2px solid transparent",
            color: "#ee82ee",
            colorwithborder: "white !important",
            padding: "0px 6px 0px 6px" //0px 12px 0px 12px
        },
        border: {        
            params: {
                border: "thin solid #006b6b"
            },
            styles: {
                one: "2px solid transparent",
                default: "thin solid cyan"
            },
        },
    },
    darkerheading: {
        background: "#171717",
        color: "black",
        fontfamily: "Rubik",
        userselect: "none"
    },
    divider1: {
        background: "#333333",
        color: "white",
        cursor: "normal",
        userselect: "none"
    }, 
    divider2: {
        background: "#4d4d4d",
        color: "white",
        cursor: "normal",
        userselect: "none"
    }, 
    divider3: {
        background: "#666666",
        color: "white",
        cursor: "normal",
        userselect: "none"
    },
    drawer: {
        base: {
            background: "#00b3b3",
            color: "black"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "15px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    grid: {
        base: {
            color: "#ccffff"
        },
        grayed: {
            color: "#00b3b3"
        }
    },
    navbar: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    userinfotextarea: {
        base: {
            background: "transparent",
            color: "#999999",
        }
    },
}