// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, useSetting } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    InputAdornment,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import components
//import { Spacer } from '../../components/Addon/Spacer'

// Import middleware
import { Greetings, GreetingIO } from '../../middleware/GreetingIO'
import styled, { keyframes, css } from 'styled-components'

// Import utils
import { PrettyNumber, settingLookup } from '../../middleware/utils'

// Animation
// eslint-disable-next-line
import { MotionAnimate } from 'react-motion-animate'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
`

const ShakeWrapper = styled.div`
    ${({ shake }) =>
        shake && 
        css`
            animation: ${shakeAnimation} 0.5s ease-in-out;
        `
    }
`

// Keep it to two words only
const steps = [
    'Basic Information',
    'Create username',
    'Lock It',
    'Another name',
    'Contacting you',
    'Access Card',
    'Finished'
]

export default function Register (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------    
    // Set navigate
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        //AccountFormIsDisabled,
        currentUser,
        isAuthenticated,
        registerUser,
        regDone,
        RegisterFormIsDisabled,
        shake,

        // Functions
        logoutUser,
        setRegDone,
        setShake,
        setIsRegistering,
        setRegisterFormIsDisabled
    } = useAuth() 

    // Set default user
    const [user, setUser] = useState({
        alphaCode: '',
        firstName: '',
        lastName: '',
        userName: '', 
        phone: '',
        displayName: '',
        email: '',
        password: '', 
        confirmPassword: '',         
        
        /*
        alphaCode: 'TESTER2024',
        firstName: 'John',
        lastName: 'Titor',
        userName: 'jtitor', 
        phone: '+1 222 239 4434',
        displayName: 'Titor',
        email: 'foxcorpofficial@gmail.com',
        password: 'password', 
        confirmPassword: 'password',             
        */
    })

    const {
        // Variables
        variables,

    } = useSetting()

    // Set slides
    const slides = [
        {
            image: "https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg",
            alt: "Join us",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 255, 255, 0)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Join Our Thriving Community Today!",
                    cursor: 'normal',
                    fontsize: '28px',
                    height: '40px',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px 0px 0px 0px',
                    right: '0px',
                    textalign: 'left',
                    top: '100px',
                    width: '400px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 255, 255, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Are you looking for a place where you can truly belong, connect with like-minded individuals, and grow both personally and professionally? Look no further! Our community is the perfect place for you to engage, share, and thrive.",
                    cursor: 'normal',
                    fontsize: '18px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    margin: '0px 20px 0px 0px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '190px',
                    width: 'auto'
                },
            ]
        },
        {
            image: "https://png.pngtree.com/background/20210710/original/pngtree-positive-energy-running-poster-background-picture-image_1001417.jpg",
            alt: "Join Us Today",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Why Join Us?",
                    cursor: 'normal',
                    fontsize: '30px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '5px',
                    right: '20px',
                    textalign: 'center',
                    top: '100px',
                    width: '200px'
                },
            ]
        },
        {
            image: "https://static.vecteezy.com/system/resources/thumbnails/028/249/574/original/wi-fi-network-connections-concept-background-with-flashing-blue-wi-fi-icons-connected-by-a-digital-plexus-network-this-internet-technology-background-is-full-hd-and-a-seamless-loop-video.jpg",
            alt: "Connections",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(61, 165, 249, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "**1. Genuine Connections",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '280px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(61, 165, 249, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'black',
                    content: "Forge meaningful relationships with people who share your interests and values. Whether you're passionate about technology, art, health, or any other field, you'll find like-minded individuals who are eager to connect and collaborate.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    margin: '0px 20px 0px 0px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '0px',
                    width: 'auto'
                },
            ]
        },
        {
            image: "https://png.pngtree.com/background/20210710/original/pngtree-positive-energy-running-poster-background-picture-image_1001417.jpg",
            alt: "Inspired",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(154, 69, 123, 1)',
                    borderradius: '5px',
                    bottom: '60px',
                    button: false,
                    color: 'white',
                    content: "**2. Inspire and Be Inspired",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '0px',
                    width: '280px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(154, 69, 123, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Our community is a hub for creativity and inspiration. Share your stories, experiences, and achievements, and get inspired by the journeys of others. Every member has a unique voice, and together, we create a tapestry of diverse perspectives.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '80px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://codecapsules.io/wp-content/uploads/2023/08/code-capsules-hack-days-and-free-tech-consulting-768x768.png",
            alt: "Access",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(130, 182, 165, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'black',
                    content: "**3. Access Exclusive Resources",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '0px',
                    right: '0px',
                    textalign: 'left',
                    top: '0px',
                    width: '280px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(130, 182, 165, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Gain access to a wealth of resources tailored to your needs. From webinars and workshops to articles and discussion forums, our community provides you with the tools and knowledge to succeed in your personal and professional endeavors.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '10px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://wallpaper.forfun.com/fetch/52/52516e3de6c2d36c9f5d9bae854a5636.jpeg",
            alt: "Growth",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(98, 189, 248, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "**4. Opportunities for Growth",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '0px',
                    right: '0px',
                    textalign: 'left',
                    top: '80px',
                    width: '280px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(221, 84, 130, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'black',
                    content: "Whether you're looking to advance your career, develop new skills, or simply expand your horizons, our community offers numerous opportunities for growth. Participate in events, join interest groups, and take advantage of mentorship programs designed to help you reach your full potential.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '10px',
                    right: '20px',
                    textalign: 'left',
                    top: 'px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://womensagenda.com.au/wp-content/uploads/2021/07/work-office-1024x683.jpg",
            alt: "Supportive",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "**5. Supportive Environment",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '280px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'white',
                    content: "We pride ourselves on fostering a supportive and inclusive environment. Our members are here to uplift and encourage each other, creating a positive space where everyone can feel valued and heard. Whatever challenges you face, you can count on our community for support and guidance.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '0px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://insights.ise.org.uk/wp-content/uploads/2022/10/Economic-trends-that-will-affect-recruitment-and-employment.png",
            alt: "Economy",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'white',
                    content: "**7. Uniquely-Defined Economy",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px 5px 0px 5px',
                    right: '20px',
                    textalign: 'left',
                    top: '0px',
                    width: '280px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(106, 120, 142, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Get rewarded for everything you do on our site:",
                    cursor: 'normal',
                    fontsize: '26px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '80px',
                    width: '400px'
                },
                {
                    id: 4,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 0, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Following a user = 200 Qoin",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '40px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '170px',
                    width: '260px'
                },
                {
                    id: 5,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 165, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Liking a post = 10 Qoin",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '40px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '220px',
                    width: '260px'
                },
                {
                    id: 6,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 128, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "...and so much more!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '40px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '270px',
                    width: '260px'
                },
            ]
        },
        {
            image: "https://academy.education.investing.com/wp-content/uploads/2022/03/Exchange-rate-definition.jpg",
            alt: "Exchange",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'white',
                    content: "**7. Uniquely-Defined Economy+",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px 5px 0px 5px',
                    right: '20px',
                    textalign: 'left',
                    top: '0px',
                    width: '280px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(106, 120, 142, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Other economic functionalities:",
                    cursor: 'normal',
                    fontsize: '26px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '80px',
                    width: '400px'
                },
                {
                    id: 6,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 255, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Exchange any amount of Qoin for benefits",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '40px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '140px',
                    width: '260px'
                },
                {
                    id: 7,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 128, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "...and so much more!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '40px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '215px',
                    width: '260px'
                },
            ]
        },  
        {
            image: "https://assets.funnygames.org/3/115043/100909/1024x1024/my-virtual-pet-shop.webp",
            alt: "Virtual Pets",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'white',
                    content: "**8. Virtual Pets",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px 5px 0px 5px',
                    right: '20px',
                    textalign: 'left',
                    top: '0px',
                    width: '280px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(106, 120, 142, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "One of the features our magicians wanted to add is something similiar to Neopets.",
                    cursor: 'normal',
                    fontsize: '26px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '80px',
                    width: '400px'
                },
            ]
        },       
        {
            image: "https://imagevars.gulfnews.com/2023/01/26/many-hands-make-light-work_185edcd7269_large.jpg",
            alt: "Make a Difference",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "**9. Make a Difference",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '280px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '80px',
                    button: false,
                    color: 'white',
                    content: "Our community is not just about connecting and growing; it's about making a positive impact. Engage in community projects, volunteer initiatives, and other activities that contribute to the greater good. Together, we can make a difference.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '0px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://assets.newatlas.com/dims4/default/38f82ee/2147483647/strip/true/crop/1902x1268+0+818/resize/1200x800!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2Fe4%2F0d%2Fa061db6c4fd7a344204e9a4905fd%2Ffirefly-aerospace-first-launch-1.jpg",
            alt: "Alpha Launch",
            shouldDisplay: settingLookup("systemMode", variables) === "alpha" ? true : false,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "ALPHA Access",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '140px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Our system is currently in Alpha Phase and we are awarding the following benefits to those who join us:",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '160px',
                    width: '400px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 0, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "200,000 Qoin upon successful registration",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '260px',
                    width: '400px'
                },
                {
                    id: 4,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 165, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Distinguishing Early-Adopter/Pioneer Badge",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '310px',
                    width: '400px'
                },
                {
                    id: 5,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 255, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "2 x FREE Virtual Pet with unlimited lives",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '360px',
                    width: '400px'
                },
                {
                    id: 6,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 128, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "...and so much more!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '410px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://appsierra-site.s3.ap-south-1.amazonaws.com/menskool_Blog_8bc00bbb17.jpg",
            alt: "Beta Launch",
            shouldDisplay: settingLookup("systemMode", variables) === "beta" ? true : false,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "BETA Access",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: '55px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '0px',
                    right: '20px',
                    textalign: 'left',
                    top: '80px',
                    width: '140px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Our system is currently in Beta Phase and we are awarding the following benefits to those who join us:",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '160px',
                    width: '400px'
                },
                {
                    id: 3,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 0, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "100,000 Qoin upon successful registration",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '260px',
                    width: '400px'
                },
                {
                    id: 4,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 165, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "Distinguished Beta Member badge",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '310px',
                    width: '400px'
                },
                {
                    id: 5,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(255, 255, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'black',
                    content: "FREE Virtual Pet with unlimited lives",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '360px',
                    width: '400px'
                },
                {
                    id: 6,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 128, 0, 0.8)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "...and so much more!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '20px',
                    padding: '10px',
                    right: '0px',
                    textalign: 'left',
                    top: '410px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://m.economictimes.com/thumb/msid-89615893,width-1200,height-900,resizemode-4,imgsize-34508/header_-7.jpg",
            alt: "Join",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "How to join",
                    cursor: 'normal',
                    fontsize: '22px',
                    height: '60px',
                    justifycontent: 'center',
                    left: '35%',
                    padding: '5px',
                    right: '30%',
                    textalign: 'center',
                    top: '100px',
                    width: '150px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Joining our community is easy and free! Simply sign up, using the form provided, complete your profile, and start exploring all that we have to offer. Your journey to a more connected, inspired, and fulfilled life starts here.",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '13%',
                    padding: '5px',
                    right: '30%',
                    textalign: 'center',
                    top: '200px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://png.pngtree.com/background/20210710/original/pngtree-positive-energy-running-poster-background-picture-image_1001417.jpg",
            alt: "Image Alt Text 2",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Don’t miss out on the opportunity to be a part of something special. Join "+settingLookup("SiteTitle", variables)+" today and take the first step towards a brighter future!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '14%',
                    padding: '5px',
                    right: '30%',
                    textalign: 'center',
                    top: '160px',
                    width: '400px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Sign up now and become a valued member of our community!",
                    cursor: 'normal',
                    fontsize: '20px',
                    height: 'auto',
                    justifycontent: 'center',
                    left: '14%',
                    padding: '5px',
                    right: '30%',
                    textalign: 'center',
                    top: '280px',
                    width: '400px'
                },
            ]
        },
        {
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUrOKqHnOD_zcBUzFPC8woVyMEWYF27vLwvfv5PYz2sxeFF6gMQO-1GuJB5pvLoGn7Cvc&usqp=CAU",
            alt: "Safe and Secure",
            shouldDisplay: true,
            elements: [
                {
                    id: 1,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "Your Information is Safe With Us",
                    cursor: 'normal',
                    fontsize: '22px',
                    height: '40px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '10px',
                    right: '20px',
                    textalign: 'center',
                    top: '370px',
                    width: '320px'
                },
                {
                    id: 2,
                    alignitems: 'center',
                    backgroundcolor: 'rgba(0, 0, 0, 1)',
                    borderradius: '5px',
                    bottom: '0px',
                    button: false,
                    color: 'white',
                    content: "We take your privacy seriously. Your data is encrypted and securely stored. We will never share your personal information with third parties without your consent. For more details, read our Privacy Policy.",
                    cursor: 'normal',
                    fontsize: '16px',
                    height: '82px',
                    justifycontent: 'center',
                    left: '0px',
                    padding: '10px',
                    right: '20px',
                    textalign: 'left',
                    top: '440px',
                    width: '320px'
                },
            ]
        },
        {
            image: "https://static.standard.co.uk/2023/10/25/20/7a252aa2cb2e0d88d2f00e466aabef07Y29udGVudHNlYXJjaGFwaSwxNjk4MzQ3OTI2-2.73855058.jpg?trim=102,0,102,0&quality=75&auto=webp&width=1024",
            alt: "The Doctor Donna",
            shouldDisplay: true,
            elements: [
                
            ]
        },
        {
            image: "https://miro.medium.com/v2/resize:fit:1400/1*Hm-G7dLwMZtLOPWbL6nkww.jpeg",
            alt: "MERN Stack",
            shouldDisplay: true,
        }
        // Add more slides as needed
    ]

    // Set showPassword
    const [showPassword, setShowPassword] = useState(false)

    // Set Greeting
    const [ Greeting, setGreeting ] = useState([null])

    // eslint-disable-next-line
    // eslint-disable-next-line
    const [ phone, setPhone ] = useState('')

    // Set skipStory for controlling to go to form and skip story
    // eslint-disable-next-line
    const [ skipStory, setSkipStory ] = useState(false)

    // Set up stepper
    const [ activeStep, setActiveStep ] = useState(0)
    const [ skippedStep, setSkippedStep ] = useState(new Set())
    const [ creationStatus, setCreationStatus ] = useState('Creating User...')

    // Set up to open story
    // eslint-disable-next-line
    const [ storyOpened, setStoryOpened ] = useState(true)

    // Set active slide
    const [ activeSlideIndex, setActiveSlideIndex ] = useState(0)
    
    // ----- States for frames ----------------------------------------------------------------------------
    // Frame 1
    const [ finishedFrame1, setFinishedFrame1 ] = useState(false)

    // Frame 2
    const [ finishedFrame2, setFinishedFrame2 ] = useState(false)

    // Frame 3
    const [ finishedFrame3, setFinishedFrame3 ] = useState(false)

    // Frame 4
    const [ finishedFrame4, setFinishedFrame4 ] = useState(false)

    // Frame 5
    const [ finishedFrame5, setFinishedFrame5 ] = useState(false)
    const [ hasSetName, setHasSetName ] = useState(false)
    const [ learnedAIName, setLearnedAIName ] = useState(false)

    // Frame 6
    const [ finishedFrame6, setFinishedFrame6 ] = useState(false)

    // Frame 7
    const [ finishedFrame7, setFinishedFrame7 ] = useState(false)

    // Frame 8
    const [ finishedFrame8, setFinishedFrame8 ] = useState(false)
    const [ askedQuestionUsername, setAskedQuestionUsername ] = useState(false)
    const [ hasSetUserName, setHasSetUserName ] = useState(false)

    // Frame 9
    const [ finishedFrame9, setFinishedFrame9 ] = useState(false)
    // ----------------------------------------------------------------------------------------------------

    // eslint-disable-next-line
    const [ scrollPositionState, setScrollPositionState ] = useState(0)

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    //const [ regDone, setRegDone ] = useState(false)
    const isStepOptional = (step) => {
        // Define optional steps here
        //return step === 1
    }

    const isStepSkipped = (step) => {
        return skippedStep.has(step)
    }

    const handleNextStep = () => {
        let newSkipped = skippedStep
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStep)
        }
        
        if (activeStep === steps.length - 1) {
            if (!regDone) {
                setCreationStatus('Creating User...')
                setRegDone(true)
                setRegisterFormIsDisabled(true)
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)

        setSkippedStep(newSkipped)
    }

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard again something like this
            // it should never occur unless someone's actively trying to break something
            throw new Error("You can't skip a step that isn't optional")
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)

        setSkippedStep((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())

            newSkipped.add(activeStep)

            return newSkipped
        })        
    }

    const handleReset = () => {
        setRegDone(false)
        setRegisterFormIsDisabled(false)
        setActiveStep(0)
    }

    // Handle Go to Dashboard
    const handleGotoDashboard = () => {
        navigate("/dashboard")
    }

    // Handle Go to login
    const handleGotoLogin = () => {
        navigate("/login")
    }

    // Handle Go to My Account
    const handleGotoMyAccount = () => {
        navigate("/dashboard/account")
    }

    // Handle Go to My Profile
    const handleGotoMyProfile = () => {
        navigate("/dashboard/profile")
    }

    const handleLogout = () => {
        // Do context login
        logoutUser()
    }

    const handleSlideNext = () => {
        const displayedSlides = slides.filter(slide => {
            return slide.shouldDisplay !== false
        })
        setActiveSlideIndex((prevSlideIndex) => (prevSlideIndex === displayedSlides.length - 1 ? 0 : prevSlideIndex + 1))
    }

    const handleSlidePrev = () => {
        const displayedSlides = slides.filter(slide => {
            return slide.shouldDisplay !== false
        })

        setActiveSlideIndex((prevSlideIndex) => (prevSlideIndex === 0 ? displayedSlides.length - 1 : prevSlideIndex - 1))
    }

    const handleRegister = () => {
        //event.preventDefault()

        // handle form submission
        // Get form
        const { 
            alphaCode, betaCode, firstName, lastName, userName, email, password, phone, confirmPassword 
        } = user
        
        // Validation
        //console.log(regDone)
        if (regDone) {
            if(!firstName || !lastName || !userName || !email || !password || !phone || !confirmPassword) {
                // Failed; nothing in field
                setShake(true)
                toast('Please fill all the fields', {type: 'error'})
                setRegDone(false)
                setRegisterFormIsDisabled(false)
    
                // Reset shake after 0.5s
                setTimeout(() => {
                    setShake(false)                    
                }, 500)
    
                return
            } else {
                // Initial Success; send to api
                if (settingLookup('systemMode', variables) === "alpha") {
                    if(!alphaCode) {
                        setShake(true)
                        toast('During this time, we require an Alpha Code', {type: 'error'})
                        setRegDone(false)
                        setRegisterFormIsDisabled(false)
                        // Reset shake after 0.5s
                        setTimeout(() => {
                            setShake(false)                            
                        }, 500)
                        return
                    }
                }
                
                if (settingLookup('systemMode', variables) === "beta") {
                    if (settingLookup('requireBetaCodeOnRegister', variables) === "yes") {
                        if(!betaCode) {
                            setShake(true)
                            toast('During this time, we require an Beta Code', {type: 'error'})
                            setRegDone(false)
                            setRegisterFormIsDisabled(false)
                            // Reset shake after 0.5s
                            setTimeout(() => {
                                setShake(false)                                
                            }, 500)
                            return
                        }
                    }
                    
                }
        
                if (settingLookup('systemMode', variables) === "closed") {
                    setShake(true)
                    toast('Registration is closed.', {type: 'error'})
                    setRegDone(false)
                    setRegisterFormIsDisabled(false)
                    // Reset shake after 0.5s
                    setTimeout(() => {
                        setShake(false)
                    }, 500)
                    return
                    
                }
        
                if(password !== confirmPassword) {
                    setShake(true)
                    toast('Passwords do not match', {type: 'error'})
                    setRegDone(false)
                    setRegisterFormIsDisabled(false)
                    // Reset shake after 0.5s
                    setTimeout(() => {
                        setShake(false)
                    }, 500)
                    return
                }

                setRegDone(true)
        
                // Set isDisabled (disable form)
                setRegisterFormIsDisabled(true)
        
                // Set Registering 
                setIsRegistering(true)
        
                // Register the user
                registerUser(user)  
            }
        }
    }

    // Handle for scrolling
    const handleScroll = () => {
        const scrollPosition = window.scrollY

        //console.log(scrollPosition)

        setScrollPositionState(scrollPosition)
    }

    // Handle for skipping story
    // eslint-disable-next-line
    const handleSkipStory = () => {
        //toast.info(`WIP: Skip the story`, {theme: 'light'})
        setSkipStory(true)
    }

    // ----- Handles for frames ---------------------------------------------------------------------------
    // --- Frame 1 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 2 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 3 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 4 ----------------------------------------------------------------------------------------
    // Handle SetName
    const handleSetName = () => {
        if (user.firstName !== "" && user.lastName !== "") {
            setHasSetName(true)
        } else {
            toast.error('Please tell me your name', { theme: 'light' })
        }
    }
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 5 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 6 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 7 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 8 ----------------------------------------------------------------------------------------
    const handleSetUserName = () => {
        if (user.userName !== "") {
            setHasSetUserName(true)
        } else {
            toast.error('Please tell me another name.', { theme: 'light' })
        }
    }
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    const handleTelChange = (newPhone) => {
        setPhone(newPhone)

        // Set the user state
        setUser({...user, phone: newPhone})
    }

    // State management for handling scrolling
    useEffect(() => {
        handleScroll()

        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    // State management for debugging
    useEffect(() => {
        //if (finshedFrame1) console.log(finshedFrame1)
    }, [ 
        //finshedFrame1
     ])
    
    
    // #endregion -----------------------------------------------------------------------------------------------

    useEffect(() => {
        setGreeting(GreetingIO(Greetings))
    }, [])

    const creationStatusPhrases = [
        'Buying more Qoin...',
        'Creating User...',
        'Opening up a Wormhole...',
        'Sweeping Up Pieces...'                
    ]

    // State management for creationStatus
    useEffect(() => {
        let waitTime = 0
        const intervalCall = setInterval(() => {
            if (regDone) {
                if (waitTime <= 2) {
                    let count = creationStatusPhrases.length            
                    let min = 1
                    let max = count
                    let random = Math.floor(Math.random() * (max - min + 1)) + min
                    //console.log(creationStatusPhrases[random - 1])
                    setCreationStatus(creationStatusPhrases[random - 1])
    
                    waitTime++
                } else {
                    // Waited some time, send to back end
                    handleRegister()
                }
            }
            console.log("DEBUG (waitTime): "+waitTime)
        }, 2000)

        // Clear the interval
        return () => {
            clearInterval(intervalCall)
        }       
    // eslint-disable-next-line         
    }, [ regDone ])

    // State Rendering for debugging
    useEffect(() => {
        if (isAuthenticated) {
            //console.log("isAuthenticated: "+isAuthenticated)
        }
    }, [ isAuthenticated ])

    // Build frames for story
    // eslint-disable-next-line
    const frames = [
        /* TEMPLATE
        
        */
        {/* FRAME 1 - Chapter 1: Beginning */
            id: 1,
            description: "FRAME 1 - Beginning",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to be set
            ],
            scrollReq: 0,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 0,
                        speed: 1,
                        reset: false,
                    },
                    text: "..."
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to begin)"
                },
                {
                    id: 3,
                    type: "setter",
                    delay: 4,
                    required: [
                        
                    ],
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame1",
                            variable: finishedFrame1,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame1(value)                                
                                    console.log("changed finishedFrame1 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 2 - Hello Traveler! */
            id: 2,
            description: "FRAME 2 - Hello Traveler!",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                }
            ],
            scrollReq: 900,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "Hello Traveler!"
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 6,
                        speed: 2,
                        reset: false,
                    },
                    text: "You've been asleep a long time"
                },
                {
                    id: 3,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 9,
                        speed: 1.5,
                        reset: false,
                    },
                    text: "You may be wondering - what is going on?"
                },
                {
                    id: 4,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 10,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 5,
                    delay: 11,
                    required: [
                        
                    ],
                    type: "setter",
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame2",
                            variable: finishedFrame2,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame2(value)                                
                                    console.log("changed finishedFrame2 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 3 - Introduction */
            id: 3,
            description: "FRAME 3 - Introduction",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                },
                {
                    id: 2,
                    name: "finishedframe2",
                    variableValue: finishedFrame2
                }
            ],
            scrollReq: 1800,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "You just appeared out of thin air."
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 6,
                        speed: 1,
                        reset: false,
                    },
                    text: "Do you know where you are?"
                },
                {
                    id: 3,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 12,
                        speed: 1,
                        reset: false,
                    },
                    text: "Hello?"
                },
                {
                    id: 4,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 18,
                        speed: 2,
                        reset: false,
                    },
                    text: "Not much of a talker eh?"
                },
                {
                    id: 5,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 19,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 6,
                    type: "setter",
                    delay: 20,
                    required: [
                        
                    ],
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame3",
                            variable: finishedFrame3,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame3(value)                                
                                    console.log("changed finishedFrame3 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 4 - Introduction (con't) */
            id: 4,
            description: "FRAME 4 - Introduction (con't)",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                },
                {
                    id: 2,
                    name: "finishedframe2",
                    variableValue: finishedFrame2
                },
                {
                    id: 3,
                    name: "finishedframe3",
                    variableValue: finishedFrame3
                },
            ],
            scrollReq: 2700,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "No worries. Fortunately for you, I am able to read your thoughts."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: false,
                    },
                    text: "...as well as interpret data from you to understand you."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 13,
                        speed: 1,
                        reset: false,
                    },
                    text: "It's weird. But let me introduce myself. I am [Network's AI Name]"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 18,
                        speed: 1.4,
                        reset: false,
                    },
                    text: "Can you think about your name and tell me it?"
                },
                {
                    id: 5,
                    type: "setter",
                    required: [
                        
                    ],
                    delay: 19,
                    variableToSet: [
                        {
                            id: 1,
                            name: "learnedAIName",
                            variable: learnedAIName,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setLearnedAIName(value)                                
                                    console.log("changed learnedAIName to "+value)
                                }
                            },
                        }
                    ]
                },
                {
                    id: 6,
                    type: "input",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'learnedAIName',
                            variableValue: learnedAIName
                        }
                    ],
                    inputData: [
                        {
                            id: 1,
                            type: "textfield",
                            label: "First Name",
                            name: "firstName",
                            value: user.firstName,
                            onChange: (e) => setUser({...user, firstName: e.target.value}),
                            params: {
                                xs: 6
                            }
                        },
                        {
                            id: 2,
                            type: "textfield",
                            label: "Last Name",
                            name: "lastName",
                            value: user.lastName,
                            onChange: (e) => setUser({...user, lastName: e.target.value}),
                            params: {
                                xs: 6
                            }
                        },
                        {
                            id: 3,
                            type: "button",
                            label: "Continue",
                            onClick: handleSetName,
                            params: {
                                xs: 12
                            }
                        },
                    ]

                },
                {
                    id: 7,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 0,
                        speed: 1,
                        reset: false,
                    },
                    text: "interpreting results..."
                },
                {
                    id: 8,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "Got it! Nice to meet you "+user.firstName+" "+user.lastName+"!"
                },
                {
                    id: 9,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 4,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 10,
                    type: "setter",
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 5,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame4",
                            variable: finishedFrame4,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame4(value)                                
                                    console.log("changed finishedFrame4 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 5 - Mysterious Dialog */
            id: 5,
            description: "FRAME 5 - Mysterious Dialog",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame4",
                    variableValue: finishedFrame4
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 3600,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "(you exchange dialog with [Network's AI Name]"
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame4",
                            variableValue: finishedFrame4
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 4,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 3,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame4",
                            variableValue: finishedFrame4
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 5,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame5",
                            variable: finishedFrame5,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame5(value)                                
                                    console.log("changed finishedFrame5 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 6 - AI learns about you */
            id: 6,
            description: "FRAME 6 - AI learns about you",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame5",
                    variableValue: finishedFrame5
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 4500,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "So "+user.firstName+" "+user.lastName+". What your saying is - you are not really here but you are really here?"
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: true,
                    },
                    text: "That is surely strange."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 13,
                        speed: 1,
                        reset: true,
                    },
                    text: "*[Network's AI Name] is confused*"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 14,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 4,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    delay: 15,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame6",
                            variable: finishedFrame6,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame6(value)                                
                                    console.log("changed finishedFrame6 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 7 - AI suggest a visit to a specialist */
            id: 7,
            description: "FRAME 7 - AI suggest a visit to a specialist",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame6",
                    variableValue: finishedFrame6
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 5400,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", it sounds like what you need is a specialist to help you find yourself."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 10,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", I can take you to The Capital Centre to get some help."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 17,
                        speed: 1,
                        reset: true,
                    },
                    text: "(you nod in agreement)"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 9,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 24,
                        speed: 1,
                        reset: true,
                    },
                    text: "(You and [NETWORK'S AI NAME] continue down a road to The Capital Centre)"
                },
                {
                    id: 8,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 25,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 9,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 26,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame7",
                            variable: finishedFrame7,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame7(value)                                
                                    console.log("changed finishedFrame7 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 8 - AI has a problem */
            id: 8,
            description: "FRAME 8 - AI has a problem",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame7",
                    variableValue: finishedFrame7
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 6300,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", this world is vast and filled with lots to explore."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 9,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can interact with many sentient beings here."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 15,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can discover all kinds of information as well."
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 21,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can feed a dol..."
                },
                {
                    id: 5,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 27,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can feed a dolp..."
                },
                {
                    id: 6,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 33,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can..."
                },
                {
                    id: 7,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 40,
                        speed: 1,
                        reset: true,
                    },
                    text: "([NETWORK'S AI NAME] appears to be struggling)"
                },
                {
                    id: 8,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 41,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 9,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },                        
                    ],
                    delay: 41,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame8",
                            variable: finishedFrame8,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame8(value)                                
                                    console.log("changed finishedFrame8 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 9 - AI has a problem (con't) */
            id: 9,
            description: "FRAME 9 - AI has a problem (con't)",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame8",
                    variableValue: finishedFrame8
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 7200,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 3,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "That was weird. Something is a little bit wrong. It's getting cumbersome to say your name over and over."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: true,
                    },
                    text: "Do you by any chance have another name you want me to call you?"
                },
                {
                    id: 3,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },
                    ],
                    delay: 9,
                    variableToSet: [
                        {
                            id: 1,
                            name: "askedQuestionUsername",
                            variable: askedQuestionUsername,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setAskedQuestionUsername(value)                                
                                    console.log("changed askedQuestionUsername to "+value)
                                }
                            },
                        }
                    ]
                },
                {
                    id: 4,
                    type: "input",
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'askedQuestionUsername',
                            variableValue: askedQuestionUsername
                        }
                    ],
                    inputData: [
                        {
                            id: 1,
                            type: "textfield",
                            label: "Your Response",
                            name: "userName",
                            value: user.userName,
                            onChange: (e) => setUser({...user, userName: e.target.value}),
                            params: {
                                xs: 12
                            }
                        },                        
                        {
                            id: 3,
                            type: "button",
                            label: "Continue",
                            onClick: handleSetUserName,
                            params: {
                                xs: 12
                            }
                        },
                    ]

                },
                {
                    id: 5,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'hasSetUserName',
                            variableValue: hasSetUserName
                        },                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "Alright, I will refer to you as "+user.userName+" from now on."
                },
                {
                    id: 6,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 34,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 7,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },                        
                    ],
                    delay: 35,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame9",
                            variable: finishedFrame9,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame9(value)                                
                                    console.log("changed finishedFrame9 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        }
        
        
    ]

    const shouldSkipSlide = (slide) => {
        let result = false

        if (!slide.shouldDisplay) {
            return true
        }

        // Return the slab!
        return result
    }

    const filteredSlides = slides.filter(slide => !shouldSkipSlide(slide))

    // Render
    return (
        <>
        <props.skeleton.StyledContainer sx={{ paddingTop: { xs: '8vh', sm: '19vh', md: '19vh', lg: '19vh' } }} component="main" maxWidth="lg">
            <ShakeWrapper shake={shake}>
                <props.skeleton.StyledPaper elevation={3} rounded="true" rounedamt="15px" border="border1">
                    {
                        (!isAuthenticated)
                        ? (
                            <>
                            
                            <props.skeleton.CustomGrid container>
                                <props.skeleton.CustomGrid item xs={12} sm={6}>
                                    <props.skeleton.ImageContainer heightfull="true">
                                    {
                                        // Single Image
                                        //<props.skeleton.LRImage src="https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg" alt="yes" />
                                    }
                                    {
                                        // Slides
                                        slides.filter(slide => {
                                            return slide.shouldDisplay !== false
                                        })
                                        .map((slide, index) => {
                                            if (shouldSkipSlide(slide)) {
                                                return null
                                            }

                                            return (
                                                <props.skeleton.CarouselSlide key={index} activeindex={activeSlideIndex} index={index}>
                                                    <props.skeleton.LRImage alt={slide.alt} src={slide.image} />
                                                    {
                                                        (slide.elements) 
                                                        ? (
                                                            slide.elements.map((element, index) => {
                                                                return (
                                                                    <props.skeleton.OverlayBox key={element.id} onClick={() => handleGotoLogin()} params={{  
                                                                        alignitems: element.alignitems,
                                                                        backgroundColor: element.backgroundcolor, 
                                                                        borderradius: element.borderradius,
                                                                        bottom: element.bottom,
                                                                        button: element.button,
                                                                        color: element.color,
                                                                        cursor: element.cursor,
                                                                        fontsize: element.fontsize,
                                                                        height: element.height,
                                                                        left: element.left,
                                                                        margin: element.margin,
                                                                        justifycontent: element.justifycontent, 
                                                                        padding: element.padding,    
                                                                        right: element.right,                  
                                                                        textalign: element.textalign,                           
                                                                        top: element.top, 
                                                                        width: element.width
                                                                    }}>
                                                                        {element.content}
                                                                    </props.skeleton.OverlayBox>
                                                                )
                                                            })
                                                        )
                                                        : null
                                                    }
                                                </props.skeleton.CarouselSlide>
                                            )
                                        })
                                    }
                                    {
                                        // #region ----[ Back Btn ]--------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleGotoLogin()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 1)', 
                                            borderradius: '20px',
                                            button: true,
                                            color: 'black',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            left: '20px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            top: '20px', 
                                            width: '150px'
                                        }}>
                                            Back to Login
                                        </props.skeleton.OverlayBox>                                            
                                        // #endregion ----[ Back Btn ]---------------------------------------------------------------------------
                                    }
                                    {
                                        // #region ----[ Next Slide Btn ]------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleSlideNext()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 1)', 
                                            borderradius: '10px 0px 0px 0px',
                                            button: true,
                                            color: 'black',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            right: '5px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            bottom: '5px', 
                                            width: '50px'
                                        }}>
                                            Next
                                        </props.skeleton.OverlayBox>                                            
                                        // #endregion ----[ Next Slide Btn ]---------------------------------------------------------------------
                                    }
                                    {
                                        // #region ----[ Prev Slide Btn ]------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleSlidePrev()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 1)', 
                                            borderradius: '0px 0px 0px 10px',
                                            button: true,
                                            color: 'black',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            right: '5px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            top: '5px', 
                                            width: '50px'
                                        }}>
                                            Prev
                                        </props.skeleton.OverlayBox>                                            
                                        // #endregion ----[ Prev Slide Btn ]---------------------------------------------------------------------
                                    }
                                    </props.skeleton.ImageContainer>                                        
                                </props.skeleton.CustomGrid>

                                <props.skeleton.CustomGrid item xs={12} sm={6} center="true" sx={{ 
                                    paddingBottom: { xs: '5vh', sm: '5vh', md: '5vh', lg: '5vh' },
                                    paddingLeft: { xs: '5vh', sm: '2vh', md: '5vh', lg: '5vh' }, 
                                    paddingRight: { xs: '5vh', sm: '2vh', md: '5vh', lg: '5vh' },
                                    paddingTop: { xs: '5vh', sm: '5vh', md: '5vh', lg: '5vh' }
                                }}>
                                    <props.skeleton.CustomGrid container spacing={0}>
                                    {
                                        // #region ====[ Desktop - Stepper ]==============================================================
                                    }
                                        <props.skeleton.CustomGrid sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'inline' } }} item xs={12}>
                                        {
                                        // #region ===[ BEGIN STEPPER ]===================================================================
                                        }

                                            <props.skeleton.CustomBox nobg="true" sx={{
                                                display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '10vh',
                                                width: '100%',
                                                padding: '0 20px',
                                                boxSizing: 'border-box'
                                            }}>
                                                <props.skeleton.CustomStepper activeStep={activeStep} alternativeLabel>
                                                {
                                                    (settingLookup("systemMode", variables) !== "closed" && settingLookup("systemMode", variables) !== "maintenance")
                                                    ? (
                                                        steps.map((label, index) => {
                                                            const stepProps = {}
                                                            const labelProps = {}
                
                                                            if (isStepOptional(index)) {
                                                                labelProps.optional = (
                                                                    <props.skeleton.CustomTypography variant="caption">Optional</props.skeleton.CustomTypography>
                                                                )
                                                            }
                
                                                            if (isStepSkipped(index)) {
                                                                stepProps.completed = false
                                                            }
                
                                                            return (
                                                                <props.skeleton.CustomStep key={label} {...stepProps}>
                                                                    <props.skeleton.CustomStepLabel active={activeStep === index ? "true" : null} {...labelProps}>
                                                                        {label}
                                                                    </props.skeleton.CustomStepLabel>
                                                                </props.skeleton.CustomStep>
                                                            )
                                                        })
                                                    )
                                                    : null
                                                }
                                                </props.skeleton.CustomStepper>
                                            </props.skeleton.CustomBox>

                                            {
                                            (settingLookup("systemMode", variables) !== "closed")
                                            ? (
                                                (settingLookup("systemMode", variables) === "maintenance")
                                                ? (
                                                    <>
                                                    <props.skeleton.CustomBox nobg="true" sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '32vh',                                            
                                                        justifyContent: 'center',
                                                        textAlign: 'center'
                                                    }}>
                                                        <props.skeleton.CustomTypography sx={{ }} nobg="true" variant="h5">
                                                            Maintenance Mode
                                                        </props.skeleton.CustomTypography>
                                                        <props.skeleton.CustomTypography variant="h6" nobg="true">
                                                            Our system is undergoing maintenance and registration has been closed...check back later.
                                                        </props.skeleton.CustomTypography>
                                                    </props.skeleton.CustomBox>
                                                    </>
                                                )
                                                : (
                                                    activeStep === steps.length ? (                                            
                                                        <>
                                                        <props.skeleton.CustomBox rounded="true" roundedamt="15px" sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',                                                                                                                
                                                            height: '22.3vh',
                                                            justifyContent: 'center',
                                                            mt: 2,
                                                            mb: 1,
                                                            textAlign: 'center'
                                                        }}>
                                                            <props.skeleton.CustomTypography nobg="true">
                                                                {creationStatus}                                                        
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomBox>
            
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 0, mb: 1.8 }}>
                                                            <props.skeleton.CustomBox nobg="true" sx={{ flex: '1 1 auto'}} />
                                                            <props.skeleton.CustomButton onClick={handleReset}>Start Over</props.skeleton.CustomButton>
                                                        </props.skeleton.CustomBox>
                                                    </>
                                                ) : (
                                                    <>
                                                        <props.skeleton.CustomTypography center="true" variant="h5" nobg="true" sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</props.skeleton.CustomTypography>
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            {(() => {
                                                                if (activeStep === 0) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Tell me who you are
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{ 
                                                                                        mb: 1,
                                                                                        mt: 0
                                                                                    }}
                                                                                    autoFocus
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="firstName"                                                                            
                                                                                    label="Tell me your First Name"
                                                                                    name="firstName"
                                                                                    size="small"
                                                                                    value={user.firstName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, firstName: e.target.value})}
                                                                                />
            
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{ 
                                                                                        mb: { xs: 6, sm: 5.6, md: 4, lg: 4 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="lastName"
                                                                                    label="Tell me your Last Name" 
                                                                                    name="lastName"
                                                                                    size="small"
                                                                                    value={user.lastName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, lastName: e.target.value})}
                                                                                />                                                                                                                                               
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 1) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Create a username
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 0
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="username"
                                                                                    label="Username"
                                                                                    name="username"
                                                                                    size="small"
                                                                                    variant="outlined"   
                                                                                    onChange={(e) => setUser({...user, userName: e.target.value})}                    
                                                                                    value={user.userName}
                                                                                />
                                                                                <props.skeleton.StyledButton
                                                                                    sx={{
                                                                                        mb: { xs: 3.5, sm: 3.2, md: 1.5, lg: 1.6 }
                                                                                    }}
                                                                                    color="primary"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    type="submit"
                                                                                    variant="contained"
                                                                                >
                                                                                    Check Availability
                                                                                </props.skeleton.StyledButton>
            
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 2) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Define a password
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: { xs: 1, sm: 1, md: 1, lg: 1 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="password"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}>
                                                                                            {showPassword.password ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                    label="Password"
                                                                                    name="password"
                                                                                    size="small"
                                                                                    onChange={(e) => setUser({...user, password: e.target.value})}
                                                                                    type={showPassword.password ? 'text' : 'password'}
                                                                                    value={user.password}
                                                                                />
            
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: { xs: 6, sm: 5.6, md: 4, lg: 4 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="confirmPassword"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={() => setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})}>
                                                                                            {showPassword.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                    label="Confirm Password"
                                                                                    name="confirmPassword"
                                                                                    size="small"
                                                                                    onChange={(e) => setUser({...user, confirmPassword: e.target.value})}
                                                                                    placeholder="Confirm Password"
                                                                                    type={showPassword.confirmPassword ? 'text' : 'password'}
                                                                                    value={user.confirmPassword}
                                                                                />
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 3) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Choose a nickname
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 0
                                                                                    }}
                                                                                    autoFocus
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="displayName"
                                                                                    label="Nickname" 
                                                                                    name="displayName"
                                                                                    size="small"
                                                                                    value={user.displayName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, displayName: e.target.value})}
                                                                                />
                                                                                <props.skeleton.CustomTypography sx={{ 
                                                                                    mb: { xs: 9, sm: 8.6, md: 7, lg: 7 }
                                                                                }} nobg="true" center="true" h6="true">
                                                                                    NOTE: This will be your display name
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 4) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    How can I contact you?
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 1,
                                                                                        mt: 0
                                                                                    }}
                                                                                    autoComplete="email"
                                                                                    margin="normal"
                                                                                    fullWidth
                                                                                    id="email"
                                                                                    label="Email Address"
                                                                                    name="email"
                                                                                    onChange={(e) => setUser({...user, email: e.target.value})}                    
                                                                                    size="small"
                                                                                    value={user.email}
                                                                                    variant="outlined"                                                                               
                                                                                />

                                                                                <props.skeleton.CustomMuiTelInput
                                                                                    sx={{ 
                                                                                        mb: { xs: 5.7, sm: 5.4, md: 3.8, lg: 3.8},
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="phone"
                                                                                    label="Phone"  
                                                                                    name="phone"                                                 
                                                                                    size="small"                                             
                                                                                    onChange={handleTelChange}
                                                                                    value={user.phone}
                                                                                    variant="outlined"
                                                                                />
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 5) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" h4="true"
                                                                                    sx={{
                                                                                        mb: 1
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        ((settingLookup('systemMode', variables) === "alpha") || ((settingLookup('systemMode', variables) === "beta")))
                                                                                        ? (
                                                                                            (settingLookup('systemMode', variables) === "alpha") 
                                                                                            ? (
                                                                                                <>
                                                                                                At this time, I require an alpha code to save your information.
                                                                                                </>
                                                                                            )
                                                                                            : (
                                                                                                (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                                                                ? (
                                                                                                    <>
                                                                                                    At this time, I require an beta code to save your information.
                                                                                                    </>
                                                                                                )
                                                                                                : (
                                                                                                    <>
                                                                                                    <props.skeleton.CustomTypography center="true" nobg="true" variant="h5">
                                                                                                        
                                                                                                    </props.skeleton.CustomTypography>
                                                                                                    </>
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                        : (
                                                                                            <>
                                                                                                <props.skeleton.CustomTypography center="true" nobg="true" variant="h5">
                                                                                                    
                                                                                                </props.skeleton.CustomTypography>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                {
                                                                                    ((settingLookup('systemMode', variables) === "alpha") || ((settingLookup('systemMode', variables) === "beta"))) 
                                                                                    ? (
                                                                                        (settingLookup('systemMode', variables) === "alpha")
                                                                                        ? (
                                                                                            <>
                                                                                            <props.skeleton.CustomTextField
                                                                                                sx={{
                                                                                                    mb: { xs: 7, sm: 6.6, md: 7.8, lg: 8 }
                                                                                                }}
                                                                                                fullWidth
                                                                                                disabled={RegisterFormIsDisabled}
                                                                                                id="alphaCode"
                                                                                                margin="normal"                                                                                                                
                                                                                                label="Alpha Code"
                                                                                                name="alphaCode"
                                                                                                size="small"
                                                                                                onChange={(e) => setUser({...user, alphaCode: e.target.value})}
                                                                                                value={user.alphaCode || ''} 
                                                                                                variant="outlined"
                                                                                            />  
                                                                                            </>
                                                                                        )
                                                                                        : (
                                                                                            (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                                                            ? (
                                                                                                <>
                                                                                                <props.skeleton.CustomTextField
                                                                                                    sx={{
                                                                                                        mb: { xs: 7, sm: 6.6, md: 8, lg: 8 }
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    disabled={RegisterFormIsDisabled}
                                                                                                    id="betaCode"
                                                                                                    margin="normal"                                                                                                                
                                                                                                    label="Beta Code"
                                                                                                    name="betaCode"
                                                                                                    size="small"
                                                                                                    onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                                                    value={user.betaCode || ''} 
                                                                                                    variant="outlined"
                                                                                                />  
                                                                                                </>
                                                                                            )
                                                                                            : (
                                                                                                <>
                                                                                                <props.skeleton.CustomTypography center="true" nobg="true" variant="h5" sx={{
                                                                                                    mb: 1
                                                                                                }}>
                                                                                                    Although a beta code is not required, you can still add one to receive rewards.
                                                                                                </props.skeleton.CustomTypography>
            
                                                                                                <>
                                                                                                <props.skeleton.CustomTextField
                                                                                                    sx={{
                                                                                                        mb: { xs: 7, sm: 7.3, md: 2, lg: 2 }
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    disabled={RegisterFormIsDisabled}
                                                                                                    id="betaCode"
                                                                                                    margin="normal"                                                                                                                
                                                                                                    label="Beta Code"
                                                                                                    name="betaCode"
                                                                                                    size="small"
                                                                                                    onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                                                    value={user.betaCode || ''} 
                                                                                                    variant="outlined"
                                                                                                />  
                                                                                                </>
                                                                                                </>  
                                                                                            )                                                                                    
                                                                                        )
                                                                                    )
                                                                                    : (
                                                                                        <>
                                                                                        <props.skeleton.CustomTypography sx={{
                                                                                            mb: { xs: 7, sm: 7.3, md: 8, lg: 8 }
                                                                                        }} center="true" nobg="true" variant="h5">
                                                                                            Good news! Access has been granted to you. You may proceed.
                                                                                        </props.skeleton.CustomTypography>
                                                                                        </>  
                                                                                    )
            
                                                                                }
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 6) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h5"
                                                                                    sx={{
                                                                                        mb: 2
                                                                                    }}
                                                                                >
                                                                                    That's all! For now. Click Finish to save your account.
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography sx={{
                                                                                    mb: { xs: 7.9, sm: 7.6, md: 6, lg: 6 }
                                                                                }} nobg="true" h5="true">hmm...</props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                return null // Return null if no condition matches
                                                            })()}
                                                        </props.skeleton.CustomBox>
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', pt: 2}}>                                               
                                                            <props.skeleton.CustomButton
                                                                color="inherit"
                                                                disabled={activeStep === 0}
                                                                onClick={handleBackStep}
                                                                sx={{ mr: 1}}
                                                            >
                                                                Back
                                                            </props.skeleton.CustomButton>
                                                            <props.skeleton.CustomBox nobg="true" sx={{ flex: '1 1 auto'}} />
                                                            {
                                                                isStepOptional(activeStep) && (
                                                                    <props.skeleton.CustomButton color="inherit" onClick={handleSkip} sx={{ mr: 1}}>
                                                                        Skip
                                                                    </props.skeleton.CustomButton>
                                                                )
                                                            }
            
                                                            <props.skeleton.CustomButton onClick={handleNextStep}>
                                                                {
                                                                    activeStep === steps.length - 1 ? 'Finish' : 'Next'
                                                                }
                                                            </props.skeleton.CustomButton>
                                                        </props.skeleton.CustomBox>
                                                        <props.skeleton.CustomBox nobg="true">
                                                            
                                                        </props.skeleton.CustomBox>
                                                    </>
                                                )
                                                )                                                
                                            )
                                            : (
                                                 <>
                                                <props.skeleton.CustomBox nobg="true" sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: '32vh',                                            
                                                    justifyContent: 'center',
                                                    textAlign: 'center'
                                                }}>
                                                    <props.skeleton.CustomTypography sx={{ }} nobg="true" variant="h5">
                                                        Registration Closed
                                                    </props.skeleton.CustomTypography>
                                                    <props.skeleton.CustomTypography variant="h6" nobg="true">
                                                        At this time, we are not accepting any new registrations. Stay tuned to find out when we open back up. 
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomBox>
                                                </>
                                                )                                                
                                            }                            

                                            {    
                                        // #endregion ===[ END STEPPER ]==================================================================
                                        }                                
                                        </props.skeleton.CustomGrid>
                                    {
                                        // #endregion ====[ Desktop - Stepper ]===========================================================
                                    }
                                    
                                    {
                                        // #region ====[ Mobile - Stepper ]===============================================================
                                    }
                                        {
                                            (settingLookup("systemMode", variables) !== "closed")
                                            ? (
                                                <props.skeleton.CustomGrid item xs={3} sx={{ 
                                                    display: { xs: 'inline', sm: 'inline', md: 'inline', lg: 'none' },
                                                    height: { xs: '47vh', sm: '55vh', md: '50vh' },
                                                }}>
                                                    <props.skeleton.CustomBox nobg="true" sx={{
                                                        display: { xs: 'inline', sm: 'inline', md: 'inline', lg: 'none' },
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '10vh',
                                                        width: '100%',
                                                        padding: '0 20px',
                                                        position: 'relative',
                                                        bottom: { xs: '60px', sm: '25px', md: '45px' },
                                                        right: { xs: '25px', sm: '10px', md: '25px' },
                                                        boxSizing: 'border-box'
                                                    }}>
                                                        <props.skeleton.CustomStepper activeStep={activeStep} orientation="vertical">
                                                        {
                                                            (settingLookup("systemMode", variables) !== "closed")
                                                            ? (
                                                                steps.map((label, index) => {
                                                                    const stepProps = {}
                                                                    const labelProps = {}
                        
                                                                    if (isStepOptional(index)) {
                                                                        labelProps.optional = (
                                                                            <props.skeleton.CustomTypography variant="caption">Optional</props.skeleton.CustomTypography>
                                                                        )
                                                                    }
                        
                                                                    if (isStepSkipped(index)) {
                                                                        stepProps.completed = false
                                                                    }
                        
                                                                    return (
                                                                        <props.skeleton.CustomStep key={label} {...stepProps}>
                                                                            <props.skeleton.CustomStepLabel active={activeStep === index ? "true" : null} {...labelProps}>
                                                                                {label}
                                                                            </props.skeleton.CustomStepLabel>
                                                                        </props.skeleton.CustomStep>
                                                                    )
                                                                })
                                                            )
                                                            : null
                                                        }
                                                        </props.skeleton.CustomStepper>
                                                    </props.skeleton.CustomBox>
                                                </props.skeleton.CustomGrid>
                                            )
                                            : null
                                        }
                                        
                                        <props.skeleton.CustomGrid item xs={(settingLookup("systemMode", variables) !== "closed") ? 9 : 12} sx={{ display: { xs: 'inline', sm: 'inline', md: 'inline', lg: 'none' } }}>
                                        {
                                            (settingLookup("systemMode", variables) !== "closed")
                                            ? (
                                                activeStep === steps.length ? (                                            
                                                    <>
                                                        <props.skeleton.CustomBox rounded="true" roundedamt="15px" sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',                                                                                                                
                                                            height: '27.2vh',
                                                            justifyContent: 'center',
                                                            mt: 2,
                                                            mb: { xs: 12.5, sm: 20, md: 12.5 },
                                                            textAlign: 'center'
                                                        }}>
                                                            <props.skeleton.CustomTypography nobg="true">
                                                                {creationStatus}                                                        
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomBox>
            
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 0 }}>
                                                            <props.skeleton.CustomBox nobg="true" sx={{ flex: '1 1 auto'}} />
                                                            <props.skeleton.CustomButton onClick={handleReset}>Start Over</props.skeleton.CustomButton>
                                                        </props.skeleton.CustomBox>
                                                    </>
                                                ) : (
                                                    <>
                                                        <props.skeleton.CustomTypography center="true" variant="h5" nobg="true" sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</props.skeleton.CustomTypography>
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                                                            {(() => {
                                                                if (activeStep === 0) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Tell me who you are
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{ 
                                                                                        mb: 1,
                                                                                        mt: 0
                                                                                    }}
                                                                                    autoFocus
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="firstName"                                                                            
                                                                                    label="Tell me your First Name"
                                                                                    name="firstName"
                                                                                    size="small"
                                                                                    value={user.firstName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, firstName: e.target.value})}
                                                                                />
            
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{ 
                                                                                        mb: { xs: 15, sm: 23.1, md: 23.1, lg: 4 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="lastName"
                                                                                    label="Tell me your Last Name" 
                                                                                    name="lastName"
                                                                                    size="small"
                                                                                    value={user.lastName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, lastName: e.target.value})}
                                                                                />                                                                                                                                               
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 1) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Create a username
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 0
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="username"
                                                                                    label="Username"
                                                                                    name="username"
                                                                                    size="small"
                                                                                    variant="outlined"   
                                                                                    onChange={(e) => setUser({...user, userName: e.target.value})}                    
                                                                                    value={user.userName}
                                                                                />
                                                                                <props.skeleton.StyledButton
                                                                                    sx={{
                                                                                        mb: { xs: 12.5, sm: 20.6, md: 20.6, lg: 1.5 }
                                                                                    }}
                                                                                    color="primary"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    type="submit"
                                                                                    variant="contained"
                                                                                >
                                                                                    Check Availability
                                                                                </props.skeleton.StyledButton>
            
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 2) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Define a password
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: { xs: 1, sm: 1, md: 1, lg: 1 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="password"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}>
                                                                                            {showPassword.password ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                    label="Password"
                                                                                    name="password"
                                                                                    size="small"
                                                                                    onChange={(e) => setUser({...user, password: e.target.value})}
                                                                                    type={showPassword.password ? 'text' : 'password'}
                                                                                    value={user.password}
                                                                                />
            
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: { xs: 14.9, sm: 23, md: 23, lg: 4 }
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="confirmPassword"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})}>
                                                                                            {showPassword.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                        </InputAdornment>,
                                                                                    }}
                                                                                    label="Confirm Password"
                                                                                    name="confirmPassword"
                                                                                    size="small"
                                                                                    onChange={(e) => setUser({...user, confirmPassword: e.target.value})}
                                                                                    placeholder="Confirm Password"
                                                                                    type={showPassword.confirmPassword ? 'text' : 'password'}
                                                                                    value={user.confirmPassword}
                                                                                />
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                else if (activeStep === 3) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    Choose a nickname
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 0
                                                                                    }}
                                                                                    autoFocus
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="displayName"
                                                                                    label="Nickname" 
                                                                                    name="displayName"
                                                                                    size="small"
                                                                                    value={user.displayName} 
                                                                                    variant="outlined"                                                
                                                                                    onChange={(e) => setUser({...user, displayName: e.target.value})}
                                                                                />
                                                                                <props.skeleton.CustomTypography sx={{ 
                                                                                    mb: { xs: 14.8, sm: 23, md: 25.9, lg: 7 }
                                                                                }} nobg="true" center="true" h6="true">
                                                                                    NOTE: This will be your display name
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 4) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h6">
                                                                                    How can I contact you?
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTextField
                                                                                    sx={{
                                                                                        mb: 1,
                                                                                        mt: 0
                                                                                    }}
                                                                                    autoComplete="email"
                                                                                    margin="normal"
                                                                                    fullWidth
                                                                                    id="email"
                                                                                    label="Email Address"
                                                                                    name="email"
                                                                                    onChange={(e) => setUser({...user, email: e.target.value})}                    
                                                                                    size="small"
                                                                                    value={user.email}
                                                                                    variant="outlined"                                                                               
                                                                                />

                                                                                <props.skeleton.CustomMuiTelInput
                                                                                    sx={{ 
                                                                                        mb: { xs: 14.7, sm: 22.8, md: 22.6, lg: 3.8},
                                                                                    }}
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    fullWidth
                                                                                    id="phone"
                                                                                    label="Phone"  
                                                                                    name="phone"                                                 
                                                                                    size="small"                                             
                                                                                    onChange={handleTelChange}
                                                                                    value={user.phone}
                                                                                    variant="outlined"
                                                                                />
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 5) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" h4="true"
                                                                                    sx={{
                                                                                        mb: 1
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        ((settingLookup('systemMode', variables) === "alpha") || ((settingLookup('systemMode', variables) === "beta")))
                                                                                        ? (
                                                                                            (settingLookup('systemMode', variables) === "alpha") 
                                                                                            ? (
                                                                                                <>
                                                                                                At this time, I require an alpha code to save your information.
                                                                                                </>
                                                                                            )
                                                                                            : (
                                                                                                (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                                                                ? (
                                                                                                    <>
                                                                                                    At this time, I require an beta code to save your information.
                                                                                                    </>
                                                                                                )
                                                                                                : (
                                                                                                    <>
                                                                                                    <props.skeleton.CustomTypography center="true" nobg="true" variant="h5">
                                                                                                        
                                                                                                    </props.skeleton.CustomTypography>
                                                                                                    </>
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                        : (
                                                                                            <>
                                                                                                <props.skeleton.CustomTypography center="true" nobg="true" variant="h5">
                                                                                                    
                                                                                                </props.skeleton.CustomTypography>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                {
                                                                                    ((settingLookup('systemMode', variables) === "alpha") || ((settingLookup('systemMode', variables) === "beta"))) 
                                                                                    ? (
                                                                                        (settingLookup('systemMode', variables) === "alpha")
                                                                                        ? (
                                                                                            <>
                                                                                            <props.skeleton.CustomTextField
                                                                                                sx={{
                                                                                                    mb: { xs: 15.9, sm: 23.8, md: 23.8, lg: 8 }
                                                                                                }}
                                                                                                fullWidth
                                                                                                disabled={RegisterFormIsDisabled}
                                                                                                id="alphaCode"
                                                                                                margin="normal"                                                                                                                
                                                                                                label="Alpha Code"
                                                                                                name="alphaCode"
                                                                                                size="small"
                                                                                                onChange={(e) => setUser({...user, alphaCode: e.target.value})}
                                                                                                value={user.alphaCode || ''} 
                                                                                                variant="outlined"
                                                                                            />  
                                                                                            </>
                                                                                        )
                                                                                        : (
                                                                                            (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                                                            ? (
                                                                                                <>
                                                                                                <props.skeleton.CustomTextField
                                                                                                    sx={{
                                                                                                        mb: { xs: 7, sm: 6.6, md: 8, lg: 8 }
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    disabled={RegisterFormIsDisabled}
                                                                                                    id="betaCode"
                                                                                                    margin="normal"                                                                                                                
                                                                                                    label="Beta Code"
                                                                                                    name="betaCode"
                                                                                                    size="small"
                                                                                                    onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                                                    value={user.betaCode || ''} 
                                                                                                    variant="outlined"
                                                                                                />  
                                                                                                </>
                                                                                            )
                                                                                            : (
                                                                                                <>
                                                                                                <props.skeleton.CustomTypography center="true" nobg="true" variant="h5" sx={{
                                                                                                    mb: 1
                                                                                                }}>
                                                                                                    Although a beta code is not required, you can still add one to receive rewards.
                                                                                                </props.skeleton.CustomTypography>
            
                                                                                                <>
                                                                                                <props.skeleton.CustomTextField
                                                                                                    sx={{
                                                                                                        mb: { xs: 7, sm: 7.3, md: 2, lg: 2 }
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    disabled={RegisterFormIsDisabled}
                                                                                                    id="betaCode"
                                                                                                    margin="normal"                                                                                                                
                                                                                                    label="Beta Code"
                                                                                                    name="betaCode"
                                                                                                    size="small"
                                                                                                    onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                                                    value={user.betaCode || ''} 
                                                                                                    variant="outlined"
                                                                                                />  
                                                                                                </>
                                                                                                </>  
                                                                                            )                                                                                    
                                                                                        )
                                                                                    )
                                                                                    : (
                                                                                        <>
                                                                                        <props.skeleton.CustomTypography sx={{
                                                                                            mb: { xs: 7, sm: 7.3, md: 8, lg: 8 }
                                                                                        }} center="true" nobg="true" variant="h5">
                                                                                            Good news! Access has been granted to you. You may proceed.
                                                                                        </props.skeleton.CustomTypography>
                                                                                        </>  
                                                                                    )
            
                                                                                }
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                } else if (activeStep === 6) {
                                                                    return (
                                                                        <>
                                                                        <props.skeleton.CustomGrid container spacing={0}>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography nobg="true" center="true" variant="h5"
                                                                                    sx={{
                                                                                        mb: 2
                                                                                    }}
                                                                                >
                                                                                    That's all! For now. Click Finish to save your account.
                                                                                </props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.CustomTypography sx={{
                                                                                    mb: { xs: 13, sm: 20.8, md: 24.8, lg: 6 }
                                                                                }} nobg="true" h5="true">hmm...</props.skeleton.CustomTypography>
                                                                            </props.skeleton.CustomGrid>
                                                                        </props.skeleton.CustomGrid>
                                                                        </>
                                                                    )
                                                                }
                                                                return null // Return null if no condition matches
                                                            })()}
                                                        </props.skeleton.CustomBox>
                                                        <props.skeleton.CustomBox nobg="true" sx={{ display: 'flex', flexDirection: 'row', pt: 2}}>                                               
                                                            <props.skeleton.CustomButton
                                                                color="inherit"
                                                                disabled={activeStep === 0}
                                                                onClick={handleBackStep}
                                                                sx={{ mr: 1, mb: 0 }}
                                                            >
                                                                Back
                                                            </props.skeleton.CustomButton>
                                                            <props.skeleton.CustomBox nobg="true" sx={{ flex: '1 1 auto'}} />
                                                            {
                                                                isStepOptional(activeStep) && (
                                                                    <props.skeleton.CustomButton color="inherit" onClick={handleSkip} sx={{ mr: 1}}>
                                                                        Skip
                                                                    </props.skeleton.CustomButton>
                                                                )
                                                            }
            
                                                            <props.skeleton.CustomButton onClick={handleNextStep} sx={{
                                                                mb: 0
                                                            }}>
                                                                {
                                                                    activeStep === steps.length - 1 ? 'Finish' : 'Next'
                                                                }
                                                            </props.skeleton.CustomButton>
                                                        </props.skeleton.CustomBox>
                                                        <props.skeleton.CustomBox nobg="true">
                                                            
                                                        </props.skeleton.CustomBox>
                                                    </>
                                                )
                                            )
                                            : (
                                                <>
                                                <props.skeleton.CustomBox nobg="true" sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: '32vh',                                            
                                                    justifyContent: 'center',
                                                    textAlign: 'center'
                                                }}>
                                                    <props.skeleton.CustomTypography sx={{ }} nobg="true" variant="h5">
                                                        Registration Closed
                                                    </props.skeleton.CustomTypography>
                                                    <props.skeleton.CustomTypography variant="h6" nobg="true">
                                                        At this time, we are not accepting any new registrations. Stay tuned to find out when we open back up. 
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomBox>
                                                </>
                                            ) 
                                            } 
                                        </props.skeleton.CustomGrid>
                                    {
                                        // #endregion ====[ Mobile - Stepper ]============================================================
                                    }
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>                                                                                   
                            </props.skeleton.CustomGrid>                                             
                            </>
                        )
                        : (
                            <>
                            <props.skeleton.CustomGrid container>
                                <props.skeleton.CustomGrid item xs={12} sm={6}>
                                    <props.skeleton.LRImage
                                        alt="Image Alt Text"
                                        src="https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg"
                                    />
                                </props.skeleton.CustomGrid>

                                <props.skeleton.CustomGrid item xs={12} sm={6} sx={{ 
                                    paddingBottom: { xs: '5vh', sm: '5vh', md: '10vh', lg: '11vh' },
                                    paddingLeft: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' }, 
                                    paddingRight: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' },
                                    paddingTop: { xs: '5vh', sm: '5vh', md: '5vh', lg: '10vh' }
                                }}>
                                    <props.skeleton.CustomTypography center="true" nobg="true" component="h1" variant="h4">
                                        {Greeting} {currentUser?.userName}!
                                    </props.skeleton.CustomTypography> 

                                    <props.skeleton.CustomTypography center="true" nobg="true" component="h1" variant="h6">
                                        No need to register again
                                    </props.skeleton.CustomTypography> 

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoDashboard()}
                                    >
                                        Dashboard
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoMyAccount()}
                                    >
                                        My Account
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleGotoMyProfile()}
                                    >
                                        My Profile
                                    </props.skeleton.StyledButton>

                                    <props.skeleton.StyledButton
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleLogout()}
                                    >
                                        Log Out
                                    </props.skeleton.StyledButton>                               
                                </props.skeleton.CustomGrid>                                                                              
                            </props.skeleton.CustomGrid>
                            </>
                        )
                    }
                </props.skeleton.StyledPaper>
            </ShakeWrapper>
        </props.skeleton.StyledContainer>
        </>
    )
}