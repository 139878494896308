export const avatar = {
    border: {
        params: {
            border: "thin solid #006b6b"
        },
        styles: {
            one: "2px solid violet",
            default: "thin solid cyan"
        },
    },
}