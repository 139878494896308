export const avatar = {
    border: {
        params: {
            border: "thin solid #e65fa2"
        },
        styles: {
            one: "thick solid white",
            default: "thin solid cyan"
        },
    },
}