export const textfield = {
    base: {
        background: "#303030",
        border: "thin solid #5e5e5e",
        color: "cyan",        
        labelBackground: "#ee82ee",
        labelBackgroundFocus: "cyan",
        labelColor: "black",
        labelColorFocus: "black",
        labelBorder: 'thin solid black',
        labelBorderFocus: 'thin solid black',
        labelFontsize: "14px",
        labelFontsizeFocus: "18px",
        labelPadding: '0px',
        labelPaddingFocus: '0px',
    }
}