// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from MUI
import {
    
} from '@mui/material'

// Import libraries
import { LookupLicon } from '../libraries/licons'

// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export const DrawerNav = ({ primary, navItems, currentUser, HandleDrawerButtonClick, HandleLogoutUser, NotARealFunction, LookupLicon, skeleton: S }) => { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #endregion --------------[ State Variables ]---------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################   
    // #end region ----------------[ Variables ]------------------------------------------------------------------
    // Setup variables
    const isMainOrDashboard = primary === "Main" || primary === "Dashboard"

    // Determine if Main or Dashboard
    if (!isMainOrDashboard) return null
    // #endregion ------------------------------------------------------------------------------------------------ 
    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    
    // #end region -------------[ State Variables ]---------------------------------------------------------------

    // #end region ----------------[ Variables ]------------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    const renderNavItem = (item, key) => {
        const renderBar = (onClickFunc, additionalProps = {}) => (
            <S.Bar key={key} drawernavbutton="true" onClick={onClickFunc} {...additionalProps}>
                {LookupLicon(item.icon)} {item.name}
            </S.Bar>
        )

        if (item.type === "single-level") {
            return item.partialPage.func
                ? item.partialPage.func === "HandleLogoutUser"
                    ? renderBar(() => HandleLogoutUser(item.path))
                    : renderBar(() => NotARealFunction())
                : renderBar(() => HandleDrawerButtonClick(item.partialPage, item.path))
        }

        if (item.type === "multi-level") {
            return (
                <div key={item.name + key}>
                    <S.Heading drawernavbuttonheading="true">
                        {LookupLicon(item.icon)} {item.name}
                    </S.Heading>
                    {item.children.map((child, index) => renderChildItem(child, index))}
                </div>
            )
        }
        return null
    }

    const renderChildItem = (child, index) => {
        const renderChildBar = (onClickFunc, additionalProps = {}) => (
            <S.Bar key={index} drawernestednavbutton="true" underbar="true" onClick={onClickFunc} {...additionalProps}>
                {LookupLicon(child.icon)} {child.name}
            </S.Bar>
        )

        return child.partialPage.func
            ? child.partialPage.func === "HandleLogoutUser"
                ? renderChildBar(() => HandleLogoutUser(child.path))
                : renderChildBar(() => NotARealFunction())
            : (currentUser?.role && (!child.roleReq || child.roleReq.includes(currentUser.role)))
            ? renderChildBar(() => HandleDrawerButtonClick(child.partialPage, child.path), { active: "true" })
            : renderChildBar(() => HandleDrawerButtonClick(child.partialPage, child.path))
    }

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
            {navItems.map((item, key) => (
                <div key={key}>{renderNavItem(item, key)}</div>
            ))}
        </>
    )
}