export const textfield = {
    base: {
        background: "#ccffff",
        border: "thin solid #191919",
        color: "black",
        label: "#004d4d",
        labelBackground: "#191919",
        labelBackgroundFocus: "#ee82ee",
        labelColor: "#ee82ee",
        labelColorFocus: "black",
        labelBorder: 'thin solid black',
        labelBorderFocus: 'thin solid black',
        labelFontsize: "14px",
        labelFontsizeFocus: "18px",
        labelPadding: '0px',
        labelPaddingFocus: '0px',
    }
}