export const bar = {
    background: "#FFF",
    border: {
        params: {
            border: "thin solid #e65fa2"
        },
        styles: {
            one: "thick solid white",
            default: "thin solid cyan"
        },
    },
    button: {
        base: {
            background: "#009999",
            backgroundHover: "pink",  
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black"
        },
        nobg: {
            background: "black",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "white",
            colorHover: "white",
            colorActive: "white"
        },
        error: {
            background: "red",
            backgroundHover: "pink",  
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black"
        }
    }
}