export const card = {
    base: {
        background: "#00b3b3",
        backgroundHover: "lime",
        backgroundActive: "lime",
        color: "white",
        colorGray: 'gray',
        colorHover: "black",
        colorActive: "black",
        params: {
            padding: '10px'
        }
    },
}