import * as base from './base/config.ts'
import * as bar from './bar/config.ts'
import * as customlink from './customlink/config.ts'
import * as drawer from './drawer/config.ts'
import * as footer from './footer/config.ts'
import * as gem from './gem/config.ts'
import * as header from './header/config.ts'
import * as item from './item/config.ts'
import * as menu from './menu/config.ts'
import * as navbar from './navbar/config.ts'
import * as llswitch from './llswitch/config.ts'
import * as notification from './notification/config.ts'
import * as pagination from './pagination/config.ts'
import * as textfield from './textfield/config.ts'
import * as typography from './typography/config.ts'
import * as paper from './paper/config.ts'

export const modules = {
    ...base,
    ...bar,
    ...customlink,
    ...drawer,
    ...footer,
    ...gem,
    ...header,
    ...item,
    ...llswitch,
    ...menu,
    ...navbar,
    ...notification,
    ...textfield,
    ...pagination,
    ...paper,
    ...typography,
}   