export const tabs = {
    base: {
        background: "#191919",
        color: "cyan",
        indicatorColor: "cyan"
    },
    tab: {
        base: {
            background: "#191919",
            backgroundSelected: "#009999",
            color: "cyan",
            colorSelected: "black"
        }
    }
}