// Import context
import { useAuth } from '../middleware/contextHooks'

export const BreadCrumbs = (props) => {  
    // Get from context
    const {
        currentUser,
        isAuthenticated
    } = useAuth()

    if (isAuthenticated) {
        return (
            <props.skeleton.CustomBreadcrumbs aria-label="breadcrumb"
                separator={
                    (currentUser.bgImage && (props.secondary === "profile")) 
                    ? (
                        <props.skeleton.CustomBreadcrumbsSeparator border="border1">&rsaquo;</props.skeleton.CustomBreadcrumbsSeparator>
                    )
                    : (
                        <props.skeleton.CustomBreadcrumbsSeparator>&rsaquo;</props.skeleton.CustomBreadcrumbsSeparator>
                    )
                }
            >
                {
                    props.list.map((item, index) => {
                        if (currentUser.bgImage) {
                            return (
                                <div key={index}>
                                    {
                                        (item.type === "link" && item.grayed) &&
                                        <props.skeleton.CustomLink border="border1" rounded="true" roundedamt="10px" breadcrumb grayed fontheading1 href={item.url}>
                                            {item.name}
                                        </props.skeleton.CustomLink>    
                                    }
        
                                    {
                                        (item.type === "link" && !item.grayed) &&
                                        <props.skeleton.CustomLink border="border1" rounded="true" roundedamt="10px" breadcrumb fontheading1 href={item.url}>
                                            {item.name}
                                        </props.skeleton.CustomLink>    
                                    }
        
                                    {
                                        (item.type === "text" && item.grayed) &&
                                        <props.skeleton.Heading border="border1" rounded="true" roundedamt="10px" breadcrumb fontheading1 grayed>
                                            {item.name}
                                        </props.skeleton.Heading> 
                                    }           
        
                                    {
                                        (item.type === "text" && !item.grayed) &&
                                        <props.skeleton.Heading border="border1" rounded="true" roundedamt="10px" fontheading1 breadcrumb>
                                            {item.name}
                                        </props.skeleton.Heading> 
                                    }           
                                </div>                        
                            )  
                        } else {
                            return (
                                <div key={index}>
                                    {
                                        (item.type === "link" && item.grayed) &&
                                        <props.skeleton.CustomLink rounded="true" roundedamt="10px" breadcrumb grayed fontheading1 href={item.url}>
                                            {item.name}
                                        </props.skeleton.CustomLink>    
                                    }
        
                                    {
                                        (item.type === "link" && !item.grayed) &&
                                        <props.skeleton.CustomLink rounded="true" roundedamt="10px" breadcrumb fontheading1 href={item.url}>
                                            {item.name}
                                        </props.skeleton.CustomLink>    
                                    }
        
                                    {
                                        (item.type === "text" && item.grayed) &&
                                        <props.skeleton.Heading rounded="true" roundedamt="10px" breadcrumb fontheading1 grayed>
                                            {item.name}
                                        </props.skeleton.Heading> 
                                    }           
        
                                    {
                                        (item.type === "text" && !item.grayed) &&
                                        <props.skeleton.Heading rounded="true" roundedamt="10px" fontheading1 breadcrumb>
                                            {item.name}
                                        </props.skeleton.Heading> 
                                    }           
                                </div>                        
                            )  
                        }               
                    })
                }            
            </props.skeleton.CustomBreadcrumbs>
        )
    } else {
        return (
            <props.skeleton.CustomBreadcrumbs aria-label="breadcrumb"
                separator={<props.skeleton.CustomBreadcrumbsSeparator>&rsaquo;</props.skeleton.CustomBreadcrumbsSeparator>}
            >
                {
                    props.list.map((item, index) => {
                        return (
                            <div key={index}>
                                {
                                    (item.type === "link" && item.grayed) &&
                                    <props.skeleton.CustomLink rounded="true" roundedamt="10px" breadcrumb grayed fontheading1 href={item.url}>
                                        {item.name}
                                    </props.skeleton.CustomLink>    
                                }
    
                                {
                                    (item.type === "link" && !item.grayed) &&
                                    <props.skeleton.CustomLink rounded="true" roundedamt="10px" breadcrumb fontheading1 href={item.url}>
                                        {item.name}
                                    </props.skeleton.CustomLink>    
                                }
    
                                {
                                    (item.type === "text" && item.grayed) &&
                                    <props.skeleton.Heading rounded="true" roundedamt="10px" breadcrumb fontheading1 grayed>
                                        {item.name}
                                    </props.skeleton.Heading> 
                                }           
    
                                {
                                    (item.type === "text" && !item.grayed) &&
                                    <props.skeleton.Heading rounded="true" roundedamt="10px" fontheading1 breadcrumb>
                                        {item.name}
                                    </props.skeleton.Heading> 
                                }           
                            </div>                        
                        )               
                    })
                }            
            </props.skeleton.CustomBreadcrumbs>
        )
    }
}