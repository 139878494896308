// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

export function LikeMod(type, what, user, slicedata, data, oppositedata, skeleton, functions) {
    // Setup variables
    let alreadyLiked        = false
    let alreadyUnliked      = false
    let determineStat       = false
    let result

    // Controllables
    let likePhrase          = "give pixel"               // ie. like this
    let unlikePhrase        = "void it"
    let likeNameSingular    = "pixel"                    // ie. like
    let likeNamePlural      = "pixels"                   // ie. likes
    let unlikeNameSingular    = "void"                 // ie. unlike
    let unlikeNamePlural      = "voids"                // ie. likes
    let likePhraseAction    = "given a pixel to"         // ie. liked
    let likePhraseActionNot = "give pixel to"            // ie. like this
    let showUnlikes         = true                       // Defaulted to show; if it gets out of hand, set this to false
    let unlikePhraseAction  = "voided"

    // Determined variables
    let likePhraseActionSent = likePhraseActionNot
    // eslint-disable-next-line

    if (user) {
        if (data) {
            // Sift through likes
            data.forEach(ele => {
                if (ele.data.whom === user.userName) {
                    alreadyLiked = true                    
                }
            })
        }

        if (oppositedata) {
            // Sift through unlikes
            oppositedata.forEach(ele => {
                if (ele.data.whom === user.userName) {
                    alreadyUnliked = true                    
                }
            })
        }

        // Determine if logged user has liked
        if (alreadyLiked) {
            determineStat = true
            likePhraseActionSent = likePhraseAction
        }

        // Determine if logged user has unliked
        if (alreadyUnliked) {
            determineStat = true
            likePhraseActionSent = unlikePhraseAction
        }
    }

    const handleLike = (type, slicedata, data, stat, info, functions, user) => {
        if (stat) {
            if (type === "like") {toast.error(`You have already ${info} this.`, {theme: "light"})}
            else if (type === "unlike") {toast.error(`You have already ${info} this.`, {theme: "light"})}
        } else {
            //toast.info(`${info} post`, {theme: "light"})

            //console.log(slicedata.slug)
            
            // Doesn't do anything
            //data = data

            if (user) {
                //console.log(functions)
                //toast.info(`WIP (Update4): -1 currency deducted from your account.`, {theme: "light"})
                functions.LikePost(type, slicedata.slug, user.userName)
            } else {
                toast.error(`You must be logged in to ${info} post.`, {theme: 'light'})
            }
        }
    }

    switch (what) {
        case 'button':
            result = (
                <skeleton.CustomGrid container>
                    <skeleton.CustomGrid item xs={6}>
                        <skeleton.Bar center="true" button="true" padding10="true" onClick={() => handleLike("like", slicedata, data, determineStat, likePhraseActionSent, functions, user) } sx={{ 
                            border: '' }}>
                            {
                                (alreadyLiked || alreadyUnliked)
                                ? (
                                    <>
                                    {
                                        (data)
                                        ? (
                                            <skeleton.Heading button center h6 nobg>{ data.length } { (data.length === 1) ? likeNameSingular : likeNamePlural }</skeleton.Heading>
                                        )
                                        : (
                                            <skeleton.Heading button center h6 nobg>0 { likeNamePlural }</skeleton.Heading>                                        
                                        )
                                    }
                                    </>
                                )
                                : (
                                    <>
                                    <skeleton.Heading button right h6 nobg>{ likePhrase }</skeleton.Heading>                                
                                    </>
                                )
                            }
                        </skeleton.Bar>
                    </skeleton.CustomGrid>
                    <skeleton.CustomGrid item xs={6}>
                        <skeleton.Bar center="true" button="true" padding10="true" onClick={() => handleLike("unlike", slicedata, data, determineStat, likePhraseActionSent, functions, user) } sx={{ 
                            border: '' 
                        }}>
                            {
                                (alreadyLiked || alreadyUnliked)
                                ? (
                                    <>
                                    {
                                        (oppositedata)
                                        ? (
                                            (
                                                (showUnlikes)
                                                ? <skeleton.Heading button center h6 nobg>{ oppositedata.length } { (oppositedata.length === 1) ? unlikeNameSingular : unlikeNamePlural }</skeleton.Heading>
                                                : <skeleton.Heading button center h6 nobg>? { unlikeNamePlural }</skeleton.Heading>
                                            )
                                        )
                                        : (
                                            <skeleton.Heading button center h6 nobg>0 { unlikeNamePlural }</skeleton.Heading>                                        
                                        )
                                    }
                                    </>
                                )
                                : (
                                    <>
                                    <skeleton.Heading button center right h6 nobg>{ unlikePhrase }</skeleton.Heading>                                
                                    </>
                                )
                            }
                        </skeleton.Bar>
                    </skeleton.CustomGrid>
                </skeleton.CustomGrid>
            )
        break

        case 'textPhrase':
            result = ( 
                (alreadyLiked || alreadyUnliked) 
                ? (
                    (
                        (data)
                        ? " " + data.length.toLocaleString(undefined, { minimumFractionDigits: 0 }) + " " + ((data.length === 1) ? likeNameSingular : likeNamePlural) 
                        : " | 0 likes"
                    )
                    +
                    (
                        (showUnlikes)
                        ?
                        (
                            (oppositedata) 
                            ? " | " + oppositedata.length.toLocaleString(undefined, { minimumFractionDigits: 0 }) + " " + ((oppositedata.length === 1) ? unlikeNameSingular : unlikeNamePlural)
                            : " | 0 Unlikes"
                        )
                        : " ? " + likeNamePlural
                    )
                )
                : " ? " + likeNamePlural  
            )
        break

        default:
            result = (
                <skeleton.Bar center="true" button="true" padding10="true" sx={{ border: 'thin solid red' }}>
                    <skeleton.CustomGrid container>
                        {
                            <>
                            <skeleton.CustomGrid item xs={12}>
                                <skeleton.Heading button center h2 nobg>Unknown Case</skeleton.Heading>
                            </skeleton.CustomGrid>
                            </>                            
                        }
                    </skeleton.CustomGrid>
                </skeleton.Bar>
            )
        break
    }

    return result
}