export const telinput = {
    base: {
        background: "#ccffff",
        border: "thin solid #191919",
        borderHover: "thin solid #191919",
        borderFocused: "thin solid #ee82ee",
        color: "black",
        label: "#004d4d",
        labelBackground: "#191919",
        labelBackgroundFocus: "#ee82ee",
        labelColor: "#ee82ee",
        labelColorFocus: "black",
        labelBorder: 'thin solid black',
        labelBorderFocus: 'thin solid black',
        labelPadding: '5px',
        labelPaddingFocus: '5px',
    }
}