// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import utils
import { DeTemps } from '../../middleware/utils'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import Mdoules
import { LikeMod } from '../../middleware/LikeMod'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Paper,
    //Button,
} from '@mui/material'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { Spacer } from '../../components/Addon/Spacer'

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadSliceImg (userName, query, image, settingServerAPIBaseUrl) {
    return settingServerAPIBaseUrl+query+"/file/byuser/"+userName+"/"+image
}

// eslint-disable-next-line
function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export function GemItem (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    const handleClick = (url) => {
        navigate(url)
    }

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    let useBorder = false
    let border = 'thin solid red'

    // Render
    return (
        <>
        <props.skeleton.CustomGrid item xs={12} sm={12} md={6} lg={4}>
            {/* --[ Item ]----------------------------------------------------------------------------------- */}
            <props.skeleton.CustomStack gemitem="true" justifyContent={'space-between'} sx={{ 
                border: (useBorder) ? border : 'none',
                
                paddingRight: '12px',
                paddingLeft: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                height: '700px'
            }}>
                <props.skeleton.CustomItem button onClick={() => handleClick('/post/'+props.data.slug)}>
                    {/* #region [ Featured Image ]----------------------------------------------------------- */}
                    <props.skeleton.CustomBox
                        component="img"
                        sx={{
                            height: {xs: '219px', sm: '219px', md: '219px', lg: '219px'},
                            width: '97%',
                            filter: (
                                (props.data?.spoiler) ? "blur(50px)" : ""
                                ||
                                (props.data?.nsfw) ? "blur(10px)" : ""
                            ),
                        }}
                        alt="Post image"
                        src={
                            (props.data.imageUrl && props.data.imageUrl !== "no-featuredimage.png" && props.data.imageUrl !== "") 
                            ? (
                                loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            )
                            : loadSliceImg(props.data.authorDetails[0].userName, props.data.type, "no-featuredimage.png", props.settingServerAPIBaseUrl)
                        }
                    />            
                    {/* #endregion -------------------------------------------------------------------------- */}
                </props.skeleton.CustomItem>
                <props.skeleton.CustomItem onClick={() => handleClick('/post/'+props.data.slug)}>
                    {/* #region [ Title ]-------------------------------------------------------------------- */}
                    {
                        <props.skeleton.Heading nobg="true" h3 padding10>
                            {(props.data.type === "longpost") && ((props.data.title.length > 21) ? props.data.title.substr(0, 21)+"..." : props.data.title) }
                            {(props.data.type === "shortpost") && ("ShortPost #"+props.data.uuid.substr(0, 7)+"...") }
                            {(props.data.type === "stillpost") && ((props.data.title.length > 27) ? props.data.title.substr(0, 27)+"..." : props.data.title) }
                            {(props.data.type === "videopost") && ((props.data.title.length > 24) ? props.data.title.substr(0, 24)+"..." : props.data.title) }
                        </props.skeleton.Heading>                                 
                    }
                    {/* #endregion -------------------------------------------------------------------------- */}                    
                </props.skeleton.CustomItem>
                
                <Spacer length={1} />

                <props.skeleton.CustomItem>
                    {/* #region [ Snippet ]------------------------------------------------------------------ */}
                    <props.skeleton.Heading nobg="true" h8 left padding10>
                        {(props.data.type === "longpost") && props.data.snippet?.substr(0, 80)+"..."}
                        {(props.data.type === "shortpost") && props.data.content?.substr(0, 80)+"..."}
                        {(props.data.type === "stillpost") && props.data.snippet?.substr(0, 80)+"..."}
                        {(props.data.type === "videopost") && props.data.snippet?.substr(0, 80)+"..."}
                    </props.skeleton.Heading>
                    {/* #endregion ----------------------------------------------------------------------------- */}                    
                </props.skeleton.CustomItem>

                <Spacer length={3} />

                <props.skeleton.CustomItem>
                    {/* #region ---[ InfoBox ]------------------------------------------------------------------ */}
                    <props.skeleton.CustomGrid container 
                        spacing={0} 
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{  }}>
                        <props.skeleton.CustomGrid item xs={12}>
                        {/* #region --[ Left ]------------------------------------------------------------------ */}
                        <props.skeleton.Heading nobg h4 left padding10>
                            <props.skeleton.CustomLink bgprimary nopadding href={'/category/'+props.data.category}>{props.data.category}</props.skeleton.CustomLink><br />{DeTemps('prettyShort', props.data.createdAt)}<br /> {DeTemps('timeFrom', props.data.createdAt)}
                        </props.skeleton.Heading>
                        {/* #endregion ------------------------------------------------------------------------- */}
                        </props.skeleton.CustomGrid>

                        <props.skeleton.CustomGrid justifyContent={'stretch'} item xs={4} sx={{  }}>
                        {/* #region --[ Right ]----------------------------------------------------------------- */}
                        
                        {/* #endregion ------------------------------------------------------------------------- */}
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                    {/* #endregion ----------------------------------------------------------------------------- */}                    
                </props.skeleton.CustomItem>

                {/* #region ---[ InfoBox ]------------------------------------------------------------------ */}
                <props.skeleton.CustomGrid container 
                    spacing={0} 
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ border: 'thin solid black' }}>
                    <props.skeleton.CustomGrid item xs={12}>
                    {/* #region --[ Left ]------------------------------------------------------------------ */}
                        {LikeMod("like", "button", props.user, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)}
                    {/* #endregion ------------------------------------------------------------------------- */}
                    </props.skeleton.CustomGrid>

                    <props.skeleton.CustomGrid justifyContent={'stretch'} item xs={4} sx={{  }}>
                    {/* #region --[ Right ]----------------------------------------------------------------- */}
                    
                    {/* #endregion ------------------------------------------------------------------------- */}
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomStack>
            {/* ------------------------------------------------------------------------------------------------ */}
        </props.skeleton.CustomGrid> 
        </>
    )
}