export const bar = {
    background: "#003333",
    button: {
        base: {
            background: "#003333",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "cyan",
            colorHover: "cyan",
            colorActive: "cyan"
        }
    }
}