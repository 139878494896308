// eslint-disable-next-line
import { useEffect } from 'react'

// Import parser
import parse from 'html-react-parser'

// Import utils
import { settingLookup } from '../middleware/utils'

export function NatchParse(data) {
    // Bold Selector - Find what's in between **, replace with <b></b>, remove remaining *
    data = data?.replace(/(?<=\*)(.*?)(?=\*)/ig, '<b>$1</b>').replaceAll('*', '')

    // Break Selector - Find double space, replace with <br />
    data = data?.replace(/\s\s/ig, '<br />')

    // Underline Selector - Find what's in between _, replace with <u></u>, remove remaining _
    // eslint-disable-next-line
    data = data?.replace(/(?<=\_)(.*?)(?=\_)/ig, '<span style=\'text-decoration: underline;\'>$1</span>').replaceAll('_', '')

    // list('personal space', '...personal space', 'don't get in my personal space, 'I don't remember the other two')
    // WIP

    // #region --------------------[ URL Selector ]----------------------------------------------------------------------------
    // Set up variables
    // eslint-disable-next-line
    const URLSelector = data // save data as a variable to avoid mutating (???)
    const searchKeyword = /\[(.*?)\]\((.+?)\)/g // search for this    

    data = data?.replace(searchKeyword, '<a href=\'$2\' target=\'_blank\'>$1</a>')
    // #endregion -------------------------------------------------------------------------------------------------------------

    if (data) {
        data = parse(data)
    }

    // Return the slab!
    return data
}

export function ParseGem (content, regex, variables) {
    let parsed = "" // Needs a "" or you will get undefined on each line

    switch (regex) {
        case 'singlet':
            // --- Parse Singlet ---------------------------------------------------------------------------------------
            let regexSinglet = /\[singlet properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store singlets as an array
            let snippetSinglets = content.match(regexSinglet)

            // DEBUG: Display singlets
            //console.log(snippetSinglets)

            if (snippetSinglets && snippetSinglets.length > 0) {
                // for each singlet, parse it
                Array.from(snippetSinglets).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let cfontsize       // 1 
                    let color           // 2
                    let con             // 3
                    let cpadding        // 4
                    let padding         // 5
                    let textalign       // 6
                    let tfontsize       // 7       
                    let title           // 8
                    let tpadding        // 9

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replaceAll("questionmark", "?")
                    background = background.replaceAll("period", ".")  
                    // increment index
                    index++      

                    cfontsize = properties[index]
                    let cfontsizeParts = cfontsize.split("=")
                    cfontsize = cfontsizeParts[1]
                    cfontsize = cfontsize.replace("\"", "")
                    cfontsize = cfontsize.replace("\"", "")
                    // Replace reserve words
                    cfontsize = cfontsize.replaceAll("questionmark", "?")
                    cfontsize = cfontsize.replaceAll("period", ".")
                    // increment index
                    index++
                    
                    color = properties[index]
                    let colorParts = color.split("=")
                    color = colorParts[1]
                    color = color.replace("\"", "")
                    color = color.replace("\"", "")
                    // Replace reserve words
                    color = color.replaceAll("questionmark", "?")
                    color = color.replaceAll("period", ".")   
                    // increment index
                    index++     
                    
                    con = properties[index]
                    let conParts = con.split("=")
                    con = conParts[1]
                    con = con.replace("\"", "")
                    con = con.replace("\"", "")
                    // Replace reserve words
                    con = con.replaceAll("colon", ": ")  
                    con = con.replaceAll("comma", ",")         
                    con = con.replaceAll("doublequote", "\"")                      
                    con = con.replaceAll("newline", "<br />")  
                    con = con.replaceAll("period", ".")  
                    con = con.replaceAll("questionmark", "?")  
                    // Replace Functional reserve words
                    con = con.replaceAll("DISPLAYTIME", new Date())   
                    con = con.replaceAll("NETWORKNAME", settingLookup('SiteTitle', variables))                                        
                    // increment index
                    index++      
                    
                    cpadding = properties[index]
                    let cpaddingParts = cpadding.split("=")
                    cpadding = cpaddingParts[1]
                    cpadding = cpadding.replace("\"", "")
                    cpadding = cpadding.replace("\"", "")
                    // Replace reserve words
                    cpadding = cpadding.replaceAll("questionmark", "?")
                    cpadding = cpadding.replaceAll("period", ".")   
                    // increment index
                    index++ 
                    
                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replaceAll("questionmark", "?")
                    padding = padding.replaceAll("period", ".")
                    // increment index
                    index++

                    textalign = properties[index]
                    let textalignParts = textalign.split("=")
                    textalign = textalignParts[1]
                    textalign = textalign.replace("\"", "")
                    textalign = textalign.replace("\"", "")
                    // Replace reserve words
                    textalign = textalign.replaceAll("questionmark", "?")
                    textalign = textalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    tfontsize = properties[index]
                    let tfontsizeParts = tfontsize.split("=")
                    tfontsize = tfontsizeParts[1]
                    tfontsize = tfontsize.replace("\"", "")
                    tfontsize = tfontsize.replace("\"", "")
                    // Replace reserve words
                    tfontsize = tfontsize.replace("questionmark", "?")
                    tfontsize = tfontsize.replace("period", ".")
                    // increment index
                    index++

                    title = properties[index]
                    let titleParts = title.split("=")
                    title = titleParts[1]
                    title = title.replace("\"", "")
                    title = title.replace("\"", "")
                    // Replace reserve words
                    title = title.replaceAll("questionmark", "?")
                    title = title.replaceAll("period", ".")   
                    // Replace Functional reserve words
                    title = title.replaceAll("NETWORKNAME", "MPress")  
                    // increment index
                    index++
                    
                    tpadding = properties[index]
                    let tpaddingParts = tpadding.split("=")
                    tpadding = tpaddingParts[1]
                    tpadding = tpadding.replace("\"", "")
                    tpadding = tpadding.replace("\"", "")
                    // Replace reserve words
                    tpadding = tpadding.replaceAll("questionmark", "?")
                    tpadding = tpadding.replaceAll("period", ".")    
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <div style="background: ${background}; color: ${color}; padding: ${padding}; text-align: ${textalign};">
                                <h1 style="font-size: ${tfontsize}; padding: ${tpadding};">${title}</h1><p style="font-size: ${cfontsize}; padding: ${tpadding};">${con}</p>
                            </div>
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(cfontsize)
                    //console.log(color)
                    //console.log(con)
                    //console.log(cpadding)
                    //console.log(tfontsize)
                    //console.log(title)
                    //console.log(tpadding)            
                })
            }
            // --- End Parse Singlet ------------------------------------------------------------------------------------   
        break

        case 'heading':
            // --- Parse heading ---------------------------------------------------------------------------------------
            let regexHeading = /\[heading properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store headings as an array
            let snippetHeadings = content.match(regexHeading)

            // DEBUG: Display headings
            //console.log(snippetHeadings)

            if (snippetHeadings && snippetHeadings.length > 0) {
                // for each heading, parse it
                Array.from(snippetHeadings).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let color           // 1
                    let con             // 2
                    let fontsize        // 3
                    let margin          // 4
                    let padding         // 5
                    let textalign       // 6

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replace("questionmark", "?")
                    background = background.replace("period", ".")  
                    // increment index
                    index++      
                    
                    color = properties[index]
                    let colorParts = color.split("=")
                    color = colorParts[1]
                    color = color.replace("\"", "")
                    color = color.replace("\"", "")
                    // Replace reserve words
                    color = color.replace("questionmark", "?")
                    color = color.replace("period", ".")   
                    // increment index
                    index++  
                    
                    con = properties[index]
                    let conParts = con.split("=")
                    con = conParts[1]
                    con = con.replace("\"", "")
                    con = con.replace("\"", "")
                    // Replace reserve words                    
                    con = con.replace("period", ".") 
                    con = con.replace("questionmark", "?") 
                    // Replace Functional reserve words
                    con = con.replaceAll("NETWORKNAME", settingLookup('SiteTitle', variables))  
                    // increment index
                    index++
                    
                    fontsize = properties[index]
                    let fontsizeParts = fontsize.split("=")
                    fontsize = fontsizeParts[1]
                    fontsize = fontsize.replace("\"", "")
                    fontsize = fontsize.replace("\"", "")
                    // Replace reserve words
                    fontsize = fontsize.replace("questionmark", "?")
                    fontsize = fontsize.replace("period", ".")  
                    // increment index
                    index++   

                    margin = properties[index]
                    let marginParts = margin.split("=")
                    margin = marginParts[1]
                    margin = margin.replace("\"", "")
                    margin = margin.replace("\"", "")
                    // Replace reserve words
                    margin = margin.replace("questionmark", "?")
                    margin = margin.replace("period", ".") 
                    // increment index
                    index++
                    
                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replace("questionmark", "?")
                    padding = padding.replace("period", ".") 
                    // increment index
                    index++

                    textalign = properties[index]
                    let textalignParts = textalign.split("=")
                    textalign = textalignParts[1]
                    textalign = textalign.replace("\"", "")
                    textalign = textalign.replace("\"", "")
                    // Replace reserve words
                    textalign = textalign.replace("questionmark", "?")
                    textalign = textalign.replace("period", ".") 
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <h1 style="background: ${background}; color: ${color}; font-size: ${fontsize}; margin: ${margin}; padding: ${padding}; text-align: ${textalign}">${con}</h1>                
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(color)
                    //console.log(con)
                    //console.log(padding)
                    //console.log(textalign)
                })
            }
            // --- End Parse heading ------------------------------------------------------------------------------------ 
        break

        case 'twocolumn':
            // --- Parse heading ---------------------------------------------------------------------------------------
            let regexTwocolumn = /\[twocolumn properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store headings as an array
            let snippetTwocolumns = content.match(regexTwocolumn)

            // DEBUG: Display headings
            //console.log(snippetHeadings)

            if (snippetTwocolumns && snippetTwocolumns.length > 0) {
                // for each heading, parse it
                Array.from(snippetTwocolumns).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let conebackground  // 1
                    let ctwobackground  // 2                    
                    let conecolor       // 3
                    let ctwocolor       // 4
                    let conecontent     // 5
                    let ctwocontent     // 6
                    let conefontsize    // 7
                    let ctwofontsize    // 8
                    let conepadding     // 9
                    let ctwopadding     // 10
                    let conetextalign   // 11
                    let ctwotextalign   // 12
                    let padding         // 13
                    let tfontsize       // 14
                    let title           // 15
                    let tpadding        // 16

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replaceAll("questionmark", "?")
                    background = background.replaceAll("period", ".")  
                    // increment index
                    index++   
                    
                    conebackground = properties[index]
                    let conebackgroundParts = conebackground.split("=")
                    conebackground = conebackgroundParts[1]
                    conebackground = conebackground.replace("\"", "")
                    conebackground = conebackground.replace("\"", "")
                    // Replace reserve words
                    conebackground = conebackground.replaceAll("questionmark", "?")
                    conebackground = conebackground.replaceAll("period", ".")  
                    // increment index
                    index++ 

                    ctwobackground = properties[index]
                    let ctwobackgroundParts = ctwobackground.split("=")
                    ctwobackground = ctwobackgroundParts[1]
                    ctwobackground = ctwobackground.replace("\"", "")
                    ctwobackground = ctwobackground.replace("\"", "")
                    // Replace reserve words
                    ctwobackground = ctwobackground.replaceAll("questionmark", "?")
                    ctwobackground = ctwobackground.replaceAll("period", ".")  
                    // increment index
                    index++  

                    conecolor = properties[index]
                    let conecolorParts = conecolor.split("=")
                    conecolor = conecolorParts[1]
                    conecolor = conecolor.replace("\"", "")
                    conecolor = conecolor.replace("\"", "")
                    // Replace reserve words
                    conecolor = conecolor.replaceAll("questionmark", "?")
                    conecolor = conecolor.replaceAll("period", ".")   
                    // increment index
                    index++

                    ctwocolor = properties[index]
                    let ctwocolorParts = ctwocolor.split("=")
                    ctwocolor = ctwocolorParts[1]
                    ctwocolor = ctwocolor.replace("\"", "")
                    ctwocolor = ctwocolor.replace("\"", "")
                    // Replace reserve words
                    ctwocolor = ctwocolor.replaceAll("questionmark", "?")
                    ctwocolor = ctwocolor.replaceAll("period", ".")   
                    // increment index
                    index++
                    
                    conecontent = properties[index]
                    let conecontentParts = conecontent.split("=")
                    conecontent = conecontentParts[1]
                    conecontent = conecontent.replace("\"", "")
                    conecontent = conecontent.replace("\"", "")
                    // Replace reserve words
                    conecontent = conecontent.replaceAll("colon", ":")  
                    conecontent = conecontent.replaceAll("newline", "<br />")
                    conecontent = conecontent.replaceAll("period", ".")      
                    conecontent = conecontent.replaceAll("questionmark", "?")     
                    // Replace Functional Reserve Words
                    conecontent = conecontent.replaceAll("LISTFEATURES", "Advanced Support Ticketing System<br />>AI guided exploration (way far future)<br />Complete RESTful API backend<br />Dynamic Navigation System (DNS)<br />Dynamic Page loading (via database or file)<br />Dynamic Permission Node System (DPNS)<br />Forgot Password System and Email Verify System<br />Full-Featured Dashboard<br />In-house Mailer as to not have to rely on gmail<br />Magic-Execution<br />One-of-a-kind Payment Network<br />Plugin System<br />Robust Theming Engine<br />Secure JWT authentication")
                    conecontent = conecontent.replaceAll("LISTPLUGINS", "34 Utility Plugins<br />1,200 Social Plugins<br />230 Gamification plugins<br />23 Security Plugins")
                    conecontent = conecontent.replaceAll("LISTTHEMES", "CandyCrush2022<br />Darwinian2023<br />Reboot2021<br />and so much more!")                                          
                    conecontent = conecontent.replaceAll("NETWORKNAME", "MPress")
                    // increment index
                    index++   

                    ctwocontent = properties[index]
                    let ctwocontentParts = ctwocontent.split("=")
                    ctwocontent = ctwocontentParts[1]
                    ctwocontent = ctwocontent.replace("\"", "")
                    ctwocontent = ctwocontent.replace("\"", "")
                    // Replace reserve words
                    ctwocontent = ctwocontent.replaceAll("colon", ":")  
                    ctwocontent = ctwocontent.replaceAll("newline", "<br />")  
                    ctwocontent = ctwocontent.replaceAll("period", ".") 
                    ctwocontent = ctwocontent.replaceAll("questionmark", "?")         
                    // Replace Functional Reserve Words
                    ctwocontent = ctwocontent.replaceAll("LISTFEATURES", "Advanced Support Ticketing System<br />>AI guided exploration (way far future)<br />Complete RESTful API backend<br />Dynamic Navigation System (DNS)<br />Dynamic Page loading (via database or file)<br />Dynamic Permission Node System (DPNS)<br />Forgot Password System and Email Verify System<br />Full-Featured Dashboard<br />In-house Mailer as to not have to rely on gmail<br />Magic-Execution<br />One-of-a-kind Payment Network<br />Plugin System<br />Robust Theming Engine<br />Secure JWT authentication")
                    ctwocontent = ctwocontent.replaceAll("LISTPLUGINS", "34 Utility Plugins<br />1,200 Social Plugins<br />230 Gamification plugins<br />23 Security Plugins")
                    ctwocontent = ctwocontent.replaceAll("LISTTHEMES", "CandyCrush2022<br />Darwinian2023<br />Reboot2021<br />and so much more!")                            
                    ctwocontent = ctwocontent.replaceAll("NETWORKNAME", "MPress")   
                    // increment index
                    index++

                    conefontsize = properties[index]
                    let conefontsizeParts = conefontsize.split("=")
                    conefontsize = conefontsizeParts[1]
                    conefontsize = conefontsize.replace("\"", "")
                    conefontsize = conefontsize.replace("\"", "")
                    // Replace reserve words
                    conefontsize = conefontsize.replaceAll("questionmark", "?")
                    conefontsize = conefontsize.replaceAll("period", ".")   
                    // increment index
                    index++  
                    
                    ctwofontsize = properties[index]
                    let ctwofontsizeParts = ctwofontsize.split("=")
                    ctwofontsize = ctwofontsizeParts[1]
                    ctwofontsize = ctwofontsize.replace("\"", "")
                    ctwofontsize = ctwofontsize.replace("\"", "")
                    // Replace reserve words
                    ctwofontsize = ctwofontsize.replaceAll("questionmark", "?")
                    ctwofontsize = ctwofontsize.replaceAll("period", ".")   
                    // increment index
                    index++
                    
                    conepadding = properties[index]
                    let conepaddingParts = conepadding.split("=")
                    conepadding = conepaddingParts[1]
                    conepadding = conepadding.replace("\"", "")
                    conepadding = conepadding.replace("\"", "")
                    // Replace reserve words
                    conepadding = conepadding.replaceAll("questionmark", "?")
                    conepadding = conepadding.replaceAll("period", ".") 
                    // increment index
                    index++

                    ctwopadding = properties[index]
                    let ctwopaddingParts = ctwopadding.split("=")
                    ctwopadding = ctwopaddingParts[1]
                    ctwopadding = ctwopadding.replace("\"", "")
                    ctwopadding = ctwopadding.replace("\"", "")
                    // Replace reserve words
                    ctwopadding = ctwopadding.replaceAll("questionmark", "?")
                    ctwopadding = ctwopadding.replaceAll("period", ".") 
                    // increment index
                    index++

                    conetextalign = properties[index]
                    let conetextalignParts = conetextalign.split("=")
                    conetextalign = conetextalignParts[1]
                    conetextalign = conetextalign.replace("\"", "")
                    conetextalign = conetextalign.replace("\"", "")
                    // Replace reserve words
                    conetextalign = conetextalign.replaceAll("questionmark", "?")
                    conetextalign = conetextalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    ctwotextalign = properties[index]
                    let ctwotextalignParts = ctwotextalign.split("=")
                    ctwotextalign = ctwotextalignParts[1]
                    ctwotextalign = ctwotextalign.replace("\"", "")
                    ctwotextalign = ctwotextalign.replace("\"", "")
                    // Replace reserve words
                    ctwotextalign = ctwotextalign.replaceAll("questionmark", "?")
                    ctwotextalign = ctwotextalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replaceAll("questionmark", "?")
                    padding = padding.replaceAll("period", ".") 
                    // increment index
                    index++

                    tfontsize = properties[index]
                    let tfontsizeParts = tfontsize.split("=")
                    tfontsize = tfontsizeParts[1]
                    tfontsize = tfontsize.replace("\"", "")
                    tfontsize = tfontsize.replace("\"", "")
                    // Replace reserve words
                    tfontsize = tfontsize.replaceAll("questionmark", "?")
                    tfontsize = tfontsize.replaceAll("period", ".") 
                    // increment index
                    index++

                    title = properties[index]
                    let titleParts = title.split("=")
                    title = titleParts[1]
                    title = title.replace("\"", "")
                    title = title.replace("\"", "")
                    // Replace reserve words
                    title = title.replaceAll("questionmark", "?")
                    title = title.replaceAll("period", ".") 
                    // increment index
                    index++

                    tpadding = properties[index]
                    let tpaddingParts = tpadding.split("=")
                    tpadding = tpaddingParts[1]
                    tpadding = tpadding.replace("\"", "")
                    tpadding = tpadding.replace("\"", "")
                    // Replace reserve words
                    tpadding = tpadding.replaceAll("questionmark", "?")
                    tpadding = tpadding.replaceAll("period", ".") 
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <h1>${title}</h1>
                            <div style="display: flex; background: ${background}; padding: ${padding}">
                                <div style="flex: 50%; background: ${conebackground}; color: ${conecolor}; font-size: ${conefontsize}; padding: ${conepadding}; text-align: ${conetextalign};">
                                    ${conecontent}
                                </div>

                                <div style="flex: 50%; background: ${ctwobackground}; color: ${ctwocolor}; font-size: ${ctwofontsize}; padding: ${ctwopadding}; text-align: ${ctwotextalign};">
                                    ${ctwocontent}
                                </div>
                            </div>
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(conefontsize)
                    //console.log(ctwofontsize)
                    //console.log(conecolor)
                    //console.log(ctwocolor)
                    //console.log(conecontent)
                    //console.log(ctwocontent)
                    //console.log(conepadding)
                    //console.log(ctwopadding)
                    //console.log(conetextalign)
                    //console.log(ctwotextalign)
                    //console.log(padding)
                    //console.log(tfontsize)
                    //console.log(title)
                    //console.log(tpadding)
                })
            }
            // --- End Parse heading ------------------------------------------------------------------------------------ 
        break

        default: 
            
        break
    }

    return parsed
}