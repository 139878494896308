/* 
#############################[ NOTES ]#######################################################################################################
* Page-specific styles
* 
#############################################################################################################################################
*/

// import styled-components
import styled, { css } from 'styled-components'

import { 
    Accordion,
    AccordionActions,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Breadcrumbs,
    Box, 
    Button,
    CardContent,
    CardMedia,
    Checkbox,
    Grid,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tab,
    Tabs,
    TablePagination,
    TableRow,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    
} from '@mui/material'

// Import from MUI lab
import {
    //TabContext,
    TabList,
    //TabPanel
} from '@mui/lab'

// Import Components
import Drawer from "../../../components/Drawer"

// Import telinput to handle telephone input
import { MuiTelInput } from 'mui-tel-input'

// Import MUI Data Grid
import { DataGrid } from '@mui/x-data-grid'

import { ToastContainer } from 'react-toastify'

export const Bar = styled(Box)`
    align-items: center;   
    display: flex;
    flex-direction: row;
    flex: 0 0 86%;
    height: 37px;
    justify-content: ${(props) => 
        (props.center)
        ? "center"
        : (
            (props.right)
            ? "right"
            : "left"
        )
    };     

    ${(props) => 
        props.bottomgizmo &&
        css`
            background: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.base.background};
            bottom: 0;            
            color: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.base.color};
            padding: 12px 6px 12px 6px;            
            position: sticky;
            position: -webkit-sticky;                        
            width: 100%;
            z-index: 100;            
        `
    }
        
    ${(props) => 
        props.bottomgizmobutton &&
        css`
            background: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.background};           
            color: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.color};         
            cursor: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.cursor};     
            height: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.height};
            justify-content: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.textalign};            
            margin: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.margin};
            padding: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.padding};            
            text-align: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.textalign};
            width: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.width};            

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.drawer.bottomgizmo.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.button &&
        css`
            background: ${(props) => props.theme.dashboard.modules.bar.button.base.background};
            color: ${(props) => props.theme.dashboard.modules.bar.button.base.color};
            cursor: pointer;
            font-size: ${(props) => props.fontsize || '18px'};
            user-select: none;
            font-family: 'Rubik', sans-serif;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.bar.button.base.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.bar.button.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.bar.button.base.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.bar.button.base.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.buttonsmallnobg &&
        css`
            background: none};
            color: ${(props) => props.theme.dashboard.modules.bar.button.base.color};
            cursor: pointer;
            font-size: 12px;
            user-select: none;
            font-family: 'Rubik', sans-serif;

            &:hover,
            &:focus {
                background: none;
                color: ${(props) => props.theme.dashboard.modules.bar.button.base.colorHover};
            }
            &:active {
                background: none;
                color: ${(props) => props.theme.dashboard.modules.bar.button.base.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.buttonerror &&
        css`
            background: ${(props) => props.theme.dashboard.modules.bar.button.error.background};
            color: ${(props) => props.theme.dashboard.modules.bar.button.error.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.bar.button.error.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.bar.button.error.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.bar.button.error.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.bar.button.error.colorActive};
            }
        `
    }

    ${(props) => 
        props.buttonnobg &&
        css`
            background: ${(props) => props.theme.dashboard.modules.bar.button.nobg.background};
            color: ${(props) => props.theme.dashboard.modules.bar.button.nobg.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;
            font-family: 'Rubik', sans-serif;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.bar.button.nobg.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.bar.button.nobg.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.bar.button.nobg.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.bar.button.nobg.colorActive};
            }
        `
    }

    ${(props) => 
        props.center &&
        css`
            align-items: center;
            justify-content: center;
        `
    }

    ${(props) => 
        props.danger &&
        css`
            padding: 0px 20px 0px 13px;
        `
    }

    ${(props) => 
        props.drawer &&
        css`
            background: ${(props) => props.theme.dashboard.modules.drawer.base.background};
            color: ${(props) => props.theme.dashboard.modules.drawer.base.color};
            cursor: auto;
        `
    }

    ${(props) => 
        props.drawernavbutton &&
        css`
            background: ${(props) => props.theme.dashboard.modules.drawer.button.base.background};
            color: ${(props) => props.theme.dashboard.modules.drawer.button.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.params.fontweight};
            padding-bottom: ${(props) => props.theme.dashboard.modules.drawer.button.params.paddingbottom};
            padding-left: ${(props) => props.theme.dashboard.modules.drawer.button.params.paddingleft};
            padding-right: ${(props) => props.theme.dashboard.modules.drawer.button.params.paddingright};
            padding-top: ${(props) => props.theme.dashboard.modules.drawer.button.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.drawer.button.base.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.drawer.button.base.colorHover};
                font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.drawer.button.base.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.drawer.button.base.colorActive};
                font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.drawernestednavbutton &&
        css`
            background: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.background};
            color: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.fontweight};
            padding-bottom: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.paddingbottom};
            padding-left: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.paddingleft};
            padding-right: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.paddingright};
            padding-top: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.colorHover};
                font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.drawer.button.nested.base.colorActive};
                font-weight: ${(props) => props.theme.dashboard.modules.drawer.button.nested.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.fontbarfooterbutton &&
        css`
            background: ${(props) => props.theme.dashboard.modules.footer.button.background};
            color: ${(props) => props.theme.dashboard.modules.footer.button.color};
            cursor: pointer;
            user-select: none;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.footer.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.fullheight &&
        css`
            min-height: 100%;
        `
    }

    ${(props) => 
        props.fullwidth &&
        css`
            min-width: 100%;
        `
    }

    ${(props) => 
        props.navbutton &&
        css`
            background: ${(props) => props.theme.dashboard.modules.navbar.button.background};
            color: ${(props) => props.theme.dashboard.modules.navbar.button.color};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.navbar.button.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.navbar.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.navbar.button.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.navbar.button.colorActive};
            }
        `
    }


    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding5lronly &&
        css`
            padding: 0 5px 0 5px;
        `
    }

    ${(props) => 
        props.padding10lronly &&
        css`
            padding: 0 10px 0 10px;
        `
    }

    ${(props) => 
        props.padding15 &&
        css`
            padding: 15px;
        `
    }

    ${(props) => 
        props.padding15LROnly &&
        css`
            padding: 0 15x 0 15px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) => 
        props.padding20LROnly &&
        css`
            padding: 0 20px 0 20px;
        `
    }

    ${(props) => 
        props.paddingbottom30  &&
        css`
            padding-bottom: 30px;
        `
    }

    ${(props) => 
        props.richtable && 
        css`
            font-size: 1.5em;
            background: white;
            color: black;
            cursor: pointer;
            text-align: center;

            &:hover,
            &:focus {
                background: violet;
                color: black;
            }
            &:active {
                background: violet;
                color: black;
            }
        `
    }

    ${(props) => 
        props.richtablebutton && 
        css`
            font-size: 1.5em;
            background: #ffd3db;
            color: black;
            cursor: pointer;
            text-align: center;
            padding: 0px 5px 0px 5px;

            &:hover,
            &:focus {
                background: red;
                color: white;
            }
            &:active {
                background: red;
                color: white;
            }
        `
    }

    ${(props) => 
        props.sink &&
        css`
            background: cyan;
            color: black;
            cursor: pointer;
            text-align: center;        

            &:hover,
            &:focus {
                background: violet;
                color: black;
            }
            &:active {
                background: violet;
                color: black;
            }
        `
    }

    ${(props) => 
        props.sticky &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            top:0;
            z-index: 100;
        `
    }

    ${(props) =>
        props.underbar &&
        css`
            border: ${(props) => props.theme.dashboard.modules.navbar.button.underbar.params.border};
            border-width: ${(props) => props.theme.dashboard.modules.navbar.button.underbar.params.borderwidth};
        `
    }
`

export const Body = styled.div`
    padding: 3em 5em;
`

export const Bold = styled.b`
    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }
`

export const Card = styled.div`
    display: inline-block;
    margin: 10px;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.dashboard.modules.gem.card.backgroundHover};
        color: ${(props) => props.theme.dashboard.modules.gem.card.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.dashboard.modules.gem.card.backgroundActive};
        color: ${(props) => props.theme.dashboard.modules.gem.card.colorActive};
    }
`

export const CustomAccordian = styled(Accordion)`   
    color: black;
    border: thin solid black;
    font-size: 20px;

    & .MuiAccordionDetails-root {
        background: #004848;
    }

    & .MuiAccordionSummary-root {
        background: #00b3b3;
    }
`

export const CustomAccordianActions = styled(AccordionActions)`   
    background: #001212;
    color: black;
    border: thin solid black;
    font-size: 25px;
`

export const CustomAccordianSummary = styled(AccordionSummary)`   
    // Inner content
    & .MuiAccordionSummary-content {
        background: #33c2c2;
        color: black;
        padding: 10px;
    }

    border: thin solid black;
    font-size: 22px;
`

export const CustomAccordianDetails = styled(AccordionDetails)`   
    background: #004848;
    color: white;
    border: thin solid black;
    font-size: 22px;
`

export const CardActions = styled.div`
    display: flex;
    background: ${(props) => props.theme.secondaryBackground};
    width: 100%;
    min-height: 5em;
    align-items: center;
    justify-content: center;
`

export const CustomAvatarLetter = styled.div`
    background-color: red;
    border-radius: 50px;
    font-weight: 500;
    padding: 10px 15px 15px 15px;
`

export const CustomAvatar = styled(Avatar)`
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.dashboard.modules.avatar.border.styles.one
            } else {
                return props.theme.dashboard.modules.avatar.border.params.border
            }            
        } else if (props.bordertopbottom) {
            return props.theme.dashboard.modules.avatar.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};
`

export const CustomCardContent = styled(CardContent)`
    
`

export const CustomCardMedia = styled(CardMedia)`
    
`

export const CustomBreadcrumbs = styled(Breadcrumbs)`
    
`

export const CustomBreadcrumbsSeparator = styled.div`    
    align-items: center;
    background: ${(props) => props.theme.dashboard.modules.typography.breadcrumbSeparator.base.background};
    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.dashboard.modules.typography.breadcrumbSeparator.border.styles.one
            } else {
                return props.theme.dashboard.modules.typography.breadcrumbSeparator.base.border
            }            
        }        
    }};
    color: ${(props) => {
        if (props.border) {
            return props.theme.dashboard.modules.typography.breadcrumbSeparator.base.colorwithborder
        } else {
            return props.theme.dashboard.modules.typography.breadcrumbSeparator.base.color
        }
    }};
    display: flex;
    font-size: 25px;
    justify-content: center;
    padding: ${(props) => props.theme.dashboard.modules.typography.breadcrumbSeparator.base.padding};
`

export const CustomButton = styled(Button)`
   
`

export const CustomBox = styled(Box)`
    align-items: center;    
    background: ${(props) => (props.bg) ? props.bg : props.theme.dashboard.modules.box.base.background };
    color: ${(props) => (props.color) ? props.color : props.theme.dashboard.modules.box.base.color};
    cursor: ${(props) => 
        (props.button)
        ? "pointer"
        : "auto"
    };
    justify-content: center; 
    margin: 0;   
    padding: ${(props) => 
        (props.headerimage) 
        ? "0px"
        : (
            (props.paddingzero)
            ? "0px"
            : "0px"
        )
    };

    ${(props) => 
        props.leftAlign &&
        css` 
            align-items: left;
            justify-content: left;
        `
    }    

    ${(props) =>
        props.modal && 
        css`
            background: #191919;
            border: 1px solid #000;
            boxShadow: 24;
            left: 50%;
            p: 4;
            position: absolute;
            top: 50%;            
            transform: translate(-50%, -50%);
            width: 400;                        
        `
    }

    ${(props) => 
        props.stickyBottom &&
        css` 
            position: fixed;
            bottom: 0;
        `
    }
`

export const CustomCheckbox = styled(Checkbox)`

`

export const CustomDataGrid = styled(DataGrid)`
    && {
        .MuiDataGrid-columnSeparator {
            display: 'flex';
            color: red;
        }
    
        .MuiDataGrid-columnHeader {
            background: ${(props) => props.theme.dashboard.modules.grid.richtable.header.background};
            color: ${(props) => props.theme.dashboard.modules.grid.richtable.header.color};
            font-family: 'Rubik', sans-serif;
        }
    
        & .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        }

        .MuiCheckbox-root {
            color: ${(props) => props.theme.checkbox};
        }
    
        .Mui-Checked {
            background: red;
        }
    
        & .MuiDataGrid-root {
            //border: "thin solid";
            //borderColor: ${(props) => props.theme.secondaryBackground};
        }
    
        & .MuiDataGrid-cell {    
            background: ${(props) => props.theme.dashboard.modules.grid.richtable.rows.background}; 
            borderColor: (props) => props.theme.secondaryBackground;
            color: ${(props) => props.theme.dashboard.modules.grid.richtable.rows.color}; 
            font-family: 'Rubik', sans-serif;
            font-size: 1.25em;
        }
    
        & .MuiDataGrid-cell:hover {
            color: ${(props) => props.theme.dashboard.modules.grid.richtable.rows.colorHover};
            background: ${(props) => props.theme.dashboard.modules.grid.richtable.rows.backgroundHover};
        }
    
        & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
            outline: none !important;
        }
    
        & .MuiDataGrid-columnHeader:focus-within, 
        & .MuiDataGrid-columnHeader:focus {
            outline: none !important;            
        }
    
        & .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus {
            outline: none;
        }
    }
`

export const CustomDrawer = styled(Drawer)`
    
`

export const CustomFormControl = styled(FormControl)`
    width: 100%;
`

export const CustomFormControlLabel = styled(FormControlLabel)`
    
`

export const CustomFormGroup = styled(FormGroup)`

`

export const CustomGrid = styled(Grid)` 
    background: ${(props) => {
        if (props.gem) {
            switch (props.color) {
                case "primary":
                    return props.theme.dashboard.modules.gem.base.primary.background
                case "secondary":
                    return props.theme.dashboard.modules.gem.base.secondary.background
                case "tertiary":
                    return props.theme.dashboard.modules.gem.base.tertiary.background
                case "quarternary":
                    return props.theme.dashboard.modules.gem.base.quarternary.background
                case "quarternarydarker":
                    return props.theme.dashboard.modules.gem.base.quarternarydarker.background
                case "transparent":
                    return "none"
                default:
                    return props.theme.dashboard.modules.gem.base.primary.background
            }
        } else if (props.sink) {
            return props.theme.dashboard.modules.grid.sink.background
        } else if (props.highlight) {
            switch (props.highlight) {
                case "golden":
                    return props.theme.dashboard.modules.grid.highlight.golden.base.background
                case "normal":
                    return props.theme.dashboard.modules.grid.highlight.normal.base.background
                default:
                    return props.highlight
            }
        } else if (props.panel) {
            return props.theme.dashboard.modules.grid.panel.background
        } else if (props.panelback) {
            return props.theme.dashboard.modules.grid.panelback.background
        } else if (props.darkerheading) {
            return props.theme.dashboard.modules.grid.darkerheading.background
        } else {
            return "none"
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.dashboard.modules.grid.border.styles.one
            } else {
                return props.theme.dashboard.modules.grid.border.params.border
            }            
        } else if (props.bordertopbottom) {
            return props.theme.dashboard.modules.grid.bordertopbottom.params.border
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }};

    border-width: ${(props) => {
        if (props.border) {
            return props.theme.dashboard.modules.grid.border.params.borderWidth
        } else if (props.bordertopbottom) {
            return props.theme.dashboard.modules.grid.bordertopbottom.params.borderWidth
        } else {
            return "none"           
        }
    }};

    color: ${(props) => {
        if (props.gem) {
            if (props.color === "primary") {
                return props.theme.dashboard.modules.gem.base.primary.color
            } else if (props.color === "secondary") {
                return props.theme.dashboard.modules.gem.base.secondary.color
            } else if (props.color === "tertiary") {
                return props.theme.dashboard.modules.gem.base.tertiary.color
            } else if (props.color === "quarternary") {
                return props.theme.dashboard.modules.gem.base.quarternary.color
            } else {
                return props.theme.dashboard.modules.gem.base.primary.color
            }
        } else if (props.sink) {
            return props.theme.dashboard.modules.grid.sink.color
        } else if (props.highlight) {
            if (props.highlight === "golden") {
                return props.theme.dashboard.modules.grid.highlight.golden.base.color
            } else {
                return props.highlight
            }
        } else if (props.panel) {
            return props.theme.dashboard.modules.grid.panel.color
        } else {
            return "none"
        }
    }};

    font-size: ${(props) => {
        if (props.gem) {
            return "20px";
        } else if (props.sink) {
            return props.theme.dashboard.modules.grid.params.fontsize
        } else {
            return "12px"
        }
    }};

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.gem) {
            return props.padding ? `${props.padding}px` : "0px"
        } else {
            return props.padding ? props.padding : "0px"
        }
    }};

    user-select: ${(props) => {
        if (props.nouserselect) {
            return 'none'
        } else {
            return 'auto'
        }
    }}
    ;

    text-align: ${(props) => {
        if (props.center) {
            return 'center'
        } else if (props.right) {
            return 'right'
        } else {
            return 'left'
        }
    }}
    ;

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
            background: ${(props) => props.theme.dashboard.modules.grid.button.primary.background};
            color: ${(props) => props.theme.dashboard.modules.grid.button.primary.color};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.grid.button.primary.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.grid.button.primary.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.grid.button.primary.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.grid.button.primary.colorActive};
            }
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            //border: thin solid black;
            background: white;
            color: white;
        `
    }

    ${(props) => 
        (props.richtablenougat) &&
        css`
            background: ${props.theme.dashboard.modules.grid.richtablenougat.base.background};
            //border: thin solid green;
        `
    }
    
    ${(props) => 
        (props.richtabletopbar) &&
        css`
            background: ${props.theme.dashboard.modules.grid.richtablenougat.topbar.background};
            padding: 10px;
            //border: thin solid green;
        `
    }
`

export const CardHeader = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`

export const CustomIconButton = styled.div`
    color: ${(props) => props.theme.dashboard.base.iconbutton.color};
    cursor: pointer;
`
export const CustomInput = styled(Input)`
    
`

export const CustomInputAdornment = styled(InputAdornment)`
    && {
        background: ${(props) => props.theme.dashboard.modules.inputadornment.base.background};
        color: ${(props) => props.theme.dashboard.modules.inputadornment.base.color};
        padding: ${(props) => props.theme.dashboard.modules.inputadornment.base.params.padding};
        height: 100%;
        user-select: none;
    }
`
export const CustomInputLabel = styled(InputLabel)`

`

export const CustomItem = styled.div`
    background: ${(props) => {
        if (props.footeritem) {
            return props.theme.dashboard.modules.footer.item.base.background;
        } else if (props.footerfullitem) {
            return props.theme.dashboard.modules.footer.item.full.background;
        } else if (props.sink) {
            return props.theme.dashboard.modules.item.sink.base.background;
        } else if (props.nobg) {
            return "none";
        } else if (props.bottomgizmo) {
            return props.theme.dashboard.modules.item.bottomgizmo.background;
        } else {
            return props.theme.dashboard.modules.item.base.background;
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }         
        } else {
            return "0px"
        }
    }};

    color: ${(props) => {
        if (props.footeritem) {
            return props.theme.dashboard.modules.footer.item.color
        } else if (props.footerfullitem) {
            return props.theme.dashboard.modules.footer.item.full.color
        } else if (props.sink) {
            return props.theme.dashboard.modules.item.sink.base.color
        } else {
            return props.theme.dashboard.modules.item.color
        }
    }};

    font-size: ${(props) => {
        if (props.footeritem) {
            return props.theme.dashboard.modules.footer.base.fontsize
        } else if (props.bottomgizmo) {
            if (props.bottomgizmodetailsprimary) {
                return props.theme.dashboard.modules.drawer.bottomgizmo.params.fontsizeprimary
            } else if (props.bottomgizmodetailssecondary) {
                return props.theme.dashboard.modules.drawer.bottomgizmo.params.fontsizesecondary
            } else {
                return props.theme.dashboard.modules.drawer.bottomgizmo.params.fontsize
            }
        } else {
            return '20px'
        }
    }};

    font-weight: ${(props) => {
        if (props.footeritem) {
            return 500
        } else if (props.bottomgizmo) {
            if (props.bottomgizmodetailsprimary) {
                return 900
            } else if (props.bottomgizmodetailssecondary) {
                return 400
            } else {
                return 100
            }
        } else {
            return 400
        }
    }};

    margin-right: ${(props) => {
        if (props.footeritem) {
            return 500
        } else {
            if (props.bottomgizmo) {
                if (props.bottomgizmodetailsprimary) {
                    return 900
                } else {
                    if (props.bottomgizmodetailssecondary) {
                        return 400
                    } else {
                        return 100
                    }
                }
            } else {
                return 400
            }
        }
    }};

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.padding10) {
            return "10px 10px 10px 10px"
        } else {
            if (props.bottomgizmo) {
                return "1px 1px 1px 1px"
            } else {
                return "0px 0px 0px 0px"
            }
        }
    }};
    
    text-align: ${(props) => {
        if (props.richtable) {
            return "left"
        } else {
            return "center"
        }
    }};

    user-select: ${(props) => {
        if (props.footerfullitem) {
            return "none"
        }
    }};

    ${(props) => 
        (props.richtable) &&
        css`
            background: none !important;
        `
    }

    ${(props) => 
        (props.userinfobar) &&
        css`
            font-size: 13px;
        `
    }
`

export const CustomLink = styled.a`
    background: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.dashboard.modules.customlink.copyrightlink.base.background
        } else {
            if (props.nobg) {
                return "none"
            } else if (props.alt) {
                return props.theme.dashboard.modules.customlink.basealt.background
            } else if (props.breadcrumb) {
                return props.theme.dashboard.modules.customlink.breadcrumb.base.background
            } else {
                return props.theme.dashboard.modules.customlink.base.background
            }
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.dashboard.modules.customlink.border.styles.one
            } else {
                return props.theme.dashboard.modules.customlink.border.params.border
            }            
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.richtable) {
            return props.theme.dashboard.modules.customlink.richtable.base.borderradius
        } else if (props.rounded) {
            if (props.rounded) {
                if (props.roundedamt) {
                    return props.roundedamt
                } else {
                    return "10px"
                }    
            } else {
                return "0px"
            }
        } else {
            return "0px"
        }
    }};

    color: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.dashboard.modules.customlink.copyrightlink.base.color
        } else if (props.alt) {
            return props.theme.dashboard.modules.customlink.basealt.color
        } else if (props.breadcrumb) {
            return props.theme.dashboard.modules.customlink.breadcrumb.base.color
        } else {
            return props.theme.dashboard.modules.customlink.base.color
        }
    }};

    cursor: pointer;

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};
    
    padding: ${(props) => {
        if (props.copyrightlink) {
            return props.theme.dashboard.modules.customlink.copyrightlink.base.params.padding
        } else {
            if (props.padding5) {
                return "5px"
            } else {
                return props.theme.dashboard.modules.customlink.base.params.padding
            }
        }
    }};
    
    text-decoration: underline;

    user-select: none;

    &:hover,
    &:focus {
        ${(props) => {
            if (props.breadcrumb) {
                return (
                    css `
                        background: ${(props) => props.theme.dashboard.modules.customlink.breadcrumb.base.backgroundHover};
                        color: ${(props) => props.theme.dashboard.modules.customlink.breadcrumb.base.colorHover};
                    `
                )
            } else {
                return (
                    css`
                        background: ${(props) => (props.nohoverbg) ? "none" : props.theme.dashboard.modules.customlink.base.backgroundHover};
                        color: ${(props) => props.theme.dashboard.modules.customlink.base.colorHover};
                    `
                )
            }
        }
    }
    &:active {
        background: ${(props) => (props.nohoverbg) ? "none" : props.theme.dashboard.modules.customlink.base.backgroundActive};
        color: ${(props) => props.theme.dashboard.modules.customlink.base.colorActive};
    } 

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            background: ${(props) => 
                props.theme.dashboard.modules.customlink.richtable.base.background
            };
            color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.color};
            font-size: 16px;
            padding: ${(props) => props.theme.dashboard.modules.customlink.richtable.params.padding};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.colorActive};
            }
        `
    }
`

export const CustomList = styled(List)`
    
`

export const CustomListItem = styled(ListItem)`
    background: cyan;
    color: black;
    border: thin solid black;
`
export const CustomListItemText = styled(ListItemText)`
    font-size: 40px;
`

// SHOUTOUT: To this for helping for solve:
// https://stackoverflow.com/questions/73925276/how-to-override-mui-paperprops-with-styled-components
export const CustomMenu = styled(Menu)`
    && {
        & .MuiPaper-root {
            background-color: ${(props) => props.theme.dashboard.modules.menu.base.background};
            color: ${(props) => props.theme.dashboard.modules.menu.base.color};
            min-width: ${(props) => props.theme.dashboard.modules.menu.params.minwidth};
            padding: ${(props) => props.theme.dashboard.modules.menu.params.padding};
        }

        // arrow
        & .MuiPaper-root:before {
            background-color: ${(props) => props.theme.dashboard.modules.menu.arrow.base.background};
            top: ${(props) => props.theme.dashboard.modules.menu.arrow.params.top};
            right: ${(props) => props.theme.dashboard.modules.menu.arrow.params.right};
            height: ${(props) => props.theme.dashboard.modules.menu.arrow.params.height};
            transform: translateY(${(props) => props.theme.dashboard.modules.menu.arrow.params.transform.rotate}) rotate(${(props) => props.theme.dashboard.modules.menu.arrow.params.transform.rotate});
        }
    }
`   

// SHOUTOUT: To this for helping solve this: 
// https://stackoverflow.com/questions/73335643/how-to-apply-styles-to-mui-menulist-with-styled-compoents
export const CustomMenuItem = styled(MenuItem)`
    && {
        padding: ${(props) => props.theme.dashboard.modules.menu.item.params.padding};

        &.Mui-selected {
            background-color: ${(props) => props.theme.dashboard.modules.menu.item.base.background};
            color: ${(props) => props.theme.dashboard.modules.menu.item.base.color};
        }

        &:hover {
            background-color: ${(props) => props.theme.dashboard.modules.menu.item.base.backgroundHover};
            color: ${(props) => props.theme.dashboard.modules.menu.item.base.colorHover};
        }
    }
`

export const CustomModal = styled(Modal)`
    
`

export const CustomMuiTelInput = styled(MuiTelInput)`
    && {
        .MuiInputBase-root {
            background-color: ${(props) => props.theme.dashboard.modules.telinput.base.background};
            color: ${(props) => props.theme.dashboard.modules.telinput.base.color};
        }
        
        .MuiInputBase-root.Mui-focused {
            background-color: ${(props) => props.theme.dashboard.modules.telinput.base.backgroundHover};
            color: ${(props) => props.theme.dashboard.modules.telinput.base.colorHover};
        }

        .MuiOutlinedInput-root {
            & fieldset {
                border: ${(props) => props.theme.dashboard.modules.telinput.base.border};
            }   

            &:hover fieldset {
                border: ${(props) => props.theme.dashboard.modules.telinput.base.borderHover};
            }   
            
            &.Mui-focused fieldset {
                border: ${(props) => props.theme.dashboard.modules.telinput.base.borderFocused};
            }
        }

        .MuiInputLabel-root {
            background-color: ${(props) => props.theme.dashboard.modules.textfield.base.labelBackground};
            border: ${(props) => props.theme.dashboard.modules.textfield.base.labelBorder};
            color: ${(props) => props.theme.dashboard.modules.textfield.base.labelColor}; 
            padding: ${(props) => props.theme.dashboard.modules.textfield.base.labelPadding};
        }
        .MuiInputLabel-root.Mui-focused {
            background-color: ${(props) => props.theme.dashboard.modules.textfield.base.labelBackgroundFocus};
            border: ${(props) => props.theme.dashboard.modules.textfield.base.labelBorderFocus};
            color: ${(props) => props.theme.dashboard.modules.textfield.base.labelColorFocus};
            padding: ${(props) => props.theme.dashboard.modules.textfield.base.labelPaddingFocus};
        }
    }
    width: 100%;
    height: 3.5em;
`

export const CustomStack = styled(Stack)`
    ${(props) =>
        (props.gemitem) &&
        css `
            &:hover {
                border: thin solid red;
            }
        `
    }

    ${(props) => 
        (props.bottomgizmo) &&
        css`
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            z-index: 100;
        `
    }

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
        `
    }
`

export const CustomSelect = styled(Select)`
    background: cyan;
`

export const CustomSwitch = styled(Switch)`
    & .MuiSwitch-track {
        background: ${(props) => 
            props.theme.dashboard.modules.llswitch.nougat.background
        };
    }

    & .MuiSwitch-thumb {
        background: ${(props) =>
            props.theme.dashboard.modules.llswitch.button.background
        };
    }
`

export const CustomTextField = styled(TextField)`
    width: 100%; 
    z-index: 1;

    && {
        .MuiInputLabel-root {
            background-color: ${(props) => props.theme.dashboard.modules.textfield.base.labelBackground};
            border: ${(props) => props.theme.dashboard.modules.textfield.base.labelBorder};
            color: ${(props) => props.theme.dashboard.modules.textfield.base.labelColor}; 
            padding: ${(props) => props.theme.dashboard.modules.textfield.base.labelPadding};
        }
        .MuiInputLabel-root.Mui-focused {
            background-color: ${(props) => props.theme.dashboard.modules.textfield.base.labelBackgroundFocus};
            border: ${(props) => props.theme.dashboard.modules.textfield.base.labelBorderFocus};
            color: ${(props) => props.theme.dashboard.modules.textfield.base.labelColorFocus};
            padding: ${(props) => props.theme.dashboard.modules.textfield.base.labelPaddingFocus};
        }
    }

    & label.Mui-focused {
        background: pink;
        border: thin solid black;
        color: black;
        font-size: 22px;
        font-family: 'Rubik', sans-serif;
        padding: 5px 5px 0px 5px;
    }

    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiInputBase-multiline {
        margin: 0px;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border: ${(props) => props.theme.dashboard.modules.textfield.base.border};
        }
        &:hover fieldset {
            border-color: black;
        }
        &.Mui-focused fieldset {            
            color: black;
            border-color: violet;
        }
    }

    & .MuiOutlinedInput-input {
        background: ${(props) => props.theme.dashboard.modules.textfield.base.background};
        color: ${(props) => props.theme.dashboard.modules.textfield.base.color};

        &:focus,
        &:hover {
            background-color: ${(props) => props.theme.dashboard.modules.textfield.base.backgroundHover};
            color: ${(props) => props.theme.dashboard.modules.textfield.base.colorHover};
        }
    }
`

/*
'width': '100%',
'background': (props) => props.theme.quartinaryBackground,


'& label.Mui-focused': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiInput-underline:after': {
    borderBottomColor: 'black',
},
'& .MuiInputLabel-root': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiOutlinedInput-input': {
    color: (props) => props.theme.drawergizmoinputcolor,
},
'& .MuiOutlinedInput-root': {
    '& fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&:hover fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-focused fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-disabled fieldset': {        
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& label.Mui-disabled': {
    color: (props) => props.theme.drawergizmoinputcolor,
    }
},*/

export const CustomToastContainer = styled(ToastContainer)` 
    // ???
    .Toastify {
        
    }
    
    // ???
    .Toastify__toast-container {
        
    } 

    // Normal progress bar style
    .Toastify__progress-bar {
        background: ${(props) => props.theme.dashboard.modules.notification.progressbar.normal.background};
    }

    // Error progress bar style
    .Toastify__progress-bar--error {
        background: ${(props) => props.theme.dashboard.modules.notification.progressbar.error.background};
    }

    // Info progress bar style
    .Toastify__progress-bar--info {
        background: ${(props) => props.theme.dashboard.modules.notification.progressbar.info.background};
    }

    // Warning progress bar style
    .Toastify__progress-bar--warning {
        background: ${(props) => props.theme.dashboard.modules.notification.progressbar.warning.background};
    }

    // Icon (WIP: cant find it)
    .Toastify__toast-icon {
        
    } 
    
    // Body of notification (text and what not)
    .Toastify__toast-body {
        font-size: 20px;
        font-family: 'Rubik';
    }

    // Normal style 
    .Toastify__toast {
        background: ${(props) => props.theme.dashboard.modules.notification.body.normal.background};
        color: ${(props) => props.theme.dashboard.modules.notification.body.normal.color};
        min-height: 60px; // minimum is 60px
        width: 300px;
    } 

    // Error style
    .Toastify__toast--error {
        background: ${(props) => props.theme.dashboard.modules.notification.body.error.background};
        color: ${(props) => props.theme.dashboard.modules.notification.body.error.color};
    }

    // Info style
    .Toastify__toast--info {
        background: ${(props) => props.theme.dashboard.modules.notification.body.info.background};
        color: ${(props) => props.theme.dashboard.modules.notification.body.info.color};
    }

    // Warning style
    .Toastify__toast--warning {
        background: ${(props) => props.theme.dashboard.modules.notification.body.warning.background};
        color: ${(props) => props.theme.dashboard.modules.notification.body.warning.color};
    }
`

export const CustomToggleButton = styled(ToggleButton)`

`

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`

`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
    
`

export const CustomTable = styled(Table)`

`

export const CustomTableBody = styled(TableBody)`

`

export const CustomTableCell = styled(TableCell)`

`

export const CustomTableContainer = styled(TableContainer)`

`

export const CustomTableHead = styled(TableHead)`

`

export const CustomTab = styled(Tab)`
    && {
        &.Mui-selected {
            background-color: ${(props) => props.theme.dashboard.modules.tabs.tab.base.backgroundSelected}; /* Background color of selected tab */
            color: ${(props) => props.theme.dashboard.modules.tabs.tab.base.colorSelected} !important; /* Text color of selected tab */
        }

        &:not(.Mui-selected) {
            background-color: ${(props) => props.theme.dashboard.modules.tabs.tab.base.background}; /* Background color of unselected tabs */
            color: ${(props) => props.theme.dashboard.modules.tabs.tab.base.color} !important; /* Text color of unselected tabs */
        }
  }
`

export const CustomTabs = styled(Tabs)`
    background: ${(props) => props.theme.dashboard.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;

    && {
        .MuiTab-root {
            color: ${(props) => props.theme.dashboard.modules.tabs.base.color}; 
        }

        .MuiTabs-indicator {
            background-color: ${(props) => props.theme.dashboard.modules.tabs.base.indicatorColor}; 
        }
    }
`

export const CustomTabList = styled(TabList)`
    background: ${(props) => props.theme.dashboard.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;
`

export const CustomTablePagination = styled(TablePagination)`

`

export const CustomTableRow = styled(TableRow)`

`

export const CustomToolbar = styled.div`
    
`

export const CustomTooltip = styled.div`
    
`

export const CustomTypography = styled(Typography)`    
    background: ${(props) => (props.nobg) ? "none" : props.theme.dashboard.modules.typography.base.background};
    color: ${(props) => (props.nobg && props.bottomgizmo) ? props.theme.dashboard.modules.typography.base.colornobg : props.theme.dashboard.modules.typography.base.color};
    font-size: 1em;
    font-weight: 500;
    margin: ${(props) => props.margin || "0px"}
    padding: ${(props) => props.padding || "0px"}
    user-select: ${(props) => props.nouserselect};

    ${(props) => {
        if (props.center) {
            return css`text-align: center;`
        } else if (props.left) {
            return css`text-align: left;`
        } else if (props.right) {
            return css`text-align: right;`
        }
    }}

    ${(props) => {
        if (props.fontheading1) {
            return css`font-family: 'Rubik', sans-serif;`
        } else if (props.fontheading6) {
            return css`font-family: 'Borel', sans-serif;`
        }
    }}

    ${(props) => {
        if (props.h1) {
            return css`font-size: 2.4em;`
        } else if (props.h2) {
            return css`font-size: 2.2em;`
        } else if (props.h3) {
            return css`font-size: 2em;`
        } else if (props.h4) {
            return css`font-size: 1.85em;`
        } else if (props.h5) {
            return css`font-size: 1.6em;`
        } else if (props.h6) {
            return css`font-size: 1.3em;`
        } else if (props.h7) {
            return css`font-size: 1em;`
        } else if (props.h8) {
            return css`font-size: 0.9em;`
        }
    }}

    ${(props) => 
        props.outline &&
        css`
            border: thin solid ${(props) => props.theme.text};
            border-radius: 5px;
        `
    }

    ${(props) => 
        props.grayed &&
        css` color: ${(props) => props.theme.textGrayed}; `
    }
`;

export const Heading = styled.div`
    background: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.dashboard.modules.typography.divider1.background
        } else if (props.footerdivider2) {
            return props.theme.dashboard.modules.typography.divider2.background
        } else if (props.footerdivider3) {
            return props.theme.dashboard.modules.typography.divider3.background
        } else if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.dashboard.modules.typography.badge.permissiontier0.background
            } else if (props.permissiontier1) {
                return props.theme.dashboard.modules.typography.badge.permissiontier1.background
            } else if (props.permissiontier2) {
                return props.theme.dashboard.modules.typography.badge.permissiontier2.background
            } else if (props.permissiontier3) {
                return props.theme.dashboard.modules.typography.badge.permissiontier3.background
            } else {
                return props.theme.dashboard.modules.typography.badge.base.background
            }
        } else {
            if (props.nobg) {
                return "none"
            } else {
                if (props.darkerheading) {
                    return props.theme.dashboard.modules.typography.darkerheading.background
                } else {
                    return props.theme.dashboard.modules.typography.base.background
                }
            }
        }
    }};

    border: ${(props) => {
        if (props.border) {
            if (props.border === "border1") {                
                return props.theme.dashboard.modules.typography.border.styles.one
            } else {
                return props.theme.dashboard.modules.typography.border.params.border
            }            
        } else {
            return "none"
        }
    }};

    border-radius: ${(props) => {
        if (props.rounded) {
            if (props.roundedamt) {
                return props.roundedamt
            } else {
                return "10px"
            }    
        } else {
            return "0px"
        }
    }};

    color: ${(props) => {
        if (props.badge) {
            if (props.permissiontier0) {
                return props.theme.dashboard.modules.typography.badge.permissiontier0.color
            } else if (props.permissiontier1) {
                return props.theme.dashboard.modules.typography.badge.permissiontier1.color
            } else if (props.permissiontier2) {
                return props.theme.dashboard.modules.typography.badge.permissiontier2.color
            } else if (props.permissiontier3) {
                return props.theme.dashboard.modules.typography.badge.permissiontier3.color
            } else {
                return props.theme.dashboard.modules.typography.badge.base.color
            }
        } else {
            if (props.grid) {
                if (props.grid === "grayed") {
                    return props.theme.dashboard.modules.typography.grid.grayed.color
                } else {
                    return props.theme.dashboard.modules.typography.grid.base.color
                }
            } else {
                if (props.highlight) {
                    if (props.highlight === "golden") {
                        return props.theme.dashboard.modules.grid.highlight.golden.base.color
                    } else if (props.highlight === "goldengrayed") {
                        return props.theme.dashboard.modules.grid.highlight.goldengrayed.base.color
                    } else {
                        return props.highlight
                    }
                } else {
                    if (props.grayed) {
                        return props.theme.dashboard.modules.typography.grayed.color
                    } else if (props.alt) {
                        return props.theme.dashboard.modules.typography.basealt.color
                    } else {
                        return props.theme.dashboard.modules.typography.base.color
                    }
                }
            }
        }
    }} !important;

    cursor: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.dashboard.modules.typography.divider1.cursor
        } else if (props.footerdivider2) {
            return props.theme.dashboard.modules.typography.divider2.cursor
        } else if (props.footerdivider3) {
            return props.theme.dashboard.modules.typography.divider3.cursor
        } else {
            if (props.nobg) {
                return "normal"
            } else {
                if (props.button) {
                    return "pointer"
                } else {
                    return props.theme.dashboard.modules.typography.base.cursor
                }
            }
        }
    }};

    font-family: ${(props) => {
        if (props.navbar) {
            return props.theme.dashboard.modules.typography.navbar.fontfamily
        } else if (props.footer) {
            return props.theme.dashboard.modules.typography.footer.fontfamily
        } else if (props.gem) {
            if (props.h1) {
                return props.theme.dashboard.modules.typography.gem.h1.fontfamily
            } else if (props.h6) {
                return props.theme.dashboard.modules.typography.gem.h6.fontfamily
            } else {
                return props.theme.dashboard.modules.typography.gem.h1.fontfamily
            }
        } else {
            return props.theme.dashboard.modules.typography.base.fontfamily
        }
    }};

    font-size: ${(props) => {
        if (props.badge) {
            return "17px"
        } else {
            if (props.h1) {
                return "2.4em"
            } else if (props.h2) {
                return "2.2em"
            } else if (props.h3) {
                return "2em"
            } else if (props.h4) {
                return "1.85em"
            } else if (props.h5) {
                return "1.6em"
            } else if (props.h6) {
                return "1.4em"
            } else if (props.h7) {
                return "1.35em"
            } else if (props.h8) {
                return "1.2em"
            } else if (props.h9) {
                return "1em"
            }
        }
    }};

    font-weight: 500;
    
    margin: ${(props) => {
        if (props.badge) {
            return "0px 3px 0px 3px"
        } else {
            return "none"
        }
    }};

    overflow: ${(props) => {
        if (props.rounded) {
            return "hidden"
        } else {
            return "none"
        }
    }};

    padding: ${(props) => {
        if (props.padding10) {
            return "10px"
        } else {
            if (props.badge) {
                return "4px"
            } else {
                if (props.padding) {
                    return props.padding
                } else {
                    return "0px"
                }
            }
        }
    }};

    text-align: ${(props) => {
        if (props.center) {
            return "center"
        } else if (props.right) {
            return "right"
        } else {
            return "left"
        }
    }};

    text-justify: distribute;
    
    text-transform: ${(props) => {
        if (props.insetmedium) {
            return "uppercase"
        } else if (props.insetlarge) {
            return "uppercase"
        } else if (props.insetxlarge) {
            return "uppercase"
        } else {
            return "none"
        }
    }};

    user-select: ${(props) => {
        if (props.footerdivider1) {
            return props.theme.dashboard.modules.typography.divider1.userselect
        } else if (props.footerdivider2) {
            return props.theme.dashboard.modules.typography.divider2.userselect
        } else if (props.footerdivider3) {
            return props.theme.dashboard.modules.typography.divider3.userselect
        } else {
            if (props.nobg) {
                return "none"
            } else if (props.button) {
                return "pointer"
            } else if (props.drawernavbuttonheading) {
                return "none"
            } else if (props.badge) {
                return "none"
            } else {
                return props.theme.dashboard.modules.typography.base.userselect
            }
        }
    }};

    ${(props) => 
        props.breadcrumb &&
        css`
            background: ${(props) => props.theme.dashboard.modules.typography.breadcrumb.base.background};
            color: ${(props) => props.theme.dashboard.modules.typography.breadcrumb.base.color};
            font-size: ${(props) => props.theme.dashboard.modules.typography.breadcrumb.base.fontsize};
            padding: ${(props) => props.theme.dashboard.modules.typography.breadcrumb.base.padding}
        `
    }

    ${(props) =>
        (props.button) &&
            css`
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 5px;
            `
        }


    ${(props) =>
    (props.drawernavbuttonheading) &&
        css`
            background: ${(props) => props.theme.dashboard.modules.typography.drawer.base.background};
            color: ${(props) => props.theme.dashboard.modules.typography.drawer.base.color} !important;
            font-size: ${(props) => props.theme.dashboard.modules.typography.drawer.params.fontsize};
            font-family: ${(props) => props.theme.dashboard.modules.typography.drawer.params.fontfamily};
            padding-bottom: ${(props) => props.theme.dashboard.modules.typography.drawer.params.paddingbottom};
            padding-left: ${(props) => props.theme.dashboard.modules.typography.drawer.params.paddingleft};
            padding-right: ${(props) => props.theme.dashboard.modules.typography.drawer.params.paddingright};
            padding-top: ${(props) => props.theme.dashboard.modules.typography.drawer.params.paddingtop};
        `
    }

    ${(props) =>
        (props.grayed) &&
        css`
            background: ${(props) => props.theme.dashboard.modules.typography.grayed.base.background};
            color: ${(props) => props.theme.dashboard.modules.typography.grayed.base.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextarea) &&
        css`
            font-size: 17px;
            background: ${(props) => props.theme.dashboard.modules.typography.userinfotextarea.base.background};
            color: ${(props) => props.theme.dashboard.modules.typography.userinfotextarea.base.color};
            padding: 4px;
        `
    }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px; /* adjust as needed */
  border-radius: 15px;
  overflow: hidden;
  border: ${(props) => (props.border) ? props.theme.dashboard.modules.grid.border.styles.one : 'none'};
`

export const InnerContent = styled.div`
    padding: ${(props) => {
        if (props.nopadding) {
            return "0px"
        } else if (props.nopaddingleft) {
            return "0px 20px 0px 0px"
        } else if (props.paddingleft20paddingright10) {
            return "0px 20px 0px 10px"
        } else {
            return "0px 20px 0px 20px"
        }
    }};
`

export const InnerDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.dashboard.modules.drawer.base.background};
    color: ${(props) => props.theme.dashboard.modules.drawer.base.color};
    padding-right: 0px;
`

export const InnerInnerDrawer = styled.div`
    height: 100vh;
    background: ${(props) => props.theme.dashboard.modules.drawer.inner.base.background};
    color: ${(props) => props.theme.dashboard.modules.drawer.inner.base.color};    
    padding-bottom: ${(props) => props.theme.dashboard.modules.drawer.inner.params.paddingbottom};
    padding-left: ${(props) => props.theme.dashboard.modules.drawer.inner.params.paddingleft};
    padding-right: ${(props) => props.theme.dashboard.modules.drawer.inner.params.paddingright};
    padding-top: ${(props) => props.theme.dashboard.modules.drawer.inner.params.paddingtop};
`

export const ItemContainer = styled.div`
    border: thin solid red;
`

export const NavBar = styled.div`
    background: ${(props) => props.theme.dashboard.modules.navbar.base.background};
    padding-top: calc(${(props) => props.theme.dashboard.modules.navbar.params.height} / 2);
    padding-bottom: calc(${(props) => props.theme.dashboard.modules.navbar.params.height} / 2);
    width: 100%;
    z-index: 900;
    
    ${(props) => 
        props.stickytop &&
        css` 
            position: fixed;
            top: 0;
        `
    }
`

export const OverlayBox = styled(Box)((props) => {
    const { params = {} } = props
    const positionStyles = {}
    const statusStyles = {}

    if (params.left !== undefined) {
        positionStyles.left = params.left
    }
    if (params.right !== undefined) {
        positionStyles.right = params.right
    }

    if (params.disabled !== undefined) {
        if (params.disabled) {
            statusStyles.backgroundColor = 'rgba(255, 0, 0, 0.7)'
            statusStyles.color = 'gray'
        } else {
            statusStyles.backgroundColor = params.backgroundColor || 'rgba(0, 0, 0, 0.5)'
            statusStyles.color = params.color || 'white'
        }
    } else {
        statusStyles.backgroundColor = params.backgroundColor || 'rgba(0, 0, 0, 0.5)'
        statusStyles.color = params.color || 'white'
    }

    return {
        alignItems: params.alignitems || 'center',
        borderRadius: params.borderradius || '8px', // Rounded corners    
        cursor: params.cursor || 'normal',
        display: 'flex',
        fontSize: params.fontsize || '20px',
        height: params.height || '50px',
        justifyContent: params.justifycontent || 'flex-start', 
        padding: params.padding || '20px', // Padding    
        position: 'absolute',
        top: params.top || '20px',
        userSelect: params.userselect || 'none',
        width: params.width || '100px',
        
        ...positionStyles, // Conditionally add left or right

        ...statusStyles,

        ...((params.button && !params.disabled) && {
            '&:focus, &:hover': {
                color: params.hoverColor || 'rgba(255, 255, 255, 1)',
                backgroundColor: params.hoverBackgroundColor || 'rgba(0, 0, 0, 0.7)', // Darker semi-transparent background
                transform: 'scale(1.05)', // Slightly scale up
                outline: 'none', // Remove default focus outline
            },
        }),
    }
})

export const Para = styled.p`
    font-size: 1.2em;
    text-align: justify;
    text-justify: distribute;
    color: ${(props) => props.theme.dashboard.modules.typography.para.base.color};

    ${(props) => 
        props.center &&
        css`            
            text-align: center; 
            justify-content: distribute;                
            `        
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        props.right &&
        css`            
            text-align: right; 
            justify-content: distribute;                
            `        
    }
`

export const StyledImage = styled('img')({
    width: (props) => props.params?.width || '100%',
    height: (props) => props.params?.height || '100%',
    borderRadius: (props) => props.params?.borderradius || '10px',
    overflow: 'hidden',
    objectFit: 'cover' // ensure the image covers the container
})

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    user-select: none;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    // inside button
    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => 
            props.theme.dashboard.modules.llswitch.nougat.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    // button
    span:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: ${(props) => 
            props.theme.dashboard.modules.llswitch.button.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: ${(props) => props.theme.dashboard.modules.llswitch.nougat.background};
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`

export const TextFieldLabel = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.text};
`