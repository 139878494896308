import * as ActionTypes from '../ContextActions'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case ActionTypes.CLEAR_CURRENT_SLICE:
            return {
                ...state,
                currentSlice: null,
                sliceCreated: false
            }
        case ActionTypes.CLEAR_ERRORS_POST:
            return {
                ...state,
                toastsTag: null
            }
        case ActionTypes.CLEAR_SLICES:
            return {
                ...state,
                theslices: null,
                currentSlice: null,
                sliceCreated: false,
                toastsTag: null
            }

        case ActionTypes.GET_ALLTAGS_SUCCESS:
            return{
                ...state,
                tags: action.payload[0].object.tags,
                tagsCreated: true
            }

        case ActionTypes.GET_ALLTAGS_FAIL:
            return{
                ...state,
                tags: null
            }

        case ActionTypes.GET_MYTAGS_SUCCESS:
            return{
                ...state,
                tags: action.payload[0].object.tags,
                tagsCreated: true
            }

        case ActionTypes.GET_MYTAGS_FAIL:
            return{
                ...state,
                categories: null
            }

        case ActionTypes.GET_ALLMYSLICES_SUCCESS:
            //console.log("From postReducer: GET_SLICES_SUCCESS: theslices: "+action.payload[0].object?.tags)
            return{
                ...state,
                theslices: action.payload[0].object?.tags,
                toastsTag: action.payload
            }
            
        case ActionTypes.GET_ALLMYSLICES_FAIL:
            
            return{
                ...state,
                theslices: null,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLFEATUREDPOSTS_SUCCESS:
            return{
                ...state,
                featuredposts: action.payload[0].object?.tags,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLFEATUREDPOSTS_FAIL:
            return{
                ...state,
                featuredposts: null,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLSLICES_FAIL:
            
            return{
                ...state,
                theslices: null,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLSLICESPUBLICALLY_FAIL:
            
            return{
                ...state,
                theslices: null,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLSLICES_SUCCESS:
            return{
                ...state,
                theslices: action.payload[0].object?.tags,
                toastsTag: action.payload
            }

        case ActionTypes.GET_ALLSLICESPUBLICALLY_SUCCESS:
            return{
                ...state,
                theslices: action.payload[0].object?.tags,
                toastsTag: action.payload
            }

        /* DEPRECATED: < 2.4.5 : Old version of what we use to store posts. Was getting a weird error
        case ActionTypes.GET_SLICES_SUCCESS:
            //console.log("From postReducer: GET_SLICES_SUCCESS: posts: "+action.payload[0].object?.tags)
            return{
                ...state,
                theposts: action.payload[0].object?.tags,
                toastsTag: action.payload
            }
        */
        // #region ------------------[ Slice  Details ]------------------------------------------------------------------------
        // #region -------------------[ Slice Author ] ------------------------------------------------------------------------
        case ActionTypes.GET_SLICEAUTHOR_SUCCESS:
            return{
                ...state,
                sliceAuthor: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICEAUTHOR_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }            
        // #endregion --------------------------------------------------------------------------------------------------------
        // #region -------------------[ Slice Comments ] ----------------------------------------------------------------------
        case ActionTypes.GET_SLICECOMMENTS_SUCCESS:
            return{
                ...state,
                sliceComments: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICECOMMENTS_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }            
        // #endregion --------------------------------------------------------------------------------------------------------
        // #region -------------------[ Slice Likes ] -------------------------------------------------------------------------
        case ActionTypes.GET_SLICELIKES_SUCCESS:
            return{
                ...state,
                sliceLikes: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICELIKES_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }            
        // #endregion --------------------------------------------------------------------------------------------------------
        // #region -------------------[ Slice Shares ] ------------------------------------------------------------------------
        case ActionTypes.GET_SLICESHARES_SUCCESS:
            return{
                ...state,
                sliceShares: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICESHARES_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }            
        // #endregion --------------------------------------------------------------------------------------------------------
        // #region -------------------[ Slice Views ] -------------------------------------------------------------------------
        case ActionTypes.GET_SLICEVIEWS_SUCCESS:
            return{
                ...state,
                sliceViews: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICEVIEWS_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }      
            
        case ActionTypes.GET_SLICEVIEWSVISITORS_SUCCESS:
            return{
                ...state,
                sliceViewsVisitors: action.payload,
                toastsTag: action.payload
            }
        case ActionTypes.GET_SLICEVIEWSVISITORS_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }     
        // #endregion --------------------------------------------------------------------------------------------------------
        // #endregion --------------------------------------------------------------------------------------------------------
        case ActionTypes.GET_SLICE_SUCCESS:
            return{
                ...state,
                currentSlice: action.payload.data.slice[0],
                toastsTag: action.payload
            }
        
        case ActionTypes.GET_SLICE_FAIL:
            return{
                ...state,
                currentSlice: null,
                toastsTag: action.payload
            }
            
        case ActionTypes.GET_SLICEBYSLUG_SUCCESS:
            return{
                currentSlice: action.payload[0].object.tags[0],
                sliceAuthor: action.payload[0].object.tags[0].authorDetails,
                toastsTag: action.payload
            }

        case ActionTypes.GET_SLICEBYSLUG_FAIL:
            return{
                ...state,
                currentSlice: null,
                toastsTag: action.payload
            }
             
        case ActionTypes.GET_SLICEFROMSLUG_SUCCESS:
            return{
                currentSlice: action.payload[0].object.tags[0],
                sliceAuthor: action.payload[0].object.tags[0].authorDetails,
                SliceFormIsDisabled: true,
                toastsCode: action.payload
            }

        case ActionTypes.GET_SLICEFROMSLUG_FAIL:
            return{
                ...state,
                currentSlice: null,
                toastsCode: action.payload
            }

        case ActionTypes.NEW_SLICE_SUCCESS:
            let theslices = state.theslices ? state.theslices : []

            return{
                ...state,
                currentSlice: action.payload[0].object.newTag,
                isCreatingSliceSentSuccess: true,
                sliceCreated: true, // dont use it?                 
                theslices: [...theslices, action.payload[0].object.newTag],
                toastsTag: action.payload
            }

        case ActionTypes.NEW_SLICE_FAIL:
            return{
                ...state,
                isCreatingSliceSent: true,
                toastsTag: action.payload
            }
            
        case ActionTypes.NEW_INTERACTION_SUCCESS:
            return{
                ...state,  
                toastsTag: action.payload              
            }

        case ActionTypes.INTERACTION_FAIL:
            return{
                ...state,
                toastsTag: action.payload
            }        
        
        case ActionTypes.UPDATE_SLICE:
            //console.log(action.payload[0])
            return {
                ...state,
                //theslices: action.payload[0].object?.tags,
                //isUpdatingSlice: false, /* DEPRECATED: Let notifications handle this */
                currentSlice: action.payload[0].object.tags,
                SliceFormIsDisabled: true,
                toastsTag: action.payload
            }
            
        case ActionTypes.UPDATE_SLICE_FAIL:

            return {
                ...state,
                theslices: action.payload[0].object?.tags,                
                toastsTag: action.payload
            }

        case ActionTypes.SLICE_DELETE:
            return {
                ...state,
                isPermDeletingSliceSentSuccess: true,
                hasDeletedSlice: true,
                toastsPost: action.payload
            }
            
        case ActionTypes.SLICE_DELETE_FAIL:
            return {
                ...state,
                isPermDeletingSliceSent: true,
                toastsTag: action.payload
            }

        case ActionTypes.GET_SLICE_BY_ID:
            return {
                ...state,
                currentSlice: state.slices ? state.slices.find(slice => slice._id === action.payload) : null
            }

        // #region ----------------------[ SETTERS ]-----------------------------------------------------------------------------------------    
        case ActionTypes.SET_CURRENTSLICE:
            return {
                ...state,
                currentSlice: action.payload,
                toastsTag: null
            }
        
        case ActionTypes.SET_SELECTEDTAGS:
            return {
                ...state,
                selectedTags: action.payload
            } 

        case ActionTypes.SET_SLICE_HASDELETEDSLICE:
            return {
                ...state,
                hasDeletedSlice: action.payload,
                toastsTag: null
            }
        
        case ActionTypes.SET_SLICE_HASMOVEDSLICETOTRASH:
            return {
                ...state,
                hasMovedSliceToTrash: action.payload,
                toastsTag: null
            }

        case ActionTypes.SET_SLICE_HASRESTOREDSLICE:
            return {
                ...state,
                hasRestoredSlice: action.payload,
                toastsTag: null
            }

        case ActionTypes.SET_SLICE_ISMOVINGTOTRASH:
            return {
                ...state,
                SliceIsMovingToTrash: action.payload,
                theslices: state.theslices, //idk if this works.
                toastsTag: null
            }

        case ActionTypes.SET_SLICEIMAGE:
            return {
                ...state,
                SliceImage: action.payload,
                toastsTag: null
            }

        case ActionTypes.SET_ISCREATINGSLICE:
            return {
                ...state,
                isCreatingSlice: action.payload,
                toastsPost: null
            }

        case ActionTypes.SET_ISCREATINGSLICESENT:
            return {
                ...state,
                isCreatingSliceSent: action.payload
            }
            
        case ActionTypes.SET_ISCREATINGSLICESENTSUCCESS:
            return {
                ...state,
                isCreatingSliceSentSuccess: action.payload
            }
        
        case ActionTypes.SET_ISSLICEPERMDELETING:
            return {
                ...state,
                isPermDeletingSlice: action.payload
            }
            
        case ActionTypes.SET_ISSLICEPERMDELETINGSENT:
            return {
                ...state,
                isPermDeletingSliceSent: action.payload
            }

        case ActionTypes.SET_ISSLICEPERMDELETINGSENTSUCCESS:
            return {
                ...state,
                isPermDeletingSliceSentSuccess: action.payload
            }

        case ActionTypes.SET_ISSLICERESTORING:
            return {
                ...state,
                isRestoringSlice: action.payload,
                toastsPost: null
            }
        
        case ActionTypes.SET_NEW_SLICE_SLUG:
            return {
                ...state,
                NewSliceSlug: action.payload,
                toastsPost: null
            }

        case ActionTypes.SET_PERM_DELETE_SLICE_SLUG:
            return {
                ...state,
                PermDeleteSliceSlug: action.payload
            }

        case ActionTypes.SET_SLICEDELETINGDATA:
            return {
                ...state,
                SliceDeletingData: action.payload
            }

        case ActionTypes.SET_SLICE_FORM_IS_DISABLED:
            return {
                ...state,
                SliceFormIsDisabled: action.payload
            }
        
        case ActionTypes.SET_SLICERESTORINGDATA:
            return {
                ...state,
                SliceRestoringData: action.payload
            }
        
        case ActionTypes.SET_ISUPDATING_SLICE:
            return {
                ...state,
                isUpdatingSlice: action.payload,
                toastsTag: null
            }

        case ActionTypes.SET_SLICEITEMDISPLAY:
            return {
                ...state,
                sliceItemDisplay: action.payload
            }

        case ActionTypes.SET_ISUPDATING_SLICE_SENT:
            return {
                ...state,
                isUpdatingSliceSent: action.payload,
                toastsTag: null
            }
        
        case ActionTypes.SET_ISUPDATING_SLICE_SENT_SUCCESS:
            return {
                ...state,
                isUpdatingSliceSentSuccess: action.payload,
                toastsTag: null
            }

        case ActionTypes.SET_SELECTEDCATEGORIES:
            return {
                ...state,
                selectedCategories: action.payload
            } 
        
        case ActionTypes.SET_UPLOADFILES:
            return {
                ...state,
                uploadFiles: action.payload,
                toastsTag: null
            }
        // #endregion -----------------------------------------------------------------------------------------------------------------------
        default:
            return state;
    }
}