// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import the GlobalContext
import { GlobalContext } from '../context/globalContext'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, useSetting } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from React
import { useContext } from 'react'

// Import from MUI
import {
    
} from '@mui/material'

import {Zoom, Slide, Bounce, Flip} from 'react-toastify'

import { LookupLicon } from '../libraries/licons'

// Import utils
import { settingLookup } from '../middleware/utils'

// #region -----------------------[ Functions ]-------------------------------------------------------------------
function transitionAnimation () {
    const list = [Zoom, Slide, Bounce, Flip];
    return list[Math.floor(Math.random() * list.length)]
}

function transitionPosition () {
    const list = ['top-right', 'top-center', 'top-left']
    return list[Math.floor(Math.random() * list.length)]
}
// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function HeaderOnlyTS (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // Get user logged
    // eslint-disable-next-line
    const {
        
    } = useAuth()   

    const {
        // Variables        
        variables

        // Functions        
    } = useSetting()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // ThemeSystem
    const { theme, themeSwitchHandler } = useContext(GlobalContext)
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Handles ]--------------------------------------------------------------------------
    const handleReturnHome = () => {
        navigate("/")
    }
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------
    // State Rendering
    useEffect(() => {
        // Save to local storage
        window.localStorage.setItem("theme", theme)
    }, [ theme ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomToastContainer
            position={transitionPosition()} autoClose={2000}
            hideProgressBar={false} newestOnTop closeOnClick
            rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover
            transition={transitionAnimation()}
        />
        <props.skeleton.NavBar stickytop aria-label="props.theme.sys.modules.navbar">
            <props.globalskeleton.CustomContainer>
                {
                    (props.license) 
                    ? (
                        (props.license[0].object) 
                        ? (
                            (!props.license[0].object.license.valid)
                            ? (
                                <props.skeleton.Bar sx={{ background: '#191919', color: 'red', padding: '10px', margin: '0 auto', width: '100%' }}>
                                    WARNING: This installation of {settingLookup('SystemName', variables)} is not officially licensed. Proceed with caution! If you are the owner of this site, contact: <props.skeleton.CustomLink sx={{ }} copyrightlink href="https://lumierelabs.org/support" target='_blank'>Lumiere Labs</props.skeleton.CustomLink>.
                                </props.skeleton.Bar>
                            )
                            : null
                        )
                        : (
                            <props.skeleton.Bar sx={{ background: '#191919', color: 'red', padding: '10px', margin: '0 auto', width: '100%' }}>
                                WARNING: This installation of {settingLookup('SystemName', variables)} is not officially licensed. Proceed with caution! If you are the owner of this site, contact: <props.skeleton.CustomLink sx={{ }} copyrightlink href="https://lumierelabs.org/support" target='_blank'>Lumiere Labs</props.skeleton.CustomLink>.
                            </props.skeleton.Bar>
                        )
                    )
                    : (
                        <props.skeleton.Bar sx={{ background: '#191919', color: 'red', padding: '10px', margin: '0 auto', width: '100%' }}>
                            WARNING: This installation of {settingLookup('SystemName', variables)} is not officially licensed (no license detected). Proceed with caution! If you are the owner of this site, contact: <props.skeleton.CustomLink sx={{ }} copyrightlink href="https://lumierelabs.org/support" target='_blank'>Lumiere Labs</props.skeleton.CustomLink>.
                        </props.skeleton.Bar>
                    )
                }
                <props.skeleton.Bar>
                    <props.globalskeleton.Flex center="true">
                        <props.skeleton.CustomGrid container spacing={0} sx={{ marginLeft: '10px' }}>
                            <props.skeleton.CustomGrid item xs={3} left>
                                <props.skeleton.Bar button="true" padding10lronly="true" aria-label="props.theme.sys.modules.bar" onClick={() => handleReturnHome()}>{LookupLicon("BackIcon")} Return Home</props.skeleton.Bar>
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={6} center="true">
                                {
                                    // #region ----------------------[ 2. The onChange prop of the switch button toggles the theme between dark and light ]-------------------------------
                                    // NOTE: Better way would be to create a separate button to call for different themes as we already have a switch-case statement to select the theme in
                                    //       Layout.js
                                    <props.skeleton.SwitchButton>
                                        <input
                                            type='checkbox'
                                            onChange={
                                                () => themeSwitchHandler(theme === "dark" ? "light" : "dark")
                                            }
                                            checked={
                                                (theme === "dark") ? "checked" : ""
                                            }
                                            
                                        />
                                        <span></span>                                
                                    </props.skeleton.SwitchButton>
                                    // #endregion ----------------------------------------------------------------------------------------------------------------------------------------
                                }
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={3} right>
                                
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    </props.globalskeleton.Flex>
                </props.skeleton.Bar>
            </props.globalskeleton.CustomContainer>
        </props.skeleton.NavBar>
        </>
    )
}