import * as avatar from './avatar/config.ts'
import * as base from './base/config.ts'
import * as bar from './bar/config.ts'
import * as box from './box/config.ts'
import * as customlink from './customlink/config.ts'
import * as drawer from './drawer/config.ts'
import * as footer from './footer/config.ts'
import * as gem from './gem/config.ts'
import * as grid from './grid/config.ts'
import * as header from './header/config.ts'
import * as inputadornment from './inputadornment/config.ts'
import * as item from './item/config.ts'
import * as menu from './menu/config.ts'
import * as navbar from './navbar/config.ts'
import * as notification from './notification/config.ts'
import * as llswitch from './llswitch/config.ts'
import * as tabs from './tabs/config.ts'
import * as telinput from './telinput/config.ts'
import * as textfield from './textfield/config.ts'
import * as typography from './typography/config.ts'

export const modules = {
    ...avatar,
    ...base,
    ...bar,
    ...box,
    ...customlink,
    ...drawer,
    ...footer,
    ...header,
    ...gem,
    ...grid,
    ...inputadornment,
    ...item,
    ...llswitch,
    ...menu,
    ...navbar,
    ...notification,
    ...tabs,
    ...telinput,
    ...textfield,
    ...typography,
}   