// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'

// Import utils
// eslint-disable-next-line
import { DeTemps } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// eslint-disable-next-line
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------


export function SiteTraffic (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Hard code for now; database later
    const trafficLast24hours = [
        { 
            id: 1,
            slug: "visitor",
            dateandtime: "2024-05-01T19:53:40.005+00:00",
        },
        { 
            id: 2,
            slug: "visitor",
            dateandtime: "2024-03-15T19:53:40.005+00:00",
        },
        { 
            id: 3,
            slug: "visitor",
            dateandtime: "2024-04-15T19:53:40.005+00:00",
        },
        { 
            id: 4,
            slug: "visitor",
            dateandtime: "2024-04-14T19:53:40.005+00:00",
        },
        { 
            id: 5,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
    ]

    const trafficAllTime = [
        { 
            id: 1,
            slug: "visitor",
            dateandtime: "2024-05-01T19:53:40.005+00:00",
        },
        { 
            id: 2,
            slug: "visitor",
            dateandtime: "2024-03-15T19:53:40.005+00:00",
        },
        { 
            id: 3,
            slug: "visitor",
            dateandtime: "2024-04-15T19:53:40.005+00:00",
        },
        { 
            id: 4,
            slug: "visitor",
            dateandtime: "2024-04-14T19:53:40.005+00:00",
        },
        { 
            id: 5,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 6,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 7,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 8,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 9,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 10,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 11,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 12,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 13,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 14,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 15,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 16,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 17,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 18,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 19,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 20,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 21,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 22,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 23,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 24,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        },
        {
            id: 25,
            slug: "visitor",
            dateandtime: "2024-04-13T19:53:40.005+00:00",
        }
    ]

    // Sort posts with highest likes
    //traffic.sort(dynamicSort("-likes"))

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions
    
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {/* #region --[ Module - PostTracker ]-------------------------------------------- */} 
        <props.skeleton.CustomGrid container spacing={0} sx={{  }} rounded="true" roundedamt="15px" border="border1">
            {/* #region -[ Heading ]------------------------------------------------------ */}
            <props.skeleton.CustomGrid item gem="true" color="quarternarydarker" xs={12} sx={{ 
                padding: '13px',
                fontSize: '12px',
                borderBottom: 'thin solid #4d4d4d'
                }}>
                <props.skeleton.Heading h3 nobg>
                    
                </props.skeleton.Heading>
                <props.skeleton.Heading h6 nobg>
                    Site Traffic
                </props.skeleton.Heading>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}

            {/* #region -[ Body ]--------------------------------------------------------- */}
            <props.skeleton.CustomGrid item xs={12} sx={{ 
                
                }}>                                        
                    <props.skeleton.CustomGrid item xs={12} gem="true" color="quarternary" sx={{ fontSize: '12px', padding: '10px', borderBottom: 'thin solid #4d4d4d' }}>
                        <props.skeleton.Heading h7 nobg>
                            Last 24 hours
                        </props.skeleton.Heading>

                        <props.skeleton.Heading h8 nobg>
                            {trafficLast24hours.length} UVs ({ (trafficLast24hours.length / trafficAllTime.length)*100 }%)
                        </props.skeleton.Heading>
                    </props.skeleton.CustomGrid>

                    <props.skeleton.CustomGrid item xs={12} gem="true" color="quarternary" sx={{ fontSize: '12px', padding: '10px', borderBottom: 'thin solid #4d4d4d' }}>
                        <props.skeleton.Heading h8 nobg>
                            All Time
                        </props.skeleton.Heading>

                        <props.skeleton.Heading h7 nobg>
                            {trafficAllTime.length} UVs
                        </props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}
            
        </props.skeleton.CustomGrid>
        {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}