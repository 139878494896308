export const paper = {
    base: {
        background: "#ff9999",
        color: "black"
    },
    border: {        
        params: {
            border: "thin solid #006b6b"
        },
        styles: {
            one: "4px solid white",
            default: "thin solid cyan"
        },
    },
    bordertopbottom: {
        params: {
            border: "1px solid #474747",
            borderWidth: "1px 0px 1px 0px"
        }
    }, 
}