export const bar = {
    background: "#00b3b3",
    button: {
        base: {
            background: "#00b3b3",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "black",
            colorHover: "cyan",
            colorActive: "cyan"
        },
        darker: {
            background: "#004848",
            backgroundHover: "black",  
            backgroundActive: "black",
            color: "white",
            colorHover: "cyan",
            colorActive: "cyan"
        }
    }
}