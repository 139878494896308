// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'
import { PrettyNumber } from '../../../middleware/utils'
import { LookupLicon } from '../../../libraries/licons'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function CustomWidget (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        currentUser,
        isAuthenticated,
        PublicProfile
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion -----------------------------------------------------------------------------------------------

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions

    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container spacing={0} rounded="true" roundedamt="15px" border={props.border} sx={{ 
            marginTop: ((props.params.margintop && ((isAuthenticated && PublicProfile && currentUser.userName === PublicProfile.userName) || props.params.reqAuth)) ? props.params.margintop : '0px')            
         }}>
            <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                <props.skeleton.CustomTypography h7="true" padding={props.params.titlepadding} margin={props.params.titlemargin} variant="h6">
                    {props.name}
                </props.skeleton.CustomTypography>
            </props.skeleton.CustomGrid>

            <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                <props.skeleton.CustomTypography h8="true" padding={props.params.contentpadding} margin={props.params.contentmargin}>
                    {props.content || `I am a custom widget of type ${props.type}`}                    
                </props.skeleton.CustomTypography>

                <props.skeleton.CustomGrid container spacing={0} nouserselect="true">
                    {(() => {
                        switch (props.type) {
                            case "list":
                                return props.list && props.list.length > 0 ? (
                                    props.list.map((list, key) => (
                                        <props.skeleton.CustomGrid
                                            gem="true"
                                            color="quarternary"
                                            nouserselect="true"
                                            key={key}
                                            item
                                            xs={12}
                                            sx={{ 
                                                margin: '0px 0px 0px 0px',
                                                padding: '0px 0px 0px 0px'
                                             }}
                                        >
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid
                                                    nouserselect="true"
                                                    onClick={() => navigate(`/post/${list.slug}`)}
                                                    button="true"
                                                    item
                                                    xs={9}
                                                    sx={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <props.skeleton.CustomTypography
                                                        h9="true"
                                                        sx={{ width: '100%', background: 'none' }}
                                                        padding={'5px 0px 0px 10px'}
                                                        margin={'0px 0px 0px 0px'}
                                                    >
                                                        {list.title}
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid
                                                    button="true"
                                                    item
                                                    xs={3}
                                                    nouserselect="true"
                                                    sx={{
                                                        textAlign: 'right',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <props.skeleton.CustomTypography
                                                        h9="true"
                                                        sx={{ width: '100%', background: 'none' }}
                                                        padding={'5px 0px 0px 10px'}
                                                        margin={'0px 10px 0px 0px'}
                                                    >
                                                        {PrettyNumber(list.likes, "+")}{" "}
                                                        {LookupLicon("ThumbsUpIcon")}
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    ))
                                ) : (
                                    <props.skeleton.CustomTypography
                                        sx={{ width: '100%' }}
                                        h9="true"
                                        padding={'10px 0px 0px 10px'}
                                        margin={'0px 0px 0px 0px'}
                                    >
                                        No data 
                                    </props.skeleton.CustomTypography>
                                )

                            case "data":
                                return props.data && props.data.length > 0 ? (
                                    props.data.map((data, key) => (
                                        <props.skeleton.CustomGrid
                                            gem="true"
                                            color="quarternary"
                                            nouserselect="true"
                                            key={key}
                                            item
                                            xs={12}
                                            sx={{ 
                                                margin: '0px 0px 0px 0px',
                                                padding: '0px 0px 0px 0px'
                                            }}
                                        >
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid
                                                    button="true"
                                                    nouserselect="true"
                                                    item
                                                    xs={9}
                                                    sx={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '0px 0px 0px 10px'
                                                    }}
                                                >
                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                        <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                                                            <props.skeleton.CustomTypography
                                                                h9="true"
                                                                sx={{ width: '100%', background: 'none' }}
                                                                padding={'0px 0px 0px 0px'}
                                                                margin={'0px 0px 0px 0px'}
                                                            >
                                                                Post:{" "}
                                                                <props.skeleton.CustomLink
                                                                    onClick={() => navigate(`/post/${data.slug}`)}
                                                                    nobg="true"
                                                                    nohoverbg="true"
                                                                    smallertext="true"
                                                                >
                                                                    {data.postTitle}
                                                                </props.skeleton.CustomLink>
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomGrid>

                                                        <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                                                            <props.skeleton.CustomTypography
                                                                h9="true"
                                                                sx={{ width: '100%', background: 'none' }}
                                                                padding={'0px 0px 0px 0px'}
                                                                margin={'0px 0px 0px 0px'}
                                                            >
                                                                {data.content}
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomGrid>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid
                                                    nouserselect="true"
                                                    button="true"
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        textAlign: 'right',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '0px 10px 0px 0px'
                                                    }}
                                                >
                                                    <props.skeleton.CustomTypography
                                                        h9="true"
                                                        sx={{ width: '100%', background: 'none' }}
                                                        padding={'5px 0px 0px 10px'}
                                                        margin={'0px 0px 0px 0px'}
                                                    >
                                                        {PrettyNumber(data.likes, "+")}{" "}
                                                        {LookupLicon("ThumbsUpIcon")}
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    ))
                                ) : (
                                    <props.skeleton.CustomTypography
                                        sx={{ width: '100%' }}
                                        h9="true"
                                        padding={'10px 0px 10px 10px'}
                                        margin={'0px 0px 0px 0px'}
                                    >
                                        Nothing
                                    </props.skeleton.CustomTypography>
                                )

                            case "pet":
                                return props.data && props.data.length > 0 ? (
                                    props.data.map((data, key) => (
                                        <props.skeleton.CustomGrid
                                            gem="true"
                                            color="quarternary"
                                            nouserselect="true"
                                            key={key}
                                            item
                                            xs={12}
                                            sx={{ 
                                                margin: '0px 0px 0px 0px',
                                                padding: '0px'
                                            }}
                                        >
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid
                                                    nouserselect="true"
                                                    button="true"
                                                    item
                                                    xs={8}
                                                    sx={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <props.skeleton.CustomGrid container spacing={0}>
                                                        <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                                                            <props.skeleton.CustomTypography
                                                                h9="true"
                                                                sx={{ width: '100%', background: 'none', display: 'flex', alignItems: 'center' }}
                                                                padding={'10px 10px 10px 10px'}
                                                                margin={'0px 0px 0px 0px'}
                                                            >
                                                                {" "}
                                                                <props.skeleton.CustomLink
                                                                    onClick={() => navigate(`/pet/${data.slug}`)}
                                                                    nobg="true"
                                                                    nohoverbg="true"
                                                                >
                                                                    {data.name}
                                                                </props.skeleton.CustomLink>
                                                            </props.skeleton.CustomTypography>
                                                            <props.skeleton.CustomTypography
                                                                h9="true"
                                                                sx={{ width: '100%', background: 'none', display: 'flex', alignItems: 'center' }}
                                                                padding={'10px 10px 10px 10px'}
                                                                margin={'0px 0px 0px 0px'}
                                                            >
                                                                Species: {data.species}
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomGrid>

                                                        <props.skeleton.CustomGrid item xs={12} nouserselect="true">
                                                            <props.skeleton.CustomTypography
                                                                h9="true"
                                                                sx={{ width: '100%', background: 'none' }}
                                                                padding={'0px 0px 0px 0px'}
                                                                margin={'0px 0px 0px 0px'}
                                                            >
                                                                {data.content}
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomGrid>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid
                                                    nouserselect="true"
                                                    button="true"
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        textAlign: 'right',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <props.skeleton.CustomTypography
                                                        h9="true"
                                                        sx={{ width: '100%', background: 'none' }}
                                                        padding={'10px 10px 10px 10px'}
                                                        margin={'0px 0px 0px 0px'}
                                                    >
                                                        Level {data.level}{" "}
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    ))
                                ) : (
                                    <props.skeleton.CustomTypography
                                        sx={{ width: '100%' }}
                                        h9="true"
                                        padding={'10px 0px 10px 10px'}
                                        margin={'0px 0px 0px 0px'}
                                    >
                                        No Pets
                                    </props.skeleton.CustomTypography>
                                )

                            default:
                                return null;
                        }
                    })()}
                </props.skeleton.CustomGrid>

            </props.skeleton.CustomGrid>
        </props.skeleton.CustomGrid>        
        </>
    )
}