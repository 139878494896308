// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, usePost } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import addons
import { Spacer } from '../Spacer'

// Import BreadCrumbs
//import { BreadCrumbs } from '../../BreadCrumbs'

// Import custom hook
import useE from '../../../middleware/hooks'

// Import utils
import { PrettyNumber } from '../../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'
import { LookupLicon } from '../../../libraries/licons'

// Import Modules
import { Feed } from '../../Modules/Profile/Feed'
import { AccountButtons } from '../../Modules/Profile/AccountButtons'
import { CustomWidget } from '../../Modules/Profile/CustomWidget'

// Import pagination
import usePagination from '../../../components/Pagination'

// Import proptypes for tabs
import { PropTypes } from 'prop-types'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// ------- Outside functions -------------------------------------------------------------------------------------
function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

// Function for creating tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal.tabpanel-${index}`}
        aria-labelledby={`horizontal.tab-${index}`}
        {...other}
      >
        {value === index && (
          <props.skeleton.CustomBox sx={{ p: 0, width: '100%' }}>
            <props.skeleton.CustomBox sx={{ width: '100%' }}>{children}</props.skeleton.CustomBox>
          </props.skeleton.CustomBox>
        )}
      </div>
    )
}

// Set the propsTypes for tabpanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
// ------- End Outside functions ---------------------------------------------------------------------------------

export function PProfile (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        // Functions
        //CheckMyBalance,
        FollowUser,
        getAllUsersPublically,        
        getFollowers,  
        getFollowing,
        getFriends,
        getPublicProfile,                
        GiveToUser,
        setFollowerCount,
        setFollowingCount,
        setFriendsCount,
        setFollowers,
        setFollowing,
        setFriends,
        setPublicProfile,
        //setMyBalance,
        UnfollowUser,        

        // Variables
        //AllUsers,
        myBalance,
        currentUser,
        //Followers,
        FollowerCount,
        //Following,
        FollowingCount,        
        //Friends,
        FriendsCount,
        isAuthenticated,
        isFollowing,
        isFriend,     
        PublicProfile
    } = useAuth()   

    const { 
        // Functions
        getAllSlicesByUserNamePublically,
        LikePost,
        setTheSlices,

        // Variables
        theslices
    } = usePost()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    const params = props.params

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Pagination - posts per page
    const PER_PAGE = 6

    // Pagination - count
    // eslint-disable-next-line
    const count = Math.ceil((theslices?.length / PER_PAGE)) || 10

    // Tell data to use the pagination
    // eslint-disable-next-line
    const _DATA = usePagination(theslices, PER_PAGE)

    // Page for pagination
    // eslint-disable-next-line
    let [ page, setPage ] = useState(1)

    // Define user here
    // eslint-disable-next-line
    const [ theuser, setTheUser ] = useState(PublicProfile)

    // Set tabValue
    const [tabValue, setTabValue] = useState(0)
    
    // Enable gamification here
    // eslint-disable-next-line
    const [ useGamification, setUseGamification ] = useState(true)

    // Define gamification settings
    // eslint-disable-next-line
    const [ currencyName, setCurrencyName ] = useState('Qoin')

    // For give modal
    const [ giveInput, setGiveInput ] = useState('')

    // For sending currency
    const [ sendingCurrency, setSendingCurrency ] = useState(false)

    const [sendingCurrencyData, setSendingCurrencyData] = useState({
        amount: 0,
        whom: ''
    })
    
    // Set up breadcrumbs here
    // eslint-disable-next-line
    const BCItems = [
        {
            'name': '@',
            'type': 'text',
            'grayed': true,
            "url": '/',
        }, 
        {
            'name': 'User',
            'type': 'link',
            'grayed': true,
            'url': '/@/'
        },
        {
            'name': (PublicProfile) ? PublicProfile.userName : "Loading...",
            'type': 'text',
            'grayed': false,
            'url': '/@/'+PublicProfile.userName
        },
    ]

    const buttons = [
        '1', '2', '3',
        '4', '5', '6',
        '7', '8', '9',
        '*', '0', '#'
    ]    

    const MyPopularPosts = [
        { 
            id: 1,
            slug: 'my-best-post-ever',
            title: 'My best post ever',
            likes: 2393
        },
        { 
            id: 2,
            slug: 'my-second-best-post-ever',
            title: 'My second best post ever',            
            likes: 908
        },
        { 
            id: 3,
            
            slug: 'css-and-it--semicolon--s-many-hacks',
            title: 'CSS and it\'s many hacks',
            likes: 585
        },
        { 
            id: 4,
            slug: 'cats--colon---how-to-tell-when-they-want-something',
            title: 'Cats: How to tell when they want something',
            likes: 150
        },
        { 
            id: 5,
            slug: 'hacker-world---colon---zero-day',
            title: 'Hacker World: Zero-Day',
            likes: 50
        }
    ]

    const MostRecentComment = [
        {
            id: 1,
            content: 'FJB. Trump 2024!',
            slug: 'biden-will-win-2024',
            postTitle: 'Biden will win 2024',
            likes: 23
        }
    ]

    const myPet = [
        {
            id: 1,
            name: 'Kirby',
            level: 140,
            slug: `petpage-${theuser.userName}-kirby`,
            species: 'fox'
        },
        {
            id: 2,
            name: 'Rogue',
            level: 25,
            slug: `petpage-${theuser.userName}-rogue`,
            species: 'sable/otter'
        }
    ]

    const Modules = [
        {
            id: 1,
            thename: 'AccountButtons',
            componentCode: <AccountButtons name="Account Actions" border="border1" params={{ reqAuth: true, margintop: "0px", padding: "8px" }} skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
            location: 'left'
        },        
        {
            id: 2,
            thename: 'Most Popular Posts',
            componentCode: <CustomWidget name="Most Popular Posts" border="border1" list={MyPopularPosts} params={{ reqAuth: false, margintop: "15px", contentmargin: "0px 0px 0px 0px", contentpadding: "8px", titlemargin: "0px 0px 0px 0px", titlepadding: "8px" }} content="Some posts I'm famous for" type="list" skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
            location: 'left'
        },
        {
            id: 3,
            thename: 'Most Recent Comment',
            componentCode: <CustomWidget name="Most Recent Comment" border="border1" data={MostRecentComment} params={{ reqAuth: true, margintop: "15px", contentmargin: "0px 0px 0px 0px", contentpadding: "8px", titlemargin: "0px 0px 0px 0px", titlepadding: "8px" }} type="data" content="Here is my most recent comment" skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
            location: 'left'
        },
        {
            id: 4,
            thename: 'CustomWidgetList',
            componentCode: <CustomWidget name="CustomWidget List" border="border1" params={{ reqAuth: true, margintop: "0px", contentmargin: "0px 0px 0px 0px", contentpadding: "8px", titlemargin: "0px 0px 0px 0px", titlepadding: "8px" }} type="list" skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
            location: 'right'
        },        
        {
            id: 5,
            thename: 'My Pet',
            componentCode: <CustomWidget name="My Pet" border="border1" data={myPet} params={{ reqAuth: true, margintop: "15px", contentmargin: "0px 0px 0px 0px", contentpadding: "8px", titlemargin: "0px 0px 0px 0px", titlepadding: "8px" }} content="Here are my pets" type="pet" skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
            location: 'right'
        },

    ]

    const tabsections = [
        {
            id: 0, 
            title: 'Posts',
            name: 'posts',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Posts",
                    component: <Feed skeleton={props.skeleton} theuser={theuser} type="profile" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },      
        {
            id: 1, 
            title: 'Pages',
            name: 'pages',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Pages",
                    component: <Feed skeleton={props.skeleton} theuser={theuser} type="pages" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },    
        {
            id: 3, 
            title: 'Groups',
            name: 'groups',
            enabled: true,
            modules: [
                {
                    id: 1,
                    name: "Groups",
                    component: <Feed skeleton={props.skeleton} theuser={theuser} type="groups" settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />,
                    enabled: true,
                }     
            ]
        },        
    ]

    // eslint-disable-next-line
    const UseBorder = false
    // eslint-disable-next-line
    const Border = 'thin dashed black'

    // State variables
    // Give Modal
    const [ giveModalOpen, setGiveModalOpen ] = useState(false)
    const [ sentGive, setSentGive ] = useState(false)

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle back to users 
    const handleBackToUsers = () => {
        // Clear PublicProfile
        setPublicProfile(null)

        // Clear the followers
        setFollowers(null)

        // Clear the follower count
        setFollowerCount(0)

        // Clear the followers
        setFollowing(null)

        // Clear the following count
        setFollowingCount(0)

        // Clear the friends
        setFriends(null)

        // Clear the friends count
        setFriendsCount(0)

        // Clear the slices
        setTheSlices(null)

        // Reset RetrievedPublicProfile
        //setRetrievedPublicProfile(false)

        // Navigate
        navigate("/@")
    }
    
    // Handle Cancel Give
    const handleCancelGiveToUser = () => {
        if (useGamification) {
            //toast.error('You cannot give to yourself', { theme: 'light' })

            // Reset input
            setGiveInput('')

            // Close the modal
            setGiveModalOpen(false)
        } else {
            toast.error('Gamification is not enabled', { theme: 'light' })
        }
    }
    
    // Handle Close Give
    const handleCloseGiveToUser = () => {
        if (useGamification) {
            //toast.error('You cannot give to yourself', { theme: 'light' })

            // Reset input
            setGiveInput('')

            // Reset sentGive
            setSentGive(false)

            // Open the modal
            setGiveModalOpen(false)
        } else {
            toast.error('Gamification is not enabled', { theme: 'light' })
        }
    }

    // Handle for Change Header Image
    const handleChangeHeaderImage = () => {
        toast.info(`WIP: Change Header Image right here`, {theme: 'light'})
    }

    // Handle for Editing Profile
    const handleEditProfile = () => {
        toast.info(`WIP: Edit profile right here`, {theme: 'light'})
    }

    // Handle for Following
    const handleFollowUser = (whom) => {
        if (currentUser && currentUser.userName !== whom) {
            FollowUser(whom)
        } else if (currentUser && currentUser?.userName === whom) {
            toast.error('You cannot follow yourself', { theme: 'light' })
        } else {
            toast.error('You must be logged in to follow someone.', {theme: 'light'})
        }
    }
    
    //  Handle for Give numpad
    const handleGiveClick = (value) => {
        setGiveInput(giveInput + value)
    }

    // Handle for UnFollow
    const handleUnFollowUser = (whom) => {
        if (currentUser && currentUser.userName !== whom) {
            UnfollowUser(whom)
        } else {
            toast.error('You must be logged in to follow someone.', {theme: 'light'})
        }
    }

    // Handle Give
    const handleGiveToUser = () => {
        if (useGamification) {
            //toast.error('You cannot give to yourself', { theme: 'light' })

            // Open the modal
            setGiveModalOpen(true)
        } else {
            toast.error('Gamification is not enabled', { theme: 'light' })
        }
    }

    // Handle Report
    const handleReportUser = () => {
        if (currentUser) {
            if (currentUser.userName === theuser.userName) {
                toast.error(`Why do you want to report yourself?`, { theme: 'light'})
            } else {
                toast.info(`WIP: You have reported ${theuser.userName}!`, { theme: 'light' })
            }
        }
    }

    // Handle Send Give
    const handleSendGiveToUser = (bal) => {
        if (currentUser.userName !== theuser.userName) {
            if (giveInput === "") {
                toast.info(`Amount cannot be blank`, { theme: 'light'})
            } else {
                let amount = parseInt(giveInput)
                let balance = parseInt(bal.replace(/,/g, ''), 10)
    
                if (balance >= amount) {
                    setSentGive(true)
    
                    setSendingCurrency(true)
                    setSendingCurrencyData({...sendingCurrencyData, whom: theuser.userName, amount: amount})
    
                    //toast.info(`WIP: Gave ${amount.toLocaleString()} ${currencyName} to user.`, { theme: 'light'})
    
                    //toast.info(`NOTE: This was only a test and you did not just send ${amount.toLocaleString()} ${currencyName} to yourself.`, { theme: 'light'})
                } else {
                    toast.error(`You cannot afford to do that!`, { theme: 'light'})
                }
            }
        } else {
            toast.error(`You cannot send ${currencyName} to yourself!`, { theme: 'light'})
        }
    }

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }


    // Handle for Your Friend button
    const handleYourFriend = () => {
        if (currentUser) {
            if (isFriend) {
                toast.info(`${theuser.userName} is your friend!`, {theme: 'light'})
            }
        }
    }

    // Handle Unfollow
    // eslint-disable-next-line
    const handleUnfollowUser = (whom) => {
        if (currentUser) {
            UnfollowUser(whom)
        } else {
            toast.error('You must be logged in to unfollow someone.', {theme: 'light'})
        }
    }

    function allyProps(index) {
        return {
          id: `horizontal.tab-${index}`,
          'aria-controls': `horizontal-tabpanel-${index}`,
        }
    }

    // State Rendering for getting Public Profile
    useE(2000, false, getPublicProfile, { args: { id: props.id }, isAuthenticated }, props.id)

    // State Rendering for setting Public Profile - Slices
    useE(4000, false, getAllSlicesByUserNamePublically, { args: { id: props.id, type: 'anyposts' }, isAuthenticated }, props.id)
    
    // State Rendering for getting all users
    useE(30000, false, getAllUsersPublically, { isAuthenticated })

    // State Management for getting balance - header already pulls balance, no need to do it here
    //useE(2000, true, CheckMyBalance, { isAuthenticated })

    // State Rendering for getting - followers
    useE(8000, false, getFollowers, { args: { id: theuser.userName }, isAuthenticated })

    // State Rendering for getting - following
    useE(8000, false, getFollowing, { args: { id: theuser.userName }, isAuthenticated })
    
    // State Rendering for getting - friends
    useE(8000, false, getFriends, { args: { id: theuser.userName }, isAuthenticated })

    useEffect(() => {
        if (sendingCurrency) {
            // Send to backend
            //console.log(sendingCurrencyData)
            GiveToUser(sendingCurrencyData)

            // Reset sending currency
            setSendingCurrency(false)
        }
        // eslint-disable-next-line
    }, [ sendingCurrency,sendingCurrencyData ])

    // Debugging
    useEffect(() => {
        //document.body.style.backgroundImage = `url(${window.location.origin+"/user/thumbs/no-headerimage.png"})`
        //console.log(theuser)
    }, [  ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // eslint-disable-next-line
    const functions = {
        LikePost
    }

    // Render
    return (
        <>
        <Spacer length={2} />
        {
            // #region ===[ Section: Title ]=================================================================
            (params.aligntext === "center" &&
                <props.skeleton.CustomTypography variant="h4" props={props} center="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "left" &&
                <props.skeleton.CustomTypography variant="h4" props={props} left="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            ||
            (params.aligntext === "right" &&
                <props.skeleton.CustomTypography variant="h4" props={props} right="true">
                    { params.title }
                </props.skeleton.CustomTypography>
            )
            // #endregion ===[ Section: Title ]==============================================================
        }
        
        {
            // #region ===[ Section: Main Content ]==========================================================
            <props.skeleton.CustomGrid container spacing={0} sx={{ margin: '10px', minHeight: '100vh' }}>
            {
                // #region ==[ Modals ]==================================================================
                
                // #region ==[ Modal: Give ]=============================================================
                <props.skeleton.CustomModal
                    open={giveModalOpen}
                    BackdropProps={{
                        onClick: handleCloseGiveToUser
                    }}
                    onClose={handleCancelGiveToUser}
                    aria-labelledby="Give to User Modal"
                    aria-describedby={`Give Currency to a user`}
                >
                    <props.skeleton.CustomBox modal="true">
                        {
                            (!sentGive) 
                            ? (
                                <>
                                <props.skeleton.CustomTypography id="modal-title" variant="h6" component="h2" center="true">
                                    Give Currency to User
                                </props.skeleton.CustomTypography>
                                
                                <props.skeleton.CustomTypography id="modal-title" variant="h6" component="h2">
                                    Use this to easily give {currencyName} to this user
                                </props.skeleton.CustomTypography>

                                <Spacer length={2} />

                                <props.skeleton.CustomTypography center="true" id="modal-title" variant="h5" component="h2">
                                    Balance
                                </props.skeleton.CustomTypography>
                                
                                <props.skeleton.CustomTypography center="true" id="modal-title" variant="h6" component="h2">
                                    {myBalance}
                                </props.skeleton.CustomTypography>
                                </>
                            )
                            : null
                        }

                        <props.skeleton.CustomBox sx={{
                            width: 300,
                            margin: '0 auto',
                            textAlign: 'center'
                        }}>
                            {
                                (!sentGive)
                                ? (
                                    <>
                                    <props.skeleton.CustomTextField 
                                        value={giveInput}
                                        variant="outlined"
                                        InputProps={{ readOnly: true }}
                                        sx={{ mb: 2 }}
                                    />
                                    <props.skeleton.CustomGrid container spacing={1}>
                                        {buttons.map((value, index) => (
                                            <props.skeleton.CustomGrid item xs={4} key={index}>
                                                <props.skeleton.CustomButton
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() => handleGiveClick(value)}
                                                    sx={{ height: 60 }}
                                                >
                                                    {value}
                                                </props.skeleton.CustomButton>
                                            </props.skeleton.CustomGrid>
                                        ))}
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.Bar button="true" center="true"
                                        variant="contained"
                                        color="error"
                                        sx={{ mt: 2 }}
                                        onClick={() => setGiveInput('')}
                                    >
                                        Clear
                                    </props.skeleton.Bar>

                                    <props.skeleton.Bar button="true" center="true"
                                        variant="contained"
                                        color="error"
                                        sx={{ mt: 2 }}
                                        onClick={() => handleSendGiveToUser(myBalance)}
                                    >
                                        Send
                                    </props.skeleton.Bar>
                                    </>
                                ) 
                                : (
                                    <props.skeleton.CustomTypography variant="h4">
                                        Successfully Sent {parseInt(giveInput).toLocaleString()} {currencyName} to {theuser.userName}!
                                    </props.skeleton.CustomTypography>
                                )
                            }

                        </props.skeleton.CustomBox>

                        {
                            (!sentGive)
                            ? (
                                <props.skeleton.Bar button="true" center="true" onClick={handleCancelGiveToUser} sx={{ mt: 2 }} variant="contained">
                                    Cancel
                                </props.skeleton.Bar>
                            )
                            : (
                                <props.skeleton.Bar button="true" center="true" onClick={handleCloseGiveToUser} sx={{ mt: 2 }} variant="contained">
                                    Close
                                </props.skeleton.Bar>
                            )
                        }

                    </props.skeleton.CustomBox>
                </props.skeleton.CustomModal>
                // #endregion ==[ Modal: Give ]==========================================================
                
                // #endregion ==[ Modals ]===============================================================
            }

            {                                
                // #region ==[ Column: Left ]============================================================
                <props.skeleton.CustomGrid item xs={11.55} sm={11.55} md={11.7} lg={8.8} sx={{ padding: '10px' }}>
                    {
                        // #region =[ Top Header ]=======================================================
                        <props.skeleton.CustomGrid container spacing={0}>
                            <props.skeleton.CustomGrid item xs={12} sx={{  }}>
                                <props.skeleton.ImageContainer rounded="true" roundedamt="15px" border="border1">
                                    {
                                        // Check to see if there is a headerimage; if not display the default
                                        (theuser.headerImage && (theuser.headerImage !== "no-headerimage.png" && theuser.headerImage !== ""))
                                        ? <props.skeleton.StyledImage params={{ borderradius: '10px' }} src={loadUserHeaderImage(theuser.userName, theuser.headerImage, props.settingServerAPIBaseUrl )} alt={`${theuser.userName}'s header image`} />
                                        : <props.skeleton.StyledImage params={{ borderradius: '10px' }} src={window.location.origin+"/user/thumbs/no-headerimage.png"} alt={`${theuser.userName}'s header image`} />
                                    }

                                    {
                                        // #region ----[ Profile Image ]---------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox params={{                                                 
                                            height: '40px', 
                                            left: '20px', 
                                            padding: '0px',                                                 
                                            top: '310px', 
                                        }}>
                                            <props.skeleton.StyledImage params={{ 
                                                borderradius: '60px', // usually 50% of the width 
                                                width: '120px',
                                                height: '100px' // 20px less than width
                                            }} src={loadUserProfileImage(theuser.userName, theuser.profileImage, props.settingServerAPIBaseUrl )} />
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Profile Image ]-------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Disp Name ]--------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox params={{  
                                            backgroundColor: 'rgba(0, 0, 0, 0)',                                               
                                            fontsize: '20px',
                                            height: '40px', 
                                            left: '140px', 
                                            padding: '0px',                                                 
                                            top: '270px', 
                                            width: '500px'
                                        }}>
                                            {
                                            
                                            (theuser.isPrivate) 
                                            ? `${(theuser.displayName) ? theuser.displayName : theuser.userName}`
                                            : `${theuser.firstName} ${theuser.lastName}`
                                            
                                            }{`${'\u00A0'}`}{theuser.isVerified ? LookupLicon("VerifiedIcon") : ""}
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Disp Name ]------------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ User Name ]--------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox params={{  
                                            backgroundColor: 'rgba(0, 0, 0, 0)',                                               
                                            color: '#bababa',
                                            fontsize: '16px',
                                            height: '40px', 
                                            left: '140px', 
                                            padding: '0px',                                                 
                                            top: '290px', 
                                            width: '500px'
                                        }}>
                                            @{theuser.userName}
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ User Name ]------------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Designation ]-------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox params={{  
                                            backgroundColor: 'rgba(0, 0, 0, 0)',                                               
                                            color: 'white',
                                            fontsize: '16px',
                                            height: '40px', 
                                            left: '140px', 
                                            padding: '0px',                                                 
                                            top: '320px', 
                                            width: '500px'
                                        }}>
                                            {`${theuser.designation} at ${theuser.company} in the ${theuser.reality} reality`}
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Designation ]----------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Stats ]-------------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox params={{  
                                            backgroundColor: 'rgba(0, 0, 0, 0)',                                               
                                            color: '#bababa',
                                            fontsize: '14px',
                                            height: '40px', 
                                            left: '140px', 
                                            padding: '0px',                                                 
                                            top: '350px', 
                                            width: '500px'
                                        }}>
                                            {`${PrettyNumber(FollowingCount, "a lot of")} Following ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${PrettyNumber(FollowerCount, "a lot of")} Follower${FollowerCount === 1 ? "" : "s"} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'} ${PrettyNumber(FriendsCount, "a lot of")} Friend${FriendsCount === 1 ? "" : "s"}`}
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Stats ]----------------------------------------------------------------------------------------
                                    }

                                    {
                                        (currentUser) 
                                        ? (
                                            (currentUser.userName === PublicProfile.userName)
                                            ? (
                                                // #region ----[ Edit Btn ]----------------------------------------------------------------------------------------
                                                <props.skeleton.OverlayBox onClick={() => handleEditProfile()} params={{  
                                                    alignitems: 'center',
                                                    backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                    borderradius: '20px',
                                                    button: true,
                                                    color: 'black',
                                                    cursor: 'pointer',
                                                    fontsize: '14px',
                                                    height: '40px',
                                                    right: '300px',
                                                    justifycontent: 'center', 
                                                    padding: '0px',                                                 
                                                    top: '340px', 
                                                    width: '100px'
                                                }}>
                                                    Edit Profile
                                                </props.skeleton.OverlayBox>
                                                // #endregion ----[ Edit Btn ]-------------------------------------------------------------------------------------
                                            )
                                            : null 
                                        )
                                        : null
                                    }

                                    {
                                        // #region ----[ Report Btn ]--------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleReportUser()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 0, 0, 1)', 
                                            borderradius: '20px',
                                            button: true,
                                            color: 'white',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            right: '240px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            top: '340px', 
                                            width: '50px'
                                        }}>
                                            {LookupLicon("WarningIcon")}
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Report Btn ]----------------------------------------------------------------------------------
                                    }
                                    
                                    {
                                        (useGamification)
                                        ? (
                                            // #region ----[ Give Btn ]----------------------------------------------------------------------------------------
                                            <props.skeleton.OverlayBox onClick={() => handleGiveToUser()} params={{  
                                                alignitems: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '130px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '340px', 
                                                width: '100px'
                                            }}>
                                                Give
                                            </props.skeleton.OverlayBox>
                                            // #endregion ----[ Give Btn ]------------------------------------------------------------------------------------
                                        )
                                        : (
                                            // #region ----[ Give Btn (disabled) ]----------------------------------------------------------------------------
                                            <props.skeleton.OverlayBox onClick={() => handleGiveToUser()} params={{  
                                                alignitems: 'center',                                                    
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                disabled: true,
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '130px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '340px', 
                                                width: '100px'
                                            }}>
                                                Give
                                            </props.skeleton.OverlayBox>
                                            // #endregion ----[ Give Btn (disabled) ]------------------------------------------------------------------------
                                        )
                                    }

                                    {
                                        // #region ----[ Follow Btn ]----------------------------------------------------------------------------------------
                                        (isFollowing) 
                                        ? (
                                            <props.skeleton.OverlayBox onClick={() => handleUnFollowUser(theuser.userName)} params={{  
                                                alignitems: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '20px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '340px', 
                                                width: '100px'
                                            }}>
                                                Unfollow
                                            </props.skeleton.OverlayBox>
                                        )
                                        : (
                                            <props.skeleton.OverlayBox onClick={() => handleFollowUser(theuser.userName)} params={{  
                                                alignitems: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '20px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '340px', 
                                                width: '100px'
                                            }}>
                                                Follow
                                            </props.skeleton.OverlayBox>
                                        )

                                        // #endregion ----[ Follow Btn ]----------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Change Header Btn ]------------------------------------------------------------------------------
                                        (currentUser && currentUser.userName === theuser.userName)
                                        ? (
                                            <props.skeleton.OverlayBox onClick={() => handleChangeHeaderImage()} params={{  
                                                alignitems: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '20px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '20px', 
                                                width: '50px'
                                            }}>
                                                {LookupLicon("CameraAltIcon")}
                                            </props.skeleton.OverlayBox>
                                        )
                                        : null
                                        // #endregion ----[ Change Header Btn ]---------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Friend Btn ]-------------------------------------------------------------------------------------
                                        (isFriend) 
                                        ? (
                                            <props.skeleton.OverlayBox onClick={() => handleYourFriend()} params={{  
                                                alignitems: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 1)', 
                                                borderradius: '20px',
                                                button: true,
                                                color: 'black',
                                                cursor: 'pointer',
                                                fontsize: '14px',
                                                height: '40px',
                                                right: '300px',
                                                justifycontent: 'center', 
                                                padding: '0px',                                                 
                                                top: '340px', 
                                                width: '100px'
                                            }}>
                                                Your Friend
                                            </props.skeleton.OverlayBox>
                                        )
                                        : null

                                        // #endregion ----[ Friend Btn ]--------------------------------------------------------------------------------
                                    }

                                    {
                                        // #region ----[ Friend Btn ]-------------------------------------------------------------------------------------
                                        <props.skeleton.OverlayBox onClick={() => handleBackToUsers()} params={{  
                                            alignitems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 1)', 
                                            borderradius: '20px',
                                            button: true,
                                            color: 'black',
                                            cursor: 'pointer',
                                            fontsize: '14px',
                                            height: '40px',
                                            left: '20px',
                                            justifycontent: 'center', 
                                            padding: '0px',                                                 
                                            top: '20px', 
                                            width: '120px'
                                        }}>
                                            {LookupLicon("BackIcon")} Back to users
                                        </props.skeleton.OverlayBox>
                                        // #endregion ----[ Friend Btn ]--------------------------------------------------------------------------------
                                    }
                                </props.skeleton.ImageContainer>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                        // #endregion =[ Top Header ]====================================================                            
                    }

                    <Spacer length={3} />

                    {
                        // #region =[ Two column ]=======================================================
                        <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={12} sm={12} md={2.85}>
                                {
                                // #region ===[ Desktop ]==========================================================
                                <props.skeleton.CustomGrid container spacing={0} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                                {
                                    (Modules.length > 0)
                                    ? (
                                        Array.from(Modules).map((elm, key) => {
                                            return (
                                                (elm.location === "left")
                                                ? (
                                                    <props.skeleton.CustomGrid key={key} item xs={12}>
                                                        {
                                                            elm.componentCode
                                                        }
                                                    </props.skeleton.CustomGrid>
                                                )
                                                : null
                                            )
                                        })
                                    )
                                    : null
                                }
                                </props.skeleton.CustomGrid>
                                // #endregion ===[ Desktop ]=======================================================
                                }

                                {
                                // #region ===[ Mobile ]==========================================================
                                <props.skeleton.CustomGrid container spacing={0} sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' } }}>
                                {
                                    (Modules.length > 0)
                                    ? (
                                        Array.from(Modules).map((elm, key) => {
                                            return (
                                                (elm.location === "left")
                                                ? (
                                                    <props.skeleton.CustomGrid key={key} item xs={12}>
                                                        {
                                                            elm.componentCode
                                                        }
                                                    </props.skeleton.CustomGrid>
                                                )
                                                : (
                                                    (elm.location === "right")
                                                    ? (
                                                        <props.skeleton.CustomGrid key={key} item xs={12} sx={{ marginTop: "10px" }}>
                                                            {
                                                                elm.componentCode
                                                            }
                                                        </props.skeleton.CustomGrid>
                                                    )
                                                    : null
                                                )
                                            )
                                        })
                                    )
                                    : null
                                }
                                </props.skeleton.CustomGrid>
                                // #endregion ===[ Mobile ]=======================================================
                                }
                            </props.skeleton.CustomGrid>

                            <props.skeleton.CustomGrid item xs={11.86} sm={11.9} md={9} sx={{ 
                                marginLeft: { xs: '0px', sm: '0px', md: '10px', lg: '10px' },
                                marginTop: { xs: '15px', sm: '15px', md: '0px', lg: '0px' }, 
                                //border: 'thin solid red' 
                            }} rounded="true" roundedamt="15px" border="border1">
                                <props.skeleton.CustomTabs
                                    orientation="horizontal"
                                    centered
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="Horizontal tab"
                                    sx={{ 
                                        borderRight: 1, 
                                        borderColor: 'divider',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    {
                                        tabsections.map((tab) => {
                                            return (
                                                <props.skeleton.CustomTab key={tab.id} label={tab.title} {...allyProps(tab.id)} sx={{
                                                    flex: 1,
                                                    maxWidth: 'none'
                                                }} />
                                            )
                                        })
                                    }
                                    
                                </props.skeleton.CustomTabs>

                                {
                                Array.from(tabsections).map((ele, index) => {
                                    return (
                                        <TabPanel key={ele+index} value={tabValue} index={index} skeleton={props.skeleton}>
                                            {/* #region --[ Tab Nuget ]-------------------------------------------- */}
                                            <props.skeleton.CustomGrid container spacing={0} sx={{ padding: '0px', textAlign: 'left' }}>
                                                <props.skeleton.CustomGrid item xs={11.86} sm={11.9} md={11.94}>
                                                    {/* #region --[ Tab Inner Nuget ]------------------------------ */}
                                                    <props.skeleton.CustomGrid container spacing={0} direction={"row"} justifyContent={"stretch"} alignItems={"stretch"}>
                                                        {
                                                            (ele.enabled) 
                                                            ? (
                                                                Array.from(ele.modules).map((elem, key2) => {
                                                                    return (
                                                                        /*
                                                                        <props.skeleton.CustomTypography variant="body1">
                                                                            {`Module #${elem.id} - ${elem.name}`}
                                                                        </props.skeleton.CustomTypography>
                                                                        */
                                                                        (elem.enabled) 
                                                                        ? (
                                                                            <div key={key2}>
                                                                            {
                                                                                elem.component
                                                                            }
                                                                            </div>
                                                                        ) 
                                                                        : null
                                                                    )
                                                                })
                                                            )
                                                            : null                                                            
                                                        }                                                                                                                                                                  
                                                    </props.skeleton.CustomGrid>
                                                    {/* #endregion ------------------------------------------------ */}
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ---------------------------------------------------------*/}
                                        </TabPanel> 
                                    )
                                })
                                }
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                        // #endregion =[ Top Column ]====================================================
                    }
                </props.skeleton.CustomGrid>
                // #endregion ==[ Column: Left ]=========================================================
            }

            {
            // #region ==[ Column: Right - desktop ]=================================================
            <props.skeleton.CustomGrid item xs={3} sx={{ padding: '10px', display: { xs: 'none', sm: 'none', md: 'none', lg: 'inline' } }}>
                <props.skeleton.CustomGrid container spacing={0}>
                    {
                        (Modules.length > 0)
                        ? (
                            Array.from(Modules).map((elm, key) => {
                                return (
                                    (elm.location === "right")
                                    ? (
                                        <props.skeleton.CustomGrid key={key} item xs={12}>
                                            {
                                                elm.componentCode
                                            }
                                        </props.skeleton.CustomGrid>
                                    )
                                    : null
                                )
                            })
                        )
                        : null
                    }
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomGrid>
            // #endregion ==[ Column: Right ]========================================================
            }
            </props.skeleton.CustomGrid>                        
            // #endregion ===[ Section: Main Content ]=======================================================      
        }
        </>
    )
}