// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import React, { lazy, Suspense, useState, useEffect, useCallback, useIsFocused } from 'react'

//import {Helmet} from 'react-helmet'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth, useCategory, useCode, useNavigationitem, usePost, useSetting, useTag } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate, useLocation } from 'react-router-dom'

// Import Helmet
import { Helmet } from 'react-helmet'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import utils
// eslint-disable-next-line
import { settingLookup } from '../middleware/utils'

// Import from React dom
import { useParams } from 'react-router-dom'

// Import the GlobalContext
//import { GlobalContext } from '../context/globalContext'

// Import MUI components
import { Typography } from '@mui/material'

// Import GTNS
import useGlobalToastNotifications from '../middleware/GTNS.js'

// #startregion ---------------[ COMPONENTS ]-----------------------------------------------------------------
import Header from './Header'
import HeaderOnlyTS from './HeaderOnlyTS'
import Footer from './Footer'
import Layout from './Layout'
// #endregion -----------------------------------------------------------------------------------------------

// < 2.8.0 Version - hardcoding pages
// #region -----------------[ Partial Pages ]----------------------------------------------------------------
// Dashboard
import Account from '../partials/Dashboard/Account'
import All from '../partials/Dashboard/All'
import Detail from '../partials/Dashboard/Detail'
import New from '../partials/Dashboard/New'
import Overview from '../partials/Dashboard/Overview'
import Profile from '../partials/Dashboard/Profile'
import SettingsGlobal from '../partials/Dashboard/SettingsGlobal'
import SettingsMenu from '../partials/Dashboard/SettingsMenu'
import SettingsUser from '../partials/Dashboard/SettingsUser'

// Main
import Home from '../partials/Main/Home'
import About from '../partials/Main/About'
import API from '../partials/Main/API'
import Post from '../partials/Main/Post'
import Category from '../partials/Main/Category'
import Tag from '../partials/Main/Tag'

// Sys
import ForgotPassword from '../partials/Sys/ForgotPassword'
import Login from '../partials/Sys/Login'
import PublicProfileComponent from '../partials/Sys/PublicProfile'
import Register from '../partials/Sys/Register'
import ResetPassword from '../partials/Sys/ResetPassword'
import VerifyEmail from '../partials/Sys/VerifyEmail'

// Other
import Other from '../partials/Other.jsx'
// #endregion -----------------------------------------------------------------------------------------------

// ----[ import themes ]------------------------------------------------------------------------------
// ---[ Skeleton ]------------------------------------------------------------------------------------
// -- Version 1 - Hardcode each theme ----------------------------------------------------------------

// Import globalStyles elements
//import * as SkeletonGlobalTheme1 from '../themes/Darwinian2023/skeleton/global'
//import * as SkeletonGlobalTheme2 from '../themes/Templar2024/skeleton/global'

// ---------------------------------------------------------------------------------------------------

// -- Version 2 - Dynamic control w/ JSON data -------------------------------------------------------
import { themes } from '../themes/Themes'
import useE from '../middleware/hooks.js'
import { Spacer } from './Addon/Spacer.jsx'

// Import globalStyles elements
const buildThemeSkeletonsDashComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/dash'))
const buildThemeSkeletonsGlobalComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/global'))
const buildThemeSkeletonsMainComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/main'))
const buildThemeSkeletonsSysComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/sys'))

const SkeletonComponents = {
    dash: {        
        ...buildThemeSkeletonsDashComponents
    },
    global: {
        ...buildThemeSkeletonsGlobalComponents
    },
    main: {        
        ...buildThemeSkeletonsMainComponents
    },
    sys: {
        ...buildThemeSkeletonsSysComponents
    }
}

// DEBUG: Display the skeletons
//console.log(SkeletonGlobalTheme1)
//console.log(buildThemeSkeletonsGlobalComponents[0])
// ---------------------------------------------------------------------------------------------------

// Version 3 - Loaded themes from database using a component and useEffect() to check for themes
// ???
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------

// #startregion ---------------[ FUNCTIONS ]-----------------------------------------------------------------
function CheckDex(primary, secondary) {
    //console.log(primary+" "+secondary)
    switch (primary) {
        case 'Dashboard':
            switch (secondary) {                
                case 'Account': return false
                case 'All': return false
                case 'Detail': return false
                case 'New': return false
                case 'Overview': return false                
                case 'Profile': return false
                case 'SettingsGeneral': return false
                default: return false
            }
        case 'Main':
            switch (secondary) {
                case 'Home': return false
                
                case 'About': return false
                case 'about': return false

                case 'API': return false
                case 'api': return false

                case 'Post': return false 
                case 'Posts': return false
                case 'post': return false 
                case 'posts': return false

                case 'Categories': return false
                case 'Category': return false
                case 'categories': return false
                case 'category': return false

                case 'Tags': return false
                case 'Tag': return false
                case 'tags': return false
                case 'tag': return false
                
                default: return false
            }
        case 'Sys': 
            switch (secondary) {
                case 'ForgotPassword': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'Login': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'PublicProfile': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'Register': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'ResetPassword': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'VerifyEmail': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                default: return false
            }
        default: return true
    }        
}

function RenderHeaderThemeSwitcher(globalskeleton, skeleton, Modules, settingServerAPIBaseUrl, license) {
    return (
        <>
        <HeaderOnlyTS globalskeleton={globalskeleton} skeleton={skeleton} settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} license={license} />
        </>
    )
}

function RenderNavbarDrawer(globalskeleton, skeleton, Modules, primary, secondary, settingServerAPIBaseUrl, license) {
    //console.log(skeleton)
    //console.log(license)
    return (
        <>
        <Header globalskeleton={globalskeleton} skeleton={skeleton} settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} primary={primary} secondary={secondary} license={license} />        
        </>
    )
}

function RenderOther(skeleton, Modules, primary, secondary, tertiary) {
    return (
        <Other skeleton={skeleton} Modules={Modules} primary={primary} secondary={secondary} tertiary={tertiary} />
    )
}

function RenderPartialPage(globalskeleton, skeleton, Modules, primary, secondary, tertiary, settingServerAPIBaseUrl, systemMode, currentUser = null) {
    //console.log("inside render partial page: "+Modules?.profile?.userName)

    //console.log("primary: "+primary)
    //console.log("secondary: "+secondary)
    //console.log("teritary: "+teritary)

    if (systemMode !== "maintenance") {
        switch (primary) {
            case 'Dashboard':
                switch (secondary) {                
                    case 'Account': return (<Account settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                    case 'All': return (<All settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                    case 'Detail': return (<Detail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                    case 'New': return (<New settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                    case 'Overview': return (<Overview settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} tertiary={tertiary} />)                
                    case 'Profile': return (<Profile settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} tertiary={tertiary} />)                
                    case 'SettingsGlobal': return (<SettingsGlobal settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                    case 'SettingsMenu': return (<SettingsMenu settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                    case 'SettingsUser': return (<SettingsUser settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                    default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                }
            case 'Main':
                switch (secondary) {
                    case 'Home': return (<Home settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                    case 'About': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'about': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)
    
                    case 'API': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'api': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)
    
                    case 'Post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                    case 'Posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                    
                    case 'Category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                    case 'Categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
    
                    case 'Tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                    case 'Tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                    default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                }
            case 'Sys': 
                switch (secondary) {
                    case 'ForgotPassword': return (<ForgotPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                    case 'Login': return (<Login settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                    case 'PublicProfile': return (<PublicProfileComponent settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} id={tertiary} />)
                    case 'Register': return (<Register settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)                
                    case 'ResetPassword': return (<ResetPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                    case 'VerifyEmail': return (<VerifyEmail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                    default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                }
            default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
        }
    } else {
        if (currentUser) {
            switch (primary) {
                case 'Dashboard':
                    switch (secondary) {                
                        case 'Account': return (<Account settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                        case 'All': return (<All settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                        case 'Detail': return (<Detail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                        case 'New': return (<New settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                        case 'Overview': return (<Overview settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} tertiary={tertiary} />)                
                        case 'Profile': return (<Profile settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} tertiary={tertiary} />)                
                        case 'SettingsGlobal': return (<SettingsGlobal settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                        case 'SettingsMenu': return (<SettingsMenu settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                        case 'SettingsUser': return (<SettingsUser settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                        default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                    }
                case 'Main':
                    switch (secondary) {
                        case 'Home': return (<Home settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                        case 'About': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'about': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)
        
                        case 'API': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'api': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)
        
                        case 'Post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                        case 'Posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                        
                        case 'Category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                        case 'Categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
        
                        case 'Tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                        case 'Tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                        default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                    }
                case 'Sys': 
                    switch (secondary) {
                        case 'ForgotPassword': return (<ForgotPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                        case 'Login': return (<Login settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                        case 'PublicProfile': return (<PublicProfileComponent settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} primary={primary} secondary={secondary} id={tertiary} />)
                        case 'Register': return (<Register settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)                
                        case 'ResetPassword': return (<ResetPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                        case 'VerifyEmail': return (<VerifyEmail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                        default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
                    }
                default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
            }
        } else {
            return (
                <>
                    <Spacer length={4} />
                    <skeleton.StyledContainer sx={{ paddingTop: { xs: '9vh', sm: '25vh', md: '22vh', lg: '19vh' } }} component="main" maxWidth="lg">
                        <skeleton.StyledPaper elevation={3} rounded="true" rounedamt="15px" border="border1">
                            {                           
                                <skeleton.CustomGrid container>
                                    <skeleton.CustomGrid item xs={12} sm={6}>
                                        <skeleton.LRImage
                                            alt="Image Alt Text"
                                            src="https://t3.ftcdn.net/jpg/05/58/61/32/360_F_558613274_Z1zbjnHZKjpnTvvsjfZzYXk2TIeUl54a.jpg"
                                        />
                                    </skeleton.CustomGrid>
    
                                    <skeleton.CustomGrid item xs={12} sm={6} center="true" sx={{ 
                                        paddingBottom: { xs: '5vh', sm: '5vh', md: '10vh', lg: '11vh' },
                                        paddingLeft: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' }, 
                                        paddingRight: { xs: '5vh', sm: '5vh', md: '9vh', lg: '11vh' },
                                        paddingTop: { xs: '5vh', sm: '5vh', md: '5vh', lg: '10vh' }
                                    }}>
                                        <skeleton.CustomGrid icon="true" item xs={12} sx={{textAlign: 'center' }}>
                                            
                                        </skeleton.CustomGrid>
                                        <skeleton.CustomGrid item xs={12} center="true">
                                            <skeleton.CustomTypography nobg="true" component="h1" variant="h5">
                                                Pardon our dust but this site is under maintenance mode. Check back later.
                                            </skeleton.CustomTypography>
    
                                            
                                        </skeleton.CustomGrid>
                                    </skeleton.CustomGrid>                                                                                   
                                </skeleton.CustomGrid>                           
                            }
                        </skeleton.StyledPaper>
                    </skeleton.StyledContainer>
                </>
            )
        }
    }  
}

function loadUserBGImage (userName, image, settingServerAPIBaseUrl ) {
    //console.log(settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image)
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}
// #endregion ----------------------------------------------------------------------------------------------------

// #region -------------------[ VARIABLES ]-----------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const FullContainer = (props) => {
    let element

    if (props.skeleton) {
        //console.log(props.skeleton.FullContainer)
        element = (
            <props.skeleton.FullContainer>{ props.children }</props.skeleton.FullContainer>
        )
    } else {
        element = (
            <div><Typography variant='h2' fontFamily={'Borel'}><br />Loading...</Typography></div>
        )
    }
    //return props.skeleton.FullContainer
    return element
}

const Content = (props) => {
    let element
    
    if (props.skeleton) {
        //console.log(props.skeleton.Content)
        //console.log(props.children.props.primary)
        //console.log(props.children.props.secondary)
        //console.log(props.children.props.settingServerAPIBaseUrl)
        //console.log(props.params.theuser)
        element = (
            (props.children.props.primary && props.children.props.secondary) 
            ? (
                (props.children.props.primary === "Dashboard" && props.children.props.secondary === "Profile")
                ? <props.skeleton.Content profile="true" profileBG={
                    (props.params.theuser)
                    ? (
                        (props.params.theuser.bgColor)
                        ? props.params.theuser.bgColor
                        : null
                    )
                    : null
                } profileBGImage={
                    (props.params.theuser)
                    ? (
                        (props.params.theuser.bgImage !== "no-bgimage.png")
                        ? loadUserBGImage(props.params.theuser.userName, props.params.theuser.bgImage, props.children.props.settingServerAPIBaseUrl)
                        : window.location.origin+"/user/thumbs/no-bgimage.png"
                    )
                    : null
                }>{ props.children }</props.skeleton.Content>
                : (
                    (props.children.props.primary === "Sys" && props.children.props.secondary === "PublicProfile")
                    ? <props.skeleton.Content profile="true" profileBG={
                        (props.params.theuser)
                        ? (
                            (props.params.theuser.bgColor)
                            ? props.params.theuser.bgColor
                            : null
                        )
                        : null
                    } profileBGImage={
                        (props.params.theuser)
                        ? (
                            (props.params.theuser.bgImage !== "no-bgimage.png")
                            ? loadUserBGImage(props.params.theuser.userName, props.params.theuser.bgImage, props.children.props.settingServerAPIBaseUrl)
                            : window.location.origin+"/user/thumbs/no-bgimage.png"
                        )
                        : null
                    }>{ props.children }</props.skeleton.Content>
                    : <props.skeleton.Content>{ props.children }</props.skeleton.Content>
                )
            )
            : <props.skeleton.Content>{ props.children }</props.skeleton.Content>
        )
    } else {
        element = (
            <div>Loading Content...</div>
        )
    }
    //return props.skeleton.Content
    return element
}

// ------------------------[ Functions ]--------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------

export default function Board (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // Get the params
    const { p, s, t } = useParams()

    //console.log(p)
    //console.log(s)
    //console.log(t)

    // Get the page/subpage requested from the props
    let primary = props.primary || p
    let secondary = props.secondary || s
    let tertiary = props.tertiary || t

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    let slice

    //console.log(tertiary+", "+secondary+", "+primary)

    // There could be a better way to do this
    // Top-down logic - tertiary is for dashboard and primary is for frontend
    if (tertiary) {
        slice = tertiary
    } 
    // don't need to check secondary - as it's not here.
    /* else if (secondary) {
        slice = secondary
    }*/ 
    else if (primary) {
        slice = primary
    }    

    //console.log(slice)

    // Set up variables
    let resources
    let query

    // Set all the resources from the contexts
    const resourcesAuth = useAuth()
    const resourcesCategory = useCategory()
    const resourcesCode = useCode()
    const resourcesNavigationitem = useNavigationitem()
    const resourcesPost = usePost()
    const resourcesSetting = useSetting()
    const resourcesTag = useTag()

    // Conditional setting resources
    switch (slice) {
        case 'Account': 
            // eslint-disable-next-line 
            query=""
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'Categories':
            // eslint-disable-next-line 
            query="categories"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'Codes':
            // eslint-disable-next-line 
            query="codes"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'post':
            // eslint-disable-next-line 
            query="longposts"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'posts':
            // eslint-disable-next-line 
            query="longposts"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'Posts':
            // eslint-disable-next-line 
            query="longposts"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'tags': 
            // eslint-disable-next-line     
            query="tags"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        case 'Tags': 
            // eslint-disable-next-line 
            query="tags"
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
        default: 
            /* ??? No resource; what are we doing here? */
            // eslint-disable-next-line 
            query=""
            resources={resourcesAuth,resourcesCategory,resourcesCode,resourcesNavigationitem,resourcesPost,resourcesSetting,resourcesTag}
        break
    }

    // Get from contexts
    // eslint-disable-next-line
    const {
        // Variables
        currentUser,
        isAuthenticated,
        idleTime,
        isModulesSet,
        license,
        Modules,
        PublicProfile,
        
        // Functions
        CheckLicense,
        getProfile,
        setIdleTime,
        setIsModulesSet,
        setModules,        
    } = useAuth()   

    const {
        // Variables
        settings,
        variables,

        // Functions
        getSettingsPublically,   
        setVariables
    } = useSetting()

    //const {
        // Variables
        
        // Functions
        
    //} = useNavigationitem()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------

    // Set Modules - for passing props down through the containers (rarely use this; use context to do it right)
    // eslint-disable-next-line
    //const [ Modules, setModules ] = useState([])
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    // State Management for Auth
    useEffect(() => {      
        if (!isAuthenticated) {
            // Set up variables
            const token = localStorage.getItem('token')
            
            if (token) {
                const decode = JSON?.parse(atob(token?.split('.')[1]))
                // Does work...yet
                //const decode = Buffer.from(token, 'utf8').toString('base64')
        
                // Debug: Display decode contents
                //console.log(decode)
        
                // Check for actual expiration
                if (decode) {
                    if (decode.exp * 1000 < new Date().getTime())
                    {
                        // Remove token
                        localStorage.removeItem('token')
                    } else {
                        getProfile()
                    }
                }
            }
        }        
    }, [ getProfile, isAuthenticated ])

    // State Management for Modules
    useEffect(() => {
        if (!isModulesSet) {
            setModules({...Modules, 
                base: {
                    Category: {
                        use: true,
                        sections: ['Posts'],
                    },
                    Code: {
                        use: true,
                        sections: ['Codes'],
                    },
                    CodeType: {
                        use: true,
                        sections: ['Codes']
                    },
                    CodeUses: {
                        use: true,
                        sections: ['Codes']
                    },
                    CodeUsedCount: {
                        use: true,
                        sections: ['Codes'],
                    },
                    Content: {
                        use: true,
                        sections: ['Posts']
                    },
                    FeaturedImage: {
                        use: true,
                        sections: ['Posts']
                    },
                    Slug: {
                        use: true,
                        sections: ['Categories', 'Codes', 'Posts', 'Tags'],
                    },
                    Snippet: {
                        use: true,
                        sections: ['Posts']
                    },   
                    TabMan: {
                        use: true,
                        showOnDetail: false,
                        sections: ['Posts'],
                    }, 
                    Tag: {
                        use: true,
                        sections: ['Posts']
                    },
                    Title: {
                        use: true,
                        sections: ['Categories', 'Code', 'Posts', 'Tags']
                    },
                    TopGizmo: {
                        use: true,
                        sections: ['Categories', 'Posts', 'Tags']
                    },
                    TopGizmoNoVisibility: {
                        use: true,
                        sections: ['Codes']
                    },
                    TopGizmoNoVisibilityNoNamespace: {
                        use: false,
                        sections: ['Codes']
                    }
                },
                plugins: {                    
                    LoremIpsum: {
                        use: true,
                        sections: ['Posts']
                    }
                }
            })

            setIsModulesSet(true)
        }
    }, [ isModulesSet, Modules, setModules, setIsModulesSet ])

    useE(5000, false, CheckLicense, { args: { domain: window.location.host }, isAuthenticated })
    
    // State Management for Auth
    //useEffect(() => {        
        //AuthCheck(Authed, currentUser, getProfile, getGuestProfile, profile, setAuthed, setProfile)

    //}, [ Authed, currentUser, getProfile, getGuestProfile, profile, setAuthed, setProfile ])

    // Update 2.3.51 - GOAL: Replace all instance creation of AuthCheck on every component and set up one AuthCheck and pass through the components via props
    // State management for Modules
    //useEffect(() => { 
    //    if (profile) {
            // Create the Auth object
            /*
            setModules({...Modules, 
                Authed: Authed,
                currentUser: currentUser,
                Editing: Editing,
                getProfile: getProfile,
                isDisabled: isDisabled,
                isLoggingOut: isLoggingOut,
                LoggingIn: LoggingIn,
                loginUser: loginUser,
                MovingToTrash: MovingToTrash,
                NewPostSlug: NewPostSlug,
                PermDeletePost: PermDeletePost,
                PermDeletePostSlug: PermDeletePostSlug,
                Posting: Posting,
                PostingSent: PostingSent,
                profile: profile,
                registerUser,
                Sending: Sending,
                setEditing: setEditing,
                setLoggingIn: setLoggingIn,
                setIsDisabled: setIsDisabled, 
                setIsLoggingOut: setIsLoggingOut,  
                setMovingToTrash: setMovingToTrash, 
                setNewPostSlug: setNewPostSlug, 
                setPermDeletePost: setPermDeletePost,
                setPermDeletePostSlug: setPermDeletePostSlug,           
                setPosting: setPosting,
                setPostingSent: setPostingSent,
                setProfile: setProfile,                
                setSending: setSending,
                setUpdating: setUpdating,
                setUpdatingCategory: setUpdatingCategory,
                setUpdatingPost: setUpdatingPost,
                setUpdatingPostSent: setUpdatingPostSent,
                Updating: Updating,
                UpdatingCategory: UpdatingCategory, 
                UpdatingPost: UpdatingPost,
                UpdatingPostSent: UpdatingPostSent,
                updateUser: updateUser,
            })
            */
        //}
        // eslint-disable-next-line
    //}, [ 
        /*
        Authed, 
        currentUser, 
        Editing, 
        getProfile,
        isDisabled,
        isLoggingOut, 
        LoggingIn, 
        loginUser,
        MovingToTrash, 
        NewPostSlug, 
        Posting, 
        PostingSent, 
        profile, 
        registerUser, 
        Sending, 
        setEditing,
        setIsLoggingOut, 
        setNewPostSlug, 
        setProfile, 
        setPosting, 
        setPostingSent, 
        setModules,
        updateUser, 
        Updating, 
        UpdatingCategory, 
        UpdatingPost, 
        UpdatingPostSent 
        */
    //])

    // State rendering for debugging
    useEffect(() => {
        //console.log(license)
        //console.log(resourcesAuth)
    }, [  ])

    props = {
        ...props,
        resources
    }

    useGlobalToastNotifications(props)

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    

    // Contingency for discovering /u/ (the public profile URI)
    if (primary === "@") {        
        primary = "Sys"
        tertiary = secondary
        secondary = "PublicProfile"        
    }

    //console.log("what is primary? "+primary)

    // Handle DEX - NOTE: How there are && being used and not ||; Logically this makes sense as all three or more would have to match to execute
    if (primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") {
        // Loading something like "blog/id_of_post"
        //console.log("loading something other than sections; therefore Main")

        // Set the teritary to what was secondary
        tertiary = secondary

        if (primary) {
            // Set the primary (something like "blog") to the secondary
            secondary = primary
        } else {
            // Detected a "blank" or missing first param - usually if it's the home page or index; so this is Home
            secondary = "Home"
        }
        
        // Set what is in secondary to primary
        primary = "Main"        
    }

    let FullDex = CheckDex(primary, secondary)

    //console.log(FullDex)

    //console.log(primary+" "+secondary+" "+tertiary)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------ 
    
    // State Rendering for getting settings
    /*
    useEffect(() => {
        if (!settings) {
            // Get the settings from database live
            getSettingsPublically(null, 'any', 'active', 'ASC', 'title')
        } else {
            setTimeout(() => {
                getSettingsPublically(null, 'any', 'active', 'ASC', 'title')
            }, 30000)
        }

        // NOTE: In order to live update the settings, settings is required in the dependancy. Idk if this will cause a maximum depth. 
        //       we will find out.
        // eslint-disable-next-line
    }, [ 
        settings
    ])
    */

    useE(30000, false, getSettingsPublically, { args: { limit: null, namespace: 'any', status: 'active', order: 'ASC', orderBy: 'title' } })

    /* DEPRECATED < 2.7.2 - We no longer set the settings hardly instead we get the setting using:
    settingLookup('<settingName>', variables)
    
    NOTE: DON'T FORGET TO INCLUDE 
    
        import { settingLookup } from '../middleware/utils'
    
    on the component you want to get a setting. And don't forget to add:

        const {
            // Variables
            variables,

        } = useSetting()

    on the component you want to get a setting.


    // State management for settings - hardcoding (not as efficient)
    useEffect(() => {
        if (settings) {
            // #region ----------------[ Set Settings ]--------------------------------------------------------------------------------
            Array.from(settings).forEach((ele) => {
                switch(ele.name) {
                    case 'aboutpageSingletOne':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('aboutpageSingletOne')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setsettingAboutpageSingletOne(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break
                    case 'MPressVersion':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('MPressVersion')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingMPressVersion(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'ServerAPIBaseUrl':
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('ServerAPIBaseUrl')                            
                        }
                        
                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingServerAPIBaseUrl(ele.value)
                        }
                    break
                    
                    case 'SiteSlogan':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the slogan of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (SiteSlogan) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SiteSlogan')
                            //console.log('retrieved setting by name')
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSiteSlogan(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'SiteTitle':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the title of the site via the database setting
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (SiteSlogan) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SiteTitle')
                            //console.log('retrieved setting by name')
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSiteTitle(ele.value)
                        }

                        // Set the title of the document
                        //document.title = ele.value + settingSiteSlogan
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'SystemName':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SystemName')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSystemName(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'themeName':
                        // 2.7.0 - Set the theme of the site via database
                        // ----- Check to see if there is a theme --------------------------------------------------------------------
                        // Get setting (themeName) from database as the currentSetting
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('themeName')                            
                        }

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingThemeName(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'themeNumber':
                        // 2.7.0 - Set the theme number of the site via database; not the name
                        // ----- Check to see if there is a theme --------------------------------------------------------------------
                        // Get setting (themeName) from database as the currentSetting
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('themeNumber')                            
                        }

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingThemeNumber(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    default: 
                    // Nothing to set
                    break
                }
            })
            // #endregion --------------------------------------------------------------------------------------------------------------
        }
        
    }, [ 
        // Variables
        clearCurrentSetting, 
        currentSetting, 
        settings, 
        settingCreated,

        // Functions
        getSettingByName,   
        setsettingAboutpageSingletOne,
        setSettingServerAPIBaseUrl,      
        setSettingMPressVersion, 
        setSettingSiteSlogan, 
        setSettingSiteTitle, 
        setSettingSystemName,
        setSettingThemeName,
        setSettingThemeNumber
    ])
    */

    // VERSION: 2.7.2
    // State management for setting the settings to be used
    useEffect(() => {
        if (settings) {
            Array.from(settings).forEach((setting) => {
                //console.log(setting.name)
                //console.log(setting.value)

                setVariables(setting.name, setting.value)
            })
        }
        // It will scream and say setVariables needs to be in dependency; turn off eslint highlighting for now
        // eslint-disable-next-line
    }, [ settings ])

    // DEPRECATED < 2.8.2 : State management for setting meta tags => HANDLE WITH HELMET NOW
    /*useEffect(() => {
        if (variables) {
            if (settingLookup('SiteTitle', variables) && settingLookup('SiteSlogan', variables)) {
                document.title = settingLookup('SiteTitle', variables) + " - " + settingLookup('SiteSlogan', variables)
            } else if (settingLookup('SiteTitle', variables)) {
                document.title = settingLookup('SiteTitle', variables)
            }

            // Update Meta
            // Site Description
            if (settingLookup('SiteDescription', variables)) {
                //document.getElementsByTagName("META")[2].content = settingLookup('SiteDescription', variables)      
            }

            // Site Description
            if (settingLookup('SiteTags', variables)) {
                //document.getElementsByTagName("META")[3].content = settingLookup('SiteTags', variables)                                
            }
        }
        
    }, [ variables ])
    */

    // State Rendering for currentUser - required to keep currentUser set
    useEffect(() => {
        // Check for authentication
        if (isAuthenticated) {
            if (!currentUser) {
                getProfile()    
            }
        }
    }, [ currentUser, getProfile, isAuthenticated ])

    // State Management for updating idling time    
    useEffect(() => {
        let tempIdleTime = idleTime

        setInterval(() => {
            tempIdleTime++

            setIdleTime(tempIdleTime)
        }, 1000)

        // eslint-disable-next-line
    }, [ idleTime ])

    const location = useLocation()

    // Listen for location change in navigate
    useEffect(() => {
        // Reset Idle time
        setIdleTime(0)
        
        // eslint-disable-next-line
    }, [ location ])
    
    // State Rendering for debugging
    useEffect(() => {        
        //console.log("SiteTitle: "+settingLookup('SiteTitle', variables))
        //if (settings) console.log(settings)
        //if (isAuthenticated) {console.log("isAuthenticated: "+isAuthenticated)}       
        //if (currentUser) {console.log("currentUser: "+currentUser.userName)}
        //if (settingMPressVersion) {console.log("MPressVersion: "+settingMPressVersion)}
        //if (settingThemeName) {console.log("themeName: "+settingThemeName)}
        //if (settingThemeNumber) {console.log("themeNumber: "+settingThemeNumber)}
    }, [ 
        idleTime
        //settings,
        //variables
        //currentUser, 
        //isAuthenticated, 
        //settingMPressVersion
        //settingThemeName
        //settingThemeNumber
     ])
    // #endregion -----------------------------------------------------------------------------------------------

    // Version 2.8.0 - Set themeNumber based on the themeName and not from separate variable in database
    let themeNumber = 0

    // Conditional search through themes to get themeNumber
    if (themes) {
        themes.forEach((ele) => {
            if (ele.name === settingLookup('themeName', variables)) {
                themeNumber = ele.id
            }    
        })
    }
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>  
        <Helmet>
            <title>
                {
                    (secondary !== "Home") 
                    ? (
                        // Determine if a tertiary is found
                        (tertiary) 
                        ? secondary+" "+(
                            
                            // Hardcode
                            //((tertiary === "Posts") && "Post" ) ||
                            //((tertiary === "Categories") && "Category" ) ||
                            //((tertiary === "Tags") && "Tag" ) ||
                            //((tertiary === "Codes") && "Code" ) ||

                            // Based on ending 's"
                            (
                                ((secondary === "New") && (
                                    (tertiary.charAt(tertiary.length - 1) === "s")) && (
                                        // If "s" or "ies"
                                        // eslint-disable-next-line
                                        (tertiary.charAt(tertiary.length - 3) === "i") && tertiary.substr(0, tertiary.length - 3) + "y" ||
                                        tertiary.substr(0, tertiary.length - 1)
                                    )
                                )
                                ||
                                ((secondary === "Detail") && (
                                    (tertiary.charAt(tertiary.length - 1) === "s")) && (
                                        // If "s" or "ies"
                                        // eslint-disable-next-line
                                        (tertiary.charAt(tertiary.length - 3) === "i") && " of " + tertiary.substr(0, tertiary.length - 3) + "y" ||
                                        " of " + tertiary.substr(0, tertiary.length - 1)
                                    )
                                )
                                
                            ) ||

                            (tertiary)
                            )+" | "
                        : secondary+" | "
                    )
                    : ""
                }

                {settingLookup('SiteTitle', variables)+settingLookup('SiteSlogan', variables)}
            </title>
            <meta name="description" content={`${
                    // Global site description
                    settingLookup('SiteDescription', variables)

                    +

                    // Post (if detected) snippet
                    (
                        (secondary === "post") 
                        ? " | A post about: " + resourcesPost.currentSlice?.snippet
                        : ""
                    )
                }`} />
            <meta name="keywords" content={`${
                settingLookup('SiteTags', variables)
                
                +

                // Post (if detected) snippet
                (
                    (secondary === "post") 
                    ? "," + resourcesPost.currentSlice?.tags
                    : ""
                )
                }`} />
        </Helmet>
        <Layout themeName={settingLookup('themeName', variables)} themeNumber={themeNumber}>
            {
                // ---[ Header - Dashboard ]---------------------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Header - Main ]---------------------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Header - Sys ]---------------------------------------------------------------------------------
                (primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }
            
            {
                // ---[ Header ]---------------------------------------------------------------------------------
                ((primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") && SkeletonComponents.global[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables), license)
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Body ]-----------------------------------------------------------------------------------
                // ---[ Body - Dashboard ]-----------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]} params={{ theuser: currentUser || PublicProfile }}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables), settingLookup("systemMode", variables), currentUser)
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Body - Main ]----------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]} params={{ theuser: currentUser || PublicProfile }}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables), settingLookup("systemMode", variables), currentUser)
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {    
                // ---[ Body - Sys ]-----------------------------------------------------------------------
                (primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]} params={{ theuser: PublicProfile }}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables), settingLookup("systemMode", variables), currentUser)
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Dashboard ]---------------------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.dash[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Main ]---------------------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.main[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Sys ]---------------------------------------------------------------------------------
                ((primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) && 
                (
                    (secondary !== "Login" && secondary !== "login") && 
                    (secondary !== "Register" && secondary !== "register")) && 
                    (secondary !== "VerifyEmail" && secondary !== "verifyemail") && 
                    (secondary !== "ForgotPassword" && secondary !== "forgotpassword") && 
                    (secondary !== "ResetPassword" && secondary !== "resetpassword")) 
                ? (
                    <Footer skeleton={SkeletonComponents.sys[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Global ]---------------------------------------------------------------------------------
                ((primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") && SkeletonComponents.global[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.global[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }
        </Layout>
        </>
    )
}