export const tabs = {
    base: {
        background: "white",
        color: "black",
        indicatorColor: "black"
    },
    tab: {
        base: {
            background: "white",
            backgroundSelected: "#808080",            
            color: "black",
            colorSelected: "cyan"
        }
    }
}