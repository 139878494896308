// Import from react
import { useEffect, useCallback } from "react"

// Import Auth
import { useAuth } from '../middleware/contextHooks'

// Custom useEffect Hook
export default function useE(time, reqAuth, fn, req, dependencies) {
    const {
        // Functions        

        // Variables
        // eslint-disable-next-line
        idleTime
    } = useAuth() 

    //const [ id, setId ] = useState(null)
    //const [ type, setType ] = useState(null)

    // Step 1: Memorize the function
    const CB = useCallback(() => {
        // Function to call here
        if (req.args) {
            if (req.args.id) {
                //console.log(req.args.id)    

                if (req.args.id && req.args.type) {
                    //setId(req.args.id)
                    //setType(req.args.type)
    
                    // Apply the arguments
                    fn(req.args.id, req.args.type)
                }
                else if (req.args.id) {
                    //setId(req.args.id)
    
                    // Apply the arguments
                    fn(req.args.id)
                }
            } else {
                // ID NOT SET   
                if (req.args.userName && req.args.type && req.args.limit && req.args.namespace && req.args.status && req.args.order && req.args.orderBy) {
                    //setId(req.args.id)
                    //setType(req.args.type)                    
    
                    // Apply the arguments
                    fn(req.args.userName, req.args.type, req.args.limit, req.args.namespace, req.args.status, req.args.order, req.args.orderBy)
                } else if (req.args.type && req.args.limit && req.args.namespace && req.args.status && req.args.order && req.args.orderBy) {
                    //setId(req.args.id)
                    //setType(req.args.type)                    
    
                    // Apply the arguments
                    fn(req.args.type, req.args.limit, req.args.namespace, req.args.status, req.args.order, req.args.orderBy)
                } else if (req.args.type && req.args.namespace && req.args.status && req.args.order && req.args.orderBy) {
                    //setId(req.args.id)
                    //setType(req.args.type)
    
                    // Apply the arguments
                    fn(req.args.type, null, req.args.namespace, req.args.status, req.args.order, req.args.orderBy)
                } else if (req.args.limit && req.args.namespace && req.args.status && req.args.order && req.args.orderBy) {
                    //setId(req.args.id)
                    //setType(req.args.type)
    
                    // Apply the arguments
                    fn(req.args.limit, req.args.namespace, req.args.status, req.args.order, req.args.orderBy)
                } else if (req.args.namespace && req.args.status && req.args.order && req.args.orderBy) {
                    //setId(req.args.id)
                    //setType(req.args.type)
    
                    // Apply the arguments
                    fn(null, req.args.namespace, req.args.status, req.args.order, req.args.orderBy)
                } else if (req.args.domain) {
                    fn(req.args.domain)
                }
            }
        } else {
            // Function without arguments
            fn()
        }

        // fn is required to remember the function
        // req.args is required to keep track of the id
    }, [ fn, req.args ])

    // Step 2: Side Effect    
    useEffect(() => {  
        if (reqAuth) {
            if (req.isAuthenticated) {
                // Call the callback function with auth
                CB()
                
                // === DO NOT TOUCH ===============================================================================================
                // Set interval function
                const intervalCall = setInterval(() => {
                    CB()
                }, time)

                // Clear the interval
                return () => {
                    clearInterval(intervalCall)
                }        
                // ===============================================================================================================
            }            
        } else {
            // Call the callback function without auth
            CB()
                
            // === DO NOT TOUCH ===============================================================================================
            // Set interval function
            const intervalCall = setInterval(() => {
                CB()
            }, time)

            // Clear the interval
            return () => {
                clearInterval(intervalCall)
            }        
            // ===============================================================================================================
        }

    // eslint-disable-next-line
    }, [ dependencies ])
}